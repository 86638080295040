import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const RegisterSuccess = () => {
    return ( 
        <div className="register-success">
        <div className="col-12 subTitel text-center" style={{color : "#FCB503", fontSize : "1.35rem"}}><TranslatesContent contentKey="successful-password-reset"/>!</div>
        <p className="text-center">
        <TranslatesContent contentKey="to-claim-your-gift"/>
        </p>
        <a href="https://api.whatsapp.com/send?phone=94766384916&text=GIFT">
            <img style={{maxWidth : "220px"}} src="https://storage.googleapis.com/itonecdn/imgWeb/chat-on-whatsup.png" alt="" srcset="" /></a>
        </div>
     );
}
 
export default RegisterSuccess;