import { Fragment, useEffect, useState } from "react";
import { GetLanguages, setCurrentLanguage } from "../../DataStore";
import { connect } from "react-redux";
import { FLAG_IMAGES_URL } from "../../DataStore/ApiConstant";

const LanguageSwitcher = ({ Languages, getLanguages, setCurrentLanguage }) => {
  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Fragment>
      <div className="item align-self-center">
        <div className="btn-group">
          <button
            style={{ background: "transparent", border: "none" }}
            className="btn btn-secondary dropdown-toggle transparent-btn"
            type="button"
            id="defaultDropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            {Languages &&
              Languages.data &&
              Languages.data.data.map((language, key) => {
                return Languages.current_lang == language.code3 ? (
                  <img
                    key={key}
                    src={FLAG_IMAGES_URL + language.image}
                    alt="mdo"
                    width="32"
                    height="32"
                    className="rounded-circle"
                  />
                ) : (
                  ""
                );
              })}
          </button>
          <ul
            className="dropdown-menu dropdown-menu-dark"
            aria-labelledby="defaultDropdown"
          >
            {Languages &&
              Languages.data &&
              Languages.data.data.map((Language, key) => {
                return (
                  <li key={key}>
                    <a
                      style={{cursor: "pointer"}}
                      className="dropdown-item text-end"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentLanguage(Language.code3);
                      }}
                    >
                      {Language.lang} &nbsp;
                      <img
                        src={FLAG_IMAGES_URL + Language.image}
                        width="32"
                        height="32"
                      />
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToPros = (state) => {
  return {
    Languages: state.SiteLanguages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: () => dispatch(GetLanguages()),
    setCurrentLanguage: (lang) => dispatch(setCurrentLanguage(lang)),
  };
};

export default connect(mapStateToPros, mapDispatchToProps)(LanguageSwitcher);
