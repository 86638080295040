const initState = {
    loading : false,
    data : '',
    error : ''
}

export const BnkAccountReducer = (state = initState , action) => {
    switch (action.type) {
        case 'BANK_ACCOUNT_LOADING' : 
        return {
            ...state,
            loading : true
        }

        case 'BANK_ACCOUNT_SUCCESS' : 
        return {
            ...state,
            loading : false,
            data : action.data,
            err : ''
        }

        case 'BANK_ACCOUNT_ERR' :
            return {
                ...state,
                loading : false,
                data : '',
                error : action.err
            }

            default : return state;
    }

}