const initState = {
    users: [],
    loading: false,
    error: ''
}

export const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOADING_FETCH':
            return {
                ...state,
                loading: true
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                loading : false,
                users: action.payload,
                error :  ''
            }
            case 'FETCH_ERROR':
                return {
                    ...state,
                    loading :false,
                    users: [],
                    error: action.payload
                }
                default : return state;
    }

}