
import { SLOT_GAMES_DATA } from "../../DataStore/ApiConstant";
import GameTabs from "./GameTabs";
import SlotContent from "./SlotContent";
import {slotData} from '../../mockdata/data';
import {useSlotGames} from "../../services/slot";
import { useState,useEffect } from "react";

const SlotGames = SLOT_GAMES_DATA.slots;

const sData = slotData.slice(0 , 100);
const GameArray = {
  "evoplay" : "Evoplay",
  "play n go" : "play n go",
  "pgsoft" : "PGSoft",
  "habanero" : "Pragmatic Play"
}
const SlotsArea = ({ login, openPopup }) => {
  // const slotGames = useSlotGames();
  const [gameResult, setGameResult] = useState([]);
  const getKey= async (val='evoplay')=>{
    if(login){
      let filteredData =await sData.filter(item => item.MerchantName == GameArray[val])
      return setGameResult(filteredData) 
    }
    let filteredData =await sData.filter(item => item.MerchantName == GameArray[val])
      return setGameResult(filteredData.slice(0,10)) 
  }

  useEffect(() => {
    getKey()
  },[])

  return (
    <div
      className="container-fluid mt-lg-5"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <GameTabs games={SlotGames} getKey={getKey} slot={true}/>
      <SlotContent games={SlotGames} gameResult={gameResult} login={login} openPopup={openPopup} />
    </div>
  );
};

export default SlotsArea;
