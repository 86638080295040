import axios from "axios";
import { WITHDROWDATA_URL } from "../../../ApiConstant";

export const withdrowDataLoading = () => {
  return {
    type: "WITHDROW_LODING",
  };
};

export const withdrowDataSuccess = (data) => {
  return {
    type: "WITHDROW_SUCCESS",
    data: data,
  };
};

export const withdrowDataErr = (err) => {
  return {
    type: "WITHDROW_ERR",
    err: err,
  };
};

export const getWithdrowData = () => {
  return (dispatch) => {
    dispatch(withdrowDataLoading);
    axios
      .get(WITHDROWDATA_URL)
      .then((response) => {
        dispatch(withdrowDataSuccess(response.data));
      })
      .catch((err) => {
        dispatch(withdrowDataErr(err));
      });
  };
};
