import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
// import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { CONTACT_DETAILS } from "../../constants/index";

const ByHandPayment = () => {
  return (
    <div
      className="tab-pane fade"
      id="v-pills-settings"
      role="tabpanel"
      aria-labelledby="v-pills-settings-tab"
    >
      <div className="row">
        <div className="col-12 col-lg-7 col-md-7 col-sm-7 col-xl-7 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4 offset-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12">
                  <div className="row text-center">
                    <div className="col-12 mb-2 mb-lg-3">
                      <label
                        for="exampleFormControlInput1"
                        className="cashierNewTitel"
                      >
                        {" "}
                        <TranslatesContent contentKey="by-hand-cash-pickup" />
                      </label>
                    </div>

                    <div className="col-12">
                        <div className="newcashierButton">
                        <a href="https://wa.me/+94765992992" target="_blank">
                          <i
                            className="fa-brands fa-whatsapp"
                            style={{ fontSize: "25px" }}
                          ></i>{" "}
                          +94 770 000 000
                          </a>
                        </div>
                      
                    </div>
                    <div className="col-12 mt-2 mt-lg-3">
                    <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                      <img
                        src={IMAGE_BUCKET_URL + "By-hand-new.png"}
                        class="img-fluid zoom"
                        alt=""
                      ></img>
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <WhatsappButton /> */}
      </div>
    </div>
  );
};

export default ByHandPayment;
