import axios from "axios";
import { useEffect, useState } from "react";
import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import WhatsappButton from "./whatsappButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { fundTransfer } from "../../DataStore";
import { UserData } from "../../DataStore";

//   useEffect(() => {

//   }, []);

const Ewallet = () => {
    return (
      <>
        <div className="row p-4">
          <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0">
            <div className="row mb-3">
              <div className="col-12">
                <div className="cashierNewTitel text-center">
                  Cash Available in e-WALLET
                </div>
              </div>
              <div className="col-12">
                <div
                  className="cashierNewTitel text-center"
                  style={{ color: "#25ac5b" }}
                >
                  54,526,12.00
                </div>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col p-5 m-3 ewalletInnerBox">
                <form>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="cashierNewTitel mb-2"
                  >
                    Deposit to e-WALLET
                  </label>
                  <input
                    type="email"
                    className="form-control exampleFormControlInput1 fs-6"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Type Your Amount"
                  />
                  <div className="row">
                    <div className="mb-3 col-8 col-lg-8 col-md-8 col-sm-10">
                      <label htmlFor="startDate" className="cashierNewTitel">   
                      </label>
                    </div>
                    <div className="mb-3 col-8 col-lg-8 col-md-8 col-sm-10">
                        <button className="newcashierButton"><TranslatesContent contentKey="submit-cashier" /></button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col p-5 m-3 ewalletInnerBox">
                <form>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="cashierNewTitel mb-2"
                  >
                    Withdraw from e-WALLET
                  </label>
                  <input
                    type="email"
                    className="form-control exampleFormControlInput1 fs-6"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Type Your Amount"
                  />
                  <div className="row">
                    <div className="mb-3 col-8 col-lg-8 col-md-8 col-sm-10">
                      <label htmlFor="startDate" className="cashierNewTitel">   
                      </label>
                    </div>
                    <div className="mb-3 col-8 col-lg-8 col-md-8 col-sm-10">
                        <button className="newcashierButton"><TranslatesContent contentKey="submit-cashier" /></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>  
      </>
    );
  }
export default connect()(Ewallet);