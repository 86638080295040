import { Link } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux"; 
import {IMGBUCKETURL} from '../../DataStore/ApiConstant';
import {IMAGE_BUCKET_URL} from '../../DataStore/ApiConstant';
import { nanoid } from "nanoid";
import { useAtom } from "jotai";
import { reRenderLobby } from "../../DataStore/atom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LogoComponent = ({login , siteImages}) => {
  const [, setRenderLobby] = useAtom(reRenderLobby);
  const history = useHistory();

    let url = '/';

    login ?  
    url="/lobby" : url = "/";

    const goToLobby = () => {
        login ?  
        url="/lobby" : url = "/";
        history.push(url);
        window.location.reload();
      }

    return ( 
        // <div className="item flex-shrink-2 text-center text-lg-start text-md-start flex-lg-grow-1 flex-sm-grow-1">
            <Link to={url}>
                {/* <img src={IMAGE_BUCKET_URL + "breeze-new-logo.png"} className="img-fluid"/> */}
                <img onClick={(e)=>{setRenderLobby(nanoid());goToLobby();}} src={IMGBUCKETURL + "logo_replace_new.png"} class="img-fluid d-none d-sm-block"></img>
                <img onClick={(e)=>{setRenderLobby(nanoid());goToLobby();}} src={IMGBUCKETURL + "mobilelogo.png"} class="img-fluid d-block d-sm-none"></img>
                {/* <img src={IMGBUCKETURL + "logo_replace.png"} className="img-fluid" style={{width: "125px"}}/>  */}
                
            </Link>
        // </div>
     );
}

const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
})
 
export default connect(BindStateToProps)(LogoComponent);