import React from 'react';
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";

const contactDetails = {
  withdrow: {
    link: "https://wa.me/",
    whatsapp: "+94777107104",
    telegram: "+94772764685",
    logo:"whatsapp"
  },
  deposit: {
    link: "https://t.me/",
    whatsapp: "+94771482222",
    telegram: "+94771482222",
    logo:"telegram"
  },
  "fund-transfer": {
    link: "https://t.me/",
    whatsapp: "+94771482222",
    telegram: "+94762555666",
    logo:"telegram"
  },
  transfer: {
    link: "https://t.me/",
    whatsapp: "+94771482222",
    telegram: "+94762555666",
    logo:"telegram"
  },
  other: {
    link: "https://t.me/",
    whatsapp: "+94771482222",
    telegram: "+94771482222",
    logo:"telegram"
  }
};

const WhatsappButton = ({ cashierType }) => {
  const contacts = contactDetails[cashierType] || contactDetails.other;

  return (
    <div className="row">
      <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0 newcashierContactBox">
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="newcashierButton">
              <a href={`${contacts.link}${contacts.whatsapp}`} target="_blank" rel="noopener noreferrer">
                <i className={`fa-brands fa-${contacts.logo}`} style={{ fontSize: "25px" }}></i>{" "}
                {contacts.whatsapp}
              </a>
            </div>
          </div>
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="tobIncontact mt-0 mt-3 mt-lg-2 mt-md-2 mt-sm-2 mt-xl-2 mb-2">
              24X7 Help Line
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default WhatsappButton;
