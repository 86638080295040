import { connect } from "react-redux";
import { decodedWord } from "./LanguageDecrypt";
import parse from "html-react-parser";

const TranslatedContent = ({ contentKey, LanguageContent }) => {
  return (
    <>
      {LanguageContent &&
        LanguageContent.lang_content &&
        LanguageContent.lang_content[`${contentKey}`] &&
        parse(decodedWord(LanguageContent.lang_content[`${contentKey}`]))}
    </>
  );
};

const mapStateTorops = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
  };
};

export default connect(mapStateTorops)(TranslatedContent);
