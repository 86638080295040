import { useState, useEffect, forwardRef } from "react";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import axios from "axios";
import { CRYPTOURL, CRYPTO_MERCHANT, DEOSITBONUSURL,} from "../../DataStore/ApiConstant";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { useAtom } from "jotai";
import { bonusStore } from "../../DataStore/atom";
import { getFormData, calculateBonus, calculateTurnover } from "../../utils";
import { useDepositBonus } from "../../services/cashier";
import { useInrToUsd } from "../../services/config";
import { NumericFormat } from "react-number-format";

import { useParams } from 'react-router-dom';
const currencyRules = [
  { currency: "USD", min: 5, max: 20000 }
];

const getValidatorSchema = () => {
  const { min, max } = currencyRules.find((rule) => rule.currency === "USD");
  return yup.object().shape({
    amount: yup
      .number()
      // .required("Please provide amount.")
      .min(min, `Must be greater than or equal to ${min}`)
      .max(max, `Must be less than or equal to ${max}`)
      .typeError("Amount must be a number"),
  });
};

const ForwardedNumericFormat = forwardRef((props, ref) => (
  <NumericFormat {...props} getInputRef={ref} />
));
const Crypto = () => {
  const [BonusStore, setBonusStore] = useAtom(bonusStore);
  const [depositBonus, setDepositBonus] = useState(null);
  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);
  const [usd, setUsd] = useState(0);
  const [load, setLoad] = useState(false);

  const { type } = useParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [amount, setAmount] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);

  const schema = getValidatorSchema();
  const { handleSubmit, formState: { errors }, register, reset } = useForm({ resolver: yupResolver(schema) });
  
  // const bonus = useWithdrawBonus();
  const bonus = useDepositBonus();
  const InrToUsd = useInrToUsd();
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      // style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const submitHandler = async () => {
    if(amount === "" || amount <= 0){
      setPaymentErr("Please enter a valid amount")
      const timer = setTimeout(() => {
        setPaymentErr(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
    setLoad(true)
    let bonusAmt = 0;
    depositBonus == null ? (bonusAmt = 0) : (bonusAmt = 1);
    const formData = new FormData();
    formData.append('amount', usd);
    formData.append('url', "/cashier");
    formData.append('bonus', bonusAmt);
    axios
      .post(CRYPTOURL, formData)
      .then((resp) => {
        // console.log(resp?.data?.data.token);
        let ResState = resp?.data?.state
        let token = resp?.data?.data.token
        if(ResState == true){
          let url = CRYPTO_MERCHANT + token;
          window.location.href = url;
          // setSuccessMsg(resp.data.message || 'Please Wait');
          setLoad(false);
        }
        setErrmsg('');
      })
      .catch((err) => {
        setPaymentErr(err);
        setLoad(false);
      })
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const calculateBonuses = (reAmount) => {
    setAmount(reAmount);

    if (bonus.data) {
      let bonusRet = calculateBonus(
        reAmount,
        bonus?.data?.maxbonus || 0,
        bonus?.data?.bonusprecent || 0
      );
      setReBonus(bonusRet);
      let turnRet = calculateTurnover(
        reAmount,
        bonus?.data?.maxbonus || 0,
        bonus?.data?.turnover || 0,
        bonus?.data?.bonusprecent || 0
      );
      setReTurn(turnRet);

      // if(!InrToUsd.isLoading && InrToUsd?.data){
      //   let lkrToUsdAmount = parseFloat(reAmount) * parseFloat(InrToUsd?.data[0]?.value)
      //   const { min, max } = currencyRules.find((rule) => rule.currency === "USD");
      //   if (reAmount !== "" && (lkrToUsdAmount < min || lkrToUsdAmount > max)) {
      //     setPaymentErr(`USD amount must be between min and max limit: ${min} - ${max}`);
      //     setLoad(true)
      //   } else {
      //     setPaymentErr(false);
      //     setLoad(false)
      //   }
      //   lkrToUsdAmount = parseFloat(lkrToUsdAmount.toFixed(2));
      //   console.log(lkrToUsdAmount);
      //   setUsd(lkrToUsdAmount || 0)
      // }
      if(!InrToUsd.isLoading && InrToUsd?.data) {
        const conversionRate = parseFloat(InrToUsd?.data[0]?.value);
        const amountInFloat = parseFloat(reAmount);
  
        if (!isNaN(conversionRate) && !isNaN(amountInFloat)) {
          let lkrToUsdAmount = amountInFloat * conversionRate;
          const { min, max } = currencyRules.find((rule) => rule.currency === "USD");
  
          if (reAmount !== "" && (lkrToUsdAmount < min || lkrToUsdAmount > max)) {
            setPaymentErr(`USD amount must be between min and max limit: ${min} - ${max}`);
            setLoad(true)
          } else {
            setPaymentErr(false);
            setLoad(false)
          }
  
          lkrToUsdAmount = parseFloat(lkrToUsdAmount.toFixed(2));
          console.log(lkrToUsdAmount);
          setUsd(lkrToUsdAmount || 0);
        } else {
          console.error('Invalid conversion rate or amount:', { conversionRate, reAmount });
          setUsd(0);
          setPaymentErr('Invalid conversion rate or amount.');
        }
      }
    }
  };
  const bonusOfferSwitch = async (status) => {
    // alert(status)
    setBonusStore(status);
    if (status) {
      axios
        .get(DEOSITBONUSURL)
        .then((resp) => {
          setDepositBonus(resp);
        })
        .catch((err) => {});
    } else {
      setDepositBonus(null);
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (type == "success") {
      const cryptoTab = document.getElementById('v-pills-crypto-tab');
      if (cryptoTab) cryptoTab.click()
      setShowSuccessMessage("Your transaction was successful!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
    if(type == "cancel"){
      const cryptoTab = document.getElementById('v-pills-crypto-tab');
      if (cryptoTab) cryptoTab.click()
      setShowSuccessMessage("Your transaction failed!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
  }, [type]);
  return (
    <div
      className="tab-pane fade "
      id="v-pills-crypto"
      role="tabpane5"
      aria-labelledby="v-pills-crypto-tab"
    >
      <div className="row">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="col-12 col-lg-10 col-md-8 col-sm-10 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mt-4">
                          <label htmlFor="exampleFormControlInput1" className="cashierNewTitel" style={{fontSize: "20px"}}>
                            Deposit to account
                          </label>
                          <div className="col-12 col-lg-12 col-md-7 col-sm-12 col-xl-7 mt-4">

                          <div className="mb-3 row">
                            <label htmlFor="staticEmail" className="col-sm-8 col-form-label">
                            Enter amount in INR :
                            </label>
                            <div className="col-sm-4">
                                <ForwardedNumericFormat
                                style={{ width: "8rem", textAlign: "right", height: "calc(1.5em + 0.75rem + 2px)" }}
                                value={amount}
                                thousandSeparator={true}
                                // decimalScale={2}
                                fixedDecimalScale={true}
                                className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
                                onValueChange={(values) => {
                                  const { value } = values;
                                  calculateBonuses(value);
                                }}
                                onFocus={handleFocus}
                                // customInput="input"
                              />
                            </div>
                          </div>
                          {/* <div className="col-12 col-lg-12 ">
                            Enter amount in INR
                          </div> */}
   
                          </div>
                          {errors?.amount?.message && (
                            <div className="invalid-feedback">
                              {errors.amount.message}
                            </div>
                          )}

                        </div>
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 ">
                          <div className="col-12 col-lg-12 col-md-7 col-sm-12 col-xl-7">
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-8 col-form-label">
                                Chargeble USD amount :
                                </label>
                                <div className="col-sm-4">
                                  <input disabled className="form-control" style={{textAlign: "right", width: "8rem",height: "calc(1.5em + 0.75rem + 2px)",backgroundColor:"black",color:"white"}}
                                    value={formatAmount(usd)}
                                  />
                                </div>
                                {/* <label htmlFor="staticEmail" className="col-sm-4 col-form-label" style={{border: "1px white solid", padding: "7px", borderRadius: "5px", textAlign: "right",}}>
                                {formatAmount(usd)}
                                </label> */}
                            </div>
                          </div>
                          {/* <div className="col-12 col-lg-8 ">
                            Chargeble USD amount
                            :
                          </div>
                          <div className="col-12 col-lg-4">
                            {formatAmount(usd)}
                          </div> */}
                        </div>
                        {/* <div id="emailHelp" className="form-text text-start" style={{ color: '#818181', marginTop: '10px' }}>
                            <TranslatesContent contentKey="min-max-limit" />
                            {" USD 50 / USD 20,000"}
                          </div> */}
                        <div>
                          <div className="row">
                            <div className="col-12">
                                {successMsg && (
                                  <div
                                    style={{ marginTop: "30px" }}
                                    className="alert alert-success"
                                  >
                                    {successMsg}
                                  </div>
                                )}
                                {errmsg && (
                                  <div
                                    style={{ marginTop: "30px" }}
                                    className="alert alert-danger"
                                  >
                                    {errmsg}
                                  </div>
                                )}
                                {paymentErr && (
                                  <div
                                    style={{ marginTop: "30px" }}
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {paymentErr}
                                  </div>
                                )}
                                {/* <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                                  <button
                                    disabled={loader}
                                    type="submit"
                                    className="newcashierButton"
                                  >
                                    {loader ? (
                                      "Processing..."
                                    ) : (
                                      <TranslatesContent contentKey="submit" />
                                    )}
                                  </button>
                                </div> */}
                              </div>
                            </div>
                        </div>
                      </div>
                      <div>
                        {showSuccessMessage && (
                          <div className={showSuccessMessage.includes("failed") ? "success-message-error" : "success-message"}>
                            {showSuccessMessage}
                          </div>
                        )}
                        {/* The rest of your component code */}
                      </div>

                    </div>
                  </div>

              </div>
              <BonusOffer name={"deposit-bonus"} bonusAmount={!bonus.isLoading && bonus?.data?.bonusprecent}/>
              <div className="col-lg-6 offset-xl-1 offset-lg-2 offset-md-2 offset-sm-0 offset-0 mt-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row my-3  my-xl-4 my-lg-4 my-md-4 my-sm-4">
                      <div className="col-12">
                        <label
                          for="exampleFormControlInput1"
                          className="cashierNewTitel"
                        >
                          <TranslatesContent contentKey="bonus" /> Activation
                        </label>
                      </div>
                      <div className="col-12">
                        <input
                          type="checkbox"
                          className="my-checkbox"
                          data-val="1"
                          checked={BonusStore}
                          onChange={(e) => {
                            bonusOfferSwitch(e.target.checked);
                          }}
                        />
                        <TranslatesContent contentKey="activate" />
                      </div>
                    </div>
                    {BonusStore ? (
                      <div>
                        <div className="row">
                          <div className="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="bonus-amount" />:
                          </div>
                          <div className="col-12 col-lg-7 bonusResultRight">
                            {reBonus}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="withdrawal-turnover" />
                            :
                          </div>
                          <div className="col-12 col-lg-7 bonusResultRight">
                            {reTurn}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {paymentErr && (
                      // <div
                      //   style={{ marginTop: "30px" }}
                      //   className="alert alert-danger"
                      // >
                      //   {paymentErr}
                      // </div>
                      <div className="alert alert-danger" role="alert">
                        {paymentErr}
                      </div>
                    )} */}
                    <div className="row mt-4">
                      <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                        <button
                          type="submit"
                          className="newcashierButton"
                          disabled={load}
                        >
                          {(
                            <TranslatesContent contentKey="submit" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Crypto;
