import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const ForgotPasswordBtn = ({ requestNewPassword }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyRecaptha = async () => {
    if (!executeRecaptcha) {
      return false;
    }

    const token = await executeRecaptcha("yourAction");
    return token;
  };

  const checkRecaptha = (e) => {
    e.preventDefault();
    verifyRecaptha().then((token) => {
      if (token) {
        requestNewPassword(e, token);
      } else {
      }
    });
  };
  return (
    <button
      className="frontButton3"
      onClick={(e) => checkRecaptha(e)}
      type="submit"
    >
      <TranslatesContent contentKey="join-now-proceed" />{" "}
    </button>
  );
};

export default ForgotPasswordBtn;
