import { combineReducers } from "redux";
import { UserReducer } from "./User/userReducer";
import { SiteImagesReducer } from "./SiteImages/SiteImageReducer";
import { LoginReducer } from "./User/Login/LoginReducer";
import { userDataReducer } from "./User/UserData/UserDataReducer";
import { BaccaratResultReducer } from "./GameData/Baccarat/BaccaratResultReducer";
import { FundsReducer } from "./User/UserData/FundTransferReducer";
import { LanguageReducer } from "./Languages/LanguagesReducer";
import { BnkAccountReducer } from "./User/UserData/BankAccountReducer";
import { WithdorwDataReducer } from "./User/UserData/Withdrow/WithdrowDataReducer";
import { WithdorwMethodsreducer } from "./User/UserData/Withdrow/WithdrowMethodsReducer";
import { TransferHistoryReducer } from "./User/UserData/Cashier/TransferHistoryReducer";
import { countryReducer } from "./Countries/CountryReducer";
import { VersionsReducer } from "./versions/VersionsReducer";


export const appReducer = combineReducers(
    {
        users : UserReducer,
        siteImages : SiteImagesReducer,
        login : LoginReducer,
        userData : userDataReducer,
        BaccaratResults : BaccaratResultReducer,
        FundTransfers : FundsReducer,
        SiteLanguages : LanguageReducer,
        BankAccount : BnkAccountReducer,
        WidthdrowData : WithdorwDataReducer,
        WithdrowMethods : WithdorwMethodsreducer,
        TransferHistory : TransferHistoryReducer,
        CountryList : countryReducer,
        Versions : VersionsReducer
    }
)


export const RootReducer = (state, action)=>{
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
      }
    
      return appReducer(state, action)
}