import { connect } from "react-redux";
import { decodedWord } from "../../../DataStore/Languages/LanguageDecrypt";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const ContactComponent = () => {
  return (
    <>
      <div class="row">
        <div class="col-12 text-center">
          <i class="fas fa-headphones fa-4x"></i>
        </div>
        <div class="col-12 mainTitel">
          {" "}
          <TranslatesContent contentKey="contact-us" />{" "}
        </div>
        <div class="col-6 col-lg-4 col-md-4 col-sm-4">
          <div class="col-12 text-center">
            <a href="tel:+94117871871">
              <i class="fas fa-phone-volume fa-3x"></i>
            </a>
          </div>
          <div class="col-12 ">
            <br />
            <h5>
              <TranslatesContent contentKey="phone" />
            </h5>
          </div>
          <div class="col-12 text-center">
            <a href="tel:+94117871871">
              {" "}
              <span style={{ color: "white" }}>+94 777 067 064</span>
            </a>
          </div>
          <div class="col-12 text-center align-items-center justify-content-center mt-2">
            <a
              href="tel:+94117871871"
              class="btn btn-warning align-middle joinTopbutton"
            >
              <TranslatesContent contentKey="contact-us" />
            </a>
          </div>
        </div>
        <div class="col-6 col-lg-4 col-md-4 col-sm-4">
          <div class="col-12 text-center">
            <a target="_blank" href="https://wa.me/?+94765889889">
              <i class="fab fa-whatsapp fa-3x"></i>
            </a>
          </div>
          <div class="col-12 ">
            {" "}
            <br />
            <h5>
              <TranslatesContent contentKey="whatsapp" />
            </h5>
          </div>
          <div class="col-12 text-center">
            <a target="_blank" href="https://wa.me/?+94765889889">
              {" "}
              <span style={{ color: "white" }}>+94 765 889 889</span>
            </a>
          </div>
          <div class="col-12 text-center align-items-center justify-content-center mt-2">
            <a
              target="_blank"
              href="https://wa.me/?+94765889889"
              class="btn btn-warning align-middle joinTopbutton"
            >
              <TranslatesContent contentKey="contact-us" />
            </a>
          </div>
        </div>
        <div class="col-6 offset-3 offset-lg-0 offset-md-0 offset-sm-0 col-lg-4 col-md-4 col-sm-4">
          <div class="col-12 text-center">
            <a target="_blank" href="https://wa.me/?+94765992992">
              <i class="fab fa-whatsapp fa-3x"></i>
            </a>
          </div>
          <div class="col-12 ">
            {" "}
            <br />
            <h5>
              <TranslatesContent contentKey="whatsapp" />
            </h5>
          </div>
          <div class="col-12 text-center">
            <a target="_blank" href="https://wa.me/?+94765992992">
              {" "}
              <span style={{ color: "white" }}>+94 765 992 992</span>
            </a>
          </div>
          <div class="col-12 text-center align-items-center justify-content-center mt-2">
            <a
              target="_blank"
              href="https://wa.me/?+94765992992"
              class="btn btn-warning align-middle joinTopbutton"
            >
              <TranslatesContent contentKey="contact-us" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateTorops = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
  };
};

export default connect(mapStateTorops)(ContactComponent);
