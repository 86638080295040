import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";

const DepositNavBar = function ({ object, unKey }) {

  return (
    <button
      key={"dpn" + unKey}
      class={`newd nav-link py-2 my-0 ${unKey == 0 ? `active` : ``} ${
        object.pill == "redeposit" ? `py-2 mb-0 mt-2` : ``
      }`}
      id={`v-pills-${object.pill}-tab`}
      data-bs-toggle="pill"
      data-bs-target={`#v-pills-${object.pill}`}
      type="button"
      role="tab"
      aria-controls={`v-pills-${object.pill}`}
      aria-selected="true"
    >
      <div class="row">
        <div class="col-3 col-lg-3 col-md-3 col-sm-3 p-0 d-none d-sm-block ">
          <img src={IMAGE_BUCKET_URL + object.image} class="img-fluid" alt="" />
        </div>
        <div class="col-12 col-lg-9 col-md-9 col-sm-9 pt-xl-3 pt-lg-3 pt-md-3 pt-sm-3">
          {object.name}
        </div>
      </div>
    </button>
  );
};

export default DepositNavBar;
