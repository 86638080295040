import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const FAQComponent = () => {
    return ( 
    <div class="row">
        <TranslatesContent contentKey="faq"/> 
    </div>
    );
}
 
export default FAQComponent;