import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const AgreementComponent = () => {
    return ( 

        <div className="row">
            <TranslatesContent contentKey="agreement"/>
        {/* //                 <div className="col-12 mainTitel"> BALLYSBREEZE TERMS AND CONDITIONS </div>
        //                 <div class="col-12">
        //                     <ol>
        //                         <li>
        //                             <h6>A person cannot request to open a Player Account or hold a Player Account if
        //                                 such person
        //                                 will not be the Player Account holder or if they are acting on behalf of a third
        //                                 party.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>You are allowed to open only one Player Account on the Website, and BallysBreeze
        //                                 Online
        //                                 reserves the right to close all multiple Player Accounts that are open, under
        //                                 the same name
        //                                 or connected to the same person. If a person has opened multiple Player Accounts
        //                                 on the
        //                                 Website, BallysBreeze Online team will have the right to take any action.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>Opening an online account will be required to submit player's details along with
        //                                 copy of
        //                                 DL/ID/Passport and Valid Mobile Number.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>In order to play with BallysBreeze Online and to use the Services, you must
        //                                 complete the
        //                                 application for account opening and membership in the manner set out on the
        //                                 Website and the
        //                                 same time you can request for a demo account.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>Your personal data shall be kept confidential and will be processed in
        //                                 accordance with our
        //                                 Privacy Policy. We will not disclose your personal data or betting information
        //                                 unless
        //                                 compelled to do so by any applicable laws, regulations, and court orders or by
        //                                 the relevant
        //                                 gambling authorities.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>You are at least 18 years of age, or the legal age is required by the governing
        //                                 laws in your
        //                                 country.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>If BallysBreeze Online mistakenly credits Your Account with winnings that do not
        //                                 belong to
        //                                 you, either due to a technical or human error, the amount will remain the
        //                                 property of
        //                                 BallysBreeze Online and it will be transferred from Your Account. If, prior to
        //                                 BallysBreeze
        //                                 Online becoming aware of the error, you have withdrawn funds that do not belong
        //                                 to you, the
        //                                 incorrectly paid amount will constitute a debt owed by you to BallysBreeze
        //                                 Online. In the
        //                                 event of an incorrect credit to Your Account, you are obliged to notify
        //                                 BallysBreeze Online
        //                                 immediately.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>It is the Player's responsibility to ensure that all information provided to
        //                                 BallysBreeze
        //                                 Online is accurate, precise and up to date.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>You may not under any circumstance authorize a third party to use your Player
        //                                 Account, your
        //                                 password or your identity to access the Services.</h6>
        //                         </li>
        //                         <li>
        //                             <h6>You accept that you are solely responsible for the supply and maintenance of all
        //                                 the
        //                                 computer equipment and telecommunication network and internet access services
        //                                 that you need
        //                                 to use in order to access the Services. We will not be liable for any loss
        //                                 caused to you by
        //                                 any failure of your computer equipment, telecommunications network and/or
        //                                 internet access.
        //                             </h6>
        //                         </li>


        //                     </ol>

        //                 </div> */}
        </div>     
    );
}
 
export default AgreementComponent;