import axios from "axios";
import { useHistory } from 'react-router-dom';

const axiosInstance = axios.create({
  baseURL: "/api",
});

class APIClient {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAll = (config) => {
    return axiosInstance.get(this.endpoint, config).then((res) => res.data).catch((error) => {
      if (localStorage.getItem('login') && error.response && error.response.status === 401) {
        localStorage.removeItem('login')
        window.location.href = '/'
       
        console.log('Unauthorized access. Please log in.');
        // const history = useHistory();
        // history.push('/home');

      } else {
        console.error('Error:', error.message);
      }
      throw error;
    });;
  };
}

export default APIClient;
