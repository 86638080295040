import TranslatesContent from "../../DataStore/Languages/translatesContent";

const MobileField = ({country , updatePhone , phoneNumber}) => {
    return ( 
        <div className="form-group">
        <label htmlFor="exampleInputPassword1"><TranslatesContent contentKey="mobile-number"/></label>
        <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">+{country.split('-')[1]}</span>
            <input onChange={(e)=>updatePhone(e)} value={phoneNumber} type="text" className="form-control" placeholder="XXX XXX XXX"
                aria-label="XXX XXX XXX" aria-describedby="basic-addon1"/>
        </div>
        </div>
     );
}
 
export default MobileField;