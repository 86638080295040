import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ForgotPasswordSucess from "../ForgotPassword/ForgotPasswordSucess";
import LoginForm from "./LoginForm";
import LoginFormNew from "./LoginFormNew";
import RegisterFormWrapper from "./Register/RegisterFormWrapper";
import { useEffect, useRef, useState } from "react";

const LoginModalWrapper = ({ type, status, closePopup, changeContent, openGeneralModal }) => {
  const [display, setDisplay] = useState("none");
  const [opacity, setOpacity] = useState(1);

  const handleModalClose = () => {
    // setTimeout(() => closePopup(), 1000);

    setDisplay("none");
    setOpacity(0);
    closePopup();
  };

  useEffect(() => {
    if (status === "active") {
      setDisplay("block");
      setTimeout(() => setOpacity(5), 10);

      // setOpacity(1);
    }
  }, [status]);
  return (
    <>
    { status == "active" ? (
    <div
      className="modal showModal"
      id="loginpopup"
      style={{
        backgroundColor: "#000000a1",
      }}
    >
      <div className="modal-dialog showModalcontent">
        <div className="modal-content popupBg">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                handleModalClose();
                // closePopup();
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ backgroundColor: "white" }}
            ></button>
          </div>
          <div className="modal-body">
            {type === "login" ? (
            <LoginForm openGeneralModal={openGeneralModal} changeContent={changeContent} closePopup={closePopup} />
              // <LoginFormNew openGeneralModal={openGeneralModal} changeContent={changeContent} closePopup={closePopup} />
            ) : type === "register" ? (
              <RegisterFormWrapper />
            ) : type === "password-reset" ? (
              <ForgotPassword changeContent={changeContent} />
            ) : (
              <ForgotPasswordSucess />
            )}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
    ):null}
    </>
  );
};

export default LoginModalWrapper;
