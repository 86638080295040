import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import SportsGamesTab from "../GameAreas/SportsGamesTab";
import SportsBetArea from "../Layouts/SportsBetArea";

const SportGames = GUEST_GAMES_DATA.sports;

const SportsArea = ({ openPopup, login }) => {
  return (
    <>
      <div
        className="container-fluid mt-lg-5"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <SportsGamesTab SportGames={SportGames} login={login} openPopup={openPopup} />
        <SportsBetArea login={login} openPopup={openPopup} />
      </div>
      {/* <div className="tab-content mt-lg-5" id="myTabContent">
          {SportGames &&
            Object.keys(SportGames).map((gameky, ky) => {
              return (
                <div
                  key={gameky}
                  className={"tab-pane fade" + (ky === 0 ? " show active" : "")}
                  id={gameky.toUpperCase()}
                  role="tabpanel"
                  aria-labelledby={gameky.toUpperCase() + "-tab"}
                >
                  <div className="row">
                    {SportGames[gameky].games &&
                      Object.keys(SportGames[gameky].games).map(
                        (gmeky, key) => {
                          return (
                            <div
                              key={gmeky}
                              className="col-lg-2 col-md-4 col-sm-4 col-6 mx-0 px-1 mb-2"
                            >
                              <div className="row m-0 p-1">
                                <div className="col-12 ms-0 p-0">
                                  <img
                                    src={SportGames[gameky].games[gmeky].image}
                                    className="img-fluid mx-auto d-block shineImg"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              );
            })}
        </div> */}
    </>
  );
};

export default SportsArea;
