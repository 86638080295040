import axios from "axios";
import { GET_BACCARAT_SCORE } from "../../ApiConstant";

export const LoadingBacResult = () => {
  return {
    type: "LOADING_BAC_RESULT",
  };
};

export const BaccaratResultSuccess = (data) => {
  return {
    type: "BAC_RESULT_SUCCESS",
    data: data,
  };
};

export const BaccaratResultError = (error) => {
  return {
    type: "BAC_RESULT_ERROR",
    error: error,
  };
};

export const BaccaratResult = (param) => {
  return (dispatch) => {
    dispatch(LoadingBacResult());
    axios
      .get(GET_BACCARAT_SCORE + param)
      .then((response) => {
        dispatch(BaccaratResultSuccess(response));
      })
      .catch((error) => {
        dispatch(BaccaratResultError(error));
      });
  };
};
