import { useEffect, useState } from "react";
import axios from "axios";
import { useBankAccounts } from "../../services/cashier";
import { DELETE_BANK_ACCOUNT_URL } from "../../DataStore/ApiConstant";

const BankAccountsTable = function () {
const bankAccounts = useBankAccounts();
const [errmsg, setErrmsg] = useState("");
const [successMsg, setSuccessMsg] = useState("");
const removeBankAccount = function (e, id){
    e.preventDefault()
    const userConfirmed = window.confirm('Are you sure you want to remove this bank account?');
    if(userConfirmed){
        console.log(DELETE_BANK_ACCOUNT_URL + id);
        axios
        .get(DELETE_BANK_ACCOUNT_URL + id)
        .then((resp) => {
            setSuccessMsg("Account Removed")
            bankAccounts.refetch();
            setTimeout(() => {
                setSuccessMsg("");
                  }, 3000);
        })
        .catch((err) => {
            setErrmsg("Account remove Failed");
            setTimeout(() => {
            setErrmsg("");
              }, 3000);
        });
    }
}
  useEffect(() => {
    // bankSet(0);
    // return bankSet(0);
  }, []);

  return (
    <div className="row mt-4">
        <div className="col-12">
        <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
            Bank Account Details
        </label>
            {errmsg && (
              <div style={{ marginTop: "30px" }} className="alert alert-danger">{errmsg}</div>
            )}
            {successMsg && (
              <div style={{ marginTop: "30px" }}className="alert alert-success">{successMsg}</div>
            )}
        </div>
        <div className="col-12">
        <table className="table table-dark table-striped">
            <tbody>
            <tr>
                <th
                scope="row"
                style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}
                >
                Bank Name
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                Account Name
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                Account Number
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                Delete
                </th>
            </tr>
            {bankAccounts?.data?.map((bankacc) => (

            <tr style={{ color: "white" }} key={bankacc["id"]}>
                <td scope="row">{bankacc["bank_name"]}</td>
                <td>{bankacc["nick_name"]}</td>
                <td>{bankacc["acc_number"]}</td>
                <td>
                <a href="" onClick={(e)=>removeBankAccount(e, bankacc["id"])}>
                    <i className="fas fa-trash-alt" style={{ color: "white !important" }}/>
                </a>
                </td>
            </tr>
            ))}
            </tbody>
        </table>
        </div>
    </div>
  );
};

export default BankAccountsTable;
