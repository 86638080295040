import axios from "axios";
import { USERDATA_URL } from "../../ApiConstant";

export const LoadingUserdata = () => {
  return {
    type: "LOADING_USER_DATA",
  };
};

export const UserDataSuccess = (data) => {
  return {
    type: "USER_DATA_SUCCESS",
    data: data,
  };
};
export const userDataError = (error) => {
  return {
    type: "USER_DATA_ERROR",
    error: error,
  };
};

export const UserData = () => {
  return (dispatch) => {
    dispatch(LoadingUserdata());
    axios
      .get(USERDATA_URL)
      .then((respond) => {
        dispatch(UserDataSuccess(respond.data));
      })
      .catch((error) => {
        dispatch(userDataError(error));
      });
  };
};
