const initState = {
    loading: false,
    images: '',
    error: ''

}
export const SiteImagesReducer = (state = initState, actions) => {
    switch (actions.type) {
        case 'LOADING_IMAGE_FETCH':
            return {
                ...state,
                loading: true
            }
            case 'IMAGE_FETCH_SUCCESS':
                return {
                    ...state,
                    loading: false,
                        images: actions.images,
                        error: ''
                }
                case 'IMAGE_FETCH_ERROR':
                    return {
                        ...state,
                        loading: false,
                            images: '',
                            error: actions.error
                    }
                    default:
                        return state;
    }
}