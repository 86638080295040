import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const RulesComponent = () => {
    return ( 
        <div className="row">
            <TranslatesContent contentKey="game-rules-cont"/>
                        {/* <div className="col-12 mainTitel"> RULES </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3>How to Play Ballys Live Roulette</h3>
                                </div>
                                <div class="col-12 contentNewBox">
                                    Roulette is its simple game format and exciting betting system. Although the table
                                    may look confusing at first glance, it's easy to play once you're aware of the
                                    following:<br/><br/>

                                    Table Layout: The Table visual displays all your bets. Every bet you can make is
                                    visible on the table visual, and so table layouts may vary slightly between
                                    different game types, though the basic overall layout remains the same. The squares
                                    on the table correspond to numbers on the wheel; some are clearly marked while
                                    others require your chips to be placed in specific areas to indicate your bet
                                    preference. To place your bets, simply set your chip size and then place your chips
                                    on the bet area and spin the wheel. Roulette Bets: Some bets are wagered on a single
                                    number e.g. straight (paying up to 35:1) or may cover a range of numbers e.g. column
                                    (place chip at end of the column), split (place your chips between your 2 chosen
                                    numbers) and corner (place the chip on the inside corner of 4 numbers). Other bets
                                    can cover bigger ranges and are clearly marked on the visual table layout , such as
                                    red/black, and odds/evens.<br/><br/>

                                    The Roulette Wheel and Numbers- Winning : When the ball is spun and comes to a stop
                                    in a number, that number determines whether your bet has won. You can win multiple
                                    bets at once, depending on the bets you have placed. Ballys Roulette wheels have a
                                    single zero. Start playing now ( Click Link to Live Roulette )
                                </div>
                                <div class="col-12">
                                    <h3>Odds and Payouts</h3>
                                </div>
                                <div class="col-12 contentNewBox">
                                    Bets known as "outside bets" offer better odds of winning (almost 50%) when you play
                                    roulette online, and their payout of even money (1:1) reflects this higher chance of
                                    winning. Single numbers have the lowest odds of winning, but offer a much higher
                                    payout of 35/1, offering the biggest returns in any standard game.<br/><br/>

                                    Payouts are instant via our intuitive software, and all wins are highlighted on the
                                    screen. The biggest standard payout is for matching a single number, so if you bet
                                    cash amount on the number 4 at 35:1, and that hits, you would be paid out 35 x your
                                    cash amount plus your cash amount bet
                                </div>
                                <div class="col-12">
                                    <h3>Top Tips for Roulette</h3>
                                </div>
                                <div class="col-12 contentNewBox">
                                    To enjoy the best possible Ballys online roulette experience with us, make sure you
                                    follow these tips:<br/><br/>

                                    Make sure you check that the table limits match your bankroll and preferred betting
                                    patterns, and that the game-specific rules match how you like to play for the best
                                    possible Ballys Live Roulette experience. Think about your minimum or maximum bets:
                                    keep within your bankroll limits to maximize your enjoyment and make the most of
                                    your time at the roulette tables.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3>Bonus and Promotions</h3>
                                </div>
                                <div class="col-12 contentNewBox">
                                    Visit Ballys promotions page to view our latest casino bonus offers; we have a
                                    variety of bonuses that are tailor-made for all Ballys table games players, as well
                                    as bonuses for both new and existing players. <br/><br/>

                                    Choose a bonus that suits all table games: all casino bonuses have wagering
                                    requirements and this can mean wagering on the specific game - check the T&amp;Cs of
                                    your chosen bonus to make sure table games are included ( Click Link to T&amp;Cs - Bonus
                                    and Promotions )
                                </div>


                            </div>

                        </div> */}
        </div>     
    );
}
 
export default RulesComponent;