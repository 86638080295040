import axios from "axios";
import { FUNDTRANSFERURL } from "../../ApiConstant";

export const FundsLoading = () => {
  return {
    type: "FUNDS_LOADING",
  };
};
export const fundsSuccess = (data) => {
  return {
    type: "FUNDS_SUCCESS",
    data: data,
  };
};

export const fundsError = (error) => {
  return {
    type: "FUNDS_ERROR",
    error: error,
  };
};

export const fundTransfer = () => {
  return (dispatch) => {
    axios
      .get(FUNDTRANSFERURL)
      .then((resond) => {
        dispatch(fundsSuccess(resond));
      })
      .catch((err) => {
        dispatch(fundsError(err));
      });
  };
};
