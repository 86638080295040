const initState = {
    loading: false,
    data: '',
    err: '',
    current_lang : '',
    lang_content : ''
}

export const LanguageReducer = (state = initState, actions) => {
    switch (actions.type) {
        case 'LANGUAGE_LOADING':
            return {
                ...state,
                loading: true
            }
        case 'LANGUAGE_SUCCESS':
            return {
                ...state,
                loading: false,
                data: actions.data,
                err: ''
            }
        case 'LANGUAGE_ERROR':
            return {
                ...state,
                loading: false,
                data: '',
                err: actions.err
            }

        case 'CURRENT_LANGUAGE' : 
        return {
            ...state,
            current_lang : actions.language
        }

        case 'LANGUAGE_CONTENT' : 
        return {
            ...state,
            lang_content : actions.content
        }

            default: return state;
    }
}