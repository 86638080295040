const initState = {
    loading : false,
    data : '',
    error : ''
}

export const countryReducer = (state = initState , action)=>{
    switch (action.type) {
        case 'COUNTRIES_LOADING':
            return {
                ...state,
                loading : true
            }
            
    case 'COUNTRIES_LOADED' : 
        return{
            ...state,
            loading : false,
            data : action.data,
            err : ''
        }
    case 'COUNTRYLIST_ERROR' :
        return {
            ...state,
            loading : false,
            data : '',
            err : action.err
        }
        default:
            return  state 
    }
}