import React, { useState, useEffect } from 'react';

const CaptchaComponent = () => {
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [c, setC] = useState(0);
  const [captcha, setCaptcha] = useState('');
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []); // Run this effect only once on component mount

  const generateCaptcha = () => {
    const newA = Math.ceil(Math.random() * 10);
    const newB = Math.ceil(Math.random() * 10);
    setA(newA);
    setB(newB);
    setC(newA + newB);
  };

  const checkCaptcha = (e) => {
    setCaptcha(e);
    if (parseInt(e) === c) {
      setValidCaptcha(true);
      return
    } else {
      setValidCaptcha(false);
    }
  };

  const handleInputChange = (e) => {
    setCaptcha(e.target.value);
    checkCaptcha();
  };

  const handleRefresh = () => {
    if (!locked) {
      setLocked(true);
      setTimeout(() => {
        setLocked(false);
        generateCaptcha();
        setValidCaptcha(false); // Reset validCaptcha when refreshing captcha
      }, 500);
    }
  };

  return (
    <div>
      <div>{a} + {b} = ?</div>
      <input
        type="text"
        value={captcha}
        onChange={(e) => checkCaptcha(e.target.value)}
      />
      <button onClick={handleRefresh} disabled={locked}>
        Refresh
      </button>
      {validCaptcha && <p>Captcha is valid!</p>}
    </div>
  );
};

export default CaptchaComponent;
