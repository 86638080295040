import { useEffect, useRef } from "react";
import { BOARD_SELECT_COIN } from "../../constants/index";

const Boards = ({ item, i }) => {
  const inputElement = useRef();
  let type = item.tbid.slice(0, 3);

  useEffect(() => {
    const handleLoad = () => {
      if(type=='DRT'){
        let boardContent = inputElement.current.contentDocument;
        item.betPositions.length > 0 && 
        item.betPositions.map(val => {
          let coin = boardContent.getElementById(BOARD_SELECT_COIN[type][val.type]);
          if(coin){
            coin.style.display = "block";
            coin.childNodes[3].childNodes[1].textContent = val.amount / 1000 + "K";
          }
          
        })
      }
      if(type=='LU7'){
        let boardContent = inputElement.current.contentDocument;
        item.betPositions.length > 0 && 
        item.betPositions.map(val => {
          let coin = boardContent.getElementById(BOARD_SELECT_COIN[type][val.type]);
          if(coin){
            coin.style.display = "block";
            coin.childNodes[1].childNodes[0].textContent = val.amount / 1000 + "K";
          }

        })
      }
      if(type=='AND'){
        let boardContent = inputElement.current.contentDocument;
        item.betPositions.length > 0 && 
        item.betPositions.map(val => {
          let coin = boardContent.getElementById(BOARD_SELECT_COIN[type][val.type]);
          if(coin){
            coin.style.display = "block";
            // coin.childNodes[1].childNodes[0].textContent = val.amount / 1000 + "K";
          }

        })
      }
      if(type=='BAC'){
        let boardContent = inputElement.current.contentDocument;
        item.betPositions.length > 0 && 
        item.betPositions.map(val => {
          let coin = boardContent.getElementById(BOARD_SELECT_COIN[type][val.type]);
          if(coin){
            coin.style.display = "block";
            coin.childNodes[3].childNodes[0].textContent = val.amount / 1000 + "K";

          }

        })
      }
      if(type=='ROU'){
        let boardContent = inputElement.current.contentDocument;
        item.betPositions.length > 0 && 
        item.betPositions.map(val => {
          let coin = boardContent.getElementById(val.type+'-coin-set');
          if(coin){
            coin.style.display = "block";
            coin.childNodes[3].childNodes[0].textcontent=  val.amount
            
          }
        })
      }
    }
    const svgElement = inputElement.current;

    if (svgElement) {
      if (svgElement.contentDocument) {
        handleLoad();
      } else {
        svgElement.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  return (
    <>
      <object
        ref={inputElement}
        key={"object" + i}
        // data='/assets/img/boards/DRT.svg'
        data={"/assets/img/boards/" + type + ".svg"}
        // class="collapse mt-3"
        id={"mybets" + i}
      >
        {" "}
      </object>
    </>
  );
};

export default Boards;

