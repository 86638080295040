import { useQuery ,useMutation } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import { genarateQueryString } from "../utils/";
import ms from "ms";


export const useNotification = () => {
    const apiClient = new APIClient("/getnotification");
    return useQuery({
      queryKey: ["notification"],
      queryFn: apiClient.getAll,
      // staleTime: ms("1m"),
    });
  };

  export const useNotifyView = () => {
    const apiClient = new APIClient('/set_notify_view');
  
    const notifyViewMutation = useMutation((notifyid) => {
      return apiClient.getAll({ params: { notifyid } });
    });
  
    return notifyViewMutation;
  };
  
  
  
  
  
  


