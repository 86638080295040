import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import axios from "axios";
import { useState, useEffect } from "react";
import { REGISTER_URL, RECAPTCHA_SITEKEY, VERIFY_OTP_URL, REGISTER_USERDATA_URL, IMAGE_BUCKET_URL } from "../../../../DataStore/ApiConstant";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RegisterButton from "./RegisterButton";
import OtpInput from 'react-otp-input';
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const RegisterFormNew = ({ phone }) => {

  const [fullname, setFullname] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [passwordReqErr, setPasswordReqErr] = useState("");
  const [username, setUsername] = useState("");
  const [initPassword, setPassword] = useState("");
  const [confirmedPass, setConfirmedPass] = useState("");
  const [formErr, setFormErr] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setConfirmPasswordVisible] = useState(false);

  const [nameErr, setNameErr] = useState("");
  const [dobErr, setDobErr] = useState("");

  const [promocode, setPromocode] = useState(""); 
  const [btag, setBtag] = useState("");
  const [referral, setReferral] = useState("");

  const [phoneNumber, setPhone] = useState("");
  const [phoneNumberWithCountry, setPhoneNumberWithCountry] = useState("");
  const [onlyPhoneNumber, setOnlyPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpCorrect, setOtpCorrect] = useState(false);
  const [otp, setOtp] = useState("");
  const [registerLoad, setRegisterLoad] = useState(false);

  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryDialCode, setCountryDialCode] = useState("");

  const [registerErr, setRegisterErr] = useState(null);
  const [enterOtp, setEnterOtp] = useState(null);
  const [phoneNumErr, setPhoneNumErr] = useState(null);
  const [resetPassword, setResetPassword] = useState('');
  const [resent, setResent] = useState("");
  const [otpErr, setOtpErr] = useState("");

  const today = new Date().toISOString().split('T')[0];

  const initialTime = 600; // 10 minutes in seconds
  const [time, setTime] = useState(initialTime);
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const handlePhoneNumberChange = (value, data, event, formattedValue) => {
    setOtpSent(false)
    setPhone(value);
    setOnlyPhone(value.slice(data.dialCode.length));

    setCountryCode(data.countryCode.toUpperCase());
    setCountryDialCode(data.dialCode);
    setCountryName(data.countryCode.toUpperCase())
    setPhoneNumberWithCountry(`+${value}`)
    // console.log(data);
    setCountry(`${data.countryCode.toUpperCase()}-${data.dialCode}`);
  };

  const handleGetCodeClick = async () => {
    try {
      const response = await axios.post(`${REGISTER_URL}`, {
        phone: phoneNumber,
      });
      if (response.status === 200) {
        setOtpSent(true);
        alert("OTP sent successfully!");
      }
    } catch (error) {
      console.error("Failed to send OTP:", error);
      alert("Failed to send OTP. Please try again.");
    }
  };
  let msg={
    "already":"This Mobile number already exist..",
    "Invalid Mobile Number":"Invalid Mobile Number",
  }

  const handleOtpChange = (otp) => {
    setOtp(otp);
    if (otp.length === 6) {
      verifyOtp(otp);
    }
  };

  const verifyOtp = async (otp) => {
    const digits = String(otp).split('').map(Number)
    const formData = new FormData();
    formData.append("codeBox1", digits[0]);
    formData.append("codeBox2", digits[1]);
    formData.append("codeBox3", digits[2]);
    formData.append("codeBox4", digits[3]);
    formData.append("codeBox5", digits[4]);
    formData.append("codeBox6", digits[5]);
    try {

      const resp = await axios.post(`${VERIFY_OTP_URL}`, formData);
      if (resp.data === "success") {
        setOtpSent(false)
        setOtpCorrect(true);
        setOtpErr("OTP verified successfully!");
      } else {
        setOtpCorrect(false);
        setOtpErr("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
    }
  };

  const handleError = (errorMessage) => {
    // setSuccessGenaralLoad(false);
    setFormErr(errorMessage);
  };
  
  const submitGeneralInfo = (e) => {
    setFormErr("")
    setPasswordErr("") 
    setPasswordReqErr("")
    fullname == "" ? setNameErr("Name Required") : setNameErr("")
    dob == "" ? setDobErr("Date of birth invalid") : setDobErr("")
    username == "" ? setUsernameErr("Username Required") : setUsernameErr("")
    confirmedPass == "" ? setPasswordReqErr("Password Required") : setPasswordReqErr("")
    if (fullname == ""  || dob == "" || username =="" || confirmedPass =="" || initPassword=="")  return
    const formData = new FormData();

    formData.append("name", fullname);
    formData.append("country", countryName);
    formData.append("phone", phoneNumberWithCountry);
    formData.append("dob", dob);
    formData.append("email", email);
    formData.append("promocode", promocode);
    formData.append("gender", gender);
    formData.append("usname", username);
    formData.append("passw", confirmedPass);
    formData.append("btag", btag);
    formData.append("referral", referral);

    if(initPassword !== confirmedPass){
      // setSuccessGenaralLoad(false)
      return setPasswordErr("Password doesn't match") 
    }

    axios
      .post(REGISTER_USERDATA_URL, formData)
      .then((resp) => {
        // console.log(resp.data === "err");
        if(resp.data === "done"){
          localStorage.removeItem('promoCode')
          localStorage.removeItem('btag_code')
          localStorage.removeItem('referral_code')
          setTimeout(() => {
            
          }, 3000);
        }
        else if( resp.data=== "err") {
            return handleError("Fill out all the required fields");
        }
        else if( resp.data=== "age") {
            return handleError("Age must be grater than 18");
        }
        else if( resp.data=== "Username is already Taken") {
            return handleError("Username is already Taken");
        }

        if (resp.data !== "done" || resp.data !== "success") {
          if (resp.data === "Acc false") {
            // setSuccessGenaralLoad(false)
            setFormErr("Something went wrong!");
          }
        }
        else{
          // setSuccessGenaralLoad(false)
          setFormErr(resp.data);
        }
      })
      .catch((err) => {});

  }

  const registerSubmit = (e, token) => {
    setOtp("")
    setRegisterLoad(true);
    e.preventDefault();
    setRegisterErr("");
    setPhoneNumErr("");
    setResetPassword("");
    let countryValue = country.split("-")[1];
    let data = {
      country: country,
      phone: onlyPhoneNumber,
      token: token,
    };
    if (token === null) {
      setRegisterErr("Recaptcha failed! Please try again.");
    } else if (phoneNumber === "") {
      setRegisterErr("Please enter a phone number!");
      setRegisterLoad(false);
    } else {
      axios
        .post(REGISTER_URL, data)
        .then((resp) => {
          console.log(resp);
          if (resp.data === "ok") {
            setTimeout(() => {
            setOtpSent(true);
              // setFormType("otpform");
            }, 2000);
          } else if (resp.data === "already") {
            setTime(initialTime)
            setResetPassword('This mobile number already Registered');
            setOtpSent(false);
            setEnterOtp("Enter your OTP code here")

            setTimeout(() => {
              setResetPassword("");
            }, 3000);
            setRegisterLoad(false);
          } else {
            setRegisterErr(msg[resp.data] || "An error occurred during registration.");
            setPhone("");
            setRegisterLoad(false);
          }
        })
        .catch((err) => {
          console.error("Error during registration:", err);
          setRegisterErr("An error occurred during registration. Please try again.");
          setRegisterLoad(false);
        });
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!passwordConfirmVisible);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          // Handle timer expiration here
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount

  }, [resent]);
  useEffect(() => {

    const setLocalStorageValue = (key, setStateFunction) => {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        setStateFunction(storedValue);
      }
    };
    setLocalStorageValue("promoCode", setPromocode);
    setLocalStorageValue("btag_code", setBtag);
    setLocalStorageValue("referral_code", setReferral);
  },[])
  useEffect(() => {
    if(localStorage.getItem("promocode")){
      let promoCode = localStorage.getItem("promocode")
      setPromocode(promoCode)
    }
    setUsername("")
    setPassword("")
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-md-12 col-sm-12">
        <div className="col-12">
          <div className="newJoinPopupTitel">Register as a new user</div>
          <div className="newJoinPopupTitel2">Get your instant ID from WhatsApp</div>
        </div>
        <div className="col-12 my-3" style={{ textAlign: "center" }}>
            <a
              style={{ color: "white", textDecoration: "none" }}
              target="_blank"
              href="https://wa.me/+94771482222"
            >
          <img src={IMAGE_BUCKET_URL + "join-whatsapp.png"} className="img-fluid" alt="" />
          </a>
        </div>
        <div className="text-divider">OR</div>
        <div className="col-12">
          <section>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 mt-2 mb-2">
                  <div className="form-area">
                    <div className="form-inner">
                      {/* <form> */}
                        <div className="form-group" style={{ position: "relative" }}>
                          <label htmlFor="phoneNumber" className="form-label required">
                            Phone Number
                          </label>
                          <div className="form-group">
                            <PhoneInput
                              country={'lk'}
                              value={phoneNumber}
                              onChange={handlePhoneNumberChange}
                              containerClass="custom-phone-input"
                              inputClass="custom-phone-input-input"
                              enableSearch={true}
                              preferredCountries={['lk', 'cn', 'in', 'np', 'kr', 'jp']}
                              inputProps={{
                                name: 'phoneNumber',
                                required: true,
                                placeholder: "Enter phone number",
                                style: { width: '100%' }
                              }}
                            />
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
                              <RegisterButton registerLoad={registerLoad} registerSubmit={registerSubmit} disabled={!isPhoneValid} otpSent={otpSent}/>
                            </GoogleReCaptchaProvider>
                          </div>
                        </div>

                        <div className="register-error m-2" style={{ color: "#ff5d5d", fontSize: "12px" }}>
                          {registerErr && registerErr}
                        </div>
                        <div style={{ color: "#ff5d5d", fontSize: "12px" }}>
                          {resetPassword && resetPassword}
                        </div>
                        <div className="register-error" style={{ color: "#ff5d5d", fontSize: "12px" }}>
                          {phoneNumErr && phoneNumErr}
                        </div>
                        { otpSent ? 
                        <div className="form-group">
                          <label htmlFor="otp" className="form-label required">
                            Enter Code
                          </label>
                          <input
                            maxlength="6"
                            type="number"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={otp}
                            // onChange={(e) => handleOtpChange(e.target.value)}
                            onChange={(e) => handleOtpChange(e.target.value)}
                            disabled={!otpSent}
                          />
                            {/* <OtpInput value={otp} onChange={handleOtpChange} numInputs={6} renderSeparator={<span style={{color:'transparent',fontSize:'40px'}}>-</span>}
                            renderInput={(props) => <input {...props} 
                            style={{width:'12%',height:'40px',border:'1px solid #ffff',
                            backgroundColor:'transparent',borderRadius:'5px',color:'#fff',textAlign:'center'}}/>}
                            inputType="tel"
                            placeholder="0" /> */}
                        <div className="register-error m-2" style={{ color: "#ff5d5d", fontSize: "12px" }}>
                          {/* {enterOtp && enterOtp} */}
                        OTP EXPIRES IN { formatTime(time) }
                        </div>
                        </div>
                        : null
                        }
                        <div className="otp-err" style={{ color: "#ff5d5d" }}>
                          {otpErr && otpErr}
                        </div>
                        <div className="form-group">
                          <label htmlFor="userId" className="form-label required">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            disabled={!otpCorrect}
                            onChange={(e) => setFullname(e.target.value)}
                          />
                        </div>
                        
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                          {nameErr && nameErr}
                        </div>
                        <div className="form-group">
                          <label htmlFor="userId" className="form-label required">
                            Date of birth
                          </label>
                          <input
                          type="date"
                          onChange={(e) => setDob(e.target.value)}
                          disabled={!otpCorrect}
                          className="form-control"
                          min="1900-01-01" 
                          max={today}
                        />
                        </div>
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                          {dobErr && dobErr}
                        </div>
                        <div className="form-group">
                          <label htmlFor="userId" className="form-label">
                            Gender
                          </label>
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            name=""
                            id=""
                            className="form-select form-control"
                            disabled={!otpCorrect}
                          >
                            <option value="" disabled>
                              Select Gender
                            </option>
                            <option value={"male"}>
                              <TranslatesContent contentKey="male" />
                            </option>
                            <option value={"female"}>
                              <TranslatesContent contentKey="female" />
                            </option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="userId" className="form-label">
                            Email
                          </label>
                          <input
                          type="text"
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={!otpCorrect}
                          placeholder="Email"
                          className="form-control"
                        />
                        </div>
                        <div className="form-group">
                          <label htmlFor="userId" className="form-label required">
                            User ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="User ID"
                            disabled={!otpCorrect}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                          {usernameErr && usernameErr}
                        </div>
                        <div className="password-field">
                          <label htmlFor="password" className="form-label required">
                            Password
                          </label>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            placeholder="Password"
                            className="form-control"
                            disabled={!otpCorrect}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i id="toggler" 
                            onClick={togglePasswordVisibility}
                           className="fa-solid fa-eye"
                            />
                        </div>
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                            {passwordReqErr && passwordReqErr}
                        </div>
                        <div className="password-field">
                          <label htmlFor="confirmPassword" className="form-label required">
                            Confirm Password
                          </label>
                          <input
                            type={passwordConfirmVisible ? "text" : "password"}
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            className="form-control"
                            disabled={!otpCorrect}
                            onChange={(e) => setConfirmedPass(e.target.value)}
                          />
                          <i id="toggler"
                          onClick={toggleConfirmPasswordVisibility}
                          className="fa-solid fa-eye" />
                        </div>
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                            {passwordErr && passwordErr}
                        </div>

                        <div className="form-group">
                          <label htmlFor="referralCode" className="form-label">
                            Referral Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Referral Code"
                            disabled={!otpCorrect}
                            onChange={(e) => setReferral(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="referralCode" className="form-label">
                            Promo Code
                          </label>
                          {
                            !localStorage.getItem("promoCode") ? 
                            <input
                              // onChange={(e) => setPromocode(e.target.value)}
                              onChange={(e) => setPromocode(e.target.value)}
                              style={{color: "black"}}
                              value={promocode}
                              type="text"
                              className="form-control"
                              placeholder="Promo Code"
                              disabled={!otpCorrect}
                            />
                            :
                            <input
                              // onChange={(e) => setPromocode(e.target.value)}
                              style={{color: "black"}}
                              value={promocode}
                              type="text"
                              className="form-control"
                              placeholder="Promo Code"
                              disabled
                            />
                          }
                        </div>
                        <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
                          {formErr && formErr}
                        </div>
                        <div className="form-group">
                          <label className="form-check-label" htmlFor="exampleCheck1">
                            By clicking on the Register button, you agree to the Terms and Conditions and confirm that you are over 18 years old.
                          </label>
                        </div>
                        <button className="btn btn-primary form-submit w-100 frontButton3" onClick={(e) => submitGeneralInfo()} disabled={!otpCorrect}>
                          Register
                        </button>
                        <div>
                          {/* <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
                            <RegisterButton registerLoad={registerLoad} registerSubmit={registerSubmit} />
                          </GoogleReCaptchaProvider> */}
                        </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RegisterFormNew;
