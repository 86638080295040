import { useEffect, useRef, useState } from "react";
import { SVG_PATH } from "../../../DataStore/ApiConstant";
import { SVG_BAC_MOBI_PATH } from "../../../DataStore/ApiConstant";

const SingleBacResultBoardTwo = ({ Tblekey, result, index, config, configGame, isLoad }) => {
  const svgRef = useRef();
  const [gemeConfig, setGameConfig] = useState([]);
const loadGame = (gameLink) => {
  window.location.replace("/livebaccarat/#" + gameLink);
};
useEffect(() => {
  if(!isLoad ){
    if(configGame ){
        let data = Object.values(configGame)
        setGameConfig(data[1].bac[1][Tblekey.substring(3, 4)] || []);
    }
  }
},[isLoad])
useEffect(() => {
  const rows = 6;
  const patterns = result;
  const handleLoad = () => {
    if(svgRef.current){
      let svgObj_mobile = svgRef.current.contentDocument;
      Object.keys(patterns).forEach(function (pattern) {
        if ( pattern === "bigRoad" || pattern === "bigEyeBoy" || pattern === "smallRoad" || pattern === "cockroach" || pattern === "beadPlate") {
            patterns[pattern].forEach(function (res, k) {
              let cellCount = k + 1;
              res.forEach(function (j) {
                if (j !== "") {
                  if (svgObj_mobile != null) {
                    if(svgObj_mobile.getElementById(`${pattern}_${cellCount}`)){
                      let restOneMobi = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                      if (restOneMobi != null) {
                        restOneMobi.setAttribute("xlink:href",`#${pattern}_${j}`);
                      } else {
                        restOneMobi.setAttribute("xlink:href", `#none`);
                      }
                    }
                  }
                }else{
                  if(svgObj_mobile.getElementById(`${pattern}_${cellCount}`)){
                    let restOneMobi = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                    restOneMobi.setAttribute("xlink:href", `#none`);
                  }
                }
                cellCount += rows;
              })
            });
        } 
        else if (pattern === "predictResult") {
          let predictPattern = ['bigEyeBoy', 'smallRoad', 'cockroach'];
              patterns[pattern].forEach(function(results, i) {
                let objCopy=[...results]
                let bp = objCopy[0]
                objCopy.shift();
                  // let bp = results[0]
                  // results.shift();
                  objCopy.forEach(function(result, j){
                      if(result){
                          if(svgObj_mobile!=null){
                              let mobPredect = svgObj_mobile.getElementById(`predictor_${bp}_${j}`);
                              if(mobPredect!=null){
                                  mobPredect.setAttribute("xlink:href", `#${predictPattern[j]}_${result}`);
                              }
                          }
                      }
                  })
              });
      }
        else if (pattern === "resultCount") {
            let count = patterns[pattern];
            if(svgObj_mobile.getElementById("statictics-b")) svgObj_mobile.getElementById("statictics-b").textContent =  count?.b
            if(svgObj_mobile.getElementById("statictics-p")) svgObj_mobile.getElementById("statictics-p").textContent =  count?.p || 0;
            if(svgObj_mobile.getElementById("statictics-t")) svgObj_mobile.getElementById("statictics-t").textContent =  count?.t || 0;
            if(svgObj_mobile.getElementById("statictics-bp")) svgObj_mobile.getElementById("statictics-bp").textContent =  count?.bb || 0;
            if(svgObj_mobile.getElementById("statictics-pp")) svgObj_mobile.getElementById("statictics-pp").textContent = count?.pp || 0;
            if(svgObj_mobile.getElementById("statictics-s6")) svgObj_mobile.getElementById("statictics-s6").textContent = count?.b6 || 0;
        }
      });
    }
  }

    const svgElement = svgRef.current;

    if (svgElement) {
      if (svgElement.contentDocument) {
        // handleLoad();
        setTimeout(() => {
          handleLoad();
        },1000);
      } else {
        svgElement.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('load', handleLoad);
      }
    };
  }, [result]);

  return (
    <>
        <div className="col-lg-4 col-lg-4 mx-0 px-1 mb-2" style={{ position: "relative", cursor:"pointer" }} key={"foryou"+Tblekey} onClick={() => { if (gemeConfig && gemeConfig[4] !== 0) { loadGame(Tblekey)}}}>

          {
            gemeConfig && gemeConfig[4]==0 ? (
              <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose tableCloseText">
                 <div className="row ">
                     <div className="tableCloseText ">Closed
                     </div>
                 </div>
             </div>) : 
             (
               <div className="content-overlay text-center pt-5 ">
                <a className="hoverButton"  onClick={() => loadGame(Tblekey)}>
                  PLAY
                </a>
              </div>
             )
            }
        <div className="row m-0 p-1 border rounded border-white">
          <div className="col-12 ms-0 p-0">
          <object key={index}className="single-baccarat-svg" ref={svgRef}data={SVG_PATH + "/mobile_bac_board.svg"} >
        {" "}
      </object>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4 tableCountBoardName" style={{ color: "white" }}>
                {/* {result.tablename ||'N/A'} */}
                {gemeConfig ? gemeConfig[1]  : "N/A"}
                
              </div>
              <div className="col-8 " style={{ paddingRight: 0 }}>
                <div className="tableCountBoardLimit text-end" style={{ paddingRight: 0, textDecoration: "none" }}>
                    {/* {new Intl.NumberFormat().format(
                      1000
                    )}
                  -{" "}
                  {new Intl.NumberFormat().format(
                      100000
                    )} */}
                    {gemeConfig ? gemeConfig[2]  : 0}
                      -{" "}
                    {gemeConfig ? gemeConfig[3] : 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

    </>
  );
};

export default SingleBacResultBoardTwo;
