import axios from "axios";
import { BANK_ACCOUNT_URL } from "../../ApiConstant";

export const setBankAccLoading = () => {
  return {
    type: "BANK_ACCOUNT_LOADING",
  };
};

export const setBankAccData = (data) => {
  return {
    type: "BANK_ACCOUNT_SUCCESS",
    data: data,
  };
};

export const setBankAccErrror = (err) => {
  return {
    type: "BANK_ACCOUNT_ERR",
    err: err,
  };
};

export const getBankAccount = () => {
  return (dispatch) => {
    dispatch(setBankAccLoading());
    axios
      .get(BANK_ACCOUNT_URL)
      .then((respond) => {
        dispatch(setBankAccData(respond.data));
      })
      .catch((err) => {
        dispatch(setBankAccErrror(err));
      });
  };
};
