const initState = {
    loading : false,
    data : '',
    err : ''
}

export const TransferHistoryReducer = (state = initState , action) => {
    switch (action.type) {
        case 'TRANS_HISTORY_LOADING':
            return {
                ...state,
                loading : true
            }
        case 'TRANS_HISTORY_SUCCESS' :
            return {
                ...state,
                loading : false,
                data : action.data,
                err : ''
            }

        case 'TRANS_HISTORY_ERROR' : 
        return {
            ...state,
            loading : false,
            data : '',
            err : action.err
        }
    
        default:
            return state;

    }
}