import { connect } from "react-redux";
import FundTransfer from "../../Cashier/FundTransfer";
import TransferHistory from "../../Cashier/TransferHistory";
import Withdrowal from "../../WIthdrow/Withdrowal";
import DepositFormsWrapper from "./DepositFormsWrapper";
import { fundTransfer } from "../../../DataStore";
import { useEffect } from "react";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";
import WhatsappButton from "../../Cashier/whatsappButton";

const CashierModalWrapper = ({
  userData,
  fundsTransfer,
  getFUndTransfers,
  modalType,
  closePopup,
  status,
}) => {
  useEffect(() => {
    getFUndTransfers();
  }, []);
  return (
    <div
      className={
        (status === "active" ? "show active-modal" : "") + " modal fade"
      }
      id="cashierpopup"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content popupBg">
          <div class="modal-header pb-0">
            <div class="d-flex flex-wrap  text-start">
              <div>
                Hi {userData?.data?.session?.username} |{" "}
                {userData?.data?.session?.uid}{" "}
              </div>
            </div>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ backgroundColor: "white" }}
              onClick={(e) => {
                e.preventDefault();
                closePopup();
              }}
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex flex-wrap  text-center gap-3 justify-content-around mb-4">
              <div>
                <TranslatesContent contentKey="cash-balance" /> :{" "}
                <span style={{ color: "#FCB503" }}>
                  {fundsTransfer?.data?.data?.wallet?.fundBalance
                    ? new Intl.NumberFormat().format(
                        fundsTransfer?.data?.data?.wallet?.fundBalance
                      )
                    : "0"}
                </span>
              </div>
              <div>
                <TranslatesContent contentKey="non-cash-given" /> :{" "}
                <span style={{ color: "#FCB503" }}>
                  {fundsTransfer?.data?.data?.noncash?.fundBalance
                    ? new Intl.NumberFormat().format(
                      fundsTransfer?.data?.data?.noncash?.balance
                        // fundsTransfer?.data?.data?.noncash?.fundBalance
                      )
                    : "0"}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <ul class="nav nav-tabsc" id="myTab" role="tablist">
                  <li
                    class="nav-item col-4 col-lg-4 col-md-4 col-sm-4"
                    role="presentation"
                  >
                    <button
                      class="nav-linkc active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <TranslatesContent contentKey="deposit" />
                    </button>
                  </li>
                  <li
                    class="nav-item col-4 col-lg-4 col-md-4 col-sm-4 role="
                    presentation=""
                  >
                    <button
                      class="nav-linkc"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <TranslatesContent contentKey="withdrawal" />
                    </button>
                  </li>
                  <li
                    class="nav-item col-4 col-lg-4 col-md-4 col-sm-4"
                    role="presentation"
                  >
                    <button
                      class="nav-linkc"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <TranslatesContent contentKey="transactions" />
                    </button>
                  </li>
                </ul>
                <div
                  class="tab-content p-3"
                  id="myTabContent"
                  style={{ backgroundColor: "black" }}
                >
                  <DepositFormsWrapper
                    active={
                      modalType === "deposit" ||
                      !modalType ||
                      modalType === "active" ||
                      modalType === "balance" ||
                      modalType === "bankAccounts"
                    }
                  />
                  <Withdrowal />
                  <TransferHistory />
                </div>
              </div>
            </div>
            {/* <WhatsappButton /> */}
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
  };
};

export default connect(maStateTorops, mapDispatchToProps)(CashierModalWrapper);
