const initState = {
    loading : false,
    data : '',
    error : ''
}

export const userDataReducer=(state = initState, actions)=>{
    switch(actions.type){
        case 'LOADING_USER_DATA' :
        return {
            ...state,
            loading : true
        }
        break;
        case 'USER_DATA_SUCCESS' :
            return {
                ...state,
                loading : false,
                data : actions.data,
                error : ''
            }
            break;
        case 'USER_DATA_ERROR' :
            return {
                ...state,
                loading : false,
                data : '',
                error : actions.error
            }
            break;

            default : return state
    }
}