import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const GamblingComponent = () => {
    return ( 
        <div class="row">
        <div class="col-12 mainTitel"><TranslatesContent contentKey="responsible-gambling"/></div>
        <TranslatesContent contentKey="responsibility"/>
        {/* <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h3>My Activity</h3>
                </div>
                <div class="col-12 contentNewBox">
                    My Activity allows you to keep track of your activity with a visual dashboard
                    providing statistics, recent bet times and a graphical representation of your
                    account history. You can view your account activity across a 7 day, 30 day or 12
                    month period.
                </div>
                <div class="col-12">
                    <h3>Online Account History</h3>
                </div>
                <div class="col-12 contentNewBox">
                    To enable you to keep track of your activity, you can access a history of your
                    transactions,
                    deposits and withdrawals in our Members area and your balance is always visible in
                    the top right
                    corner of the page when logged in to www.ballysbreeze.com
                </div>
                <div class="col-12">
                    <h3>Win/Loss History</h3>
                </div>
                <div class="col-12 contentNewBox">
                    The Win/Loss history available in our Members area reflects your returns minus your
                    stakes for all
                    bets settled within the chosen date range (including any bets placed outside the
                    date range but
                    settled within it), giving you an easy way to track your gambling spending.
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>Net Deposits</h3>
                </div>
                <div class="col-12 contentNewBox">
                    For an overall figure of what is going in and out of your www.ballysbreeze.com
                    account, you can
                    check your net deposits in our Members area. The net deposit is simply the
                    difference between what
                    you have deposited and what you have withdrawn.
                </div>


            </div>

        </div> */}
    </div>
    );
}
 
export default GamblingComponent;