import { useBankAccounts } from "../../../services/cashier";
import { useEffect, useState } from "react";

const BankAccountDropDown = function ({ bankSet }) {
  const [amount, setAmount] = useState();
  const bankAccounts = useBankAccounts();

  useEffect(() => {
    bankSet(0);
    return bankSet(0);
  }, []);

  return (
    <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
      <label for="exampleFormControlInput1" class="cashierNewTitel">
        Bank Accounts
        {amount}
      </label>
      <select
        onChange={(e) => {
          e.target.value != 0 ? bankSet(e.target.value) : bankSet(0);
        }}
        class="form-select"
        aria-label="Default select example"
        name="bankAccount"
      >
        <option value="0">-Select-</option>
        {bankAccounts?.data?.map((bankacc) => (
          <option value={bankacc["id"]} key={"bankdrop" + bankacc["id"]}>
            {bankacc["bank_name"]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BankAccountDropDown;
