import axios from "axios";
import { TRANSFER_URL } from "../../../ApiConstant";

const setTransferLoading = () => {
  return {
    type: "TRANS_HISTORY_LOADING",
  };
};

const setTransferSuccess = (data) => {
  return {
    type: "TRANS_HISTORY_SUCCESS",
    data: data,
  };
};

const setTransferFailure = (err) => {
  return {
    type: "TRANS_HISTORY_ERROR",
    err: err,
  };
};

export const getTransHistory = () => {
  return (dispatch) => {
    dispatch(setTransferLoading);
    axios
      .get(TRANSFER_URL)
      .then((resp) => {
        dispatch(setTransferSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(setTransferFailure(err));
      });
  };
};
