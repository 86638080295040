import { useEffect, useRef, useState } from "react";
import { useGameBoardResults } from "../../../services/gameBoards";

const AndarBoard = ({config, isLoad}) => {

  const svgRef = useRef();
  const [gameResult, setGameResult] = useState({});
  const [gemeConfig, setGameConfig] = useState([]);
  const { data, isLoading, status} = useGameBoardResults('and');
  const loadGame = (gameLink) => {
    window.location.replace("/liveandar/#" + gameLink);
  };

  useEffect(() => {
    if(!isLoad ){
      if(config ){
          let data = Object.values(config)
          setGameConfig(data[2]?.and[1][1] || []);
      }
    }
  },[isLoad])

      useEffect(() => {
        const handleLoad = () => {
          if(!isLoading && status && Object.keys(data).length > 0){
              let rows = 4;
              setGameResult(data)
              if(svgRef.current){
                let svgObj_mobile = svgRef.current.contentDocument;
                      if(Object.keys(data).length > 0){
                        Object.keys(data.and1).forEach(function (pattern) {
                              if (pattern === "bigRoad") {
                                data.and1[pattern].forEach(function (res, k) {
                                  let cellCount = k + 1;
                                  res.forEach(function (j) {
                                    if (j !== "") {
                                      if (svgObj_mobile != null) {
                                        let restOne = svgObj_mobile.getElementById(
                                          `${pattern}_${cellCount}`
                                        );
              
                                        if (restOne != null) {
                                          restOne.setAttribute("xlink:href", `#${j}`);
                                        }
                                      }
                                    }
                                    cellCount += rows;
                                  });
                                });
                              
                              }else if (pattern === "resultCount") {
                                let count = data.and1[pattern];
                                if (svgObj_mobile != null) {
                                  if(svgObj_mobile.getElementById("text358")) svgObj_mobile.getElementById("text358").textContent = count?.A || 0;
                                  if(svgObj_mobile.getElementById("text97")) svgObj_mobile.getElementById("text97").textContent = count?.B || 0;
                                }
                              }
                            })
                      }
              }
                  }
              }

        const svgElement = svgRef.current;

        if (svgElement) {
          if (svgElement.contentDocument) {
            // handleLoad();
            setTimeout(() => {
              handleLoad();
            },1000);
          } else {
            svgElement.addEventListener('load', handleLoad);
          }
        }
    
        return () => {
          if (svgElement) {
            svgElement.removeEventListener('load', handleLoad);
          }
        };
      }, [data]);

  return (
      <div className="col-lg-4 col-lg-4 mx-0 px-1 mb-2" style={{ position: "relative", cursor:"pointer" }} onClick={() => { if (gemeConfig && gemeConfig[4] !== 0) { loadGame("and1")}}}>
              {
                 gemeConfig && gemeConfig[4]==0 ? (
                 <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose tableCloseText">
                    <div className="row ">
                        <div className="tableCloseText ">Closed
                        </div>
                    </div>
                </div>) : 
              (
                <div className="content-overlay text-center pt-5">
                    <a className="hoverButton " onClick={() => loadGame("and1")}>
                      PLAY
                    </a>
                </div>
              )
              }
        {/* <Link to={`/liveandar/and1`} className="single-board-link"> */}
          {/* <div class="content-overlay">
            <p style={{ textAlign: "center", marginTop: "10%" }}>PLAY</p> */}
            {/* <div class="content-overlay text-center pt-5 ">
            <a class="hoverButton " onClick={() => loadGame("and1")}>
                PLAY
              </a> */}
              {/* <a class="hoverButton" style={{ textAlign: "center", marginTop: "10%" }}>PLAY</a> */}
          {/* </div> */}
        {/* </Link> */}
        <div className="row m-0 p-1 border rounded border-white">
          <div className="col-12 ms-0 p-0">
              <object  data={"/assets/img/gameResultBoards/ab-desktop.svg"} ref={svgRef} ></object>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4 tableCountBoardName" style={{ color: "white" }}>
                {/* {gameResult.and1 && gameResult?.and1.tablename || 'N/A'} */}
                {gemeConfig ? gemeConfig[1]  : "N/A"}
              </div>
              <div className="col-8 " style={{ paddingRight: 0 }}>
                <div className="tableCountBoardLimit text-end" style={{ paddingRight: 0, textDecoration: "none" }}>
                  {/* {new Intl.NumberFormat().format(
                      gameResult.and1.min
                    )}
                  -{" "}
                  {new Intl.NumberFormat().format(
                      gameResult.and1.max
                    )} */}
                      {gemeConfig ? gemeConfig[2]  : 0}
                      -{" "}
                      {gemeConfig ? gemeConfig[3] : 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AndarBoard;
