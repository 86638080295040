import { connect } from "react-redux";

const CountryListSelect = ({
  selectedCountry,
  countrySelected,
  countryList,
}) => {
  return (
    <select
      defaultValue={selectedCountry}
      name="country"
      onChange={(e) => countrySelected(e)}
      id="country"
      formcontrolname="country"
      required=""
      className="form-select"
    >
      {countryList &&
        countryList.data &&
        countryList.data.map((country, key) => {
          return (
            <option
              key={key}
              value={country.alpha_code + "-" + country.numaric_code}
            >
              {country.name}
            </option>
          );
        })}
    </select>
  );
};

const mapStateToProps = (state) => {
  return {
    countryList: state.CountryList,
  };
};
export default connect(mapStateToProps)(CountryListSelect);
