import axios from "axios";
import { GET_VERSIONS_URL } from "../ApiConstant";

export const versionsLoading = () => {
  return {
    type: "LOADING_VERSIONS",
  };
};

export const versionsSuccess = (data) => {
  return {
    type: "VERSIONS_SUCCESS",
    data: data,
  };
};

export const versionsError = (err) => {
  return {
    type: "VERSIONS_ERROR",
    err: err,
  };
};

export const getVersions = () => {
  return (dispatch) => {
    dispatch(versionsLoading);
    axios
      .get(GET_VERSIONS_URL)
      .then((resp) => {
        dispatch(versionsSuccess(resp.data));
        localStorage.setItem("contentVersion", JSON.stringify(resp.data));
      })
      .catch((err) => {
        dispatch(versionsError(err));
      });
  };
};
