const initState = {
    loading: false,
    data: '',
    err: ''
}

export const VersionsReducer = (state = initState , actions)=>{
    switch (actions.type) {
        case 'LOADING_VERSIONS':
        return {
            ...state,
            loading : true
        }
        case 'VERSIONS_SUCCESS' : 
        return {
            ...state,
            loading : false,
            data : actions.data,
            err : ''
        }
        case 'VERSIONS_ERROR' : 
        return {
            ...state,
            loading : false,
            data : '',
            err : actions.err
        }
        default: return state;
    }
}