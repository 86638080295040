import { connect } from "react-redux";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import Carousel from "react-bootstrap/Carousel";
import { IMGBUCKETURL, IMGBUCKETURLNEW } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const InnerBanner = ({ siteImages, openLoginPopup, login }) => {
  siteImages && siteImages.images && console.log(siteImages.images.promotions1);
  return (
    <div
      className="container-fluid mt-lg-3"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <div className="row ">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 position-relative promoBanner">
          <Carousel>
            {siteImages &&
              siteImages.images &&
              siteImages.images.promotions1.map((img, key) => {
                return (
                  <Carousel.Item className="p-0">
                    <a>
                      <img
                        src={IMGBUCKETURL + img}
                        className="d-block w-100"
                        alt="..."
                      />
                    </a>
                    <div className="position-absolute top-50 start-50 translate-middle sliderButtons text-center ">
                      {!login && (
                        <a
                          className="align-middle joinTopbutton "
                          onClick={() => openLoginPopup("register")}
                        >
                          <TranslatesContent contentKey="join" />
                        </a>
                      )}
                    </div>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 position-relative promoBanner">
          <Carousel>
            {siteImages &&
              siteImages.images &&
              siteImages.images.promotions2.map((img, key) => {
                return (
                  <Carousel.Item className="p-0">
                    <a>
                      <img
                        src={IMGBUCKETURL + img}
                        className="d-block w-100"
                        alt="..."
                      />
                    </a>
                    <div className="position-absolute top-50 start-50 translate-middle sliderButtons text-center ">
                      {!login && (
                        <a
                          className="align-middle joinTopbutton "
                          onClick={() => openLoginPopup("register")}
                        >
                          <TranslatesContent contentKey="join" />
                        </a>
                      )}
                    </div>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    siteImages: state.siteImages,
  };
};

export default connect(mapStateToProps)(InnerBanner);
