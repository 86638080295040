import { useState } from "react";
import MainBanner from "../Banners/MainBanner";
import FooterComponent from "../Footer/FooterComponent";
import FeaturedGames from "../General/FeaturedGames";
import InnerBanner from "../General/InnerBanner";
import HeaderComponent from "../Header/HeaderComponent";
import LoginModalWrapper from "../Modal/loginModal/LoginModalWrapper";
import GamesWrapper from "../General/GameboardWrapper";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Promotions from "../../Components/Layouts/Promotions";
import { useAtom } from "jotai";
import { PROMO_URL, BTAG_URL, REFERREL_URL } from "../../DataStore/ApiConstant";

import { preLoader } from "../../DataStore/atom";
const FirstPage = ({
  openPopup,
  setOpenCashier,
  selectedGameAreaType,
  setSelectedGameType,
  modalStatus,
  modalType,
  openLoginPopup,
  closePopup,
  changeContent,
  generalModalStatus,
  generalModalType,
  openGeneralModal,
  gameWrapperArea,
}) => {
  const [preLoaderCoin, setPreLoader] = useAtom(preLoader);

  // const history = useHistory();
  // const checkLogin = () => {
  //   sessionStorage.getItem("login") && history.push("/lobby");
  // };
  const bTagOrRefferel = function (){
    let str = window.location.href;
    let splitted = str.split("?");
    let promo = splitted[1];
    let promoRr = promo.split("&");
    let code = promoRr[1];
    let codeSpit = code.split("=");
    return codeSpit[1];
  }
  const history = useHistory();
  const checkLogin = ()=>{
      // console.log(localStorage.getItem('login'))
      localStorage.getItem('login')&&history.push('/lobby')
  }
  useEffect(() => {
    setOpenCashier(false);
    checkLogin();
  }, []);

  useEffect(() => {
    localStorage.removeItem('promoCode')
    localStorage.removeItem('btag_code')
    localStorage.removeItem('referral_code')

    let loc = window.location.href;
    var splitted = loc.split("/");
    // console.log(tst) 
    let tst=splitted.slice(-1).pop()
    if(tst=='login'){
      openLoginPopup("login")
    }

    if((window.location.href.indexOf(PROMO_URL)) != -1){

      var splitted = loc.split("?");
      if(splitted[1]){
        let promo = splitted[1];
        let promoRr = promo.split("#");
        // alert(promoRr[1])
        if(promoRr[1]){
          openLoginPopup("register")
          localStorage.setItem("promoCode",promoRr[1])
          
        }
      }
    }

    if((window.location.href.indexOf(BTAG_URL)) != -1){
      openLoginPopup("register")
      localStorage.setItem("btag_code",bTagOrRefferel())
    }


    if((window.location.href.indexOf(REFERREL_URL)) != -1){
      openLoginPopup("register")
      localStorage.setItem("referral_code",bTagOrRefferel())
    }

  }, []);

  useEffect(() => {
    setPreLoader(true);
    const timer = setTimeout(() => {
      setPreLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {/* header */}
      <HeaderComponent login={false} openPopup={openLoginPopup} />
      {/* main  banner */}
      <MainBanner login={false} openPopup={openLoginPopup} />
      {/* featured games */}
      <FeaturedGames
        setSelectedGameType={setSelectedGameType}
        openGeneralModal={openGeneralModal}
      />
      {/* inner banner */}
      <InnerBanner openLoginPopup={openLoginPopup} login={false} />
      {/* <Promotions login={false} openPopup={openLoginPopup} /> */}
      {/* guest games */}
      <GamesWrapper
        selectedGameAreaType={selectedGameAreaType}
        login={false}
        openPopup={openLoginPopup}
        gameWrapperArea={gameWrapperArea}
      />
      {/* footer area */}
      <FooterComponent
        setOpenCashier={setOpenCashier}
        login={false}
        openPopup={openLoginPopup}
        generalModalStatus={generalModalStatus}
        closePopup={closePopup}
        generalModalType={generalModalType}
        openGeneralModal={openGeneralModal}
      />
      {/* login modal component */}
      <LoginModalWrapper
        openPopup={openPopup}
        type={modalType}
        status={modalStatus}
        closePopup={closePopup}
        changeContent={changeContent}
        openGeneralModal={openGeneralModal}
      />
    </div>
  );
};

export default FirstPage;
