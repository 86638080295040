import axios from "axios";
import { SITE_IMAGES_URL } from "../ApiConstant";

export const loadingFetchImages = () => {
  return {
    type: "LOADING_IMAGE_FETCH",
  };
};

export const fetchImagesSuccess = (images) => {
  return {
    type: "IMAGE_FETCH_SUCCESS",
    images: images,
  };
};

export const fetchImagesError = (error) => {
  return {
    type: "IMAGE_FETCH_ERROR",
    error: error,
  };
};

export const fetchImages = () => {
  return (dispatch) => {
    dispatch(loadingFetchImages());
    axios
      .get(SITE_IMAGES_URL)
      .then((Response) => {
        dispatch(fetchImagesSuccess(Response.data));
      })
      .catch((error) => {
        dispatch(fetchImagesError(error.message));
      });
  };
};
