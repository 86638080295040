import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import { useState } from "react";

const GameHistoryNavBarMobile = function ({ object, unKey }) {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleCheckboxChange = () => {
    setMenuVisible(!menuVisible);
  };
  const handleMenuItemClick = () => {
    setMenuVisible(false);
  };
  return (
    <>
    <div class="col-12" style={{height: "300px", position: "relative", top: "10px"}}>
    <input type="checkbox" id="menu" name="menu" class="menu-checkbox"  onChange={handleCheckboxChange}/>
      <div class={`menu ${!menuVisible ? 'menu-visible' : 'menu-hide'}`}>
          <label class={`${!menuVisible ? 'iconhoverout' : 'iconhoverin'}`} for="menu"><span>Toggle</span></label>
          <ul>
          {object.map((item, i) => (
              <li
                key={"dp" + i}
                class={`${i == 0 ? `active` : ``}`}
                id={`v-pills-${item.pill}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#v-pills-${item.pill}`}
                role="tab"
                aria-controls={`v-pills-${item.pill}`}
                aria-selected="true"
              >
                  <a href="#" onClick={handleMenuItemClick}>{item.name}</a>
              </li>
              ))}
          </ul>
      </div>
    </div>
    </>
  );

  // <div class="row d-block d-sm-none" style="position: relative; margin-bottom: 50%;">

  //                               <div class="col-12" style="height: 300px; position: relative; top: 10px;">

  //                                   <input type="checkbox" id="menu" name="menu" class="menu-checkbox">
  //                                   <div class="menu">
  //                                       <label class="menu-toggle" for="menu"><span>Toggle</span></label>
  //                                       <ul>
  //                                           <li>
  //                                               <a href="#">Baccarat</a>
  //                                           </li>
  //                                           <li>
  //                                               <a href="#">Roulette</a>
  //                                           </li>
  //                                           <li>
  //                                               <a href="#">Andar Bahar</a>
  //                                           </li>
  //                                           <li>
  //                                               <a href="#">Dragon Tiger</a>
  //                                           </li>
  //                                           <li>
  //                                               <a href="#">Lucky 7</a>
  //                                           </li>
  //                                       </ul>
  //                                   </div>

  //                               </div>
  //                               <div class="col-12" style="position: absolute; top: 25%; left: 0px; z-index: 0;">

  //                                   <div class="table-responsive">


  //                                       <table class="table table-dark table-striped">

  //                                           <tbody>
  //                                               <tr>
  //                                                   <th scope="row"
  //                                                       style="color: #25ac5b; font-size: 16px; font-weight: 300;">
  //                                                       Game id</th>
  //                                                   <th style="color: #25ac5b; font-size: 16px; font-weight: 300;">
  //                                                       Date/ Time</th>
  //                                                   <th style="color: #25ac5b; font-size: 16px; font-weight: 300;">
  //                                                       Total Bet</th>


  //                                                   <th style="color: #25ac5b; font-size: 16px; font-weight: 300;">
  //                                                       &nbsp;
  //                                                   </th>
  //                                               </tr>

  //                                               <tr style="color: white; vertical-align:middle;">
  //                                                   <td scope="row">1939638</td>
  //                                                   <td>2023-09-28 20:42:05</td>
  //                                                   <td>6,120</td>


  //                                                   <td><a href="" data-bs-toggle="modal" data-bs-target="#historyinfo">
  //                                                           <div class="newcashierButton" style="min-width: 100px;">
  //                                                               VIEW
  //                                                           </div>
  //                                                       </a>

  //                                                   </td>
  //                                               </tr>
  //                                               <tr style="color: white; vertical-align:middle;">
  //                                                   <td scope="row">1939638</td>
  //                                                   <td>2023-09-28 20:42:05</td>
  //                                                   <td>6,120</td>


  //                                                   <td><a href="">
  //                                                           <div class="newcashierButton" style="min-width: 100px;">
  //                                                               VIEW
  //                                                           </div>
  //                                                       </a></td>


  //                                               </tr>

  //                                               <tr style="color: white; vertical-align:middle;">
  //                                                   <td scope="row">1939638</td>
  //                                                   <td>2023-09-28 20:42:05</td>
  //                                                   <td>6,120</td>


  //                                                   <td><a href="">
  //                                                           <div class="newcashierButton" style="min-width: 100px;">
  //                                                               VIEW
  //                                                           </div>
  //                                                       </a></td>


  //                                               </tr>

  //                                           </tbody>
  //                                       </table>
  //                                   </div>
  //                               </div>
  //                           </div>
};

export default GameHistoryNavBarMobile;
