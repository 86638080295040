import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { useQRPayment } from "../../services/cashier";

const QRPayment = () => {
  const QRImage = useQRPayment();

  return (
    <div
      className="tab-pane fade"
      id="v-pills-QR"
      role="tabpanel"
      aria-labelledby="v-pills-settings-tab"
    >
      <div className="row">
        <div className="col-12 col-lg-7 col-md-7 col-sm-7 col-xl-7 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4 offset-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12">
                  <div className="row text-center">
                    <div className="col-12 mb-2 mb-lg-3">
                      <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">QR Payments</label>
                    </div>
                    <div className="col-12 mt-2 mt-lg-3">
                      {!QRImage.isLoading && QRImage.data?.[0]?.value && (
                        <img
                          src={`${IMAGE_BUCKET_URL}${QRImage.data[0].value}`}
                          className="img-fluid"
                          alt="QR Code"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <WhatsappButton /> */}
    </div>
  );
};

export default QRPayment;
