import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const ForgotPasswordSucess = () => {
    return ( 
        <div className="text-center">
            <i className="fas fa-clock fa-4x"></i>
            <h2 className="mainTitel"><TranslatesContent contentKey="successful-password-reset"/></h2>
            <p className="text-center">
            <TranslatesContent contentKey="password-reset-cont"/>
            </p>
        </div>
     );
}
 
export default ForgotPasswordSucess;