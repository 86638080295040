import _ from "lodash";
import {  useState,useEffect } from "react";

import { useGameHistory } from "../../services/cashier";
import Boards from "../HistoryCommon/Boards";

const Baccarat = ({ game, gameID, i }) => {
  var moment = require("moment"); // require

  const [gameData, setGameData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null); 
  const { data, isLoading, status} = useGameHistory(gameID);

  const openModal = (game) => {
    setSelectedGame(game);
  };
  
  useEffect(() => {
    if(gameID == "ROU" ){
      const totals = [];

      isLoading == false && data.length > 0 && data.map(item => {
        const obj=totals.find(o=>o.game_id == item.game_id)
        if(obj){
          obj.betamount= parseInt(obj.betamount)+ parseInt(item.betamount);
          obj.winamount= parseInt(obj.winamount)+ parseInt(item.winamount);
          obj.betPositions.push({ type:item.bet_type, amount:item.betamount })
        }else{
          totals.push({...item,betPositions:[{ type:item.bet_type, amount:item.betamount }]});
        }
      })
      setGameData(totals.slice(0, 10))
    }else{
      const totals = [];

      isLoading == false && data.length > 0 && data.map(item => {
        const obj=totals.find(o=>o.game_id == item.game_id)
        if(obj){
          obj.betamount= parseInt(obj.betamount)+ parseInt(item.betamount);
          obj.winamount= parseInt(obj.winamount)+ parseInt(item.winamount);
          obj.betPositions.push({ type:item.bet_type, amount:item.betamount })
        }else{
          totals.push({...item,betPositions:[ { type:item.bet_type, amount:item.betamount } ]});
        }
      })
      setGameData(totals.slice(0, 10))

      // let filtered=data?.slice(0, 10).filter((item)=> item.tbid.slice(0, 3) == gameID)
      // setGameData(filtered);
      // console.log("RRRRRRRRRR",filtered);
      }
  },[data]);


  return (
    <>
      <div
        className={i == 0 ? "tab-pane fade active show" : "tab-pane fade"}
        // id="v-pills-profile"
        id={"v-pills-" + game}
        role="tabpanel"
        // aria-labelledby="v-pills-baccarat-tab"
        aria-labelledby={"v-pills-" + game + "-tab"}
      >

        <div className="table-responsive">
          <table className="table table-dark table-striped history">
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}
                >
                  Game ID
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  Date / Time
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  Total Bet
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  Total Win
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  Table ID
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  Result
                </th>
                <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                  &nbsp;
                </th>
              </tr>

              {gameData && gameData.length > 0 ? gameData.map((item, i) => (
                      <tr style={{ color: "white", verticalAlign: "middle" }}>
                        <td scope="row">{item.game_id}</td>
                        <td>{moment(item.datetime).format(
                              "DD-MM hh:mm"
                            )}</td>
                        <td>{item.betamount}</td>
                        <td>{item.winamount}</td>
                        <td>{item.tbid}</td>
                        <td>{item.result}</td>
                        <td>
                        <a
                          href="#"
                          onClick={() => openModal(item)}
                          data-bs-toggle="modal"
                          data-bs-target={`#historyinfodesktop-${i}-${item.game_id}`}
                        >
                          <div class="newcashierButton" style={{ minWidth: '100px' }}>
                            VIEW
                          </div>
                        </a>
                          {/* <a
                            style={{ minWidth: "100px" }}
                            href=""
                            data-bs-toggle="collapse"
                            data-bs-target={"#mybets" + i}
                            aria-expanded="false"
                            aria-controls={"mybets" + i}
                          >
                            <div
                              class="newcashierButton"
                              style={{ minWidth: "100px" }}
                            >
                              MY BETS
                            </div>
                          </a> */}
                          <div class="modal fade" id={`historyinfodesktop-${i}-${item.game_id}`} tabindex="-1" aria-labelledby={`historyinfodesktopLabel-${i}-${item.game_id}`} aria-hidden="true" style={{backgroundColor: "rgba(0, 0, 0, 0.63)"}}>
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor: "white"}}></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                                  <div className="col-12 game-result-mobile">
                                                    <div className="table-responsive">
                                                      <table className="table table-dark table-striped">  
                                                        <tbody>
                                                          <tr>
                                                            <th
                                                              scope="row"
                                                              style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}
                                                            >
                                                              Game ID
                                                            </th>
                                                            <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                                                              Date / Time
                                                            </th>
                                                            <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                                                              Result
                                                            </th>
                                                          </tr>
                                                          <tr style={{ color: "white", verticalAlign: "middle" }}>
                                                            <td scope="row">{item.game_id}</td>
                                                            <td>{moment(item.datetime).format(
                                                                    "DD-MM hh:mm"
                                                                  )}</td>
                                                            <td>{item.result}</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="table-responsive">
                                                      <table className="table table-dark table-striped">
                                                        <tbody>
                                                          <tr>
                                                            <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                                                              Total Bet
                                                            </th>
                                                            <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                                                              Total Win
                                                            </th>
                                                            <th style={{ color: "#25ac5b", fontSize: 16, fontWeight: 300 }}>
                                                              Table ID
                                                            </th>
                                                          </tr>
                                                          <tr style={{ color: "white", verticalAlign: "middle" }}>
                                                            <td>{item.betamount}</td>
                                                            <td>{item.winamount}</td>
                                                            <td>{item.tbid}</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>

                                            {/* <div class="col-12"> <object data="images/bet-tble-bet_area.svg"> </object></div> */}
                                          <Boards item={item} i={i} key={i} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                  )):(
                    <tr><td colSpan={6} style={{textAlign:'center'}}>No data Found...!</td></tr>
                  ) }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Baccarat;
