import { CREATE_BANK_ACCOUNT_URL } from "../../DataStore/ApiConstant";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../constants";
import { useBankAccounts } from "../../services/cashier";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const BankAccountForm = ({setHideWithdraw}) => {
  const [bankName, setBankName] = useState("");
  const [accountName, setaccountName] = useState("");
  const [Branch, setBranch] = useState("");
  const [ifsc, setifsc] = useState("");
  const [accNum, setaccNum] = useState("");
  const [err, setErr] = useState("");
  const bankAccounts = useBankAccounts();
  const [load, setLoad] = useState(false);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const createBankAccount = () => {
    setLoad(true);
    let fomData = new FormData();
    fomData.append("bank_name", bankName);
    fomData.append("account_name", accountName);
    fomData.append("bank_branch", Branch);
    fomData.append("ifsc_code", ifsc);
    fomData.append("account_number", accNum);

    axios
      .post(CREATE_BANK_ACCOUNT_URL, fomData)
      .then((resp) => {
        if (resp.data == "done") {
          setSuccessMsg("Bank account created successfully");
          setLoad(false);

          bankAccounts.refetch();
          setBankName("");
          setaccountName("");
          setBranch("");
          setifsc("");
          setaccNum("");
          setErr("");
          setTimeout(()=>{
            document.getElementById('collapseaddnewbank').classList.remove('show')
            document.getElementById('bankMainCollapse').classList.add('collapsed')
            document.getElementById('bankMainCollapse').setAttribute('aria-expanded',false)
            setHideWithdraw(false);
            setSuccessMsg("")
          }, 1500);
        } else {
          setErr(resp.data);
          setLoad(false);
        }
      })
      .catch((err) => {
        setErrmsg("Bank account created successfully");
        setLoad(false);
        // console.log(err);
      });
  };

  return (
    <>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="bank-name" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setBankName(e.target.value)}
          value={bankName}
        />
      </div>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="account-name" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setaccountName(e.target.value)}
          value={accountName}
        />
      </div>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="bank-branch" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setBranch(e.target.value)}
          value={Branch}
        />
      </div>
      <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="ifsc-code" />*
        </label>
        <input
          className="form-control"
          type="text"
          onChange={(e) => setifsc(e.target.value)}
          value={ifsc}
        />
      </div>
      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-4">
        <label>
          <TranslatesContent contentKey="account-number" />*
        </label>
        <input
          className="form-control"
          type="number"
          onChange={(e) => setaccNum(e.target.value)}
          value={accNum}
        />
      </div>
      <br />
      <div className="form-message m-2" style={{ color: "red" }}>
        {err}
      </div>
      <br />
      {successMsg && (
        <div style={{ marginTop: "30px" }} className="alert alert-success">
          {successMsg}
        </div>
        // <div className="alert alert-success">{successMsg}</div>
      )}

      {/* bank payment error */}
      {errmsg && (
        <div style={{ marginTop: "30px" }} className="alert alert-danger">
          {errmsg}
        </div>
        // <div class="alert alert-danger" role="alert">
        //   {errmsg}
        // </div>
      )}
      <div className="row">
        <div className="col-6 col-xl-5 col-lg-5 col-md-5 col-sm-5  mt-4">
          <div className="col-6 col-xl-5 col-lg-5 col-md-5 col-sm-5  mt-4">
            <div
              disabled={load}
              className="newcashierButton"
              onClick={() => createBankAccount()}
              style={{ width: "220px", cursor: "pointer" }}
            >
              {load ? (
                "PROCESSING"
              ) : (
                <TranslatesContent contentKey="add-new-bank" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccountForm;
