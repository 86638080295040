const initState = {
    loading : false,
    data : '',
    error : ''
}

export const LoginReducer = (state = initState , action)=>{
    switch(action.type){
        case 'LOGIN_REQUEST':
        return {
            ...state,
            loading : true,
        }
        case 'LOGIN_RESPOND':
        return {
            ...state,
            loading : false,
            data : action.payload,
            error : ''
        }
        case 'LOGIN_ERROR':
        return {
            ...state,
            loading : false,
            error : action.payload,
            data : ''
        }
        default :
        return state
    }
}