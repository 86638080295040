import Bankayment from "../../Deposit/Bankayment";
import ByHandPayment from "../../Deposit/ByHand";
import CrediCardPayment from "../../Deposit/CreditCard";
import UpiPayment from "../../Deposit/UpiPayment";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const DepositFormsWrapper = ({ active }) => {
  return (
    <div
      class="tab-pane fade show active"
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <div class="d-flex align-items-start">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            class="nav-link active"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-home"
            type="button"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            UPI Payment
          </button>
          <button
            class="nav-link"
            id="v-pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-profile"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            Bank Payment
          </button>
          <button
            class="nav-link"
            id="v-pills-messages-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-messages"
            type="button"
            role="tab"
            aria-controls="v-pills-messages"
            aria-selected="false"
          >
            Credit Card
          </button>
          <button
            class="nav-link"
            id="v-pills-settings-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-settings"
            type="button"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          ></button>
        </div>
        <div
          class="tab-content"
          id="v-pills-tabContent"
          style={{
            width: "100%",
            padding: "20px",
            border: "1px solid #fcb503",
            backgroundColor: "black",
          }}
        >
          <UpiPayment />
          <Bankayment />
          <CrediCardPayment />
          <ByHandPayment />
        </div>
      </div>
    </div>
  );
};

export default DepositFormsWrapper;
