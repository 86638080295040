const initState = {
    loading : false,
    data : '',
    err : ''
}

export const FundsReducer = (state = initState , action)=>{
    switch (action.type) {
        case 'FUNDS_LOADING':
            return {
                ...state,
                loading : true
            }
        case 'FUNDS_SUCCESS':
            return {
                ...state,
                loading : false,
                data : action.data,
                err : ''
            }

        case 'FUNDS_ERROR':
            return {
                ...state,
                loading : false,
                data : '',
                err : action.err
            }
        
    
        default:
            return state;
    }
};