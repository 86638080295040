import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../Header/HeaderComponent";
import MainBanner from "../Banners/MainBanner";
import { useEffect, useRef } from "react";
import { fundTransfer } from "../../DataStore";
import { connect } from "react-redux";
import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { UserData } from "../../DataStore";

const PromotionInfoPage = ({
  userData,
  getUserData,
  getFUndTransfers,
  goToCashier,
  login,
  openPopup,
}) => {
  const bottomRef = useRef(null);
  const history = useHistory();
  const checkLogin = () => {
    // !sessionStorage.getItem("login") && history.push("/");
    localStorage.getItem('login')&&history.push('/lobby')

  };
  useEffect(() => {
    checkLogin();
    getUserData();
    getFUndTransfers();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-xl-2 offset-md-2 offset-sm-0">
          <div className="row">
            <div className="col-12">
              <img
                src={IMAGE_BUCKET_URL + "promo-1.jpg"}
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="col-12 my-4">
              <h5 className="card-title">€6,000,000 is up for grabs!</h5>
            </div>
            <div className="col-12">
              <p className="card-text">
                Experience Live Dealers by Pragmatic Play and claim your share.
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, <br /> as opposed to using
                'Content here, content here', making it look like readable
                English. Many desktop publishing packages and web page editors
                now use Lorem Ipsum as their default model text, and a search
                for 'lorem ipsum' will uncover many web sites still in their
                infancy. Various versions have evolved over the years, sometimes
                by accident, sometimes on purpose (injected humour and the like.{" "}
                <br />
                <br /> as opposed to using 'Content here, content here', making
                it look like readable English. Many desktop publishing packages
                and web page editors now use Lorem Ipsum as their default model
                text, and a search for 'lorem ipsum' will uncover many web sites
                still in their infancy. Various versions have evolved over the
                years, sometimes by accident, sometimes on purpose (injected
                humour and the like).
              </p>
            </div>
            <div className="col-12 my-4">
              <div className="col-6 col-lg-4 col-md-4 col-sm-4 offset-lg-4 offset-md-4 offset-sm-4 offset-3">
                {login ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      goToCashier("deposit");
                    }}
                  >
                    <div className="newcashierButton"> DEPOSIT NOW</div>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      openPopup("login");
                    }}
                  >
                    <div className="newcashierButton"> DEPOSIT NOW</div>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(maStateTorops, mapDispatchToProps)(PromotionInfoPage);
