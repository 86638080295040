
const SlotContent = ({ games, login, openPopup, gameResult }) => {
  return (
    <div className="row mt-2">
    {gameResult.length > 0 ? gameResult?.map((item, key) =>{
    return (
    <div style={{ position: "relative" }}  className="col-lg-2 col-md-4 col-sm-4 col-6 mx-0 px-1 mb-2" key={key}>             
          <div className="row m-0 p-1">
            <div className="col-12 ms-0 p-0">
              {
                login ? 
                <div>
                  <div className=" content-overlay text-center pt-5" >
                    <a className="hoverButton " href={`/api/slot/play?service=${item.System}&gameCode=${item.PageCode}`} target="_blank">
                      PLAY
                    </a>
                  </div>
                    <img alt="" src={item.ImageFullPath} className="img-fluid mx-auto d-block shineImg"/>
                </div>
              :
                  <div>
                    <div className=" content-overlay text-center pt-5" >
                      <a className="hoverButton " onClick={()=>openPopup('login')}>PLAY</a>
                    </div>
                        <img alt="" src={item.ImageFullPath} className="img-fluid mx-auto d-block shineImg"/>
                  </div>
             }
              </div>
          </div>
      </div>
        )
     }):<p className="mt-5" style={{textAlign : "center", fontSize : "25px"}}>No Games Found</p>}
    </div>
    );
};

export default SlotContent;
