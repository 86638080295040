import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { RequestLogin } from "../../../DataStore";
import TranslatesContent from "../../../DataStore/Languages/translatesContent";
import CaptchaComponent from './CaptchaComponent';

const LoginFormNew = (props) => {
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [c, setC] = useState(0);
  const [captcha, setCaptcha] = useState('');
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [locked, setLocked] = useState(false);
  const [validity, setValidity] = useState("");
  const [showError, setShowError] = useState("");
  const [hideButton, setHideButton] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    generateCaptcha();
    const savedUsername = localStorage.getItem('rememberedUsername');
    if (savedUsername) {
      setUsername(savedUsername);
      setRememberMe(true);
    }
  }, []);

  const generateCaptcha = () => {
    const newA = Math.ceil(Math.random() * 10);
    const newB = Math.ceil(Math.random() * 10);
    setA(newA);
    setB(newB);
    setC(newA + newB);
  };

  const checkCaptcha = (e) => {
    setShowError("");
    setCaptcha(e);
    if (parseInt(e) === c) {
      setValidCaptcha(true);
      setValidity("valid");
      setHideButton(false);
    } else {
      setValidCaptcha(false);
      setHideButton(true);
      setShowError("Incorrect value");
      setValidity("unvalid");
    }
  };

  const handleRefresh = () => {
    if (!locked) {
      setValidity("unvalid");
      setHideButton(true);
      setLocked(true);
      setTimeout(() => {
        setLocked(false);
        generateCaptcha();
        setValidCaptcha(false);
      }, 500);
    }
  };

  const changeContent = props.changeContent;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [loginErr, setLoginErr] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");

  const submitLogin = (e) => {
    setSpLoad(true);
    e.preventDefault();
    const data = { username, password };
    props.loginRequest(data, (respond) => {
      if (respond.data === "done") {
        setLogin();
      } else {
        setLoginErr(respond.data);
        setLoginSuccess("");
        setSpLoad(false);
      }
    });
  };

  const [spLoad, setSpLoad] = useState(false);

  const setLogin = () => {
    setLoginErr("");
    setLoginSuccess("Logged in Successfully");
    localStorage.setItem('login', username);
    if (rememberMe) {
      localStorage.setItem('rememberedUsername', username);
    } else {
      localStorage.removeItem('rememberedUsername');
    }
    setTimeout(() => {
      history.push("/lobby");
      setSpLoad(false);
    }, 1000);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-md-12 col-sm-12">
        <div className="col-12">
          <div className="newJoinPopupTitel2">Login</div>
          <div className="newJoinPopupTitel">
            Not a member yet? |{" "}
            <a href=""
               onClick={(e) => {
                e.preventDefault();
                changeContent("register");
              }}
              style={{ color: "#f9ae07" }}>
              Sign Up
            </a>
          </div>
        </div>
        <div className="col-12">
          <section>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 mt-2 mb-2">
                  <div className="form-area">
                    <div className="form-inner">
                      <form onSubmit={(e) => submitLogin(e)}>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            User ID
                          </label>
                          <input
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="User ID"
                          />
                        </div>
                        <div className="form-group d-flex justify-content-end">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMeCheck"
                            style={{ marginRight: 5 }}
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                          <label className="form-check-label" htmlFor="rememberMeCheck">
                            Remember me
                          </label>
                        </div>
                        <div className="password-field" style={{ position: 'relative' }}>
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Password
                          </label>
                          <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={passwordVisible ? "text" : "password"}
                            id="fakePassword"
                            placeholder="Password"
                            className="form-control"
                          />
                          <i
                            id="toggler"
                            className={`fa-solid fa-eye${passwordVisible ? "-slash" : ""}`}
                            onClick={togglePasswordVisibility}
                            style={{
                              color:'black',
                              position: 'absolute',
                              right: '10px',
                              top: '70%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer'
                            }}
                          />
                        </div>
                        <div className="form-group d-flex justify-content-end">
                          <label className="form-check-label" htmlFor="forgotPassword">
                            <a href="" 
                              onClick={(e) => {
                                e.preventDefault();
                                changeContent("password-reset");
                              }}
                              style={{ color: "#f9ae07" }}>
                                Forgot Password
                            </a>
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="submit__control">
                            <div className="row align-items-center">
                              <div className="col-12 col-lg-45 col-md-5 col-sm-5">
                                <h3>Are you human?</h3>
                              </div>
                              <div className="col-12 col-lg-7 col-md-7 col-sm-7 text-center">
                                <div className={`submit__generated ${validity}`}>
                                  <span>{a}</span> + <span>{b}</span> = {" "}
                                  <input
                                    className="submit__input"
                                    type="text"
                                    value={captcha}
                                    onChange={(e) => checkCaptcha(e.target.value)}
                                    size="2"
                                    maxLength="2"
                                    required
                                  />
                                </div>
                                <i
                                  onClick={handleRefresh}
                                  disabled={locked}
                                  className="fa fa-refresh"
                                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                                />
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 text-center">
                                    {showError && (
                                      <span className="submit__error">
                                        {showError}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 text-center">
                                    <span className="submit__error--empty hide">
                                      Required field cannot be left blank
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                          <div className="login-err pb-2" style={{ color: "red", fontSize: "14px" }}>
                            {loginErr && loginErr}
                          </div>
                          <div
                            className="loginSuccess pb-2"
                            style={{ color: "green", fontSize: "14px" }}
                          >
                            {loginSuccess && loginSuccess}
                          </div>
                          <input
                            className="submit form-submit w-100 frontButton3"
                            type="submit"
                            value="Login"
                            disabled={hideButton}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginState: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (data, callback) => {
      dispatch(RequestLogin(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormNew);
