import { useQuery } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import ms from "ms";

export const usePromotions = () => {
  const apiClient = new APIClient("/siteApi/promotion");
  return useQuery({
    queryKey: ["promotions"],
    queryFn: apiClient.getAll,
    // staleTime: ms("24h"),
  });
};
