const initState = {
    loading : false,
    data : '',
    err : ''
}

export const WithdorwDataReducer = (state = initState, action)=>{
    switch (action.type) {
        case 'WITHDROW_LODING' :
        return {
            ...state,
            loading : true
        }

        case 'WITHDROW_SUCCESS' :
            return {
                ...state,
                loading : false,
                data : action.data,
                err : action.err
            }

        case 'WITHDROW_ERR': 
        return {
            ...state,
            loading : false,
            data : '',
            err : action.err
        }

        default :   return state;
    }
}