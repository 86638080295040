import LiveCasinoArea from "../GameAreas/LiveCasino";
import SlotsArea from "../GameAreas/Slots";
import SportsArea from "../GameAreas/Sports";
import Promotions from "../Layouts/Promotions";

const GamesWrapper = ({
  selectedGameAreaType,
  login,
  openPopup,
  gameWrapperArea,
}) => {
  return (
    <div id="game-areas-wrapper" ref={gameWrapperArea}>
      {selectedGameAreaType &&
        (selectedGameAreaType === "liveCasino" ? (
          <LiveCasinoArea login={login} openPopup={openPopup} />
        ) : selectedGameAreaType === "slots" ? (
          <SlotsArea login={login} openPopup={openPopup} />
        ) : selectedGameAreaType === "sports" ? (
          <SportsArea login={login} openPopup={openPopup} />
        ) : (
          <Promotions login={login} openPopup={openPopup} />
        ))}
    </div>
  );
};

export default GamesWrapper;
