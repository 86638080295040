import { useEffect, useRef, useState } from "react";
import { useRouletteHistory } from "../../../services/gameBoards";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Boards = ({config, isLoad}) => {
  const [gameResult, setGameResult] = useState([]);
  const [gameConfig, setGameConfig] = useState([]);

  const {data, isLoading, status} = useRouletteHistory();
  const loadGame = (gameLink) => {
    window.location.replace("/liveroulette/#rou" + gameLink);
  };
// let data={

//     "1": [
//         "ROU-1",
//         [
//             [
//                 [
//                     "9",
//                     "red"
//                 ],
//                 [
//                     "26",
//                     "black"
//                 ],
//                 [
//                     "13",
//                     "black"
//                 ],
//                 [
//                     "7",
//                     "red"
//                 ],
//                 [
//                     "23",
//                     "red"
//                 ],
//                 [
//                     "15",
//                     "black"
//                 ],
//                 [
//                     "10",
//                     "black"
//                 ],
//                 [
//                     "28",
//                     "black"
//                 ],
//                 [
//                     "12",
//                     "red"
//                 ],
//                 [
//                     "4",
//                     "black"
//                 ],
//                 [
//                     "29",
//                     "black"
//                 ]
//             ],
//             {
//                 "hot": [
//                     [
//                         "19",
//                         "red"
//                     ],
//                     [
//                         "7",
//                         "red"
//                     ],
//                     [
//                         "30",
//                         "red"
//                     ],
//                     [
//                         "23",
//                         "red"
//                     ]
//                 ],
//                 "cool": [
//                     [
//                         "33",
//                         "black"
//                     ],
//                     [
//                         "35",
//                         "black"
//                     ],
//                     [
//                         "4",
//                         "black"
//                     ],
//                     [
//                         "24",
//                         "black"
//                     ]
//                 ],
//                 "statictics": {
//                     "green": 4,
//                     "red": 55,
//                     "black": 41,
//                     "odd": 53,
//                     "even": 47,
//                     "1-18": 48,
//                     "19-36": 48
//                 }
//             }
//         ]
//     ],
//     "2": [
//         "ROU-2",
//         [
//             [
//                 [
//                     "27",
//                     "red"
//                 ],
//                 [
//                     "6",
//                     "black"
//                 ],
//                 [
//                     "20",
//                     "black"
//                 ],
//                 [
//                     "7",
//                     "red"
//                 ],
//                 [
//                     "35",
//                     "black"
//                 ],
//                 [
//                     "15",
//                     "black"
//                 ],
//                 [
//                     "6",
//                     "black"
//                 ],
//                 [
//                     "11",
//                     "black"
//                 ],
//                 [
//                     "1",
//                     "red"
//                 ],
//                 [
//                     "10",
//                     "black"
//                 ],
//                 [
//                     "32",
//                     "red"
//                 ]
//             ],
//             {
//                 "hot": [
//                     [
//                         "19",
//                         "red"
//                     ],
//                     [
//                         "7",
//                         "red"
//                     ],
//                     [
//                         "27",
//                         "red"
//                     ],
//                     [
//                         "6",
//                         "black"
//                     ]
//                 ],
//                 "cool": [
//                     [
//                         "26",
//                         "black"
//                     ],
//                     [
//                         "0",
//                         "green"
//                     ],
//                     [
//                         "16",
//                         "red"
//                     ],
//                     [
//                         "25",
//                         "red"
//                     ]
//                 ],
//                 "statictics": {
//                     "green": 1,
//                     "red": 55,
//                     "black": 44,
//                     "odd": 52,
//                     "even": 48,
//                     "1-18": 50,
//                     "19-36": 49
//                 }
//             }
//         ]
//     ],

// }
useEffect(() => {
    if(!isLoad ){
      if(config){
          let data = Object.values(config)
            data[1]["rou"][1].shift();
        //   console.log(data[1]["rou"][1]);
          setGameConfig(data[1]["rou"][1] || []);
      }
    }
  },[isLoad])
useEffect(() => {
    if(!isLoading){
        let dataObjec = Object.keys(data)
        setGameResult(dataObjec)
    }
    
    // if(data){
    //     let keys =  Object.keys(data[1][1][1].statictics)
        
    //     keys.forEach(element => {
    //         let ele = document.getElementById("newRouletteRes"+element)
    //         if(ele){
    //             ele.innerHTML = data[1][1][1].statictics[element] + "%"
    //         }
    //     });

    //     for (let i = (data[1][1][0].length - 1); i >= 0; i--) {
    //         // let oneof = lastTen[i];
    //         let element2 = document.getElementById('result-d-' + i);
    //         if(element2){
    //             if(i == 0){
    //             element2.innerHTML = data[1][1][0][i][0]
    //             element2.style.backgroundColor = data[1][1][0][i][1]
    //             element2.style.color = "#fff"
    //             element2.style.border = "1px solid #fff"

    //             }else{
    //                 element2.innerHTML = data[1][1][0][i][0]
    //                 element2.style.color = data[1][1][0][i][1]
    //             }
    //         }
    
    //     }
    // }
}, [isLoading]);

// {
//     "1": [
//         "ROU-1",
//         [
//             [
//                 [
//                     "1",
//                     "red"
//                 ],
//                 [
//                     "13",
//                     "black"
//                 ],
//                 [
//                     "3",
//                     "red"
//                 ],
//                 [
//                     "23",
//                     "red"
//                 ],
//                 [
//                     "27",
//                     "red"
//                 ],
//                 [
//                     "17",
//                     "black"
//                 ],
//                 [
//                     "17",
//                     "black"
//                 ],
//                 [
//                     "13",
//                     "black"
//                 ],
//                 [
//                     "36",
//                     "red"
//                 ],
//                 [
//                     "4",
//                     "black"
//                 ],
//                 [
//                     "2",
//                     "black"
//                 ]
//             ],
//             {
//                 "hot": [
//                     [
//                         "12",
//                         "red"
//                     ],
//                     [
//                         "2",
//                         "black"
//                     ],
//                     [
//                         "0",
//                         "green"
//                     ],
//                     [
//                         "30",
//                         "red"
//                     ]
//                 ],
//                 "cool": [
//                     [
//                         "5",
//                         "red"
//                     ],
//                     [
//                         "22",
//                         "black"
//                     ],
//                     [
//                         "25",
//                         "red"
//                     ],
//                     [
//                         "7",
//                         "red"
//                     ]
//                 ],
//                 "statictics": {
//                     "green": 5,
//                     "red": 45,
//                     "black": 50,
//                     "odd": 43,
//                     "even": 57,
//                     "1-18": 47,
//                     "19-36": 48
//                 }
//             }
//         ]
//     ]
// }
  return (
    <div className="row">
         {gameResult.map((result, tbl) => {
         return <div className="col-lg-4 col-lg-4" style={{ position: "relative" }}>
            {
                 gameConfig ? gameConfig[tbl] ? gameConfig[tbl][4] == 0 ?(
                 <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose">
                    <div className="row ">
                        <div className="tableCloseText ">Closed
                        </div>
                    </div>
                </div>) : 
              (
                <div className="content-overlay text-center pt-5">
                    <a className="hoverButton " onClick={() => loadGame(parseInt(tbl)+1)}>
                      PLAY
                    </a>
                </div>
              ):
              (
                null
               ):(
                null
               )
              }
            <div className="row  border rounded border-white p-1" key={tbl}>
    
            <div className="row m-0 bg-white">
                <div className="col-12 text-center my-3">
                    <div className="d-flex justify-content-center ">
                        {
                            data[result][1][0].map((item,i)=>{
                                if(i == 0){
                                    return <div id="result-d-0" className="flex-fill newRouletteResultBoxRed active" style={{border:'1px solid #fff', color:'#fff', backgroundColor: item[1]}}>{item[0]}</div>
                                }else{
                                    return  <div id="result-d-1" className="flex-fill" style={{color:item[1]}}>{item[0]}</div> ;
                                  }
                            })
                        }
                    </div>
                </div>
                <div className="col-12 text-center mb-3">
                    <div className="d-flex justify-content-center ">
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">RED</div>
                            <div id= "newRouletteResred" className="newRouletteResRed">{data[result][1][1].statictics?.['red'] }%</div>
                        </div>
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">BLACK</div>
                            <div id= "newRouletteResblack" className="newRouletteResBlack">{data[result][1][1].statictics?.['black'] }%</div>
                        </div>
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">1-18</div>
                            <div id= "newRouletteRes1-18" className="newRouletteRes118">{data[result][1][1].statictics?.['1-18'] }%</div>
                        </div>
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">19-36</div>
                            <div id= "newRouletteRes19-36" className="newRouletteRes1936">{data[result][1][1].statictics?.['19-36'] }%</div>
                        </div>
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">ODD</div>
                            <div id= "newRouletteResodd" className="newRouletteResOdd">{data[result][1][1].statictics?.['odd'] }%</div>
                        </div>
                        <div className="flex-fill">
                            <div className="newRouletteResText text-black">EVEN</div>
                            <div id= "newRouletteReseven" className="newRouletteResEven">{data[result][1][1].statictics?.['even'] }%</div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-12">
                <div className="row">
                    <div className="col-6 tableCountBoardName">{data[result][0]}</div>
                    <div className="col-6">
                        <div className="tableCountBoardLimit text-end">
                        {gameConfig ? gameConfig[tbl] ? gameConfig[tbl][2] : 'N/A':'N/A'}
                        -{" "}
                        {gameConfig ? gameConfig[tbl] ? gameConfig[tbl][3] : 'N/A':'N/A'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        })}
        {/* <div class="col-lg-4 col-lg-4" style={{ position: "relative" }}> */}
        {
            //      !data ? (
            //      <div class="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose">
            //         <div class="row ">
            //             <div class="tableCloseText ">Closed
            //             </div>
            //         </div>
            //     </div>) : 
            //   (
            //     <div class="content-overlay text-center pt-5">
            //         <a class="hoverButton " onClick={() => loadGame("rou1")}>
            //           PLAY
            //         </a>
            //     </div>
            //   )
              }
            {/* <div class="content-overlay text-center pt-5 ">
            <a class="hoverButton " onClick={() => loadGame("rou1")}>
                  PLAY
                </a>
            </div> */}
            {/* <div class="row  border rounded border-white p-1">
                <div class="row m-0 bg-white">
                    <div class="col-12 text-center my-3">
                        <div class="d-flex justify-content-center ">
                            <div id="result-d-0" class="flex-fill newRouletteResultBoxRed active" style={{border:'1px solid #fff',color:'#fff'}}>12</div>
                            <div id="result-d-1" class="flex-fill newRouletteResultBoxRed">3</div>
                            <div id="result-d-2" class="flex-fill newRouletteResultBoxBlack">32</div>
                            <div id="result-d-3" class="flex-fill newRouletteResultBoxRed">14</div>
                            <div id="result-d-4" class="flex-fill newRouletteResultBoxGreen">0</div>
                            <div id="result-d-5" class="flex-fill newRouletteResultBoxRed">2</div>
                            <div id="result-d-6" class="flex-fill newRouletteResultBoxBlack">25</div>
                            <div id="result-d-7"class="flex-fill newRouletteResultBoxBlack">20</div>
                            <div id="result-d-8" class="flex-fill newRouletteResultBoxRed">27</div>
                            <div id="result-d-9" class="flex-fill newRouletteResultBoxRed">9</div>
                            <div id="result-d-10" class="flex-fill newRouletteResultBoxRed">10</div>
                        </div>
                    </div>
                    <div class="col-12 text-center mb-3">
                        <div class="d-flex justify-content-center ">
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">RED</div>
                                <div id= "newRouletteResred" class="newRouletteResRed">44%</div>
                            </div>
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">BLACK</div>
                                <div id= "newRouletteResblack" class="newRouletteResBlack">53%</div>
                            </div>
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">1-18</div>
                                <div id= "newRouletteRes1-18" class="newRouletteRes118">53%</div>
                            </div>
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">19-36</div>
                                <div id= "newRouletteRes19-36" class="newRouletteRes1936">48%</div>
                            </div>
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">ODD</div>
                                <div id= "newRouletteResodd" class="newRouletteResOdd">28%</div>
                            </div>
                            <div class="flex-fill">
                                <div class="newRouletteResText text-black">EVEN</div>
                                <div id= "newRouletteReseven" class="newRouletteResEven">12%</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-6 tableCountBoardName">ROU-1 </div>
                        <div class="col-6">
                            <div class="tableCountBoardLimit text-end">10 - 10,000</div>
                        </div>
                    </div>
                </div>
            </div> */}
        {/* </div> */}
    </div>
  );
};

export default Boards;
