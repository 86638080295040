import axios from "axios";
import { WITHDROWMETHODS_URL } from "../../../ApiConstant";

export const withdrowMethodActionsLoading = () => {
  return {
    type: "WITHDROW_METHOD_LODING",
  };
};

export const withdrowMethodActionsSuccess = (data) => {
  return {
    type: "WITHDROW_METHOD_SUCCESS",
    data: data,
  };
};

export const withdrowMethodActionsErr = (err) => {
  return {
    type: "WITHDROW_METHOD_ERR",
    err: err,
  };
};

export const getWithdrowMethods = () => {
  return (dispatch) => {
    dispatch(withdrowMethodActionsLoading);
    axios
      .get(WITHDROWMETHODS_URL)
      .then((response) => {
        dispatch(withdrowMethodActionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(withdrowMethodActionsErr(err));
      });
  };
};
