import { useEffect,useState } from "react";
import { useLowBalance } from "../../../services/config"
import { IMGBUCKETURL } from "../../../DataStore/ApiConstant";


const LowBalanceBanner = ({userData}) => {
  let lowBalance = useLowBalance()
  const [active, setActive] = useState(false);
  // console.log("sssssssssssssssssssssss",lowBalance.data[0].value);
  // console.log("balance",userData && userData?.data?.session?.username);
  useEffect(() => {
    !lowBalance.isLoading && lowBalance.data.length > 0 && lowBalance?.data[0]?.value > userData?.data?.data1?.amount && setActive(true)
    },[lowBalance.isLoading])
    return (
        <>
          <div >
            {
              !lowBalance.isLoading && lowBalance.data.length > 0 && active &&(
                <div className={"modal showModal"}  tabindex="-1"   aria-labelledby="exampleModalLabel"
                style={{
                  backgroundColor: "#000000a1",
                }}  >
                <div className={"modal-dialog modal-dialog-scrollable  modal-lg showModalcontent"}>
                  <div className="modal-content" style={{backgroundColor:'transparent'}}>
                  <div className="modal-header">
                    
                    <button
                      type="button" className="btn-close" onClick={(e) => {setActive(false) }} style={{ backgroundColor: "white", position: "absoulute"}} > 
                    </button>
                  </div>
                    <div style={{backgroundColor: "#524126b5"}} className="modal-body">
                        <h3 style={{fontSize: "25px"}}>Hi.. {userData && userData?.data?.session?.username}</h3>
                        <div class="mainTitel" style={{fontSize: "25px"}}>Your Account has Low Balance</div>
                        <button type="button" class="joinTopbutton" onClick={(e) => {setActive(false) }}>No
                            Thanks</button>
                        <a href="/cashier" class="joinTopbutton" style={{float: "right", textDecoration: "none", color: "white"}}>Take Me
                            To Cashier</a>
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            )
            }
          </div>
        </>
    
  );
};

export default LowBalanceBanner;
