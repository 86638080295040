import axios from "axios";
import { LOGIN_URL } from "../../ApiConstant";

export const loginRequest = () => {
  return {
    type: "LOGIN_REQUEST",
  };
};

export const loginRespond = (data) => {
  return {
    type: "LOGIN_RESPOND",
    payload: data,
  };
};

export const loginError = (error) => {
  return {
    type: "LOGIN_ERROR",
    payload: error,
  };
};

export const RequestLogin = (data, callback) => {
  return (dispatch) => {
    dispatch(loginRequest());
    axios
      .post(LOGIN_URL, data)
      .then((response) => {
        dispatch(loginRespond(response));
        callback(response);
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
};
