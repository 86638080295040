import { useQuery } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import ms from "ms";

export const useSportsLogin = () => {
  const apiClient = new APIClient("/sportBetting/sp_getLogin/");
  return useQuery({
    queryKey: ["spLogin"],
    queryFn: apiClient.getAll,
  });
};

// export const useGetEvent = (id) => {
//   const apiClient = new APIClient("/sportBetting/sp_getCompetition/" + id);
//   return useQuery({
//     queryKey: ["spEvent", id],
//     queryFn: apiClient.getAll,
//     staleTime: ms("24h"),
//   });
// };

export const useGetEvent = (id) => {
  const apiClient = new APIClient("/siteApi/sp_getCompetition/" + id);
  return useQuery({
    queryKey: ["spEvent", id],
    queryFn: apiClient.getAll,
    // staleTime: ms("24h"),
  });
};

export const useSportsData = () => {
  const apiClient = new APIClient("/api/sportBetting ");
  return useQuery({
    queryKey: ["spLogin"],
    queryFn: apiClient.getAll,
  });
};
