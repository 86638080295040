import axios from "axios";
import { GET_COUNTRIES_URL } from "../ApiConstant";

export const countriesLoading = () => {
  return {
    type: "COUNTRIES_LOADING",
  };
};

export const countriesLoaded = (data) => {
  return {
    type: "COUNTRIES_LOADED",
    data: data,
  };
};

export const countriesError = (err) => {
  return {
    type: "COUNTRYLIST_ERROR",
    err: err,
  };
};

export const countryList = () => {
  return (dispatch) => {
    dispatch(countriesLoading());
    axios
      .get(GET_COUNTRIES_URL)
      .then((resp) => {
        dispatch(countriesLoaded(resp.data));
      })
      .catch((err) => {
        dispatch(countriesError(err));
      });
  };
};
