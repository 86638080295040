import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const BonusPolicyComponent = () => {
    return ( 
        <div class="row">
            <TranslatesContent contentKey="bonus-policy-cont"/>
        {/* <div class="col-12 mainTitel">BONUS POLICY</div>

        <div class="col-12">
            <ol class="spcial-ol">
                <li>Players must fulfill the required turnover requirement (points) in order to transfer
                    or
                    withdraw.</li>
                <li>The bonus points must be met within 30 days or the remaining bonus amount shall
                    expire.</li>
                <li>Players can withdraw the bonus after fulfilling the turnover requirement.</li>
                <li>In case of early termination, the given bonus will be deducted from the wallet
                    value.</li>
                <li>Players who have enrolled for bonus program can only withdraw their winnings after
                    completing of
                    the required bonus points only.</li>
                <li>If customer still needs to withdraw the winnings, then the given Bonus will be
                    canceled. </li>
                <li>Any promotion cannot be used in conjunction with any other promotional offer. </li>
                <li>Only one account per player is allowed.</li>
                <li>Players opening multiple or fraudulent accounts will be disqualified from the
                    promotion.
                    Remaining amount could be forfeited and the account shall be frozen.</li>
                <li>ballysbreeze.com reserves the right to modify, alter, discontinue, cancel, refuse or
                    void this
                    promotion at its sole discretion.</li>
                <li>Participating players must accept and comply with the terms of this promotion.</li>
            </ol>

        </div> */}
    </div>
    );
}
 
export default BonusPolicyComponent;