import { IMAGE_BUCKET_URL } from "../../../DataStore/ApiConstant";

const AwardComponent = () => {
    return ( 
        <div className="row">
                        <div className="col-12 mainTitel">reward</div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <h3>Introduction</h3>
                                    <h4 className="text-center">Breeze VIP is an exclusive program to increase player's
                                        gaming
                                        experience in breeze.
                                        By using JW Points, players can upgrade their VIP level and receive exclusive
                                        benefits
                                        associated with each VIP level.</h4>
                                </div>
                                <div className="col-12 mb-3">
                                    <h3>VIP Level</h3>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4 offset-lg-1 offset-md-0 offset-0">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "bronze.png"} className="img-fluid" alt=""/></div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Bronze</h2>
                                        </div>
                                        <div className="col-12 text-center" style={{fontSize: "20px"}}>Deposit Player
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "silver.png"} className="img-fluid" alt=""/></div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Silver</h2>
                                        </div>
                                        <div className="col-12 text-center" style={{fontSize: "20px"}}>25,000
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "gold.png"} className="img-fluid" alt=""/></div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Gold</h2>
                                        </div>
                                        <div className="col-12 text-center" style={{fontSize: "20px"}}>150,000
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "platinum.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Platinum</h2>
                                        </div>
                                        <div className="col-12 text-center" style={{fontSize: "20px"}}>300,000
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "diamond.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Diamond</h2>
                                        </div>
                                        <div className="col-12 text-center" style={{fontSize: "20px"}}>500,000
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center mt-4">Enjoy Exclusive Benefits</div>
                                <div className="col-12 text-center mt-2">
                                    <button type="button" className="btn btn-warning joinTopbutton"  data-bs-target="#joinpopup">Join
                                        Now</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <h3>VIP Benefits</h3>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4 offset-lg-1 offset-md-0 offset-0">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "bronze.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Bronze</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>JW Points to Cash Redeem
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Monthly Bonus JW Points
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Faster Withdrawal
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Number
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Manager
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Lucky Draw
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "silver.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Silver</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>100,000 JW Points = INR 2,000
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Monthly Bonus JW Points =
                                                1,500
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Faster Withdrawal
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Number
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Manager
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Lucky Draw
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "gold.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Gold</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>100,000 JW Points = INR 2,000
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Monthly Bonus JW Points =
                                                1,500
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Faster Withdrawal
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Number
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Manager
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Lucky Draw
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "platinum.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Platinum</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>100,000 JW Points = INR 2,000
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Monthly Bonus JW Points =
                                                1,500
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Faster Withdrawal
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Number
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Manager
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Lucky Draw
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-4 col-lg-2 col-md-4 col-sm-4">
                                    <div className="row">
                                        <div className="col-12"><img src={IMAGE_BUCKET_URL + "diamond.png"} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h2 style={{fontSize: "22px"}}>Diamond</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>100,000 JW Points = INR 2,000
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Monthly Bonus JW Points =
                                                1,500
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-check" style={{color: "green"}}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>Faster Withdrawal
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Number
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Support Manager
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10" style={{fontSize: "0.7rem"}}>VIP Lucky Draw
                                            </div>
                                            <div className="col-2"><i className="fa-solid fa-x" style={{color: "red"}}></i></div>
                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
     );
}
 
export default AwardComponent;