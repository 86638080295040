import TranslatesContent from "../../../DataStore/Languages/translatesContent";

const GameGuideComponent = () => {
    return ( 
        <div className="row">
            <TranslatesContent contentKey="game-guide-cont"/>            
        </div>     
    );
}
 
export default GameGuideComponent;