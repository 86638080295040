const initState = {
    loading : false,
    data : '',
    error : ''
}

export const BaccaratResultReducer = (state = initState, action)=>{
        switch(action.type){
            case 'LOADING_BAC_RESULT' :
            return {
                ...state,
                loading : true
            }
            case 'BAC_RESULT_SUCCESS' :
            return {
                ...state,
                loading : false,
                data : action.data,
                error : ''
            }
            case 'BAC_RESULT_ERROR' :
            return {
                ...state,
                loading : true,
                data : '',
                error : action.error
            }
            default : return state;
        }
}