import axios from "axios";
// import WhatsappButton from "../Cashier/whatsappButton";
import BonusOffer from "../TransactionCommon/BonusOffer";
import CashierInput from "../../Components/TransactionCommon/CashierInput";
import { useWithdrawBonus } from "../../services/cashier";
import { TOAST_CONFIG } from "../../constants";
import { redeposit } from "../../constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { getFormData, validateNumberInput } from "../../utils";
import { WITHDROW_URL } from "../../DataStore/ApiConstant";
import { toast } from "react-toastify";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

import { calculateBonus, calculateTurnover } from "../../utils";

const Redeposit = () => {
  const bonus = useWithdrawBonus();
  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);
  const [amount, setAmount] = useState(null);
  const [load, setLoad] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");


  const { handleSubmit } = useForm();
  let submitHandler = (rest) => {
    setLoad(errmsg);
    rest = { ...redeposit, redeposit: amount };
    rest = { ...redeposit, bank_account: "" };
    axios
      .post(WITHDROW_URL, getFormData(rest))
      .then((resp) => {
        if (resp.data.resp === "done") {
          // toast.success("Redeposit Successful" || "err", TOAST_CONFIG);
          setSuccessMsg("Redeposit Successfull" || "err");
          setLoad(false);
        } else {
          // toast.error(resp?.data?.resp || "err", TOAST_CONFIG);
          setErrmsg(resp?.data?.resp);
          setLoad(false);
        }
      })
      .catch((err) => {
        setErrmsg(err);
        setLoad(false);
      });
  };

  let setRedepositBonus = (reAmount) => {
    setErrmsg("")
    setSuccessMsg("")
    setAmount(reAmount);
    let bonusRet = calculateBonus(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setReBonus(bonusRet);
    let turnRet = calculateTurnover(
      reAmount,
      bonus?.data[2]["maxbonus"] || 0,
      bonus?.data[2]["turnover"] || 0,
      bonus?.data[2]["bonusprecent"] || 0
    );
    setReTurn(turnRet);
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="v-pills-redeposit"
        role="tabpanel"
        aria-labelledby="v-pills-redeposit-tab"
      >
        <div className="row">
          <div className="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                    <div className="row">
                      <div className="col-12 col-12 col-lg-10 col-md-10 col-sm-10 col-xl-10 mt-4">
                        <div className="row">
                          <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="col-12">
                              <label
                                for="exampleFormControlInput1"
                                className="cashierNewTitel"
                              >
                                <TranslatesContent contentKey="redeposit-amount" />
                              </label>
                            </div>
                            <div className="col-12 col-12 col-lg-7 col-md-7 col-sm-7 col-xl-7 mt-4">
                              <input
                                onInput={(e) => validateNumberInput(e.target)}
                                value={amount}
                                type="number"
                                onChange={(e) =>
                                  setRedepositBonus(e.target.value)
                                }
                                className="form-control"
                                placeholder=""
                              />
                            </div>

                            <div className="col-12 mt-4">
                              <div className="row">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-12 col-lg-5 bonusResultLeft">
                                      <TranslatesContent contentKey="package-name" />{" "}
                                      :
                                    </div>
                                    <div className="col-12 col-lg-7 bonusResultRight">
                                      {bonus?.data?.[2]["pkgname"] || "N/A"}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-lg-5 bonusResultLeft">
                                      <TranslatesContent contentKey="bonus-amount" />{" "}
                                      :
                                    </div>
                                    <div className="col-12 col-lg-7 bonusResultRight">
                                      {reBonus}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-lg-5 bonusResultLeft">
                                      <TranslatesContent contentKey="withdrawal-eft-turnover" />{" "}
                                      :
                                    </div>
                                    <div className="col-12 col-lg-7 bonusResultRight">
                                      {reTurn}
                                    </div>
                                  </div>
                                  {successMsg && (
                                    <div
                                      style={{ marginTop: "30px" }}
                                      className="alert alert-success"
                                    >
                                      {successMsg}
                                    </div>
                                    // <div className="alert alert-success">{successMsg}</div>
                                  )}

                                  {/* bank payment error */}
                                  {errmsg && (
                                    <div
                                      style={{ marginTop: "30px" }}
                                      className="alert alert-danger"
                                    >
                                      {errmsg}
                                    </div>
                                    // <div className="alert alert-danger" role="alert">
                                    //   {errmsg}
                                    // </div>
                                  )}

                                  <div className="row mt-4">
                                    <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                                      <button
                                        type="submit"
                                        className="newcashierButton"
                                        disabled={load}
                                      >
                                        {load ? (
                                          "Process"
                                        ) : (
                                          <TranslatesContent contentKey="submit-cashier" />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BonusOffer disablePop={false} name={"redeposit-bonus"} bonusAmount={!bonus.isLoading && bonus?.data[2]["bonusprecent"]}/>
            </div>
          </div>
          {/* <WhatsappButton /> */}
        </div>
      </div>
    </>
  );
};

export default Redeposit;
