import GeneralModalWrapper from "../Modal/GeneralModal/GeneralModalWrapper";
import { useState } from "react";
import { useWithdrawBonus } from "../../services/cashier";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";

const BonusOffer = function () {
  const bonus = useWithdrawBonus();
  const [pop, setPop] = useState("closed");
  const disableModel = function () {
    setPop("closed");
  };

  return (
    <>
      <div class="col-12 text-center">
        <label for="exampleFormControlInput1" class="cashierNewTitel">
          <TranslatesContent contentKey="redeposit-offer" />
        </label>

        <div class="bonusOfferBox">
          <div class="row" onClick={() => setPop("active")}>
            <div
              class="col-12 p-0 position-relative"
              // data-bs-toggle="modal"
              data-bs-target="#depositbonus"
              style={{ cursor: "pointer" }}
            >
              <div class="position-absolute top-0 end-0">
                <img src={IMAGE_BUCKET_URL + "info.png"} alt="" />
              </div>
              <div class="bonusOfferBoxTop">
                {" "}
                <TranslatesContent contentKey="redeposit-bonus" />
              </div>
              <div class="bonusOfferBoxBottom">
                <div class="bonusOfferBoxBottomT1">
                  <TranslatesContent contentKey="bonus" />
                </div>
                <div class="bonusOfferBoxBottomT2">
                  {bonus?.isLoading == false
                    ? bonus?.data[2]["bonusprecent"]
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeneralModalWrapper
        type="DepositBonus"
        status={pop}
        closePopup={disableModel}
      />
    </>
  );
};

export default BonusOffer;
