export const slotData=[
    {
        "ID": "2736838",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bbsplxmas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bbsplxmas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Christmas Bash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_christmas_bash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100489",
        "GSubSort": "100489",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2736838",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_christmas_bash.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2736862",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mahjxbnz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mahjxbnz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong X"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mahjong_x.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100489",
        "GSubSort": "100489",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2736862",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mahjong_x.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2730254",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20nilefort",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20nilefort",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Nile Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/nile_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100488",
        "GSubSort": "100488",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2730254",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/nile_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2726534",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaystimber",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaystimber",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Timber Stacks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/timber_stacks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100487",
        "GSubSort": "100487",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2726534",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/timber_stacks.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2724562",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sugarnudge",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sugarnudge",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sugar Supreme Powernudge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sugar_supreme_powernudge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100486",
        "GSubSort": "100486",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2724562",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sugar_supreme_powernudge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2714982",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20maskgame",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20maskgame",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Chips"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cash_chips.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100485",
        "GSubSort": "100485",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2714982",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cash_chips.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2712762",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40demonpots",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40demonpots",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Demon Pots"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/demon_pots.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100484",
        "GSubSort": "100484",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2712762",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/demon_pots.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2709482",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40rainbowr",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40rainbowr",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rainbow Reels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rainbow_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100483",
        "GSubSort": "100483",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2709482",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rainbow_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2711837",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20dhcluster",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20dhcluster",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Twilight Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/twilight_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100483",
        "GSubSort": "100483",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2711837",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/twilight_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2705780",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysstrlght",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysstrlght",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortunes of Aztec"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fortunes_of_aztec.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100482",
        "GSubSort": "100482",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2705780",
        "RTP": "96.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fortunes_of_aztec.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2706116",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20gravity",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20gravity",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gravity Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gravity_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100482",
        "GSubSort": "100482",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2706116",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gravity_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2712302",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40infwild",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40infwild",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Infective Wild"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/infective_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100482",
        "GSubSort": "100482",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2712302",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/infective_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2704751",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaystut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaystut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Book of Tut Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_book_of_tut_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100481",
        "GSubSort": "100481",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2704751",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_book_of_tut_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2707061",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10gdchalleng",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10gdchalleng",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "8 Golden Dragon Challenge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/8_golden_dragon_challenge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100481",
        "GSubSort": "100481",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "8800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2707061",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/8_golden_dragon_challenge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2703776",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysftropics",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysftropics",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Frozen Tropics"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/frozen_tropics.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100480",
        "GSubSort": "100480",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2703776",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/frozen_tropics.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2703890",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysincwnd",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysincwnd",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Oasis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gold_oasis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100480",
        "GSubSort": "100480",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "7260.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2703890",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gold_oasis.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2701779",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20candyblitz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20candyblitz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Blitz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/candy_blitz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100479",
        "GSubSort": "100479",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2701779",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/candy_blitz.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2640521",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6015",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6015",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Northern Temple"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/northern_temple.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100478",
        "GSubSort": "100478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "6258.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2640521",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/northern_temple.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2701231",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysbbhas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysbbhas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Hold & Spinner Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_hold_and_spinner_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100478",
        "GSubSort": "100478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2701231",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_hold_and_spinner_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2721341",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6060",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6060",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pumpkin Master"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/pumpkin_master.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100478",
        "GSubSort": "100478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1694.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2721341",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/pumpkin_master.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2732138",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6063",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6063",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jhana of God: Scratch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jhana_of_god_scratch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100478",
        "GSubSort": "100478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "19"
        ],
        "SortPerCategory": {
            "19": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2732138",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jhana_of_god_scratch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2022519",
        "System": "944",
        "SubSystem": null,
        "PageCode": "468",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100468",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gemix 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gemix_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2022519",
        "RTP": "96.2600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gemix_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2035054",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5776",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5776",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonanza Wheel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/bonanza_wheel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "110.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2035054",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/bonanza_wheel.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2047880",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5777",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5777",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Temple Of Dead Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/temple_of_dead_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "9602.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2047880",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/temple_of_dead_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2698867",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20supermania",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20supermania",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Supermania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/supermania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1798",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698867",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/supermania.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1798"
    },
    {
        "ID": "2698870",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20starlightx",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20starlightx",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Starlight Princess 1000"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/starlight_princess_1000.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1801",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698870",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/starlight_princess_1000.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1801"
    },
    {
        "ID": "2698873",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25jokrace",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25jokrace",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Race"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/joker_race.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2512.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698873",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/joker_race.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698879",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs4096robber",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs4096robber",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Robber Strike"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/robber_strike.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698879",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/robber_strike.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698882",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25holiday",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25holiday",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Holiday Ride"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/holiday_ride.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "175.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698882",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/holiday_ride.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698885",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024moonsh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024moonsh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Moonshot"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/moonshot.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698885",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/moonshot.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698888",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20framazon",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20framazon",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruits of the Amazon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fruits_of_the_amazon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698888",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fruits_of_the_amazon.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698891",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20gatotfury",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20gatotfury",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gatot Kaca’s Fury"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gatot_kacas_fury.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698891",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gatot_kacas_fury.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698894",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20aztecgates",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20aztecgates",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gates of Aztec"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gates_of_aztec.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698894",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gates_of_aztec.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698897",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysrabbits",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysrabbits",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Rabbits Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/5_rabbits_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698897",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/5_rabbits_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698900",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20gatotgates",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20gatotgates",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gates of Gatot Kaca"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gates_of_gatot_kaca.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698900",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gates_of_gatot_kaca.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2698903",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysluckyfish",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysluckyfish",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Fishing Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lucky_fishing_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2698903",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lucky_fishing_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699593",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20asgard",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20asgard",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Kingdom of Asgard"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/kingdom_of_asgard.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699593",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/kingdom_of_asgard.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699596",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysoldminer",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysoldminer",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Old Gold Miner Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/old_gold_miner_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1804",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699596",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/old_gold_miner_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "1804"
    },
    {
        "ID": "2699599",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayslofhero",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayslofhero",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legend of Heroes Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/legend_of_heroes_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1801",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699599",
        "RTP": "95.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/legend_of_heroes_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "1801"
    },
    {
        "ID": "2699602",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20wildman",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20wildman",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wildman Super Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wildman_super_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1804",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699602",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wildman_super_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1804"
    },
    {
        "ID": "2699605",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243ckemp",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243ckemp",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cheeky Emperor"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cheeky_emperor.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "105.60",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699605",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cheeky_emperor.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699608",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10coffee",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10coffee",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Coffee Wild"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/coffee_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699608",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/coffee_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699611",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024mahjpanda",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024mahjpanda",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong Panda"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mahjong_panda.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699611",
        "RTP": "95.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mahjong_panda.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699614",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40samurai3",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40samurai3",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise Of Samurai III"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rise_of_samurai_iii.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1801",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699614",
        "RTP": "95.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rise_of_samurai_iii.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1801"
    },
    {
        "ID": "2699617",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243koipond",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243koipond",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Koi Pond"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/koi_pond.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "105.60",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699617",
        "RTP": "95.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/koi_pond.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699620",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243discolady",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243discolady",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Disco Lady"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/disco_lady.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699620",
        "RTP": "95.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/disco_lady.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699623",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25bullfiesta",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25bullfiesta",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bull Fiesta"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bull_fiesta.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699623",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bull_fiesta.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699626",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243empcaishen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243empcaishen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Emperor Caishen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/emperor_caishen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "132.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699626",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/emperor_caishen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699629",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25tigeryear",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25tigeryear",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky New Year Tiger Treasures"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lucky_new_year_tiger_treasures.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699629",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lucky_new_year_tiger_treasures.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699632",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs88hockattack",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs88hockattack",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hockey Attack"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hockey_attack.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "176.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699632",
        "RTP": "96.3700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hockey_attack.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699635",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bblpop",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bblpop",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bubble Pop"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bubble_pop.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "27000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699635",
        "RTP": "95.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bubble_pop.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699638",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20candvil",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20candvil",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Village"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/candy_village.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699638",
        "RTP": "95.4500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/candy_village.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699641",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs576hokkwolf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs576hokkwolf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hokkaido Wolf"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hokkaido_wolf.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "4608.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699641",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hokkaido_wolf.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699644",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243chargebull",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243chargebull",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raging Bull"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/raging_bull.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "7342.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699644",
        "RTP": "95.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/raging_bull.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699647",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25aztecking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25aztecking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/aztec_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "10500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699647",
        "RTP": "95.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/aztec_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699650",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25gldox",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25gldox",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Ox"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/golden_ox.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699650",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/golden_ox.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699653",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20bonzgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20bonzgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonanza Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bonanza_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699653",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bonanza_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699656",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1fufufu",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1fufufu",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fu Fu Fu"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fu_fu_fu.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699656",
        "RTP": "96.8400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fu_fu_fu.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699659",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25tigerwar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25tigerwar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Tiger Warrior"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_tiger_warrior.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699659",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_tiger_warrior.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699662",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1money",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1money",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Money Money"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/money_money_money.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "88.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699662",
        "RTP": "97.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/money_money_money.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699878",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1ball",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1ball",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Dragon Ball"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lucky_dragon_ball.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699878",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lucky_dragon_ball.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699881",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25goldpig",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25goldpig",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Pig"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/golden_pig.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699881",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/golden_pig.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699884",
        "System": "960",
        "SubSystem": null,
        "PageCode": "cs5moneyroll",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "cs5moneyroll",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Roll"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/money_roll.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "2001.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699884",
        "RTP": "96.9300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/money_roll.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699887",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs13g",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs13g",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Devil’s 13"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/devils_13.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.13",
        "MaxBetDefault": "65.00",
        "MaxMultiplier": "36450.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699887",
        "RTP": "95.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/devils_13.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2699890",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20bl",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20bl",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Busy Bees"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/busy_bees.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3325.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699890",
        "RTP": "95.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/busy_bees.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2701531",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024mahjwins",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024mahjwins",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong Wins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mahjong_wins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "24.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2701531",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mahjong_wins.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2703323",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20saiman",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20saiman",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Saiyan Mania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/saiyan_mania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2703323",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/saiyan_mania.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2727818",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6064",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6064",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rumble in the Shadows"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rumble_in_the_shadows.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100477",
        "GSubSort": "100477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "16047.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2727818",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rumble_in_the_shadows.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2683653",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20piggybank",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20piggybank",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Piggy Bankers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/piggy_bankers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100449",
        "GSubSort": "100449",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2683653",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/piggy_bankers.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2678905",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysrockblst",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysrockblst",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rocket Blast Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rocket_blast_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100448",
        "GSubSort": "100448",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "20.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2678905",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rocket_blast_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2452332",
        "System": "944",
        "SubSystem": null,
        "PageCode": "644",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "644",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mötley Crüe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/motley_crue.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100447",
        "GSubSort": "100447",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2452332",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/motley_crue.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2670373",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50jucier",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50jucier",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sky Bounty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sky_bounty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100447",
        "GSubSort": "100447",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2670373",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sky_bounty.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2662821",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20lobcrab",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20lobcrab",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lobster Bob's Crazy Crab Shack"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lobster_bobs_crazy_crab_shack.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100446",
        "GSubSort": "100446",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2662821",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lobster_bobs_crazy_crab_shack.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2654873",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20wildparty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20wildparty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Buzzing Wilds"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/3_buzzing_wilds.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100445",
        "GSubSort": "100445",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2654873",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/3_buzzing_wilds.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2652309",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20cashmachine",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20cashmachine",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Box"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cash_box.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100444",
        "GSubSort": "100444",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2652309",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cash_box.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2644053",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243nudge4gold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243nudge4gold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hellvis Wild"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hellvis_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100443",
        "GSubSort": "100443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2644053",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hellvis_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2647569",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayspowzeus",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayspowzeus",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Power of Merlin Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/power_of_merlin_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100443",
        "GSubSort": "100443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "40000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2647569",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/power_of_merlin_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2686786",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20lvlup",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20lvlup",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pub Kings"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pub_kings.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100443",
        "GSubSort": "100443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2686786",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pub_kings.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2696875",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20earthquake",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20earthquake",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cyclops Smash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cyclops_smash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100443",
        "GSubSort": "100443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2696875",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cyclops_smash.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2640161",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20splmystery",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20splmystery",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Spellbinding Mystery"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/spellbinding_mystery.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100442",
        "GSubSort": "100442",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2640161",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/spellbinding_mystery.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2632969",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20hstgldngt",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20hstgldngt",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Heist for the Golden Nuggets"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/heist_for_the_golden_nuggets.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100441",
        "GSubSort": "100441",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2632969",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/heist_for_the_golden_nuggets.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2630048",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20procount",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20procount",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wisdom of Athena"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wisdom_of_athena.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100440",
        "GSubSort": "100440",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2630048",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wisdom_of_athena.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2630044",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bbextreme",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bbextreme",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Amazon Xtreme"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_amazon_xtreme.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100439",
        "GSubSort": "100439",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2630044",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_amazon_xtreme.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2626292",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20beefed",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20beefed",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fat Panda"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fat_panda.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100438",
        "GSubSort": "100438",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2626292",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fat_panda.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2617284",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10fdrasbf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10fdrasbf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Floating Dragon - Dragon Boat Festival"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/floating_dragon_dragon_boat_festival.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100437",
        "GSubSort": "100437",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2617284",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/floating_dragon_dragon_boat_festival.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2610776",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9outlaw",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9outlaw",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pirates Pub"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pirates_pub.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100436",
        "GSubSort": "100436",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "99.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2610776",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pirates_pub.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2603356",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20clustwild",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20clustwild",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sticky Bees"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sticky_bees.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100435",
        "GSubSort": "100435",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2603356",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sticky_bees.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2600636",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs15godsofwar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs15godsofwar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Zeus vs Hades - Gods of War"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/zeus_vs_hades_gods_of_war.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100434",
        "GSubSort": "100434",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2600636",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/zeus_vs_hades_gods_of_war.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2596308",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20jewelparty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20jewelparty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jewel Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/jewel_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100433",
        "GSubSort": "100433",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2596308",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/jewel_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2040599",
        "System": "939",
        "SubSystem": null,
        "PageCode": "ways-of-qilin",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "ways-of-qilin",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ways of the Qilin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/ways_of_the_qilis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100432",
        "GSubSort": "100432",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "106",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "7106.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2040599",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/ways_of_the_qilis.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2587892",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20lampinf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20lampinf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lamp Of Infinity"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lamp_of_infinity.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100432",
        "GSubSort": "100432",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2587892",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lamp_of_infinity.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2584760",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayseternity",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayseternity",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamonds of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/diamonds_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100431",
        "GSubSort": "100431",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "140.80",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2584760",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/diamonds_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2580608",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25spotz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25spotz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Knight Hot Spotz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/knight_hot_spotz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100430",
        "GSubSort": "100430",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2580608",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/knight_hot_spotz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2575372",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20stickywild",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20stickywild",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Bison Charge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_bison_charge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100429",
        "GSubSort": "100429",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "12000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2575372",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_bison_charge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2570473",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10kingofdth",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10kingofdth",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Kingdom of the Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/kingdom_of_the_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100428",
        "GSubSort": "100428",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2570473",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/kingdom_of_the_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2565809",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10jnmntzma",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10jnmntzma",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jane Hunter and the Mask of Montezuma"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/jane_hunter_and_the_mask_of_montezuma.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100427",
        "GSubSort": "100427",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2565809",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/jane_hunter_and_the_mask_of_montezuma.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2562813",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysmonkey",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysmonkey",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Dancing Monkeys"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/3_dancing_monkeys.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100426",
        "GSubSort": "100426",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "12077.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2562813",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/3_dancing_monkeys.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2558491",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysrsm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysrsm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Celebrity Bus Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_celebrity_bus_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100425",
        "GSubSort": "100425",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2558491",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_celebrity_bus_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2559173",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20excalibur",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20excalibur",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Excalibur Unleashed"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/excalibur_unleashed.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100425",
        "GSubSort": "100425",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2559173",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/excalibur_unleashed.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2556538",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20hotzone",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20hotzone",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "African Elephant"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/african_elephant.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100424",
        "GSubSort": "100424",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2556538",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/african_elephant.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2553253",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10gizagods",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10gizagods",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gods of Giza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gods_of_giza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100423",
        "GSubSort": "100423",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2553253",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gods_of_giza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2546026",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysredqueen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysredqueen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Red Queen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_red_queen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100422",
        "GSubSort": "100422",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2546026",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_red_queen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2533741",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sknights",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sknights",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Knight King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_knight_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100420",
        "GSubSort": "100420",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2533741",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_knight_king.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2526450",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mvwild",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mvwild",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jasmine Dreams"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/jasmine_dreams.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100419",
        "GSubSort": "100419",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2526450",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/jasmine_dreams.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2523566",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mochimon",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mochimon",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mochimon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mochimon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100418",
        "GSubSort": "100418",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2523566",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mochimon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2519470",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysultrcoin",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysultrcoin",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cowboy Coins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cowboy_coins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100417",
        "GSubSort": "100417",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2519470",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cowboy_coins.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2517254",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20doghousemh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20doghousemh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Dog House Multihold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_dog_house_multihold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100416",
        "GSubSort": "100416",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2517254",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_dog_house_multihold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2513614",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20pistols",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20pistols",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild West Duels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_west_duels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100415",
        "GSubSort": "100415",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2513614",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_west_duels.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2498842",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs12tropicana",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs12tropicana",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Club Tropicana"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/club_tropicana.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100414",
        "GSubSort": "100414",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.12",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2498842",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/club_tropicana.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2506110",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayswwriches",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayswwriches",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Wild Riches Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_wild_riches_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100414",
        "GSubSort": "100414",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2506110",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_wild_riches_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2491654",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25archer",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25archer",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Archer"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_archer.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100412",
        "GSubSort": "100412",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2491654",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_archer.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2478914",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mammoth",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mammoth",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mammoth Gold Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mammoth_gold_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100411",
        "GSubSort": "100411",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2478914",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mammoth_gold_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2482314",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10fisheye",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10fisheye",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fish Eye"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fish_eye.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100411",
        "GSubSort": "100411",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2482314",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fish_eye.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2509954",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysmorient",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysmorient",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystery of the Orient"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mystery_of_the_orient.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100410",
        "GSubSort": "100410",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "8035.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2509954",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mystery_of_the_orient.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2475926",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25spgldways",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25spgldways",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Secret City Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/secret_city_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100409",
        "GSubSort": "100409",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2475926",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/secret_city_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2469138",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayswwhex",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayswwhex",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Wild Bananas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_wild_bananas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100405",
        "GSubSort": "100405",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "120.00",
        "MaxMultiplier": "12000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2469138",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_wild_bananas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2465294",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20drgbless",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20drgbless",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Hero"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/dragon_hero.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100404",
        "GSubSort": "100404",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2465294",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/dragon_hero.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2444868",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20clspwrndg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20clspwrndg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Powernudge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sweet_powernudge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100403",
        "GSubSort": "100403",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2444868",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sweet_powernudge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2442172",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayspizza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayspizza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "PIZZA! PIZZA? PIZZA!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pizza_pizza_pizza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100402",
        "GSubSort": "100402",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2442172",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pizza_pizza_pizza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2438152",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20dugems",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20dugems",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Pepper"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_pepper.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100401",
        "GSubSort": "100401",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2438152",
        "RTP": "96.4500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_pepper.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2435416",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25rlbank",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25rlbank",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Reel Banks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/reel_banks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100400",
        "GSubSort": "100400",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2435416",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/reel_banks.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2432004",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysfuryodin",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysfuryodin",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fury of Odin Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fury_of_odin_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100399",
        "GSubSort": "100399",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2432004",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fury_of_odin_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2429248",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs12bbbxmas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs12bbbxmas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bigger Bass Blizzard - Christmas Catch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bigger_bass_blizzard_christmas_catch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100398",
        "GSubSort": "100398",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.12",
        "MaxBetDefault": "360.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2429248",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bigger_bass_blizzard_christmas_catch.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2425552",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20porbs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20porbs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Santa's Great Gifts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/santas_great_gifts.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100397",
        "GSubSort": "100397",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2425552",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/santas_great_gifts.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2422804",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10snakeeyes",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10snakeeyes",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Snakes & Ladders - Snake Eyes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/snakes_and_ladders_snake_eyes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100396",
        "GSubSort": "100396",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "53000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2422804",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/snakes_and_ladders_snake_eyes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2419628",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20lcount",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20lcount",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gems of Serengeti"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gems_of_serengeti.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100395",
        "GSubSort": "100395",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2419628",
        "RTP": "96.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gems_of_serengeti.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2417092",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sparta",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sparta",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shield Of Sparta"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/shield_of_sparta.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100394",
        "GSubSort": "100394",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2417092",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/shield_of_sparta.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2400196",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20swordofares",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20swordofares",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sword of Ares"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sword_of_ares.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100392",
        "GSubSort": "100392",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2400196",
        "RTP": "96.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sword_of_ares.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2397656",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bbkir",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bbkir",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Bonanza - Keeping it Reel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_bonanza_keeping_it_reel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100391",
        "GSubSort": "100391",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2397656",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_bonanza_keeping_it_reel.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2387256",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20muertos",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20muertos",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Muertos Multiplier Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/muertos_multiplier_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100390",
        "GSubSort": "100390",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "10.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2387256",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/muertos_multiplier_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2384588",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysconcoll",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysconcoll",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Firebird Spirit - Connect & Collect"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/firebird_spirit_connect_and_collect.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100389",
        "GSubSort": "100389",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2384588",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/firebird_spirit_connect_and_collect.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2380881",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mtreasure",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mtreasure",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pirate Golden Age"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pirate_golden_age.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100388",
        "GSubSort": "100388",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2380881",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pirate_golden_age.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2377347",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10tut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10tut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Book of Tut Respin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_book_of_tut_respin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100387",
        "GSubSort": "100387",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2377347",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_book_of_tut_respin.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2374272",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysfltdrg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysfltdrg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Floating Dragon Hold & Spin Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/floating_dragon_hold_and_spin_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100386",
        "GSubSort": "100386",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2374272",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/floating_dragon_hold_and_spin_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2109129",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25btygold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25btygold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bounty Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bounty_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100383",
        "GSubSort": "100383",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2109129",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bounty_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2118774",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bookfallen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bookfallen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of Fallen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/book_of_fallen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100382",
        "GSubSort": "100382",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2118774",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/book_of_fallen.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2103996",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40bigjuan",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40bigjuan",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Juan"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_juan.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100381",
        "GSubSort": "100381",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2103996",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_juan.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2109471",
        "System": "944",
        "SubSystem": null,
        "PageCode": "583",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100583",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Last Sundown"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_last_sundown.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100379",
        "GSubSort": "100379",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "18000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2109471",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_last_sundown.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2114436",
        "System": "944",
        "SubSystem": null,
        "PageCode": "563",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100563",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rich Wilde and the Wandering City"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rich_wilde_and_the_wandering_city.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100378",
        "GSubSort": "100378",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2114436",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rich_wilde_and_the_wandering_city.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2119125",
        "System": "944",
        "SubSystem": null,
        "PageCode": "581",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100581",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Merlin and the Ice Queen Morgana"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/merlin_and_the_ice_queen_morgana_.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100377",
        "GSubSort": "100377",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2119125",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/merlin_and_the_ice_queen_morgana_.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2439096",
        "System": "944",
        "SubSystem": null,
        "PageCode": "754",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100754",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Naughty Nick's Book"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/naughty_nicks_book.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100376",
        "GSubSort": "100376",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2439096",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/naughty_nicks_book.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2104650",
        "System": "944",
        "SubSystem": null,
        "PageCode": "564",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100564",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legend of the Ice Dragon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legend_of_the_ice_dragon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100375",
        "GSubSort": "100375",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2104650",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legend_of_the_ice_dragon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2473050",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20bnnzdice",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20bnnzdice",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Bonanza Dice"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sweet_bonanza_dice.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100366",
        "GSubSort": "100366",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3810"
        ],
        "SortPerCategory": {
            "16": 0,
            "3810": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2473050",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sweet_bonanza_dice.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2717997",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bhallbnza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bhallbnza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Halloween"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_halloween.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100366",
        "GSubSort": "100366",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "2100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2717997",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_halloween.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2722153",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayscfglory",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayscfglory",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chase for Glory"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/chase_for_glory.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100366",
        "GSubSort": "100366",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "7887.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2722153",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/chase_for_glory.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2723458",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sugarcoins",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sugarcoins",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Viking Forge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/viking_forge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100366",
        "GSubSort": "100366",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2723458",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/viking_forge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2473046",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5jokerdice",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5jokerdice",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker's Jewels Dice"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/jokers_jewels_dice.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100365",
        "GSubSort": "100365",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3810"
        ],
        "SortPerCategory": {
            "16": 0,
            "3810": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1040.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2473046",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/jokers_jewels_dice.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2532193",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5931",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5931",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Perfect Fishing"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/perfect_fishing.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100362",
        "GSubSort": "100362",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "750.00",
        "MaxMultiplier": "1024.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2532193",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/perfect_fishing.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2525118",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5973",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5973",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure-snipes Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasure_snipes_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100361",
        "GSubSort": "100361",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "4211.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2525118",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasure_snipes_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2518546",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5939",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5939",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Redrose Sanctuary Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/redrose_sanctuary_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100360",
        "GSubSort": "100360",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "4408.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2518546",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/redrose_sanctuary_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2504538",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5965",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5965",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Dreams: Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/candy_dreams_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100359",
        "GSubSort": "100359",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10017.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2504538",
        "RTP": "95.8500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/candy_dreams_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2499394",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5963",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5963",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Rio Nights Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/hot_rio_nights_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100358",
        "GSubSort": "100358",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "5178.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2499394",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/hot_rio_nights_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2436852",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5921",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5921",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Inner Fire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/inner_fire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100356",
        "GSubSort": "100356",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10148.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2436852",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/inner_fire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2058273",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5781",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5781",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Save the Hamster"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/save_the_hamster.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100329",
        "GSubSort": "100329",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "3604"
        ],
        "SortPerCategory": {
            "22": 0,
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "1000.00",
        "MaxMultiplier": "860.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2058273",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/save_the_hamster.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2248911",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5875",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5875",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Goblin Run"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/goblin_run.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100328",
        "GSubSort": "100328",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "3604"
        ],
        "SortPerCategory": {
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "1000.00",
        "MaxMultiplier": "909.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2248911",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/goblin_run.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2724034",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFruityHalloween",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFruityHalloween",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruity Halloween",
            "zh-hant": "搗蛋果果",
            "zh-hans": "捣蛋果果"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fruity_halloween.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100314",
        "GSubSort": "100314",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ef05671e-6440-4e71-bb27-75f32bc661c5",
        "MobileExternalCode": "ef05671e-6440-4e71-bb27-75f32bc661c5",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5216.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2724034",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fruity_halloween.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2717993",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSlimeParty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSlimeParty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Slime Party",
            "zh-hant": "史萊姆",
            "zh-hans": "史莱姆"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/slime_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100313",
        "GSubSort": "100313",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "bbfb7ee6-a4fd-4d32-b319-19094b36b2e6",
        "MobileExternalCode": "bbfb7ee6-a4fd-4d32-b319-19094b36b2e6",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "48900.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2717993",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/slime_party.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2705243",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBikiniIslandDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBikiniIslandDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bikini Island Deluxe",
            "id": "Bikini Island Deluxe",
            "de": "Bikini Island Deluxe",
            "es": "Bikini Island Deluxe",
            "fr": "Bikini Island Deluxe",
            "it": "Bikini Island Deluxe",
            "nl": "Bikini Island Deluxe",
            "no": "Bikini Island Deluxe",
            "pt": "Bikini Island Deluxe",
            "fi": "Bikini Island Deluxe",
            "sv": "Bikini Island Deluxe",
            "vi": "Bikini Island Deluxe",
            "tr": "Bikini Island Deluxe",
            "gr": "Bikini Island Deluxe",
            "ru": "Bikini Island Deluxe",
            "th": "Bikini Island Deluxe",
            "ja": "Bikini Island Deluxe",
            "zh-hant": "比基尼島",
            "zh-hans": "比基尼岛",
            "ko": "Bikini Island Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bikini_island_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100311",
        "GSubSort": "100311",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": "d53d26a9-2065-4457-add2-08644821f449",
        "MobileExternalCode": "d53d26a9-2065-4457-add2-08644821f449",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "8572.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2705243",
        "RTP": "96.8100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bikini_island_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2701439",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGAtomicKittens",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGAtomicKittens",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Atomic Kittens",
            "id": "Atomic Kittens",
            "de": "Atomic Kittens",
            "es": "Atomic Kittens",
            "fr": "Atomic Kittens",
            "it": "Atomic Kittens",
            "nl": "Atomic Kittens",
            "no": "Atomic Kittens",
            "pt": "Atomic Kittens",
            "fi": "Atomic Kittens",
            "sv": "Atomic Kittens",
            "vi": "Atomic Kittens",
            "tr": "Atomic Kittens",
            "gr": "Atomic Kittens",
            "ru": "Atomic Kittens",
            "th": "Atomic Kittens",
            "ja": "Atomic Kittens",
            "zh-hant": "驚人的貓派對",
            "zh-hans": "惊人的猫派对",
            "ko": "Atomic Kittens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/atomic_kittens.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100310",
        "GSubSort": "100310",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "42385847-21c6-4ac5-86ef-81948664c6e3",
        "MobileExternalCode": "42385847-21c6-4ac5-86ef-81948664c6e3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "17040.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2701439",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/atomic_kittens.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2691856",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWitchesTome",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWitchesTome",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Witches Tome",
            "id": "Witches Tome",
            "de": "Witches Tome",
            "es": "Witches Tome",
            "fr": "Witches Tome",
            "it": "Witches Tome",
            "nl": "Witches Tome",
            "no": "Witches Tome",
            "pt": "Witches Tome",
            "fi": "Witches Tome",
            "sv": "Witches Tome",
            "vi": "Witches Tome",
            "tr": "Witches Tome",
            "gr": "Witches Tome",
            "ru": "Witches Tome",
            "th": "Witches Tome",
            "ja": "Witches Tome",
            "zh-hant": "巫師秘籍",
            "zh-hans": "巫师秘籍",
            "ko": "Witches Tome"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/witches_tome.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100309",
        "GSubSort": "100309",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7120f66a-104c-4ad6-ab06-d6c12328e2ab",
        "MobileExternalCode": "7120f66a-104c-4ad6-ab06-d6c12328e2ab",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "7307.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2691856",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/witches_tome.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2653389",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTootyFruityFruits",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTootyFruityFruits",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tooty Fruity Fruits",
            "id": "Tooty Fruity Fruits",
            "de": "Tooty Fruity Fruits",
            "es": "Tooty Fruity Fruits",
            "fr": "Tooty Fruity Fruits",
            "it": "Tooty Fruity Fruits",
            "nl": "Tooty Fruity Fruits",
            "no": "Tooty Fruity Fruits",
            "pt": "Tooty Fruity Fruits",
            "fi": "Tooty Fruity Fruits",
            "sv": "Tooty Fruity Fruits",
            "vi": "Tooty Fruity Fruits",
            "tr": "Tooty Fruity Fruits",
            "gr": "Tooty Fruity Fruits",
            "ru": "Tooty Fruity Fruits",
            "th": "Tooty Fruity Fruits",
            "ja": "Tooty Fruity Fruits",
            "zh-hant": "果果樂園",
            "zh-hans": "果果乐园",
            "ko": "Tooty Fruity Fruits"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tooty_fruity_fruits.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100307",
        "GSubSort": "100307",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "56fec5aa-6a48-4df6-8fa1-8022cf9ee1b6",
        "MobileExternalCode": "56fec5aa-6a48-4df6-8fa1-8022cf9ee1b6",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5341.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2653389",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tooty_fruity_fruits.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2609172",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTheBigDealDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTheBigDealDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Big Deal Deluxe",
            "id": "The Big Deal Deluxe",
            "de": "The Big Deal Deluxe",
            "es": "The Big Deal Deluxe",
            "fr": "The Big Deal Deluxe",
            "it": "The Big Deal Deluxe",
            "nl": "The Big Deal Deluxe",
            "no": "The Big Deal Deluxe",
            "pt": "The Big Deal Deluxe",
            "fi": "The Big Deal Deluxe",
            "sv": "The Big Deal Deluxe",
            "vi": "The Big Deal Deluxe",
            "tr": "The Big Deal Deluxe",
            "gr": "The Big Deal Deluxe",
            "ru": "The Big Deal Deluxe",
            "th": "The Big Deal Deluxe",
            "ja": "The Big Deal Deluxe",
            "zh-hant": "豪門賭廳",
            "zh-hans": "豪门赌厅",
            "ko": "The Big Deal Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/the_big_deal_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100305",
        "GSubSort": "100305",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e7fd16fd-1efa-40e7-bf55-7ab248065d4e",
        "MobileExternalCode": "e7fd16fd-1efa-40e7-bf55-7ab248065d4e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2609172",
        "RTP": "96.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/the_big_deal_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2624204",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCrystopia",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCrystopia",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crystopia",
            "id": "Crystopia",
            "de": "Crystopia",
            "es": "Crystopia",
            "fr": "Crystopia",
            "it": "Crystopia",
            "nl": "Crystopia",
            "no": "Crystopia",
            "pt": "Crystopia",
            "fi": "Crystopia",
            "sv": "Crystopia",
            "vi": "Crystopia",
            "tr": "Crystopia",
            "gr": "Crystopia",
            "ru": "Crystopia",
            "th": "Crystopia",
            "ja": "Crystopia",
            "zh-hant": "神秘寶石",
            "zh-hans": "神秘宝石",
            "ko": "Crystopia"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/crystopia.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100305",
        "GSubSort": "100305",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "c06e3e3b-6543-4194-9ee1-aa58e089b424",
        "MobileExternalCode": "c06e3e3b-6543-4194-9ee1-aa58e089b424",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "45.00",
        "MaxMultiplier": "1064.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2624204",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/crystopia.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2518298",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRainbowmania",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRainbowmania",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rainbow Mania",
            "id": "Rainbowmania",
            "de": "Rainbowmania",
            "es": "Rainbowmania",
            "fr": "Rainbowmania",
            "it": "Rainbowmania",
            "nl": "Rainbowmania",
            "no": "Rainbowmania",
            "pt": "Rainbowmania",
            "fi": "Rainbowmania",
            "sv": "Rainbowmania",
            "vi": "Rainbowmania",
            "tr": "Rainbowmania",
            "gr": "Rainbowmania",
            "ru": "Rainbowmania",
            "th": "Rainbowmania",
            "ja": "Rainbowmania",
            "zh-hant": "彩虹森林",
            "zh-hans": "彩虹森林",
            "ko": "Rainbowmania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/rainbow_mania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100302",
        "GSubSort": "100302",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "07aea809-dafa-4f59-aa81-8f7bccdeb0b9",
        "MobileExternalCode": "07aea809-dafa-4f59-aa81-8f7bccdeb0b9",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "7995.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2518298",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/rainbow_mania.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2477014",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDragonTigerGate",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDragonTigerGate",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Tiger Gate",
            "id": "Dragon Tiger Gate",
            "de": "Dragon Tiger Gate",
            "es": "Dragon Tiger Gate",
            "fr": "Dragon Tiger Gate",
            "it": "Dragon Tiger Gate",
            "nl": "Dragon Tiger Gate",
            "no": "Dragon Tiger Gate",
            "pt": "Dragon Tiger Gate",
            "fi": "Dragon Tiger Gate",
            "sv": "Dragon Tiger Gate",
            "vi": "Dragon Tiger Gate",
            "tr": "Dragon Tiger Gate",
            "gr": "Dragon Tiger Gate",
            "ru": "Dragon Tiger Gate",
            "th": "Dragon Tiger Gate",
            "ja": "Dragon Tiger Gate",
            "zh-hant": "Dragon Tiger Gate",
            "zh-hans": "Dragon Tiger Gate",
            "ko": "Dragon Tiger Gate"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/dragon_tiger_gate.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100300",
        "GSubSort": "100300",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "5b107d34-3dd6-4db8-8e1e-59ab7ee47ac4",
        "MobileExternalCode": "5b107d34-3dd6-4db8-8e1e-59ab7ee47ac4",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2477014",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/dragon_tiger_gate.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2423716",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSojuBomb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSojuBomb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Soju Bomb",
            "id": "Soju Bomb",
            "de": "Soju Bomb",
            "es": "Soju Bomb",
            "fr": "Soju Bomb",
            "it": "Soju Bomb",
            "nl": "Soju Bomb",
            "no": "Soju Bomb",
            "pt": "Soju Bomb",
            "fi": "Soju Bomb",
            "sv": "Soju Bomb",
            "vi": "Soju Bomb",
            "tr": "Soju Bomb",
            "gr": "Soju Bomb",
            "ru": "Soju Bomb",
            "th": "Soju Bomb",
            "ja": "Soju Bomb",
            "zh-hant": "炸彈燒酒",
            "zh-hans": "炸弹烧酒",
            "ko": "Soju Bomb"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/soju_bomb.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100299",
        "GSubSort": "100299",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ca579d31-2437-43d6-b2c5-a9e89c37d3aa",
        "MobileExternalCode": "ca579d31-2437-43d6-b2c5-a9e89c37d3aa",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1643.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2423716",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/soju_bomb.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2013943",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMarvelousFurlongs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMarvelousFurlongs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Marvelous Furlongs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/marvelous_furlongs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100297",
        "GSubSort": "100297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "16d31b5b-203d-43df-8eb4-47e8f30cf8a6",
        "MobileExternalCode": "16d31b5b-203d-43df-8eb4-47e8f30cf8a6",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2013943",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/marvelous_furlongs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2072679",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5805",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5805",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Curse of the Pharaoh Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/curse_of_the_pharaoh_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100297",
        "GSubSort": "100297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2333.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2072679",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/curse_of_the_pharaoh_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2156912",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5845",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5845",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon's Tavern Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/dragons_tavern_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100297",
        "GSubSort": "100297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2087.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2156912",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/dragons_tavern_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172956",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5782",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5782",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cycle of Luck Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/cycle_of_luck_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100297",
        "GSubSort": "100297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5035.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172956",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/cycle_of_luck_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2398352",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTukTukThailand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTukTukThailand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tuk Tuk Thailand",
            "id": "Tuk Tuk Thailand",
            "de": "Tuk Tuk Thailand",
            "es": "Tuk Tuk Thailand",
            "fr": "Tuk Tuk Thailand",
            "it": "Tuk Tuk Thailand",
            "nl": "Tuk Tuk Thailand",
            "no": "Tuk Tuk Thailand",
            "pt": "Tuk Tuk Thailand",
            "fi": "Tuk Tuk Thailand",
            "sv": "Tuk Tuk Thailand",
            "vi": "Tuk Tuk Thailand",
            "tr": "Tuk Tuk Thailand",
            "gr": "Tuk Tuk Thailand",
            "ru": "Tuk Tuk Thailand",
            "th": "Tuk Tuk Thailand",
            "ja": "Tuk Tuk Thailand",
            "zh-hant": "嘟嘟遊",
            "zh-hans": "嘟嘟游",
            "ko": "Tuk Tuk Thailand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tuk_tuk_thailand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100297",
        "GSubSort": "100297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "4f931aa6-cacd-4656-b1db-b24faffec041",
        "MobileExternalCode": "4f931aa6-cacd-4656-b1db-b24faffec041",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2398352",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tuk_tuk_thailand.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2122461",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGNewYearsBash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGNewYearsBash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "New Years Bash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/new_years_bash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100296",
        "GSubSort": "100296",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": "ae5f757f-228c-4522-b0ee-88ab14c2f219",
        "MobileExternalCode": "ae5f757f-228c-4522-b0ee-88ab14c2f219",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21265.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2122461",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/new_years_bash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2366790",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysbook",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysbook",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of Golden Sands"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/book_of_golden_sands.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100266",
        "GSubSort": "100266",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2366790",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/book_of_golden_sands.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1938345",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5761",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5761",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mysteries of the East"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mysteries_of_the_east.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100265",
        "GSubSort": "100265",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1938345",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mysteries_of_the_east.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2357004",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20octobeer",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20octobeer",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Octobeer Fortunes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/octobeer_fortunes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100264",
        "GSubSort": "100264",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2357004",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/octobeer_fortunes.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2355486",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5strh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5strh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Striking Hot 5"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/striking_hot_5.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100263",
        "GSubSort": "100263",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2355486",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/striking_hot_5.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2354616",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40firehot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40firehot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Hot 40"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_hot_40.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100262",
        "GSubSort": "100262",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2354616",
        "RTP": "96.4300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_hot_40.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2354612",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5firehot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5firehot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Hot 5"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_hot_5.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100261",
        "GSubSort": "100261",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1392.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2354612",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_hot_5.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2354608",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20fh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20fh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Hot 20"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_hot_20.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100260",
        "GSubSort": "100260",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1234.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2354608",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_hot_20.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2354604",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs100firehot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs100firehot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Hot 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_hot_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100259",
        "GSubSort": "100259",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2354604",
        "RTP": "96.4300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_hot_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2351492",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20underground",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20underground",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Down the Rails"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/down_the_rails.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100258",
        "GSubSort": "100258",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2351492",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/down_the_rails.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2345312",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40hotburnx",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40hotburnx",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot To Burn Extreme"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_to_burn_extreme.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100257",
        "GSubSort": "100257",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2345312",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_to_burn_extreme.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2337984",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20trswild2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20trswild2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Black Bull"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/black_bull.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100256",
        "GSubSort": "100256",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2337984",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/black_bull.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2331724",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20wolfie",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20wolfie",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Greedy Wolf"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/greedy_wolf.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100255",
        "GSubSort": "100255",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2331724",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/greedy_wolf.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2325284",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024gmayhem",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024gmayhem",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gorilla Mayhem"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gorilla_mayhem.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100254",
        "GSubSort": "100254",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2325284",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gorilla_mayhem.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2319212",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysjkrdrop",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysjkrdrop",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tropical Tiki"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/tropical_tiki.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100253",
        "GSubSort": "100253",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2319212",
        "RTP": "96.4300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/tropical_tiki.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2306452",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25bomb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25bomb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bomb Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bomb_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100252",
        "GSubSort": "100252",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2015.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2306452",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bomb_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2312484",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40cosmiccash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40cosmiccash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cosmic Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cosmic_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100251",
        "GSubSort": "100251",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2650.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2312484",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cosmic_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2300200",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sugarrush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sugarrush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sugar Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sugar_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100250",
        "GSubSort": "100250",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2300200",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sugar_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2294236",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10txbigbass",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10txbigbass",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Splash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_splash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100248",
        "GSubSort": "100248",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2294236",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_splash.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2280832",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20amuleteg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20amuleteg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune of Giza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fortune_of_giza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100247",
        "GSubSort": "100247",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6750.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2280832",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fortune_of_giza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2274872",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayszombcarn",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayszombcarn",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Zombie Carnival"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/zombie_carnival.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100246",
        "GSubSort": "100246",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2274872",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/zombie_carnival.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2272384",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayswildwest",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayswildwest",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild West Gold Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_west_gold_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100245",
        "GSubSort": "100245",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2272384",
        "RTP": "96.4400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_west_gold_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2076297",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20starlight",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20starlight",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Starlight Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/starlight_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100244",
        "GSubSort": "100244",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2076297",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/starlight_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2268424",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20cleocatra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20cleocatra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cleocatra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cleocatra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100242",
        "GSubSort": "100242",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2268424",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cleocatra.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2241300",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40cleoeye",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40cleoeye",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Eye of Cleopatra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/eye_of_cleopatra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100241",
        "GSubSort": "100241",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2241300",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/eye_of_cleopatra.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2266176",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5littlegem",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5littlegem",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Little Gem Hold and Spin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/little_gem_hold_and_spin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100228",
        "GSubSort": "100228",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "2460.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2266176",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/little_gem_hold_and_spin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2162488",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25goldparty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25goldparty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gold_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100227",
        "GSubSort": "100227",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5163.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2162488",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gold_party.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2257866",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20gobnudge",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20gobnudge",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Goblin Heist Powernudge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/goblin_heist_powernudge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100214",
        "GSubSort": "100214",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2257866",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/goblin_heist_powernudge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2253303",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10firestrike2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10firestrike2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Strike 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fire_strike_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100213",
        "GSubSort": "100213",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2253303",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fire_strike_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2248305",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50northgard",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50northgard",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "North Guardians"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/north_guardians.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100212",
        "GSubSort": "100212",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2248305",
        "RTP": "96.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/north_guardians.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2246250",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10spiritadv",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10spiritadv",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Spirit of Adventure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/spirit_of_adventure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100210",
        "GSubSort": "100210",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2246250",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/spirit_of_adventure.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2243757",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20mustanggld2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20mustanggld2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Clover Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/clover_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100209",
        "GSubSort": "100209",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2243757",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/clover_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2237307",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10chkchase",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10chkchase",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chicken Chase"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/chicken_chase.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100207",
        "GSubSort": "100207",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "210.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2237307",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/chicken_chase.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2231260",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20drtgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20drtgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Drill That Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/drill_that_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100206",
        "GSubSort": "100206",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2231260",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/drill_that_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2182268",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayselements",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayselements",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Elemental Gems Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/elemental_gems_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100201",
        "GSubSort": "100201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2182268",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/elemental_gems_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2194536",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysxjuicy",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysxjuicy",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Extra Juicy MEGAWAYS"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/extra_juicy_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100201",
        "GSubSort": "100201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2194536",
        "RTP": "96.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/extra_juicy_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2207380",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20bchprty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20bchprty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Beach Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_beach_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100201",
        "GSubSort": "100201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2207380",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_beach_party.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2213616",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20rainbowg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20rainbowg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rainbow Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rainbow_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100201",
        "GSubSort": "100201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2213616",
        "RTP": "96.6300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rainbow_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2225240",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20farmfest",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20farmfest",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Barn Festival"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/barn_festival.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100201",
        "GSubSort": "100201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2225240",
        "RTP": "96.4500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/barn_festival.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2200940",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243queenie",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243queenie",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queenie"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/queenie.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100200",
        "GSubSort": "100200",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2200940",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/queenie.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2191612",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10snakeladd",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10snakeladd",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Snakes and Ladders Megadice"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/snakes_and_ladders_megadice.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100198",
        "GSubSort": "100198",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3810"
        ],
        "SortPerCategory": {
            "16": 0,
            "3810": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2191612",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/snakes_and_ladders_megadice.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2188528",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50mightra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50mightra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Might of Ra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/might_of_ra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100197",
        "GSubSort": "100197",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "22500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2188528",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/might_of_ra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2131932",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayscryscav",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayscryscav",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crystal Caverns MEGAWAYS"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/crystal_caverns_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100196",
        "GSubSort": "100196",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2131932",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/crystal_caverns_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2128248",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20smugcove",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20smugcove",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Smugglers Cove"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/smugglers_cove.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100195",
        "GSubSort": "100195",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2128248",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/smugglers_cove.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2123661",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bxmasbnza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bxmasbnza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Christmas Big Bass Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/christmas_bass_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100194",
        "GSubSort": "100194",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "2100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2123661",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/christmas_bass_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2121456",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20santawonder",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20santawonder",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Santa's Wonderland"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/santas_wonderland.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100193",
        "GSubSort": "100193",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2121456",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/santas_wonderland.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2116929",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20superx",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20superx",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super X"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/super_x.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100192",
        "GSubSort": "100192",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2116929",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/super_x.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2179128",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20ultim5",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20ultim5",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Ultimate 5"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_ultimate_5.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100190",
        "GSubSort": "100190",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2179128",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_ultimate_5.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2094759",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20daydead",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20daydead",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Day of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/day_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100189",
        "GSubSort": "100189",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2094759",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/day_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2093094",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysbankbonz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysbankbonz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cash_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100188",
        "GSubSort": "100188",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2093094",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cash_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2150248",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs4096magician",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs4096magician",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magician's Secrets"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/magicians_secrets.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100187",
        "GSubSort": "100187",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2150248",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/magicians_secrets.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1932201",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBeforeTimeRunsOut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBeforeTimeRunsOut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Before Time Runs Out"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/before_time_runs_out.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100184",
        "GSubSort": "100184",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "19c7953c-2545-4b1f-a687-00cfb79abda2",
        "MobileExternalCode": "19c7953c-2545-4b1f-a687-00cfb79abda2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "999999.99",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1932201",
        "RTP": "96.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/before_time_runs_out.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1957071",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGReturnToTheFeature",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGReturnToTheFeature",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Return To The Feature"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/return_to_the_feature.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100183",
        "GSubSort": "100183",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "c5855f30-05fa-48e7-bd6e-79f5be66edee",
        "MobileExternalCode": "c5855f30-05fa-48e7-bd6e-79f5be66edee",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "162000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1957071",
        "RTP": "96.8600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/return_to_the_feature.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1987723",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCandyTower",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCandyTower",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Tower"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/candy_tower.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100182",
        "GSubSort": "100182",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "8acdf145-fae4-4c65-9b84-d9b9a2bb80a7",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "47660.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1987723",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/candy_tower.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2099256",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20bermuda",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20bermuda",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Quest for Bermuda Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_quest_for_bermuda_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100176",
        "GSubSort": "100176",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2099256",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_quest_for_bermuda_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2090262",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayswest",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayswest",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystic Chief"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mystic_chief.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100173",
        "GSubSort": "100173",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2090262",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mystic_chief.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2071839",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs12bbb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs12bbb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bigger Bass Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/bigger_bass_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100171",
        "GSubSort": "100171",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.12",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2071839",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/bigger_bass_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2085591",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9piggybank",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9piggybank",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Piggy Bank Bills"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/piggy_bank_bills.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100169",
        "GSubSort": "100169",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2085591",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/piggy_bank_bills.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2081241",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20trsbox",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20trsbox",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure Wild"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/treasure_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100168",
        "GSubSort": "100168",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2081241",
        "RTP": "95.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/treasure_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1950101",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysbufking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysbufking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Buffalo King Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/buffalo_king_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100167",
        "GSubSort": "100167",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1950101",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/buffalo_king_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2169020",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10runes",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10runes",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gates of Valhalla"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gates_of_valhalla.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100167",
        "GSubSort": "100167",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2169020",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gates_of_valhalla.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2113938",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysbbb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysbbb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Bonanza Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_bonanza_megaways_1.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100166",
        "GSubSort": "100166",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2113938",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_bonanza_megaways_1.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2062356",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysyumyum",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysyumyum",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Yum Yum Powerways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/yum_yum_powerways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100165",
        "GSubSort": "100165",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2062356",
        "RTP": "96.4300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/yum_yum_powerways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2044067",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20fparty2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20fparty2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Party 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fruit_party_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100164",
        "GSubSort": "100164",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2044067",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fruit_party_2.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2098239",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGNineTails",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGNineTails",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Nine Tails",
            "id": "Nine Tails",
            "de": "Nine Tails",
            "es": "Nine Tails",
            "fr": "Nine Tails",
            "it": "Nine Tails",
            "nl": "Nine Tails",
            "no": "Nine Tails",
            "pt": "Nine Tails",
            "fi": "Nine Tails",
            "sv": "Nine Tails",
            "vi": "Nine Tails",
            "tr": "Nine Tails",
            "gr": "Nine Tails",
            "ru": "Nine Tails",
            "th": "Nine Tails",
            "ja": "Nine Tails",
            "zh-hant": "九尾狐",
            "zh-hans": "九尾狐",
            "ko": "Nine Tails"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/nine_tails.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100163",
        "GSubSort": "100163",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "9759e16f-ba1d-42dc-b36b-59fa94a13cc2",
        "MobileExternalCode": "9759e16f-ba1d-42dc-b36b-59fa94a13cc2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "46865.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2098239",
        "RTP": "96.8600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/nine_tails.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1965303",
        "System": "944",
        "SubSystem": null,
        "PageCode": "469",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100469",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "HammerFall"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hammerfall.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100157",
        "GSubSort": "100157",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1965303",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hammerfall.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2322532",
        "System": "939",
        "SubSystem": null,
        "PageCode": "rooster-rbl",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "rooster-rbl",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rooster Rumble"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/rooster_rumble.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100157",
        "GSubSort": "100157",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "123",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3158.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2322532",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/rooster_rumble.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2267284",
        "System": "939",
        "SubSystem": null,
        "PageCode": "queen-banquet",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "queen-banquet",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Queen's Banquet"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/the_queens_banquet.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100156",
        "GSubSort": "100156",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "120",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "9071.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2267284",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/the_queens_banquet.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2053427",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10nudgeit",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10nudgeit",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Giza PowerNudge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rise_of_giza_powernudge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100154",
        "GSubSort": "100154",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2053427",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rise_of_giza_powernudge.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2057925",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayschilheat",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayschilheat",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chilli Heat MEGAWAYS"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/chilli_heat_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100153",
        "GSubSort": "100153",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2057925",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/chilli_heat_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2079582",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFly",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFly",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fly!",
            "id": "Fly!",
            "de": "Fly!",
            "es": "Fly!",
            "fr": "Fly!",
            "it": "Fly!",
            "nl": "Fly!",
            "no": "Fly!",
            "pt": "Fly!",
            "fi": "Fly!",
            "sv": "Fly!",
            "vi": "Fly!",
            "tr": "Fly!",
            "gr": "Fly!",
            "ru": "Fly!",
            "th": "Fly!",
            "ja": "Fly!",
            "zh-hant": "飛象樂園",
            "zh-hans": "飞象乐园",
            "ko": "Fly!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fly.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100140",
        "GSubSort": "100140",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "88865855-c611-4067-8d80-38a0b0a6dcbc",
        "MobileExternalCode": "88865855-c611-4067-8d80-38a0b0a6dcbc",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "36140.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2079582",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fly.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1927225",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50juicyfr",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50juicyfr",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Juicy Fruits"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/juicy_fruits.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100139",
        "GSubSort": "100139",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1927225",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/juicy_fruits.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2132457",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5829",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5829",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon's Tavern"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/dragons_tavern.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100124",
        "GSubSort": "100124",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2067.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2132457",
        "RTP": "95.8500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/dragons_tavern.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2156664",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40wanderw",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40wanderw",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Depths"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_depths.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100123",
        "GSubSort": "100123",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2156664",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_depths.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1894379",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5752",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5752",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Super Nova"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruit_super_nova.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100120",
        "GSubSort": "100120",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1894379",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruit_super_nova.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1595044",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5517",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5517",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Triple Sevens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/hot_triple_sevens.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100119",
        "GSubSort": "100119",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1595044",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/hot_triple_sevens.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2062548",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5793",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5793",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Super Nova 30"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruit_super_nova_30.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100118",
        "GSubSort": "100118",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2062548",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruit_super_nova_30.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1831419",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5741",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5741",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Nova"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruit_nova.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100117",
        "GSubSort": "100117",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1831419",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruit_nova.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2003159",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5773",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5773",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Of Sirens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/gold_of_sirens.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100115",
        "GSubSort": "100115",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6013.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2003159",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/gold_of_sirens.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1965331",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5766",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5766",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cycle of Luck"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/cycle_of_luck.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100114",
        "GSubSort": "100114",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1965331",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/cycle_of_luck.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1817092",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5740",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5740",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ice Mania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/ice_mania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100113",
        "GSubSort": "100113",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "449.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1817092",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/ice_mania.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2076627",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5789",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5789",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Anubis’ Moon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/anubis_moon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100112",
        "GSubSort": "100112",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5008.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2076627",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/anubis_moon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170400",
        "System": "940",
        "SubSystem": null,
        "PageCode": "79",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "79",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Indiana's Quest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/indianas_quest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100111",
        "GSubSort": "100111",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1348.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170400",
        "RTP": "94.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/indianas_quest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170408",
        "System": "940",
        "SubSystem": null,
        "PageCode": "82",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "82",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Elven Princesses"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/elven_princesses.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100111",
        "GSubSort": "100111",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "696.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170408",
        "RTP": "95.4100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/elven_princesses.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2122464",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGProst",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGProst",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Prost!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/prost.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100096",
        "GSubSort": "100096",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "1e1ef619-1f28-4b9e-9ab7-21f5f39bba2a",
        "MobileExternalCode": "1e1ef619-1f28-4b9e-9ab7-21f5f39bba2a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "102783.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2122464",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/prost.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2061030",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMysticFortuneDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMysticFortuneDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystic Fortune Deluxe",
            "id": "Mystic Fortune Deluxe",
            "de": "Mystic Fortune Deluxe",
            "es": "Mystic Fortune Deluxe",
            "fr": "Mystic Fortune Deluxe",
            "it": "Mystic Fortune Deluxe",
            "nl": "Mystic Fortune Deluxe",
            "no": "Mystic Fortune Deluxe",
            "pt": "Mystic Fortune Deluxe",
            "fi": "Mystic Fortune Deluxe",
            "sv": "Mystic Fortune Deluxe",
            "vi": "Mystic Fortune Deluxe",
            "tr": "Mystic Fortune Deluxe",
            "gr": "Mystic Fortune Deluxe",
            "ru": "Mystic Fortune Deluxe",
            "th": "Mystic Fortune Deluxe",
            "ja": "Mystic Fortune Deluxe",
            "zh-hant": "東方之寶",
            "zh-hans": "东方之宝",
            "ko": "Mystic Fortune Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/mystic_fortune_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100093",
        "GSubSort": "100093",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "5bcb6c6d-297a-4757-8e12-4f506ad17d37",
        "MobileExternalCode": "5bcb6c6d-297a-4757-8e12-4f506ad17d37",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.28",
        "MaxBetDefault": "140.00",
        "MaxMultiplier": "132510.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2061030",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/mystic_fortune_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2038394",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCalaverasExplosivas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCalaverasExplosivas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Calaveras Explosivas",
            "id": "Calaveras Explosivas",
            "de": "Calaveras Explosivas",
            "es": "Calaveras Explosivas",
            "fr": "Calaveras Explosivas",
            "it": "Calaveras Explosivas",
            "nl": "Calaveras Explosivas",
            "no": "Calaveras Explosivas",
            "pt": "Calaveras Explosivas",
            "fi": "Calaveras Explosivas",
            "sv": "Calaveras Explosivas",
            "vi": "Calaveras Explosivas",
            "tr": "Calaveras Explosivas",
            "gr": "Calaveras Explosivas",
            "ru": "Calaveras Explosivas",
            "th": "Calaveras Explosivas",
            "ja": "Calaveras Explosivas",
            "zh-hant": "爆爆骷髏",
            "zh-hans": "爆爆骷髅",
            "ko": "Calaveras Explosivas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/calaveras_explosivas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100092",
        "GSubSort": "100092",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": "8f47ac60-ab9f-458e-8857-28e30f53bcc9",
        "MobileExternalCode": "8f47ac60-ab9f-458e-8857-28e30f53bcc9",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "326156.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2038394",
        "RTP": "96.7600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/calaveras_explosivas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2193192",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5855",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5855",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Budai Reels Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/budai_reels_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100072",
        "GSubSort": "100072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2193192",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/budai_reels_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2201428",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5857",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5857",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Overlords Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wild_overlords_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100072",
        "GSubSort": "100072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5578.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2201428",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wild_overlords_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2216988",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10tictac",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10tictac",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tic Tac Take"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/tic_tac_take.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100072",
        "GSubSort": "100072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2216988",
        "RTP": "96.6300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/tic_tac_take.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2246583",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5873",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5873",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Greatest Catch Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/the_greatest_catch_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100072",
        "GSubSort": "100072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7795.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2246583",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/the_greatest_catch_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2253657",
        "System": "944",
        "SubSystem": null,
        "PageCode": "584",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100584",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Gods Reckoning"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_gods_reckoning.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100072",
        "GSubSort": "100072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2253657",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_gods_reckoning.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2074638",
        "System": "939",
        "SubSystem": null,
        "PageCode": "jurassic-kdm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "jurassic-kdm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jurassic Kingdom"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/jurassic_kingdom.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100070",
        "GSubSort": "100070",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "110",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "6684.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2074638",
        "RTP": "96.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/jurassic_kingdom.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2010175",
        "System": "939",
        "SubSystem": null,
        "PageCode": "wild-bandito",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "wild-bandito",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Bandito"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/wild_bandito.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100068",
        "GSubSort": "100068",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "104",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "2525.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2010175",
        "RTP": "96.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/wild_bandito.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2039576",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20chickdrop",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20chickdrop",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chicken Drop"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/chicken_drop.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100068",
        "GSubSort": "100068",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2039576",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/chicken_drop.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2003819",
        "System": "939",
        "SubSystem": null,
        "PageCode": "majestic-ts",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "majestic-ts",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Majestic Treasures"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/majestic_treasures.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100067",
        "GSubSort": "100067",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "95",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "5236.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2003819",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/majestic_treasures.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2028335",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10luckcharm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10luckcharm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky, Grace & Charm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lucky_grace_and_charm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100066",
        "GSubSort": "100066",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2028335",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lucky_grace_and_charm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2009223",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayslions",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayslions",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Lions Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/5_lions_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100065",
        "GSubSort": "100065",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2009223",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/5_lions_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2015551",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20emptybank",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20emptybank",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Empty the Bank"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/empty_the_bank.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100065",
        "GSubSort": "100065",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2015551",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/empty_the_bank.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1977459",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20terrorv",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20terrorv",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Elevator"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cash_elevator.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100064",
        "GSubSort": "100064",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1977459",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cash_elevator.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2002459",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayslight",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayslight",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Lightning"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/lucky_lightning.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100063",
        "GSubSort": "100063",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "10100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2002459",
        "RTP": "96.4500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/lucky_lightning.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1984115",
        "System": "944",
        "SubSystem": null,
        "PageCode": "540",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100540",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Free Reelin' Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/free_reelin_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100061",
        "GSubSort": "100061",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1984115",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/free_reelin_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2000191",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5drhs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5drhs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Hot Hold & Spin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/dragon_hot_hold_and_spin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100061",
        "GSubSort": "100061",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2000191",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/dragon_hot_hold_and_spin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2053931",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5778",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5778",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shadow Of Luxor"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/shadow_of_luxor.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100052",
        "GSubSort": "100052",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3050.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2053931",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/shadow_of_luxor.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1977931",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25pandatemple",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25pandatemple",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Panda's Fortune 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pandas_fortune_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100050",
        "GSubSort": "100050",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1977931",
        "RTP": "95.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pandas_fortune_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2022535",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5775",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5775",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Super Nova 40"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruit_super_nova_40.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100044",
        "GSubSort": "100044",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3840.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2022535",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruit_super_nova_40.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2381238",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysstrwild",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysstrwild",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Stars"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/candy_stars.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100044",
        "GSubSort": "100044",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2381238",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/candy_stars.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2379870",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5919",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5919",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rueda de Chile"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rueda_de_chile.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100040",
        "GSubSort": "100040",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2275.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2379870",
        "RTP": "95.9500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rueda_de_chile.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2231708",
        "System": "944",
        "SubSystem": null,
        "PageCode": "603",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100603",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Moon Princess 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/moon_princess_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100035",
        "GSubSort": "100035",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1029",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2231708",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/moon_princess_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1029"
    },
    {
        "ID": "2300676",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5893",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5893",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Dreams: Sweet Planet"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/candy_dreams_sweet_planet.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100034",
        "GSubSort": "100034",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "14.79",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2300676",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/candy_dreams_sweet_planet.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2072013",
        "System": "944",
        "SubSystem": null,
        "PageCode": "549",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100549",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ghost of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ghost_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100031",
        "GSubSort": "100031",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2072013",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ghost_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1952173",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25hotfiesta",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25hotfiesta",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Fiesta"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_fiesta.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100028",
        "GSubSort": "100028",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1952173",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_fiesta.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1964855",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10amm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10amm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Amazing Money Machine"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/amazing_money_machine.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100027",
        "GSubSort": "100027",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1964855",
        "RTP": "96.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/amazing_money_machine.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1925658",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20wildboost",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20wildboost",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Booster"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_booster.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100026",
        "GSubSort": "100026",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1925658",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_booster.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2300420",
        "System": "944",
        "SubSystem": null,
        "PageCode": "629",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100629",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "NSYNC Pop"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/nsync_pop.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100024",
        "GSubSort": "100024",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2300420",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/nsync_pop.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1970935",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20phoenixf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20phoenixf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Phoenix Forge"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/phoenix_forge.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100022",
        "GSubSort": "100022",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1970935",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/phoenix_forge.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2369709",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5867",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5867",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blessed Flame"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/blessed_flame.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100019",
        "GSubSort": "100019",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3645.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2369709",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/blessed_flame.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1945857",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10floatdrg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10floatdrg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Floating Dragon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/floating_dragon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100015",
        "GSubSort": "100015",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1945857",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/floating_dragon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2133657",
        "System": "944",
        "SubSystem": null,
        "PageCode": "568",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100568",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hooligan Hustle"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hooligan_hustle.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100015",
        "GSubSort": "100015",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2133657",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hooligan_hustle.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1933785",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayshammthor",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayshammthor",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Power of Thor Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/power_of_thor_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100013",
        "GSubSort": "100013",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1933785",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/power_of_thor_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2044751",
        "System": "944",
        "SubSystem": null,
        "PageCode": "541",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100541",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "ZZ Top Roadside Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/zz_top_roadside_riches.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100013",
        "GSubSort": "100013",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "40000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2044751",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/zz_top_roadside_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2304848",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5889",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5889",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Minter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/money_minter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100011",
        "GSubSort": "100011",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4905.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2304848",
        "RTP": "96.1300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/money_minter.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2336828",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5902",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5902",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Volcano"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/hot_volcano.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100011",
        "GSubSort": "100011",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3078.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2336828",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/hot_volcano.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2345320",
        "System": "944",
        "SubSystem": null,
        "PageCode": "645",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100645",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mount M"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mount_m.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100011",
        "GSubSort": "100011",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "13000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2345320",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mount_m.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2399044",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5926",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5926",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Collapsed Castle"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/collapsed_castle.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100010",
        "GSubSort": "100010",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3105.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2399044",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/collapsed_castle.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2357376",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5903",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5903",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Massive Luck"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/massive_luck.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100009",
        "GSubSort": "100009",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3385.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2357376",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/massive_luck.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2263020",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20stickysymbol",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20stickysymbol",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Great Stick-Up"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_great_stick_up.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100008",
        "GSubSort": "100008",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2263020",
        "RTP": "96.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_great_stick_up.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2332208",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5901",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5901",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wolf Hiding"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wolf_hiding.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100008",
        "GSubSort": "100008",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "10068.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2332208",
        "RTP": "95.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wolf_hiding.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1911586",
        "System": "944",
        "SubSystem": null,
        "PageCode": "462",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100462",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Faces of Freya"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_faces_of_freya.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1911586",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_faces_of_freya.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1984095",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20magicpot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20magicpot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Magic Cauldron - Enchanted Brew"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_magic_cauldron_enchanted_brew.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1984095",
        "RTP": "96.4400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_magic_cauldron_enchanted_brew.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1996623",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25rio",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25rio",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Heart of Rio"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/heart_of_rio.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "10500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1996623",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/heart_of_rio.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2029699",
        "System": "939",
        "SubSystem": null,
        "PageCode": "crypto-gold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "crypto-gold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crypto Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/crypto_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "103",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "8329.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2029699",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/crypto_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2058294",
        "System": "944",
        "SubSystem": null,
        "PageCode": "548",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100548",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo Carts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugo_carts.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2058294",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugo_carts.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2175488",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20colcashzone",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20colcashzone",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Colossal Cash Zone"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/colossal_cash_zone.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2175488",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/colossal_cash_zone.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2273520",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5883",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5883",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pride Fight"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/pride_fight.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2273520",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/pride_fight.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2274412",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10egrich",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10egrich",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen of Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/queen_of_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1839.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2274412",
        "RTP": "96.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/queen_of_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2376492",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25kfruit",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25kfruit",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Blaze"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/aztec_blaze.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2376492",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/aztec_blaze.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2407352",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20kraken2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20kraken2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Release the Kraken 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/release_the_kraken_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2407352",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/release_the_kraken_2.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2413708",
        "System": "944",
        "SubSystem": null,
        "PageCode": "670",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100670",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Olympus 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_olympus_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100007",
        "GSubSort": "100007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2413708",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_olympus_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2432272",
        "System": "944",
        "SubSystem": null,
        "PageCode": "708",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "708",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Athena Ascending"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/athena_ascending.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100006",
        "GSubSort": "100006",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2432272",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/athena_ascending.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2437088",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5949",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5949",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sold it!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/sold_it.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100006",
        "GSubSort": "100006",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "3536.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2437088",
        "RTP": "96.1600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/sold_it.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2458108",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20ltng",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20ltng",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pinup Girls"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pinup_girls.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100006",
        "GSubSort": "100006",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2458108",
        "RTP": "96.4400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pinup_girls.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1915088",
        "System": "944",
        "SubSystem": null,
        "PageCode": "471",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100471",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Green Knight"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_green_knight.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1915088",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_green_knight.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1918170",
        "System": "944",
        "SubSystem": null,
        "PageCode": "467",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100467",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "House of Doom 2: The Crypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/house_of_doom_2_the_crypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1918170",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/house_of_doom_2_the_crypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1927761",
        "System": "944",
        "SubSystem": null,
        "PageCode": "472",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100472",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shimmering Woods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/shimmering_woods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1927761",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/shimmering_woods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1933765",
        "System": "944",
        "SubSystem": null,
        "PageCode": "475",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100475",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rich Wilde and the Amulet of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rich_wilde_and_the_amulet_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1933765",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rich_wilde_and_the_amulet_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1940389",
        "System": "944",
        "SubSystem": null,
        "PageCode": "466",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100466",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Toad"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fire_toad.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1940389",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fire_toad.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1945621",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5760",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5760",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Food Feast"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/food_feast.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1945621",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/food_feast.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1952857",
        "System": "944",
        "SubSystem": null,
        "PageCode": "473",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100473",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Joker Freeze"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fire_joker_freeze.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1952857",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fire_joker_freeze.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1958923",
        "System": "944",
        "SubSystem": null,
        "PageCode": "477",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100477",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rabbit Hole Riches - Court of Hearts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/court_of_hearts.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "8500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1958923",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/court_of_hearts.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1971599",
        "System": "944",
        "SubSystem": null,
        "PageCode": "474",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100474",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Clown Monty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/3_clown_monty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1971599",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/3_clown_monty.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1977915",
        "System": "944",
        "SubSystem": null,
        "PageCode": "530",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100530",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scroll of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/scroll_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1977915",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/scroll_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1984111",
        "System": "944",
        "SubSystem": null,
        "PageCode": "532",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100532",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Prism of Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/prism_of_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1984111",
        "RTP": "96.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/prism_of_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1990543",
        "System": "944",
        "SubSystem": null,
        "PageCode": "536",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100536",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Island Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/candy_island_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1990543",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/candy_island_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1996619",
        "System": "944",
        "SubSystem": null,
        "PageCode": "534",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100534",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Charlie Chance"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/charlie_chance.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1996619",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/charlie_chance.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2009719",
        "System": "944",
        "SubSystem": null,
        "PageCode": "478",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100478",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pack and Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pack_and_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2009719",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pack_and_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2015799",
        "System": "944",
        "SubSystem": null,
        "PageCode": "533",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100533",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blazin’ Bullfrog"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/blazin_bullfrog.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1215.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2015799",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/blazin_bullfrog.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2028795",
        "System": "944",
        "SubSystem": null,
        "PageCode": "479",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100479",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hotel Yeti-Way"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hotel_yeti_way.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2028795",
        "RTP": "96.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hotel_yeti_way.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2040107",
        "System": "944",
        "SubSystem": null,
        "PageCode": "537",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100537",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Odin: Protector of Realms"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/odin_protector_of_the_realms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2040107",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/odin_protector_of_the_realms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2049263",
        "System": "944",
        "SubSystem": null,
        "PageCode": "535",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100535",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamonds of the Realm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/diamonds_of_the_realms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2049263",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/diamonds_of_the_realms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2053919",
        "System": "944",
        "SubSystem": null,
        "PageCode": "542",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100542",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Agent of Hearts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/agent_of_hearts.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5262.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2053919",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/agent_of_hearts.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2062533",
        "System": "944",
        "SubSystem": null,
        "PageCode": "547",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100547",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sisters of The Sun"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sisters_of_the_sun.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2062533",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sisters_of_the_sun.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2066673",
        "System": "944",
        "SubSystem": null,
        "PageCode": "538",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100538",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dr. Toonz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/dr_toonz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "22000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2066673",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/dr_toonz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2076633",
        "System": "944",
        "SubSystem": null,
        "PageCode": "554",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100554",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Wild Class"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_wild_class.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6666.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2076633",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_wild_class.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2081424",
        "System": "944",
        "SubSystem": null,
        "PageCode": "539",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100539",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Charlie Chance and The Curse of Cleopatra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/curse_of_cleopatra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2081424",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/curse_of_cleopatra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2086083",
        "System": "944",
        "SubSystem": null,
        "PageCode": "531",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100531",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Alice Cooper and the Tome of Madness"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/alice_cooper_and_the_tome_of_madness.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2086083",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/alice_cooper_and_the_tome_of_madness.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2099934",
        "System": "944",
        "SubSystem": null,
        "PageCode": "565",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100565",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tales of Asgard: Loki’s Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/tales_of_asgard_lokis_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2099934",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/tales_of_asgard_lokis_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2123847",
        "System": "944",
        "SubSystem": null,
        "PageCode": "600",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100600",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Moon Princess: Christmas Kingdom"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/moon_princess_christmas_kingdom.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2123847",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/moon_princess_christmas_kingdom.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2139324",
        "System": "944",
        "SubSystem": null,
        "PageCode": "579",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100579",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Beasts Of Fire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/beasts_of_fire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2139324",
        "RTP": "96.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/beasts_of_fire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2144556",
        "System": "944",
        "SubSystem": null,
        "PageCode": "585",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100585",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Captain Xeno's Earth Adventure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/captain_xenos_earth_adventure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2144556",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/captain_xenos_earth_adventure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2150476",
        "System": "944",
        "SubSystem": null,
        "PageCode": "570",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100570",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tale Of Kyubiko"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/tale_of_kyubiko.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2150476",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/tale_of_kyubiko.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2156656",
        "System": "944",
        "SubSystem": null,
        "PageCode": "567",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100567",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "15 Crystal Roses: A Tale of Love"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/15_crystal_roses_a_tale_of_love.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2156656",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/15_crystal_roses_a_tale_of_love.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2162920",
        "System": "944",
        "SubSystem": null,
        "PageCode": "610",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100610",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Love Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/love_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1008.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2162920",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/love_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2169260",
        "System": "944",
        "SubSystem": null,
        "PageCode": "574",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100574",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gigantoonz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gigantoonz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2169260",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gigantoonz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2175732",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5849",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5849",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Andar Nights"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/andar_nights.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "17920.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2175732",
        "RTP": "95.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/andar_nights.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2182488",
        "System": "944",
        "SubSystem": null,
        "PageCode": "456",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100456",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cat Wilde and the Lost Chapter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cat_wilde_and_the_lost_chapter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2182488",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cat_wilde_and_the_lost_chapter.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2189000",
        "System": "944",
        "SubSystem": null,
        "PageCode": "596",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100596",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tales of Asgard: Freya's Wedding"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/tales_of_asgard_freyas_wedding.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2189000",
        "RTP": "96.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/tales_of_asgard_freyas_wedding.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2195044",
        "System": "944",
        "SubSystem": null,
        "PageCode": "606",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100606",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Secret of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/secret_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2195044",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/secret_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2201404",
        "System": "944",
        "SubSystem": null,
        "PageCode": "632",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100632",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Safari of Wealth"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/safari_of_wealth.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2201404",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/safari_of_wealth.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2207600",
        "System": "944",
        "SubSystem": null,
        "PageCode": "614",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100614",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fat Frankies"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fat_frankies.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2207600",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fat_frankies.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2207628",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5863",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5863",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Patrick’s Magic Field"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/patricks_magic_field.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "9007.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2207628",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/patricks_magic_field.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2212024",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5865",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5865",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Card"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/lucky_card.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "19"
        ],
        "SortPerCategory": {
            "19": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "45.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2212024",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/lucky_card.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2213840",
        "System": "944",
        "SubSystem": null,
        "PageCode": "607",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100607",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raging Rex 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/raging_rex_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2213840",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/raging_rex_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2219660",
        "System": "944",
        "SubSystem": null,
        "PageCode": "638",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100638",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Eye of Atum"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/eye_of_atum.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2219660",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/eye_of_atum.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2223932",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5868",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5868",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Temple of Thunder"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/temple_of_thunder.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3096.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2223932",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/temple_of_thunder.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2225492",
        "System": "944",
        "SubSystem": null,
        "PageCode": "612",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100612",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Def Leppard Hysteria"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/def_eppard_hysteria.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2225492",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/def_eppard_hysteria.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2237987",
        "System": "944",
        "SubSystem": null,
        "PageCode": "609",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100609",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Forge of Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/forge_of_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2237987",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/forge_of_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2242552",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5871",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5871",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Greatest Catch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/the_greatest_catch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6808.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2242552",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/the_greatest_catch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2244087",
        "System": "944",
        "SubSystem": null,
        "PageCode": "619",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100619",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Puebla Parade"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/puebla_parade.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2244087",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/puebla_parade.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2248878",
        "System": "944",
        "SubSystem": null,
        "PageCode": "608",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100608",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lordi Reel Monsters"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/lordi_reel_monsters.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2248878",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/lordi_reel_monsters.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2258040",
        "System": "944",
        "SubSystem": null,
        "PageCode": "633",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100633",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Idol of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/idol_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2258040",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/idol_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2258046",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5877",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5877",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Juicy Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/juicy_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2258046",
        "RTP": "96.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/juicy_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2263004",
        "System": "944",
        "SubSystem": null,
        "PageCode": "617",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100617",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Animal Madness"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/animal_madness.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2263004",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/animal_madness.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2269088",
        "System": "944",
        "SubSystem": null,
        "PageCode": "639",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100639",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "King's Mask"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/kings_mask.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2269088",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/kings_mask.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2275112",
        "System": "944",
        "SubSystem": null,
        "PageCode": "616",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100616",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Trigger"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_trigger.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2275112",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_trigger.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2281276",
        "System": "944",
        "SubSystem": null,
        "PageCode": "476",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100476",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Derby Wheel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/derby_wheel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2240.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2281276",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/derby_wheel.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2288036",
        "System": "944",
        "SubSystem": null,
        "PageCode": "586",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100586",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rocco Gallo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rocco_gallo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2288036",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rocco_gallo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2294228",
        "System": "944",
        "SubSystem": null,
        "PageCode": "613",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100613",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cat Wilde and the Pyramids of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cat_wilde_and_the_pyramids_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2294228",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cat_wilde_and_the_pyramids_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2306672",
        "System": "944",
        "SubSystem": null,
        "PageCode": "620",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100620",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Merlin's Grimoire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/merlins_grimoire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2306672",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/merlins_grimoire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2312944",
        "System": "944",
        "SubSystem": null,
        "PageCode": "624",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100624",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rotiki"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rotiki.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2312944",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rotiki.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2323744",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5899",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5899",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Chests"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/magic_chests.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "1500.00",
        "MaxMultiplier": "550.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2323744",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/magic_chests.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2325740",
        "System": "944",
        "SubSystem": null,
        "PageCode": "625",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100625",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mega Don"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mega_don.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2325740",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mega_don.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2332184",
        "System": "944",
        "SubSystem": null,
        "PageCode": "656",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100656",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun's Vault"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/leprechauns_vault.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2332184",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/leprechauns_vault.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2338652",
        "System": "944",
        "SubSystem": null,
        "PageCode": "622",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100622",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Forge of Fortunes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/forge_of_fortunes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2338652",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/forge_of_fortunes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2351724",
        "System": "944",
        "SubSystem": null,
        "PageCode": "646",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "646",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bull in a Rodeo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/bull_in_a_rodeo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2351724",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/bull_in_a_rodeo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2357364",
        "System": "944",
        "SubSystem": null,
        "PageCode": "588",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100588",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Champions of Mithrune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/champions_of_mithrune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2357364",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/champions_of_mithrune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2366967",
        "System": "944",
        "SubSystem": null,
        "PageCode": "618",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100618",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gates of Troy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gates_of_troy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2366967",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gates_of_troy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2371647",
        "System": "944",
        "SubSystem": null,
        "PageCode": "641",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100641",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "ImmorTails of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/immortails_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2371647",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/immortails_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2376495",
        "System": "944",
        "SubSystem": null,
        "PageCode": "589",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "589",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Rewind"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fortune_rewind.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "8.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2376495",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fortune_rewind.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2381229",
        "System": "944",
        "SubSystem": null,
        "PageCode": "653",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "653",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "DIO - Killing the Dragon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/dio_killing_the_dragon.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2381229",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/dio_killing_the_dragon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2388108",
        "System": "944",
        "SubSystem": null,
        "PageCode": "664",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "664",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Boat Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/boat_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2388108",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/boat_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2400972",
        "System": "944",
        "SubSystem": null,
        "PageCode": "685",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100685",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Count Jokula"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/count_jokula.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2400972",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/count_jokula.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2407356",
        "System": "944",
        "SubSystem": null,
        "PageCode": "590",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100590",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Canine Carnage"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/canine_carnage.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2407356",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/canine_carnage.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2420092",
        "System": "944",
        "SubSystem": null,
        "PageCode": "659",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100659",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "USA Flip"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/usa_flip.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2420092",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/usa_flip.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2425892",
        "System": "944",
        "SubSystem": null,
        "PageCode": "591",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100591",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Clash of Camelot"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/clash_of_camelot.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "35000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2425892",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/clash_of_camelot.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2458850",
        "System": "944",
        "SubSystem": null,
        "PageCode": "662",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "662",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shamrock Miner"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/shamrock_miner.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.12",
        "MaxBetDefault": "60.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2458850",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/shamrock_miner.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2477150",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5951",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5951",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Rio Nights"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/hot_rio_nights.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100005",
        "GSubSort": "100005",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5044.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2477150",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/hot_rio_nights.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1958027",
        "System": "939",
        "SubSystem": null,
        "PageCode": "bali-vacation",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "bali-vacation",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bali Vacation"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/bali_vacation.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "94",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "3560.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1958027",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/bali_vacation.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1984839",
        "System": "939",
        "SubSystem": null,
        "PageCode": "candy-bonanza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "candy-bonanza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/candy_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "100",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2563.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1984839",
        "RTP": "96.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/candy_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2261264",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5879",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5879",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Juicy Gems Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/juicy_gems_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2261264",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/juicy_gems_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2316528",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5sh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5sh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shining Hot 5"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/shining_hot_5.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2316528",
        "RTP": "96.3300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/shining_hot_5.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2316532",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shining Hot 20"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/shining_hot_20.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2316532",
        "RTP": "96.3500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/shining_hot_20.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2319224",
        "System": "944",
        "SubSystem": null,
        "PageCode": "587",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100587",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash of Command"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cash_of_command.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5336.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2319224",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cash_of_command.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2343436",
        "System": "940",
        "SubSystem": null,
        "PageCode": "220",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "220",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Camino de Chili Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/camino_de_chili_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2343436",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/camino_de_chili_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2355993",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5907",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5907",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Dreams: Sweet Planet Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/candy_dreams_sweet_planet_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "10051.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2355993",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/candy_dreams_sweet_planet_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2360883",
        "System": "940",
        "SubSystem": null,
        "PageCode": "229",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "229",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mega Greatest Catch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mega_greatest_catch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9007.66",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2360883",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mega_greatest_catch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2370381",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5911",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5911",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Minter Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/money_minter_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2370381",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/money_minter_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2375115",
        "System": "940",
        "SubSystem": null,
        "PageCode": "230",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "230",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Old West"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/old_west.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "19"
        ],
        "SortPerCategory": {
            "19": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "36.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2375115",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/old_west.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2385796",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5913",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5913",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Redrose Sanctuary"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/redrose_sanctuary.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2691.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2385796",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/redrose_sanctuary.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2405524",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5928",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5928",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Football Scratch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/football_scratch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "19"
        ],
        "SortPerCategory": {
            "19": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "100000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2405524",
        "RTP": "97.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/football_scratch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2411824",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5935",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5935",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rueda De Chile Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rueda_de_chile_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2275.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2411824",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rueda_de_chile_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2424408",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5937",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5937",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ethan Grand: Mayan Diaries"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/ethan_grand_mayan_diaries.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "22": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "61.44",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2424408",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/ethan_grand_mayan_diaries.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2429488",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5947",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5947",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Expanding Master"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/expanding_master.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3058.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2429488",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/expanding_master.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2430392",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5922",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5922",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mega Greatest Catch Bоnus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mega_greatest_catch_bоnus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9007.66",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2430392",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mega_greatest_catch_bоnus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2432312",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5941",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5941",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure-snipes: Christmas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasure_snipes_сhristmas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2432312",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasure_snipes_сhristmas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2443636",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5905",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5905",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Massive Luck Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/massive_luck_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3385.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2443636",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/massive_luck_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2452336",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5943",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5943",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Collapsed Castle Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/collapsed_castle_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3302.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2452336",
        "RTP": "96.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/collapsed_castle_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2465534",
        "System": "944",
        "SubSystem": null,
        "PageCode": "686",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "686",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash-a-Cabana"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cash_a_cabana.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2465534",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cash_a_cabana.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2465542",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5957",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5957",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure-snipes: Christmas Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasure_snipes_christmas_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2465542",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasure_snipes_christmas_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2472294",
        "System": "944",
        "SubSystem": null,
        "PageCode": "689",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "689",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Invading Vegas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/invading_vegas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2472294",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/invading_vegas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2477146",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5923",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5923",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Inner Fire Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/inner_fire_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10148.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2477146",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/inner_fire_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2479134",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5959",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5959",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sold it Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/sold_it_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "3536.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2479134",
        "RTP": "96.1600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/sold_it_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2492642",
        "System": "944",
        "SubSystem": null,
        "PageCode": "755",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "755",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legacy of Inca"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legacy_of_inca.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2492642",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legacy_of_inca.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2497302",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5967",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5967",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of the Priestess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/book_of_the_priestess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6029.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2497302",
        "RTP": "95.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/book_of_the_priestess.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2504286",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5969",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5969",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure-snipes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasure_snipes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "10000.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2504286",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasure_snipes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2506342",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5961",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5961",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Funny Hunting"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/funny_hunting.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100004",
        "GSubSort": "100004",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "30000.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2506342",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/funny_hunting.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2472570",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20superlanche",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20superlanche",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monster Superlanche"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/monster_superlanche.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100003",
        "GSubSort": "100003",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2472570",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/monster_superlanche.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2499334",
        "System": "944",
        "SubSystem": null,
        "PageCode": "660",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "660",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legion Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legion_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100003",
        "GSubSort": "100003",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2499334",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legion_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1940165",
        "System": "939",
        "SubSystem": null,
        "PageCode": "fortune-ox",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "fortune-ox",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Ox"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/fortune_ox.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100002",
        "GSubSort": "100002",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "98",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1940165",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/fortune_ox.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2316536",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40sh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40sh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shining Hot 40"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/shining_hot_40.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100002",
        "GSubSort": "100002",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2316536",
        "RTP": "96.3600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/shining_hot_40.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2316540",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs100sh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs100sh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shining Hot 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/shining_hot_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100002",
        "GSubSort": "100002",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2316540",
        "RTP": "96.3200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/shining_hot_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2465538",
        "System": "944",
        "SubSystem": null,
        "PageCode": "693",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "693",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Game of Gladiators Uprising"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/game_of_gladiators_uprising.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100002",
        "GSubSort": "100002",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2465538",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/game_of_gladiators_uprising.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1916679",
        "System": "939",
        "SubSystem": null,
        "PageCode": "gdn-ice-fire",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "gdn-ice-fire",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Guardians of Ice and Fire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/guardians_of_ice_and_fire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "91",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "50000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1916679",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/guardians_of_ice_and_fire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1927769",
        "System": "939",
        "SubSystem": null,
        "PageCode": "opera-dynasty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "opera-dynasty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Opera Dynasty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/opera_dynasty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "93",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "8841.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1927769",
        "RTP": "96.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/opera_dynasty.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2082114",
        "System": "939",
        "SubSystem": null,
        "PageCode": "mermaid-riches",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "mermaid-riches",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mermaid Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/mermaid_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "102",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "3513.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2082114",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/mermaid_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2105322",
        "System": "939",
        "SubSystem": null,
        "PageCode": "crypt-fortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "crypt-fortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raider Jane's Crypt of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/raider_janes_crypt_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "113",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4742.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2105322",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/raider_janes_crypt_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2322524",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10mmm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10mmm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Money Maze"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/magic_money_maze.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2322524",
        "RTP": "96.6100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/magic_money_maze.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2410696",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysfrywld",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysfrywld",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Spin & Score Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/spin_n_score_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2410696",
        "RTP": "96.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/spin_n_score_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2446544",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20schristmas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20schristmas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Starlight Christmas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/starlight_christmas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2446544",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/starlight_christmas.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2478886",
        "System": "944",
        "SubSystem": null,
        "PageCode": "684",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "684",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pilgrim of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pilgrim_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100001",
        "GSubSort": "100001",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "50000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2478886",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pilgrim_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2503262",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10powerlines",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10powerlines",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Peak Power"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/peak_power.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2503262",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/peak_power.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2506326",
        "System": "944",
        "SubSystem": null,
        "PageCode": "787",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "787",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Colt Lightning"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/colt_lightning.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2506326",
        "RTP": "96.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/colt_lightning.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2520218",
        "System": "944",
        "SubSystem": null,
        "PageCode": "690",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "690",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pandora's Box of Evil"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pandoras_box_of_evil.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2520218",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pandoras_box_of_evil.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2533789",
        "System": "944",
        "SubSystem": null,
        "PageCode": "712",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "712",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Moon Princess Trinity"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/moon_princess_trinity.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2533789",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/moon_princess_trinity.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2533801",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5979",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5979",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wonder Farm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wonder_farm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3442.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2533801",
        "RTP": "95.9500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wonder_farm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2533805",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5981",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5981",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Irish Weekend"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/irish_weekend.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2533805",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/irish_weekend.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2541037",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20goldclust",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20goldclust",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rabbit Garden™"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rabbit_garden.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2541037",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rabbit_garden.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2541046",
        "System": "944",
        "SubSystem": null,
        "PageCode": "661",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "661",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Bandolier"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_bandolier.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2541046",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_bandolier.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2541055",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5977",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5977",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of the Priestess Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/book_of_the_priestess_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "6088.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2541055",
        "RTP": "95.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/book_of_the_priestess_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2544067",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bbhas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bbhas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass - Hold & Spinner™"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_bonanza_hold_and_spinner.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2544067",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_bonanza_hold_and_spinner.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2546446",
        "System": "944",
        "SubSystem": null,
        "PageCode": "810",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "810",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Craps"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/craps.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "5000.00",
        "MaxMultiplier": "12.56",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2546446",
        "RTP": "99.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/craps.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2552074",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5983",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5983",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasures of the Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasures_of_the_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3932.16",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2552074",
        "RTP": "95.8700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasures_of_the_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2553613",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5971",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5971",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Bartender"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/super_bartender.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "32.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2553613",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/super_bartender.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2553619",
        "System": "944",
        "SubSystem": null,
        "PageCode": "709",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "709",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Slashimi"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/slashimi.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2553619",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/slashimi.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2559125",
        "System": "944",
        "SubSystem": null,
        "PageCode": "640",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "640",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Captain Glum: Pirate Hunter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/captain_glum_pirate_hunter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2559125",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/captain_glum_pirate_hunter.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2559129",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5987",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5987",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Unlimited Treasures"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/unlimited_treasures.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2559129",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/unlimited_treasures.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2559145",
        "System": "944",
        "SubSystem": null,
        "PageCode": "595",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "595",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ronin's Honour"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ronins_honour.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2559145",
        "RTP": "96.2200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ronins_honour.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2571540",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5989",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5989",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Irish Weekend Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/irish_weekend_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3120.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2571540",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/irish_weekend_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2573736",
        "System": "944",
        "SubSystem": null,
        "PageCode": "642",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "642",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gerard's Gambit"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gerards_gambit.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2573736",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gerards_gambit.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2578924",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5991",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5991",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "X-Demon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/x_demon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2560.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2578924",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/x_demon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2588412",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5993",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5993",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Neon Capital"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/neon_capital.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2588412",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/neon_capital.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2588432",
        "System": "944",
        "SubSystem": null,
        "PageCode": "735",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "735",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Luchamigos"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/luchamigos.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2588432",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/luchamigos.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2588440",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6001",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6001",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Long Ball"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/long_ball.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "3604"
        ],
        "SortPerCategory": {
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "750.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2588440",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/long_ball.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2594452",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5995",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5995",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Unlimited Treasures Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/unlimited_treasures_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2594452",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/unlimited_treasures_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2596364",
        "System": "944",
        "SubSystem": null,
        "PageCode": "808",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "808",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Highway Legends"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/highway_legends.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2596364",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/highway_legends.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2601720",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5999",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5999",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "X-Demon Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/x_demon_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2584.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2601720",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/x_demon_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2603880",
        "System": "944",
        "SubSystem": null,
        "PageCode": "737",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "737",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Free Reelin Joker 1000"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/free_reelin_joker_1000.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "105.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2603880",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/free_reelin_joker_1000.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2609152",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6007",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6007",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Soccer Solo Striker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/soccer_solo_striker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "400.00",
        "MaxMultiplier": "192.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2609152",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/soccer_solo_striker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2611304",
        "System": "944",
        "SubSystem": null,
        "PageCode": "648",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "648",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Viking Runecraft: Apocalypse"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/viking_runecraft_apocalypse.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2611304",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/viking_runecraft_apocalypse.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2618668",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6009",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6009",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Goddess of the Night"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/goddess_of_the_night.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2803.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2618668",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/goddess_of_the_night.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2618676",
        "System": "944",
        "SubSystem": null,
        "PageCode": "726",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "726",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ternion"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ternion.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2618676",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ternion.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2618684",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5997",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5997",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Neon Capital Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/neon_capital_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2618684",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/neon_capital_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2624180",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6011",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6011",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mary’s Mining Mania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/marys_mining_mania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "3604"
        ],
        "SortPerCategory": {
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "750.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2624180",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/marys_mining_mania.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2625140",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6013",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6013",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Penalty Shoot-out: Street"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/penalty_shoot_out_street.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "22": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "32.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2625140",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/penalty_shoot_out_street.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2626556",
        "System": "944",
        "SubSystem": null,
        "PageCode": "710",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "710",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Alchemy 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sweet_alchemy_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2626556",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sweet_alchemy_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2633793",
        "System": "944",
        "SubSystem": null,
        "PageCode": "674",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "674",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rascal Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rascal_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2633793",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rascal_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2638181",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSirensSpell",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSirensSpell",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Siren's Spell"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/sirens_spell.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "3843d517-2eac-40b6-8f75-cdad19f98ab5",
        "MobileExternalCode": "3843d517-2eac-40b6-8f75-cdad19f98ab5",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2638181",
        "RTP": "96.7900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/sirens_spell.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "2640501",
        "System": "944",
        "SubSystem": null,
        "PageCode": "671",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "671",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sparky & Shortz Hidden Joules"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sparky_and_shortz_hidden_joules.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2640501",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sparky_and_shortz_hidden_joules.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2647577",
        "System": "944",
        "SubSystem": null,
        "PageCode": "649",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "649",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fox Mayhem"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fox_mayhem.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2647577",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fox_mayhem.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2647857",
        "System": "944",
        "SubSystem": null,
        "PageCode": "713",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "713",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Alchemy 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sweet_alchemy_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2647857",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sweet_alchemy_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2653409",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6021",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6021",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Goddess of the Night Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/goddess_of_the_night_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2803.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2653409",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/goddess_of_the_night_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2655893",
        "System": "944",
        "SubSystem": null,
        "PageCode": "696",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "696",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Merlin: Journey of Flame"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/merlin_journey_of_flame.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2655893",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/merlin_journey_of_flame.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2660261",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50dmdcascade",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50dmdcascade",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamond Cascade"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/diamond_cascade.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "8000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2660261",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/diamond_cascade.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2661433",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6025",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6025",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Europe Transit"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/europe_transit.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2571.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2661433",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/europe_transit.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2663357",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6019",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6019",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Penalty Roulette"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/penalty_roulette.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "1366"
        ],
        "SortPerCategory": {
            "22": 0,
            "1366": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "66.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2663357",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/penalty_roulette.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2663373",
        "System": "944",
        "SubSystem": null,
        "PageCode": "807",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "807",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scroll of Seth"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/scroll_of_seth.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2663373",
        "RTP": "96.3100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/scroll_of_seth.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2668669",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6023",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6023",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Northern Temple Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/northern_temple_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "6258.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2668669",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/northern_temple_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2679473",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6033",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6033",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Porker Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/lucky_porker_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2679473",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/lucky_porker_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2687074",
        "System": "944",
        "SubSystem": null,
        "PageCode": "727",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "727",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo Legacy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugo_legacy.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2687074",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugo_legacy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2687078",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6029",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6029",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Porker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/lucky_porker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2687078",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/lucky_porker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2692810",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6035",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6035",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Europe Transit Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/europe_transit_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2571.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2692810",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/europe_transit_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2693248",
        "System": "944",
        "SubSystem": null,
        "PageCode": "714",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "714",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Honey Rush 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/honey_rush_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "50000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2693248",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/honey_rush_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2697538",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6036",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6036",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Thunder Mega Sevens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/thunder_mega_sevens.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3002.90",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2697538",
        "RTP": "95.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/thunder_mega_sevens.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2699338",
        "System": "944",
        "SubSystem": null,
        "PageCode": "675",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "675",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Boat Bonanza Colossal Catch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/boat_bonanza_colossal_catch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2699338",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/boat_bonanza_colossal_catch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2701886",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6037",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6037",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Elven Princesses: Crown Quest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/elven_princesses_crown_quest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "9007.66",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2701886",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/elven_princesses_crown_quest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2703785",
        "System": "944",
        "SubSystem": null,
        "PageCode": "643",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "643",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scales of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/scales_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2703785",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/scales_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2705789",
        "System": "944",
        "SubSystem": null,
        "PageCode": "700",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "700",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Temple of Prosperity"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/temple_of_prosperity.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2705789",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/temple_of_prosperity.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2705876",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6039",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6039",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jhana of God"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jhana_of_god.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "12068.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2705876",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jhana_of_god.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2707442",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6038",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6038",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Thunder Mega Sevens Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/thunder_mega_sevens_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3133.40",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2707442",
        "RTP": "95.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/thunder_mega_sevens_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2708102",
        "System": "944",
        "SubSystem": null,
        "PageCode": "853",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "853",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Piggy Blitz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/piggy_blitz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2708102",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/piggy_blitz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2708106",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6040",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6040",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jhana of God Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jhana_of_god_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "21392.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2708106",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jhana_of_god_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2710079",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20yisunshin",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20yisunshin",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Yi Sun Shin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/yi_sun_shin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2710079",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/yi_sun_shin.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2710769",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6042",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6042",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Catch the Wind"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/catch_the_wind.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2710769",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/catch_the_wind.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2712294",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6052",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6052",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "October Pub"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/october_pub.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "750.00",
        "MaxMultiplier": "100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2712294",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/october_pub.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2713066",
        "System": "944",
        "SubSystem": null,
        "PageCode": "680",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "680",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Return of the Green Knight"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/return_of_the_green_knight.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "40000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2713066",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/return_of_the_green_knight.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2713190",
        "System": "944",
        "SubSystem": null,
        "PageCode": "816",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "816",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ruff Heist"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ruff_heist.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2713190",
        "RTP": "96.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ruff_heist.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2715650",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6041",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6041",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Catch the Wind Bonus Buy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/catch_the_wind_bonus_buy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "5297.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2715650",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/catch_the_wind_bonus_buy.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2715658",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysraghex",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysraghex",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tundra’s Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/tundras_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2715658",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/tundras_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2715670",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5gemstone",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5gemstone",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gemstone"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gemstone.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "375.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2715670",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gemstone.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2716399",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6056",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6056",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Video Poker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/video_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10"
        ],
        "SortPerCategory": {
            "10": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2716399",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/video_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2716405",
        "System": "944",
        "SubSystem": null,
        "PageCode": "729",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "729",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raging Rex 3"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/raging_rex_3.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2716405",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/raging_rex_3.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2721345",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysmoneyman",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysmoneyman",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Money Men Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_money_men_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "360.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2721345",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_money_men_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2721349",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243goldfor",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243goldfor",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "888 Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/888_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.38",
        "MaxBetDefault": "228.00",
        "MaxMultiplier": "1378.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2721349",
        "RTP": "97.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/888_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2722267",
        "System": "944",
        "SubSystem": null,
        "PageCode": "672",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "672",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monkey: Battle for the Scrolls"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/monkey_battle_for_the_scrolls.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2722267",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/monkey_battle_for_the_scrolls.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2724055",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20olympgrace",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20olympgrace",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Grace of Ebisu"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/grace_of_ebisu.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "1801",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2724055",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/grace_of_ebisu.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1801"
    },
    {
        "ID": "2724866",
        "System": "944",
        "SubSystem": null,
        "PageCode": "681",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "681",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sherwood Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sherwood_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "6500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2724866",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sherwood_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2727254",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20rujakbnz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20rujakbnz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rujak Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/rujak_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2727254",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/rujak_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "2727266",
        "System": "940",
        "SubSystem": null,
        "PageCode": "6057",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "6057",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cursed Can"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/cursed_can.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2225.80",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2727266",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/cursed_can.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2728218",
        "System": "944",
        "SubSystem": null,
        "PageCode": "593",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "593",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legacy of Dynasties"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legacy_of_dynasties.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2728218",
        "RTP": "96.2800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legacy_of_dynasties.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2732002",
        "System": "944",
        "SubSystem": null,
        "PageCode": "701",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "701",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Invading Vegas: Las Christmas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/invading_vegas_las_christmas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "100000",
        "GSubSort": "100000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2732002",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/invading_vegas_las_christmas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2154864",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5841",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5841",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Overlords"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wild_overlords.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99999",
        "GSubSort": "99999",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5555.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2154864",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wild_overlords.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2407588",
        "System": "939",
        "SubSystem": null,
        "PageCode": "135",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "135",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Bounty Showdown"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/wild_bounty_showdown.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "99999",
        "GSubSort": "99999",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "135",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2407588",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/wild_bounty_showdown.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2445604",
        "System": "944",
        "SubSystem": null,
        "PageCode": "647",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "647",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Falls 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_falls_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99999",
        "GSubSort": "99999",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2445604",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_falls_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1952849",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5757",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5757",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Neon Shapes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/neon_shapes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "99997",
        "GSubSort": "99997",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30005.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1952849",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/neon_shapes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1903487",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5753",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5753",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mehen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mehen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "62.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1903487",
        "RTP": "95.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mehen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1911590",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5755",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5755",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Runes of Destiny"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/runes_of_destiny.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1328.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1911590",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/runes_of_destiny.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1913842",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5758",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5758",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ellen's Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/ellens_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1020.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1913842",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/ellens_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1922182",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5759",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5759",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Unlimited Wishes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/unlimited_wishes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2035.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1922182",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/unlimited_wishes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1927997",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5762",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5762",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Epic Legends"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/epic_legends.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1727.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1927997",
        "RTP": "96.1600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/epic_legends.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1934021",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5763",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5763",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Sugar"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/sweet_sugar.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "10028.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1934021",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/sweet_sugar.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1958943",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5767",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5767",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Roll to Luck"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/roll_to_luck.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1024.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1958943",
        "RTP": "96.3700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/roll_to_luck.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1990539",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5770",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5770",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "PlingoBall"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/plingo_ball.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "446.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1990539",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/plingo_ball.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2009487",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5774",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5774",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bloody Brilliant"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/bloody_brilliant.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2009487",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/bloody_brilliant.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2075298",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5791",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5791",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Disco"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruit_disco.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3373.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2075298",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruit_disco.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2081409",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5801",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5801",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Triple Chili"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/triple_chili.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99996",
        "GSubSort": "99996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5510.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2081409",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/triple_chili.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2080068",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5799",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5799",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Curse of the Pharaoh"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/curse_of_the_pharaoh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99956",
        "GSubSort": "99956",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2312.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2080068",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/curse_of_the_pharaoh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2090787",
        "System": "944",
        "SubSystem": null,
        "PageCode": "545",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100545",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Muerto En Mictlan"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/muerto_en_mictlan.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99951",
        "GSubSort": "99951",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2090787",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/muerto_en_mictlan.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1904006",
        "System": "944",
        "SubSystem": null,
        "PageCode": "463",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100463",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Thunder Screech"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/thunder_screech.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99934",
        "GSubSort": "99934",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1904006",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/thunder_screech.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1908776",
        "System": "944",
        "SubSystem": null,
        "PageCode": "465",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100465",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Paying Piano Club"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_paying_piano_club.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "99929",
        "GSubSort": "99929",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1908776",
        "RTP": "96.2600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_paying_piano_club.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1899083",
        "System": "944",
        "SubSystem": null,
        "PageCode": "460",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100460",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cat Wilde in the Eclipse of the Sun God"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cat_wilde_in_the_eclipse_of_the_sun.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "23075",
        "GSubSort": "23075",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1899083",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cat_wilde_in_the_eclipse_of_the_sun.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529123",
        "System": "944",
        "SubSystem": null,
        "PageCode": "310",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100310",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/book_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "23000",
        "GSubSort": "23000",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529123",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/book_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1547488",
        "System": "944",
        "SubSystem": null,
        "PageCode": "365",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100365",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rich Wilde and the Tome of Madness"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/tomeofmadness.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22999",
        "GSubSort": "22999",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1547488",
        "RTP": "96.5900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/tomeofmadness.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529432",
        "System": "944",
        "SubSystem": null,
        "PageCode": "339",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100339",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legacy of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legacy_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22997",
        "GSubSort": "22997",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529432",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legacy_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529579",
        "System": "944",
        "SubSystem": null,
        "PageCode": "333",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100333",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Reactoonz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/reactoonz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22996",
        "GSubSort": "22996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4570.30",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529579",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/reactoonz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529585",
        "System": "944",
        "SubSystem": null,
        "PageCode": "352",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100352",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Olympus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_olympus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22995",
        "GSubSort": "22995",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529585",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_olympus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529192",
        "System": "944",
        "SubSystem": null,
        "PageCode": "307",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100307",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fire_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22993",
        "GSubSort": "22993",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529192",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fire_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1558084",
        "System": "944",
        "SubSystem": null,
        "PageCode": "376",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100376",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Sword and The Grail"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/the_sword_and_the_grail.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22992",
        "GSubSort": "22992",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1558084",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/the_sword_and_the_grail.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1546792",
        "System": "944",
        "SubSystem": null,
        "PageCode": "377",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100377",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Merlin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_merlin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22991",
        "GSubSort": "22991",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1546792",
        "RTP": "96.5800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_merlin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1808516",
        "System": "944",
        "SubSystem": null,
        "PageCode": "429",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100429",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Reactoonz 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/reactoonz_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22990",
        "GSubSort": "22990",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5083.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1808516",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/reactoonz_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1854451",
        "System": "944",
        "SubSystem": null,
        "PageCode": "453",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100453",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Frozen Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/frozen_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22988",
        "GSubSort": "22988",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1854451",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/frozen_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1544176",
        "System": "944",
        "SubSystem": null,
        "PageCode": "373",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100373",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Perfect Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/perfect_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22987",
        "GSubSort": "22987",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1544176",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/perfect_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1836897",
        "System": "944",
        "SubSystem": null,
        "PageCode": "450",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100450",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "24K Dragon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/24k_dragon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22986",
        "GSubSort": "22986",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "24000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1836897",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/24k_dragon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1785046",
        "System": "944",
        "SubSystem": null,
        "PageCode": "436",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100436",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Ticket 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_ticket_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22984",
        "GSubSort": "22984",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1785046",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_ticket_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529489",
        "System": "944",
        "SubSystem": null,
        "PageCode": "291",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100291",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pimped"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pimped.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22983",
        "GSubSort": "22983",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529489",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pimped.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1653430",
        "System": "944",
        "SubSystem": null,
        "PageCode": "406",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100406",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Testament"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/testament.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22982",
        "GSubSort": "22982",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1653430",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/testament.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1569922",
        "System": "944",
        "SubSystem": null,
        "PageCode": "386",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100386",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Black Mamba"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/black_mamba.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22981",
        "GSubSort": "22981",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1569922",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/black_mamba.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1910906",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGOrbsOfAtlantis",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGOrbsOfAtlantis",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Orbs of Atlantis",
            "id": "Orbs of Atlantis",
            "de": "Orbs of Atlantis",
            "es": "Orbs of Atlantis",
            "fr": "Orbs of Atlantis",
            "it": "Orbs of Atlantis",
            "nl": "Orbs of Atlantis",
            "no": "Orbs of Atlantis",
            "pt": "Orbs of Atlantis",
            "fi": "Orbs of Atlantis",
            "sv": "Orbs of Atlantis",
            "vi": "Orbs of Atlantis",
            "tr": "Orbs of Atlantis",
            "gr": "Orbs of Atlantis",
            "ru": "Orbs of Atlantis",
            "th": "Orbs of Atlantis",
            "ja": "Orbs of Atlantis",
            "zh-hant": "亞特蘭蒂斯",
            "zh-hans": "亚特兰蒂斯",
            "ko": "Orbs of Atlantis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/orbs_of_atlantis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "22932",
        "GSubSort": "22932",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f3026586-7a70-415a-99ad-70a100a622a3",
        "MobileExternalCode": "f3026586-7a70-415a-99ad-70a100a622a3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "31201.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1910906",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/orbs_of_atlantis.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1594816",
        "System": "944",
        "SubSystem": null,
        "PageCode": "416",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100416",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legacy of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/legacy_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22835",
        "GSubSort": "22835",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1594816",
        "RTP": "96.5800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/legacy_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529459",
        "System": "944",
        "SubSystem": null,
        "PageCode": "334",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100334",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Moon Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/moon_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22831",
        "GSubSort": "22831",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529459",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/moon_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1860381",
        "System": "944",
        "SubSystem": null,
        "PageCode": "447",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100447",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Osiris"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_osiris.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22826",
        "GSubSort": "22826",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "12000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1860381",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_osiris.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1848415",
        "System": "944",
        "SubSystem": null,
        "PageCode": "454",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100454",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ice Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ice_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22822",
        "GSubSort": "22822",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1848415",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ice_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1894196",
        "System": "944",
        "SubSystem": null,
        "PageCode": "470",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100470",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lord Merlin and the Lady of the Lake"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/lord_merlin_and_the_lady_of_the_lake.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22478",
        "GSubSort": "22478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1894196",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/lord_merlin_and_the_lady_of_the_lake.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1889279",
        "System": "944",
        "SubSystem": null,
        "PageCode": "459",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100459",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bull in a China Shop"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/bull_in_a_china_shop.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22396",
        "GSubSort": "22396",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1889279",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/bull_in_a_china_shop.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1883910",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5746",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5746",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Temple of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/temple_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22392",
        "GSubSort": "22392",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "9999.99",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1883910",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/temple_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1883662",
        "System": "944",
        "SubSystem": null,
        "PageCode": "461",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100461",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Miner Donkey Trouble"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/miner_donkey_trouble.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22372",
        "GSubSort": "22372",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1883662",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/miner_donkey_trouble.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1876570",
        "System": "944",
        "SubSystem": null,
        "PageCode": "452",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100452",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Coils of Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/coils_of_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22146",
        "GSubSort": "22146",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1876570",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/coils_of_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1868614",
        "System": "944",
        "SubSystem": null,
        "PageCode": "458",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100458",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Piggy Bank Farm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/piggy_bank_farm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22122",
        "GSubSort": "22122",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1868614",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/piggy_bank_farm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1863179",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5749",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5749",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure Mania"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/treasure_mania.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22119",
        "GSubSort": "22119",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1219.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1863179",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/treasure_mania.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1860649",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5751",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5751",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Bullets"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wild_bullets.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "22108",
        "GSubSort": "22108",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3246.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1860649",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wild_bullets.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1912284",
        "System": "939",
        "SubSystem": null,
        "PageCode": "jack-frosts",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "jack-frosts",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jack Frost's Winter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/jack_frosts_winter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "22104",
        "GSubSort": "22104",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "97",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "3500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1912284",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/jack_frosts_winter.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1854695",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5750",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5750",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Xmas KenoCat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/xmas_keno_cat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "22089",
        "GSubSort": "22089",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "80.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1854695",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/xmas_keno_cat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1887002",
        "System": "939",
        "SubSystem": null,
        "PageCode": "jewels-prosper",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "jewels-prosper",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jewels of Prosperity"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/jewels_of_prosperity.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "22083",
        "GSubSort": "22083",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "88",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "5952.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1887002",
        "RTP": "96.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/jewels_of_prosperity.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1876342",
        "System": "939",
        "SubSystem": null,
        "PageCode": "queen-bounty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "queen-bounty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen of Bounty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/queen_of_bounty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "22082",
        "GSubSort": "22082",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "84",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7576.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1876342",
        "RTP": "96.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/queen_of_bounty.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1857806",
        "System": "939",
        "SubSystem": null,
        "PageCode": "sct-cleopatra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "sct-cleopatra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Secrets of Cleopatra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/secrets_of_cleopatra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "22081",
        "GSubSort": "22081",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "90",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "8649.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1857806",
        "RTP": "96.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/secrets_of_cleopatra.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1849419",
        "System": "939",
        "SubSystem": null,
        "PageCode": "thai-river",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "thai-river",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Thai River Wonders"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/thai_river_wonders.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "22069",
        "GSubSort": "22069",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "92",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "8716.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1849419",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/thai_river_wonders.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1841832",
        "System": "944",
        "SubSystem": null,
        "PageCode": "457",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100457",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "New Year Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/new_year_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "22007",
        "GSubSort": "22007",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1841832",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/new_year_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1897376",
        "System": "939",
        "SubSystem": null,
        "PageCode": "lucky-neko",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "lucky-neko",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Neko"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/lucky_neko.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "21977",
        "GSubSort": "21977",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "89",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "5527.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1897376",
        "RTP": "96.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/lucky_neko.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1831414",
        "System": "944",
        "SubSystem": null,
        "PageCode": "455",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100455",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Viking Runecraft Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/viking_runecraft_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "21080",
        "GSubSort": "21080",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "22": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "40.00",
        "MaxMultiplier": "2075.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1831414",
        "RTP": "95.8700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/viking_runecraft_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1828772",
        "System": "939",
        "SubSystem": null,
        "PageCode": "treasures-aztec",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "treasures-aztec",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasures of Aztec"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/treasures_of_aztec.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "21066",
        "GSubSort": "21066",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "87",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "9071.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1828772",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/treasures_of_aztec.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1848675",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5742",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5742",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tree Of Light"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/tree_of_light.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "21049",
        "GSubSort": "21049",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1617.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1848675",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/tree_of_light.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1846687",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5748",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5748",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Christmas Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/christmas_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "21048",
        "GSubSort": "21048",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "12.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1846687",
        "RTP": "97.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/christmas_party.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1841838",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5743",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5743",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pachin Girl"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/panchin_girl.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "21047",
        "GSubSort": "21047",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "299.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1841838",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/panchin_girl.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1821186",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5738",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5738",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Valley of Dreams"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/valley_of_dreams.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "21045",
        "GSubSort": "21045",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1821186",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/valley_of_dreams.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1825665",
        "System": "944",
        "SubSystem": null,
        "PageCode": "464",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100464",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Holiday Spirits"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/holiday_spirits.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "21044",
        "GSubSort": "21044",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1825665",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/holiday_spirits.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1818918",
        "System": "939",
        "SubSystem": null,
        "PageCode": "vampires-charm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vampires-charm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Vampire's Charm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/vampires_charm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "21033",
        "GSubSort": "21033",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "58",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1696.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1818918",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/vampires_charm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1820874",
        "System": "944",
        "SubSystem": null,
        "PageCode": "449",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100449",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Disco Diamonds"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/disco_diamonds.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "21026",
        "GSubSort": "21026",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1820874",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/disco_diamonds.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1817050",
        "System": "944",
        "SubSystem": null,
        "PageCode": "435",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100435",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Madame Ink"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/madame_ink.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20987",
        "GSubSort": "20987",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1817050",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/madame_ink.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1831418",
        "System": "944",
        "SubSystem": null,
        "PageCode": "451",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100451",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Athena"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_athena.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20913",
        "GSubSort": "20913",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1831418",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_athena.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1842255",
        "System": "939",
        "SubSystem": null,
        "PageCode": "circus-delight",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "circus-delight",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Circus Delight"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/circus_delight.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20887",
        "GSubSort": "20887",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "80",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5467.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1842255",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/circus_delight.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1921394",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024temuj",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024temuj",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Temujin Treasures"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/temujin_treasures.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20804",
        "GSubSort": "20804",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.38",
        "MaxBetDefault": "190.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1921394",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/temujin_treasures.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1917922",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10goldfish",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10goldfish",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fishin Reels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fishin_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20803",
        "GSubSort": "20803",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1917922",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fishin_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1914860",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20hburnhs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20hburnhs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot to Burn Hold and Spin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_to_burn_hold_and_spin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20802",
        "GSubSort": "20802",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1914860",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_to_burn_hold_and_spin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1911714",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20olympgate",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20olympgate",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gates of Olympus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gates_of_olympus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20801",
        "GSubSort": "20801",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1911714",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gates_of_olympus.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1571629",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20sbxmas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20sbxmas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Bonanza Xmas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/sweet_bonanza_xmas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20799",
        "GSubSort": "20799",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1571629",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/sweet_bonanza_xmas.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1765693",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysdogs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysdogs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Dog House Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_dog_house_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20798",
        "GSubSort": "20798",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "12305.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1765693",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_dog_house_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1857272",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bbbonanza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bbbonanza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Bass Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/big_bass_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20797",
        "GSubSort": "20797",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "2100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1857272",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/big_bass_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1663852",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40wildwest",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40wildwest",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild West Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_west_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20796",
        "GSubSort": "20796",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1663852",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_west_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1560160",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25scarabqueen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25scarabqueen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Tomb of the Scarab Queen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_scarab_queen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20794",
        "GSubSort": "20794",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "10500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1560160",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_scarab_queen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1703740",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20fruitparty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20fruitparty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fruit_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20793",
        "GSubSort": "20793",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "180.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1703740",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fruit_party.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1619935",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20kraken",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20kraken",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Release the Kraken"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/release_the_kraken.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20792",
        "GSubSort": "20792",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1619935",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/release_the_kraken.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1820700",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20goldfever",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20goldfever",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gems Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/gems_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20790",
        "GSubSort": "20790",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1820700",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/gems_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1841091",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20xmascarol",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20xmascarol",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Christmas Carol Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/christmas_carol_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20787",
        "GSubSort": "20787",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1841091",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/christmas_carol_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1836528",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10cowgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10cowgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cowboys Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cowboys_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20786",
        "GSubSort": "20786",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6065.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1836528",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cowboys_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1876118",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40voodoo",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40voodoo",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Voodoo Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/voodoo_magic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20785",
        "GSubSort": "20785",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1876118",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/voodoo_magic.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1883006",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10wildtut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10wildtut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mysterious Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mysterious_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20783",
        "GSubSort": "20783",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5533.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1883006",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mysterious_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1888775",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs432congocash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs432congocash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Congo Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/congo_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20781",
        "GSubSort": "20781",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1888775",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/congo_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1695894",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10bookoftut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10bookoftut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Book of Tut"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_book_of_tut.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20775",
        "GSubSort": "20775",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1695894",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_book_of_tut.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1686286",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysrhino",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysrhino",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Great Rhino Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/great_rhino_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20774",
        "GSubSort": "20774",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1686286",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/great_rhino_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1535302",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20fruitsw",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20fruitsw",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20fruitsw.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20736",
        "GSubSort": "20736",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "21175.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1535302",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20fruitsw.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1517176",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25mustang",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25mustang",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mustang Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mustang_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20731",
        "GSubSort": "20731",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "12000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1517176",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mustang_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1482786",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25wolfgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25wolfgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wolf Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25wolfgold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20727",
        "GSubSort": "20727",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1482786",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25wolfgold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1594042",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs4096bufking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs4096bufking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Buffalo King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/buffalo_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20720",
        "GSubSort": "20720",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "60.00",
        "MaxMultiplier": "93750.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1594042",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/buffalo_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1815520",
        "System": "939",
        "SubSystem": null,
        "PageCode": "genies-wishes",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "genies-wishes",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Genie's 3 Wishes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/genies_3_wishes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20408",
        "GSubSort": "20408",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "85",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3213.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1815520",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/genies_3_wishes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1810760",
        "System": "944",
        "SubSystem": null,
        "PageCode": "445",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100445",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rabbit Hole Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rabbit_hole_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20370",
        "GSubSort": "20370",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1810760",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rabbit_hole_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1813824",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5739",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5739",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book Of Keno"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/book_of_keno.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "20296",
        "GSubSort": "20296",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "77.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1813824",
        "RTP": "97.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/book_of_keno.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1803655",
        "System": "944",
        "SubSystem": null,
        "PageCode": "415",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100415",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Feline Fury"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/feline_fury.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20294",
        "GSubSort": "20294",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1803655",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/feline_fury.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1802386",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5737",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5737",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Night of the Living Tales"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/night_of_the_living_tales.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20293",
        "GSubSort": "20293",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3078.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1802386",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/night_of_the_living_tales.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1813816",
        "System": "944",
        "SubSystem": null,
        "PageCode": "423",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100423",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Helloween"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/helloween.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20264",
        "GSubSort": "20264",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1813816",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/helloween.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1798573",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5735",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5735",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jolly Treasures"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jolly_treasures.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20256",
        "GSubSort": "20256",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3078.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1798573",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jolly_treasures.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1790793",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5727",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5727",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Forgotten Fable"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/forgotten_fable.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20255",
        "GSubSort": "20255",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1195.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1790793",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/forgotten_fable.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1853731",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10mayangods",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10mayangods",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter And The Mayan Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_mayan_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20247",
        "GSubSort": "20247",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1853731",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_mayan_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1847951",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40spartaking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40spartaking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Spartan King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/spartan_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20246",
        "GSubSort": "20246",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7494.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1847951",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/spartan_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1790077",
        "System": "944",
        "SubSystem": null,
        "PageCode": "441",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100441",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Octopus Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/octopus_treasure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20242",
        "GSubSort": "20242",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1790077",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/octopus_treasure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1834266",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25bkofkngdm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25bkofkngdm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book Of Kingdoms"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/book_of_kingdoms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20238",
        "GSubSort": "20238",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1834266",
        "RTP": "96.6900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/book_of_kingdoms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1830404",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40pirgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40pirgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pirate Gold Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pirate_gold_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20237",
        "GSubSort": "20237",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1830404",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pirate_gold_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1825131",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024dtiger",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024dtiger",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Dragon Tiger"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/dragon_tiger.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20236",
        "GSubSort": "20236",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "18125.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1825131",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/dragon_tiger.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1809308",
        "System": "939",
        "SubSystem": null,
        "PageCode": "wild-fireworks",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "wild-fireworks",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Fireworks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/wild_fireworks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20229",
        "GSubSort": "20229",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "83",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "1356.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1809308",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/wild_fireworks.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1788861",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5736",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5736",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wheel Of Time"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/wheel_of_time.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "20209",
        "GSubSort": "20209",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "77.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1788861",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/wheel_of_time.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1783305",
        "System": "940",
        "SubSystem": "0",
        "PageCode": "5717",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5717",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raccoon Tales"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/raccoon_tales.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20208",
        "GSubSort": "20208",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2123.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1783305",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/raccoon_tales.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1908281",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25jokerking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25jokerking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/joker_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20206",
        "GSubSort": "20206",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1908281",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/joker_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1903490",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20midas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20midas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Hand of Midas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_hand_of_midas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20205",
        "GSubSort": "20205",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1903490",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_hand_of_midas.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1901324",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10eyestorm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10eyestorm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Eye of the Storm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/eye_of_the_storm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20187",
        "GSubSort": "20187",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1901324",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/eye_of_the_storm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1840182",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTabernaDeLosMuertosUltra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTabernaDeLosMuertosUltra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Taberna De Los Muertos Ultra",
            "id": "Taberna De Los Muertos Ultra",
            "de": "Taberna De Los Muertos Ultra",
            "es": "Taberna De Los Muertos Ultra",
            "fr": "Taberna De Los Muertos Ultra",
            "it": "Taberna De Los Muertos Ultra",
            "nl": "Taberna De Los Muertos Ultra",
            "no": "Taberna De Los Muertos Ultra",
            "pt": "Taberna De Los Muertos Ultra",
            "fi": "Taberna De Los Muertos Ultra",
            "sv": "Taberna De Los Muertos Ultra",
            "vi": "Taberna De Los Muertos Ultra",
            "tr": "Taberna De Los Muertos Ultra",
            "gr": "Taberna De Los Muertos Ultra",
            "ru": "Taberna De Los Muertos Ultra",
            "th": "Taberna De Los Muertos Ultra",
            "ja": "Taberna De Los Muertos Ultra",
            "zh-hant": "幽靈酒館- 極端",
            "zh-hans": "幽灵酒馆- 极端",
            "ko": "Taberna De Los Muertos Ultra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/taberna_de_los_muertos_ultra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20185",
        "GSubSort": "20185",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "3cdbfc47-c4bc-4a8e-ac5c-0b2ff030c1e0",
        "MobileExternalCode": "3cdbfc47-c4bc-4a8e-ac5c-0b2ff030c1e0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1840182",
        "RTP": "96.8100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/taberna_de_los_muertos_ultra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1778501",
        "System": "944",
        "SubSystem": null,
        "PageCode": "442",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100442",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jolly Roger 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jolly_roger_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20169",
        "GSubSort": "20169",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1778501",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jolly_roger_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1898741",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5drmystery",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5drmystery",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Kingdom - Eyes of Fire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/dragon_kingdom_eyes_of_fire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20167",
        "GSubSort": "20167",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "1250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1898741",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/dragon_kingdom_eyes_of_fire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1772887",
        "System": "940",
        "SubSystem": "0",
        "PageCode": "5721",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5721",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Forest Dreams"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/forest_dreams.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20141",
        "GSubSort": "20141",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "749.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1772887",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/forest_dreams.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1893650",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswaysmadame",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswaysmadame",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Madame Destiny Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/madame_destiny_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20137",
        "GSubSort": "20137",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1893650",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/madame_destiny_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1886778",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20ekingrr",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20ekingrr",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Emerald King Rainbow Road"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/emerald_king_rainbow_road.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20136",
        "GSubSort": "20136",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1886778",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/emerald_king_rainbow_road.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1783337",
        "System": "939",
        "SubSystem": null,
        "PageCode": "double-fortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "double-fortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/double_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20132",
        "GSubSort": "20132",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "48",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "527.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1783337",
        "RTP": "96.2200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/double_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1799525",
        "System": "939",
        "SubSystem": null,
        "PageCode": "dreams-of-macau",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "dreams-of-macau",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dreams of Macau"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/dream_of_macau.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20125",
        "GSubSort": "20125",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "79",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "6160.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1799525",
        "RTP": "96.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/dream_of_macau.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1816806",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10returndead",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10returndead",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Return of the Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/return_of_the_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20117",
        "GSubSort": "20117",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1816806",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/return_of_the_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1813460",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024lionsd",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024lionsd",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Lions Dance"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/5_lions_dance.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20109",
        "GSubSort": "20109",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1813460",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/5_lions_dance.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1810638",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20eking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20eking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Emerald King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/emerald_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20108",
        "GSubSort": "20108",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "20000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1810638",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/emerald_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1808012",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25walker",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25walker",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Walker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_walker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20106",
        "GSubSort": "20106",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4980.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1808012",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_walker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1797845",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayshive",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayshive",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Star Bounty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/star_bounty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20104",
        "GSubSort": "20104",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25920.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1797845",
        "RTP": "96.6100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/star_bounty.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1779525",
        "System": "939",
        "SubSystem": null,
        "PageCode": "mahjong-ways2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "mahjong-ways2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong Ways II"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/mahjong_ways_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "20051",
        "GSubSort": "20051",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "74",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "100000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1779525",
        "RTP": "96.9500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/mahjong_ways_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1896701",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTotemTowers",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTotemTowers",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Totem Towers",
            "id": "Totem Towers",
            "de": "Totem Towers",
            "es": "Totem Towers",
            "fr": "Totem Towers",
            "it": "Totem Towers",
            "nl": "Totem Towers",
            "no": "Totem Towers",
            "pt": "Totem Towers",
            "fi": "Totem Towers",
            "sv": "Totem Towers",
            "vi": "Totem Towers",
            "tr": "Totem Towers",
            "gr": "Totem Towers",
            "ru": "Totem Towers",
            "th": "Totem Towers",
            "ja": "Totem Towers",
            "zh-hant": "瑪雅之塔",
            "zh-hans": "玛雅之塔",
            "ko": "Totem Towers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/totem_towers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20023",
        "GSubSort": "20023",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e11c09bb-fb2d-4a2b-939f-051bda50ae4e",
        "MobileExternalCode": "e11c09bb-fb2d-4a2b-939f-051bda50ae4e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "61300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1896701",
        "RTP": "96.6300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/totem_towers.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1772644",
        "System": "944",
        "SubSystem": null,
        "PageCode": "428",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100428",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamond Vortex"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/diamond_vortex.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20017",
        "GSubSort": "20017",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1772644",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/diamond_vortex.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1840005",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGChristmasGiftRush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGChristmasGiftRush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Christmas Gift Rush",
            "id": "Christmas Gift Rush",
            "de": "Christmas Gift Rush",
            "es": "Christmas Gift Rush",
            "fr": "Christmas Gift Rush",
            "it": "Christmas Gift Rush",
            "nl": "Christmas Gift Rush",
            "no": "Christmas Gift Rush",
            "pt": "Christmas Gift Rush",
            "fi": "Christmas Gift Rush",
            "sv": "Christmas Gift Rush",
            "vi": "Christmas Gift Rush",
            "tr": "Christmas Gift Rush",
            "gr": "Christmas Gift Rush",
            "ru": "Christmas Gift Rush",
            "th": "Christmas Gift Rush",
            "ja": "Christmas Gift Rush",
            "zh-hant": "Christmas Gift Rush",
            "zh-hans": "Christmas Gift Rush",
            "ko": "Christmas Gift Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/christmas_gift_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "20006",
        "GSubSort": "20006",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e630fb7a-cf1b-4c6f-add0-671a4470a91c",
        "MobileExternalCode": "e630fb7a-cf1b-4c6f-add0-671a4470a91c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3008.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1840005",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/christmas_gift_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1789125",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5ultra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5ultra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ultra Hold and Spin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/ultra_hold_and_spin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19990",
        "GSubSort": "19990",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "2460.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1789125",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/ultra_hold_and_spin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1766761",
        "System": "944",
        "SubSystem": null,
        "PageCode": "422",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100422",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Saxon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/saxon.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19985",
        "GSubSort": "19985",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1766761",
        "RTP": "96.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/saxon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1766757",
        "System": "939",
        "SubSystem": null,
        "PageCode": "egypts-book-mystery",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "egypts-book-mystery",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Egypt's Book of Mystery"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/egypts_book_of_mystery.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "19984",
        "GSubSort": "19984",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "73",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "9546.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1766757",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/egypts_book_of_mystery.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1819594",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTabernaDeLosMuertos",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTabernaDeLosMuertos",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Taberna De Los Muertos",
            "id": "Taberna De Los Muertos",
            "de": "Taberna De Los Muertos",
            "es": "Taberna De Los Muertos",
            "fr": "Taberna De Los Muertos",
            "it": "Taberna De Los Muertos",
            "nl": "Taberna De Los Muertos",
            "no": "Taberna De Los Muertos",
            "pt": "Taberna De Los Muertos",
            "fi": "Taberna De Los Muertos",
            "sv": "Taberna De Los Muertos",
            "vi": "Taberna De Los Muertos",
            "tr": "Taberna De Los Muertos",
            "gr": "Taberna De Los Muertos",
            "ru": "Taberna De Los Muertos",
            "th": "Taberna De Los Muertos",
            "ja": "Taberna De Los Muertos",
            "zh-hant": "幽靈酒館",
            "zh-hans": "幽灵酒馆",
            "ko": "Taberna De Los Muertos"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/taberna_de_los_muertos.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19971",
        "GSubSort": "19971",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "66a59947-7116-4710-9661-8cfde0b6151a",
        "MobileExternalCode": "66a59947-7116-4710-9661-8cfde0b6151a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "78740.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1819594",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/taberna_de_los_muertos.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1803115",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs576treasures",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs576treasures",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Wild Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/wild_wild_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19944",
        "GSubSort": "19944",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4608.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1803115",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/wild_wild_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1807079",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHappyApe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHappyApe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Happy Ape",
            "id": "Happy Ape",
            "de": "Happy Ape",
            "es": "Happy Ape",
            "fr": "Happy Ape",
            "it": "Happy Ape",
            "nl": "Happy Ape",
            "no": "Happy Ape",
            "pt": "Happy Ape",
            "fi": "Happy Ape",
            "sv": "Happy Ape",
            "vi": "Happy Ape",
            "tr": "Happy Ape",
            "gr": "Happy Ape",
            "ru": "Happy Ape",
            "th": "Happy Ape",
            "ja": "Happy Ape",
            "zh-hant": "快樂猿猴",
            "zh-hans": "快乐猿猴",
            "ko": "Happy Ape"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/happy_ape.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19884",
        "GSubSort": "19884",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "65723957-5ba1-4e59-9b28-fcf9a68b7fd8",
        "MobileExternalCode": "65723957-5ba1-4e59-9b28-fcf9a68b7fd8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "86670.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1807079",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/happy_ape.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1776712",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGJellyfishFlow",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGJellyfishFlow",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jellyfish Flow"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jellyfish_flow.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "19882",
        "GSubSort": "19882",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "7065a72b-f057-435b-b225-0940e1b17f37",
        "MobileExternalCode": "7065a72b-f057-435b-b225-0940e1b17f37",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "486150.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1776712",
        "RTP": "96.7600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jellyfish_flow.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1759381",
        "System": "944",
        "SubSystem": null,
        "PageCode": "446",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100446",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blinged"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/blinged.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11487",
        "GSubSort": "11487",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1759381",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/blinged.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1756649",
        "System": "939",
        "SubSystem": null,
        "PageCode": "ganesha-fortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "ganesha-fortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ganesha Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/ganesha_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "11470",
        "GSubSort": "11470",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "75",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "7524.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1756649",
        "RTP": "96.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/ganesha_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1753084",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5695",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5695",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jelly Boom"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jelly_boom.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11459",
        "GSubSort": "11459",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1753084",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jelly_boom.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1776550",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGJellyfishFlowUltra",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGJellyfishFlowUltra",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jellyfish Flow Ultra",
            "id": "Jellyfish Flow Ultra",
            "de": "Jellyfish Flow Ultra",
            "es": "Jellyfish Flow Ultra",
            "fr": "Jellyfish Flow Ultra",
            "it": "Jellyfish Flow Ultra",
            "nl": "Jellyfish Flow Ultra",
            "no": "Jellyfish Flow Ultra",
            "pt": "Jellyfish Flow Ultra",
            "fi": "Jellyfish Flow Ultra",
            "sv": "Jellyfish Flow Ultra",
            "vi": "Jellyfish Flow Ultra",
            "tr": "Jellyfish Flow Ultra",
            "gr": "Jellyfish Flow Ultra",
            "ru": "Jellyfish Flow Ultra",
            "th": "Jellyfish Flow Ultra",
            "ja": "Jellyfish Flow Ultra",
            "zh-hant": "繽紛水母 - 極端",
            "zh-hans": "缤纷水母 - 极端",
            "ko": "Jellyfish Flow Ultra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jellyfish_flow_ultra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11453",
        "GSubSort": "11453",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "652019d7-c069-4102-a76c-afab67bdd557",
        "MobileExternalCode": "652019d7-c069-4102-a76c-afab67bdd557",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1776550",
        "RTP": "96.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jellyfish_flow_ultra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1752880",
        "System": "944",
        "SubSystem": null,
        "PageCode": "430",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100430",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Riddle Reels: A Case of Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/riddle_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11440",
        "GSubSort": "11440",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1752880",
        "RTP": "96.2200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/riddle_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1742104",
        "System": "944",
        "SubSystem": null,
        "PageCode": "424",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100424",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Volcano"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gold_volcano.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11416",
        "GSubSort": "11416",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1742104",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gold_volcano.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1772263",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20gorilla",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20gorilla",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jungle Gorilla"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/jungle_gorilla.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11412",
        "GSubSort": "11412",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2806.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1772263",
        "RTP": "96.5700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/jungle_gorilla.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1784887",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vswayswerewolf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vswayswerewolf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Curse of the Werewolf Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/curse_of_the_werewolf_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11411",
        "GSubSort": "11411",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "40976.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1784887",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/curse_of_the_werewolf_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1736785",
        "System": "939",
        "SubSystem": null,
        "PageCode": "bikini-paradise",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "bikini-paradise",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bikini Paradise"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/bikini_paradise.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "11401",
        "GSubSort": "11401",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "69",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5482.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1736785",
        "RTP": "96.9500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/bikini_paradise.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1747363",
        "System": "944",
        "SubSystem": null,
        "PageCode": "426",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100426",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Win-A-Beest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/win_a_best.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11348",
        "GSubSort": "11348",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10240.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1747363",
        "RTP": "96.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/win_a_best.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1757797",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9aztecgemsdx",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9aztecgemsdx",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Gems Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/aztec_gems_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11344",
        "GSubSort": "11344",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "22519.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1757797",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/aztec_gems_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1752437",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20rhinoluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20rhinoluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Great Rhino Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/great_rhino_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11343",
        "GSubSort": "11343",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6242.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1752437",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/great_rhino_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1746796",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40streetracer",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40streetracer",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Street Racer"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/street_racer.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11342",
        "GSubSort": "11342",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1746796",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/street_racer.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1736797",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5677",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5677",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Surf Zone"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/surf_zone.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11303",
        "GSubSort": "11303",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "598.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1736797",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/surf_zone.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1736059",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5679",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5679",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Texas Holdem Bonus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/texas_holdem_bonus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "11302",
        "GSubSort": "11302",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "96.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1736059",
        "RTP": "98.3700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/texas_holdem_bonus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1731601",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5673",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5673",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sea of Spins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/sea_of_spins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11301",
        "GSubSort": "11301",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1861.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1731601",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/sea_of_spins.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1726285",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5659",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5659",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Exploding Fruits"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/exploding_fruits.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11272",
        "GSubSort": "11272",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "728.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1726285",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/exploding_fruits.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1731400",
        "System": "939",
        "SubSystem": null,
        "PageCode": "cai-shen-wins",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "cai-shen-wins",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Caishen Wins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/caishen_wins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "11221",
        "GSubSort": "11221",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "71",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4936.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1731400",
        "RTP": "96.9200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/caishen_wins.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1751185",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWealthInn",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWealthInn",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wealth Inn"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/wealth_inn.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11202",
        "GSubSort": "11202",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "41045fc0-e89e-4010-960b-1bed24bd0b62",
        "MobileExternalCode": "41045fc0-e89e-4010-960b-1bed24bd0b62",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.08",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "1504.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1751185",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/wealth_inn.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1721011",
        "System": "944",
        "SubSystem": null,
        "PageCode": "396",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100396",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Troll Hunters 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/troll_hunters_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11169",
        "GSubSort": "11169",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1721011",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/troll_hunters_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1715521",
        "System": "944",
        "SubSystem": null,
        "PageCode": "433",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100433",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rainforest Magic Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rainforest_magic_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "11156",
        "GSubSort": "11156",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "4250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1715521",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rainforest_magic_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1713025",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGScopa",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGScopa",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scopa"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/scopa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11090",
        "GSubSort": "11090",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "5e0c9b06-98f2-46d0-bb8d-cd0013b1c225",
        "MobileExternalCode": "5e0c9b06-98f2-46d0-bb8d-cd0013b1c225",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "45000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1713025",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/scopa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1715518",
        "System": "944",
        "SubSystem": null,
        "PageCode": "420",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100420",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rally 4 Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rally_4_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11080",
        "GSubSort": "11080",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1715518",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rally_4_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1715551",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5641",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5641",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fluffy Rangers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fluffy_rangers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11074",
        "GSubSort": "11074",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "855.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1715551",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fluffy_rangers.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1709956",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5653",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5653",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Midnight Show"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/midnight_show.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11073",
        "GSubSort": "11073",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "589.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1709956",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/midnight_show.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1742116",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5687",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5687",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Football Manager"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/football_manager.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "11067",
        "GSubSort": "11067",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "3604"
        ],
        "SortPerCategory": {
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "910.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1742116",
        "RTP": "98.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/football_manager.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1704502",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTechnoTumble",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTechnoTumble",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Techno Tumble"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/techno_tumble.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11057",
        "GSubSort": "11057",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "02f237d7-81ca-493d-875e-ce592b7c8eb3",
        "MobileExternalCode": "02f237d7-81ca-493d-875e-ce592b7c8eb3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "39840.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1704502",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/techno_tumble.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1709962",
        "System": "944",
        "SubSystem": null,
        "PageCode": "421",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100421",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Twisted Sister"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/twisted_sister.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11056",
        "GSubSort": "11056",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1709962",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/twisted_sister.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1730074",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHeySushi",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHeySushi",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hey Sushi"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/hey_sushi.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11056",
        "GSubSort": "11056",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "55bd21d8-c50e-44f6-93a2-87118e86d688",
        "MobileExternalCode": "55bd21d8-c50e-44f6-93a2-87118e86d688",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "30425.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1730074",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/hey_sushi.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1726282",
        "System": "944",
        "SubSystem": null,
        "PageCode": "427",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100427",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cat Wilde and the Doom of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cat_wilde_doom_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "11036",
        "GSubSort": "11036",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1726282",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cat_wilde_doom_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1704310",
        "System": "944",
        "SubSystem": null,
        "PageCode": "413",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100413",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rich Wilde and The Shield of Athena"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rich-wilde_and_the_shield_of_athena.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10957",
        "GSubSort": "10957",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1704310",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rich-wilde_and_the_shield_of_athena.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1700038",
        "System": "944",
        "SubSystem": null,
        "PageCode": "425",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100425",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Charlie Chance in Hell to Pay"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/charlie_chance_in_hell_to_pay.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10936",
        "GSubSort": "10936",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6666.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1700038",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/charlie_chance_in_hell_to_pay.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1715524",
        "System": "944",
        "SubSystem": null,
        "PageCode": "434",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100434",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Alchemy Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sweet_alchemy_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10935",
        "GSubSort": "10935",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "8.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1715524",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sweet_alchemy_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1721779",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5669",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5669",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "French Roulette Classic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/french_roulette_classic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10894",
        "GSubSort": "10894",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1366"
        ],
        "SortPerCategory": {
            "7": 0,
            "1366": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "36.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1721779",
        "RTP": "98.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/french_roulette_classic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1696464",
        "System": "939",
        "SubSystem": null,
        "PageCode": "candy-burst",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "candy-burst",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Burst"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/candy_burst.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10868",
        "GSubSort": "10868",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "70",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "6.25",
        "MaxMultiplier": "1016.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1696464",
        "RTP": "96.9500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/candy_burst.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1692622",
        "System": "944",
        "SubSystem": null,
        "PageCode": "405",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100405",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Agent Destiny"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/agent_destiny.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10847",
        "GSubSort": "10847",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1692622",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/agent_destiny.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1702735",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5643",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5643",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Penalty shoot-out"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/penalty_shoot_out.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10809",
        "GSubSort": "10809",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "22": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "31.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1702735",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/penalty_shoot_out.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1686067",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5593",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5593",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rocket Stars"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rocket_stars.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10732",
        "GSubSort": "10732",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2092.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1686067",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rocket_stars.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1685467",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGLuckyFortuneCat",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGLuckyFortuneCat",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Fortune Cat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/lucky_fortune_cat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10731",
        "GSubSort": "10731",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "3810"
        ],
        "SortPerCategory": {
            "16": 0,
            "3810": 0
        },
        "ExternalCode": "c83ee67c-6766-4726-9ee1-ce56ae295781",
        "MobileExternalCode": "a53a108c-2830-499d-a74f-59328e204729",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.28",
        "MaxBetDefault": "140.00",
        "MaxMultiplier": "950.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1685467",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/lucky_fortune_cat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1683265",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5547",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5547",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Animal Quest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/animal_quest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10712",
        "GSubSort": "10712",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1683265",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/animal_quest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1679461",
        "System": "944",
        "SubSystem": null,
        "PageCode": "411",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100411",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ring of Odin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ring_of_odin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10683",
        "GSubSort": "10683",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1679461",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ring_of_odin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1725751",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25pyramid",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25pyramid",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pyramid King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/pyramid_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10630",
        "GSubSort": "10630",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1725751",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/pyramid_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1736218",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1600drago",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1600drago",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Drago - Jewels of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/drago_jewels_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10629",
        "GSubSort": "10629",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "48000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1736218",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/drago_jewels_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 1,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1720465",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10threestar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10threestar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Three Star Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/three_star_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10627",
        "GSubSort": "10627",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1720465",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/three_star_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1714963",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs117649starz",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs117649starz",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Starz Megaways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/starz_megaways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10626",
        "GSubSort": "10626",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1714963",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/starz_megaways.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 1,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1675039",
        "System": "944",
        "SubSystem": null,
        "PageCode": "414",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100414",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "That’s Rich"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/thats_rich.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10625",
        "GSubSort": "10625",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1675039",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/thats_rich.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1709386",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5ultrab",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5ultrab",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ultra Burn"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/ultra_burn.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10625",
        "GSubSort": "10625",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1709386",
        "RTP": "96.6200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/ultra_burn.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1672672",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5587",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5587",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Western Reels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/western_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10602",
        "GSubSort": "10602",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3058.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1672672",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/western_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1658905",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5553",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5553",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Texas Hold’em Poker 3D"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/texas_holdem_poker_3d.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10600",
        "GSubSort": "10600",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "1368"
        ],
        "SortPerCategory": {
            "16": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "93.00",
        "MaxMultiplier": "2.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1658905",
        "RTP": "99.3700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/texas_holdem_poker_3d.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1664224",
        "System": "944",
        "SubSystem": null,
        "PageCode": "412",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "412",
        "MobileAndroidPageCode": "100412",
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sticky Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sticky_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10599",
        "GSubSort": "10599",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1650.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1664224",
        "RTP": "96.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sticky_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1658902",
        "System": "944",
        "SubSystem": null,
        "PageCode": "384",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100384",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Riches of Robin"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/riches_of_robin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10585",
        "GSubSort": "10585",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1658902",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/riches_of_robin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1675036",
        "System": "944",
        "SubSystem": null,
        "PageCode": "418",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100418",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Annihilator"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/annihilator.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10541",
        "GSubSort": "10541",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1675036",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/annihilator.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1683262",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5hotburn",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5hotburn",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot to Burn"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_to_burn.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10537",
        "GSubSort": "10537",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1683262",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_to_burn.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1669036",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40frrainbow",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40frrainbow",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Rainbow"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/fruit_rainbow.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10503",
        "GSubSort": "10503",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1669036",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/fruit_rainbow.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1631980",
        "System": "944",
        "SubSystem": null,
        "PageCode": "408",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100408",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun Goes Wild"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/leprechaun_goes_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10457",
        "GSubSort": "10457",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1631980",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/leprechaun_goes_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1652032",
        "System": "939",
        "SubSystem": null,
        "PageCode": "shaolin-soccer",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "shaolin-soccer",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shaolin Soccer"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/shaolin_soccer.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10447",
        "GSubSort": "10447",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "67",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "180.00",
        "MaxMultiplier": "1662.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1652032",
        "RTP": "96.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/shaolin_soccer.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1643074",
        "System": "944",
        "SubSystem": null,
        "PageCode": "410",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100410",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortunes of Ali Baba"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fortunes_of_ali_baba.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10416",
        "GSubSort": "10416",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1643074",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fortunes_of_ali_baba.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1668061",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGKnockoutFootballRush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGKnockoutFootballRush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Knockout Football Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/knockout_football_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10405",
        "GSubSort": "10405",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "d1675524-3174-4222-8a86-59cef99e1388",
        "MobileExternalCode": "d1675524-3174-4222-8a86-59cef99e1388",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "144000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1668061",
        "RTP": "96.8800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/knockout_football_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1653082",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40madwheel",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40madwheel",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Wild Machine"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_wild_machine.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10399",
        "GSubSort": "10399",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1653082",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_wild_machine.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1637605",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243dancingpar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243dancingpar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dance Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/dance_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10398",
        "GSubSort": "10398",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "36450.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1637605",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/dance_party.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1647835",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs7776aztec",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs7776aztec",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/aztec_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10396",
        "GSubSort": "10396",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "19440.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1647835",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/aztec_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1637620",
        "System": "944",
        "SubSystem": null,
        "PageCode": "407",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100407",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dawn of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/dawn_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10393",
        "GSubSort": "10393",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1637620",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/dawn_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1662715",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5573",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5573",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mine Field"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mine_field.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10393",
        "GSubSort": "10393",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "4036",
            "4508"
        ],
        "SortPerCategory": {
            "22": 0,
            "4036": 0,
            "4508": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "14.79",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1662715",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mine_field.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1669564",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5585",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5585",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crown and Anchor"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/crown_n_anchor.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10387",
        "GSubSort": "10387",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "3810"
        ],
        "SortPerCategory": {
            "22": 0,
            "3810": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1669564",
        "RTP": "92.1300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/crown_n_anchor.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1625503",
        "System": "939",
        "SubSystem": null,
        "PageCode": "reel-love",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "reel-love",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Reel Love"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/reel_love.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10299",
        "GSubSort": "10299",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "20",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3446.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1625503",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/reel_love.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1642105",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGLoonyBlox",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGLoonyBlox",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Loony Blox"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/loony_blox.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10284",
        "GSubSort": "10284",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "3f3156ed-162e-4ae4-bbfd-5be6c041ecee",
        "MobileExternalCode": "3f3156ed-162e-4ae4-bbfd-5be6c041ecee",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "121500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1642105",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/loony_blox.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1625896",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5super7",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5super7",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super 7s"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/super_7s.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10229",
        "GSubSort": "10229",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1625896",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/super_7s.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1631779",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1masterjoker",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1masterjoker",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Master Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/master_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10228",
        "GSubSort": "10228",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1631779",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/master_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1637260",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs4096mystery",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs4096mystery",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mysterious"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/mysterious.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10227",
        "GSubSort": "10227",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9648.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1637260",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/mysterious.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1619932",
        "System": "944",
        "SubSystem": null,
        "PageCode": "395",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100395",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Coywolf Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/coywolf_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10203",
        "GSubSort": "10203",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1619932",
        "RTP": "96.3100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/coywolf_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1612990",
        "System": "939",
        "SubSystem": null,
        "PageCode": "fortune-mouse",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "fortune-mouse",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Mouse"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/fortune_mouse.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10201",
        "GSubSort": "10201",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "68",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1612990",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/fortune_mouse.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1594039",
        "System": "939",
        "SubSystem": null,
        "PageCode": "dragon-hatch",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "dragon-hatch",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Hatch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/dragon_hatch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10164",
        "GSubSort": "10164",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "57",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2027.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1594039",
        "RTP": "96.8300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/dragon_hatch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1609564",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs8magicjourn",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs8magicjourn",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Journey"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/magic_journey.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10160",
        "GSubSort": "10160",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1609564",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/magic_journey.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1615138",
        "System": "944",
        "SubSystem": null,
        "PageCode": "404",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100404",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Blood 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_blood_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10120",
        "GSubSort": "10120",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1615138",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_blood_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1614790",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25mmouse",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25mmouse",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Mouse"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/money_mouse.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10074",
        "GSubSort": "10074",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1614790",
        "RTP": "96.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/money_mouse.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1595800",
        "System": "944",
        "SubSystem": null,
        "PageCode": "393",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100393",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wildhound Derby"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wildhound_derby.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10072",
        "GSubSort": "10072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1595800",
        "RTP": "96.9300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wildhound_derby.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1593268",
        "System": "944",
        "SubSystem": null,
        "PageCode": "340",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100340",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Star Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/star_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10062",
        "GSubSort": "10062",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1593268",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/star_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1653502",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5569",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5569",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise Of Horus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rise_of_horus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10061",
        "GSubSort": "10061",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3838.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1653502",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rise_of_horus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1636063",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5551",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5551",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Irish Reels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/irish_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10060",
        "GSubSort": "10060",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2201.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1636063",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/irish_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1636060",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5549",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5549",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rich Reels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rich_reels.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10059",
        "GSubSort": "10059",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "693.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1636060",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rich_reels.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1619389",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5485",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5485",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legend of Kaan"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/legend_of_kaan.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10057",
        "GSubSort": "10057",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "239.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1619389",
        "RTP": "96.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/legend_of_kaan.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1613014",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5527",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5527",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Season Sisters"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/season_sisters.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10056",
        "GSubSort": "10056",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "106.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1613014",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/season_sisters.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1613008",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5525",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5525",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bomb Squad"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/bomb_squad.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10055",
        "GSubSort": "10055",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1613008",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/bomb_squad.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1600204",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5523",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5523",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Wheel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/magic_wheel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10054",
        "GSubSort": "10054",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "22.05",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1600204",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/magic_wheel.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1592407",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5487",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5487",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hungry Night"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/hungry_night.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10052",
        "GSubSort": "10052",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "360.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1592407",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/hungry_night.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1583107",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5489",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5489",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Courier Sweeper"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/courier_sweeper.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "10051",
        "GSubSort": "10051",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1583107",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/courier_sweeper.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1582897",
        "System": "944",
        "SubSystem": null,
        "PageCode": "360",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100360",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Divine Showdown"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/divine_showdown.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10036",
        "GSubSort": "10036",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1582897",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/divine_showdown.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1588537",
        "System": "944",
        "SubSystem": null,
        "PageCode": "398",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100398",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Frames"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_frames.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "10025",
        "GSubSort": "10025",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1588537",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_frames.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1575661",
        "System": "939",
        "SubSystem": null,
        "PageCode": "mahjong-ways",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "mahjong-ways",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong Ways"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/mahjong_ways.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "10017",
        "GSubSort": "10017",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "65",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "2893.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1575661",
        "RTP": "96.9200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/mahjong_ways.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1476626",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBirdOfThunder",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBirdOfThunder",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bird of Thunder"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bird_of_thunder.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9999",
        "GSubSort": "9999",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "789a77bd-8123-4473-b5eb-2b42f9690e30",
        "MobileExternalCode": "789a77bd-8123-4473-b5eb-2b42f9690e30",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "20890.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476626",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bird_of_thunder.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477807",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCakeValley",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCakeValley",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cake Valley"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/cake_valley.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9998",
        "GSubSort": "9998",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "ce787f60-f879-4010-bb17-8ab0cc060ebf",
        "MobileExternalCode": "ce787f60-f879-4010-bb17-8ab0cc060ebf",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "97200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477807",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/cake_valley.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476623",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPandaPanda",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPandaPanda",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Panda Panda"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/panda_panda.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9997",
        "GSubSort": "9997",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7803284f-3f52-4df2-a3e0-3cb87ac61675",
        "MobileExternalCode": "7803284f-3f52-4df2-a3e0-3cb87ac61675",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "182250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476623",
        "RTP": "96.2600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/panda_panda.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477810",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTheDeadEscape",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTheDeadEscape",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Dead Escape"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/the_dead_escape.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9996",
        "GSubSort": "9996",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7a4d978f-9bd5-41c4-814e-1a60aa4ce8cb",
        "MobileExternalCode": "7a4d978f-9bd5-41c4-814e-1a60aa4ce8cb",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "9645.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477810",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/the_dead_escape.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476629",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTheKoiGate",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTheKoiGate",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Koi Gate"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/the_koi_gate.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9995",
        "GSubSort": "9995",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "40db8f8b-4f56-460c-a450-4732d9d69a90",
        "MobileExternalCode": "40db8f8b-4f56-460c-a450-4732d9d69a90",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "1667.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476629",
        "RTP": "98.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/the_koi_gate.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476632",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFireRooster",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFireRooster",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Rooster"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fire_rooster.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9994",
        "GSubSort": "9994",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "a3d6f959-8494-4183-96d2-cb9ada6b53f4",
        "MobileExternalCode": "a3d6f959-8494-4183-96d2-cb9ada6b53f4",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "110700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476632",
        "RTP": "96.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fire_rooster.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476827",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGoldRush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGoldRush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/gold_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9993",
        "GSubSort": "9993",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "2e8aa477-c290-4be6-a765-0fd3e7a576be",
        "MobileExternalCode": "2e8aa477-c290-4be6-a765-0fd3e7a576be",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "161.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476827",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/gold_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476635",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFenghuang",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFenghuang",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fenghuang"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fenghuang.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9992",
        "GSubSort": "9992",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "48aab998-88a8-4c9e-977e-5d8d28aa243c",
        "MobileExternalCode": "48aab998-88a8-4c9e-977e-5d8d28aa243c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "11200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476635",
        "RTP": "96.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fenghuang.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476647",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSparta",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSparta",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sparta"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/sparta.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9990",
        "GSubSort": "9990",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "05c44fd1-642c-4178-97f5-87efa4554bba",
        "MobileExternalCode": "05c44fd1-642c-4178-97f5-87efa4554bba",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476647",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/sparta.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1494324",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGlamRock",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGlamRock",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Glam Rock",
            "id": "Glam Rock",
            "de": "Glam Rock",
            "es": "Glam Rock",
            "fr": "Glam Rock",
            "it": "Glam Rock",
            "nl": "Glam Rock",
            "no": "Glam Rock",
            "pt": "Glam Rock",
            "fi": "Glam Rock",
            "sv": "Glam Rock",
            "vi": "Glam Rock",
            "tr": "Glam Rock",
            "gr": "Glam Rock",
            "ru": "Glam Rock",
            "th": "Glam Rock",
            "ja": "グラムロック",
            "zh-hant": "華麗搖滾",
            "zh-hans": "华丽摇滚",
            "ko": "글램 락"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/glam_rock.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9989",
        "GSubSort": "9989",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "eeaec33e-0765-4cb3-9911-e5e8e2675539",
        "MobileExternalCode": "eeaec33e-0765-4cb3-9911-e5e8e2675539",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1494324",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/glam_rock.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476803",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SG12Zodiacs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SG12Zodiacs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "12 Zodiacs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/12_zodiacs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9988",
        "GSubSort": "9988",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7781857b-272e-4d8d-83df-81738dafd836",
        "MobileExternalCode": "7781857b-272e-4d8d-83df-81738dafd836",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "2991.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476803",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/12_zodiacs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476638",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGJugglenaut",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGJugglenaut",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jugglenaut"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jugglenaut.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9987",
        "GSubSort": "9987",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "831b6b3e-bf14-4044-9a62-c02095c1504a",
        "MobileExternalCode": "831b6b3e-bf14-4044-9a62-c02095c1504a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "28075.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476638",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jugglenaut.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476821",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFaCaiShen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFaCaiShen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fa Cai Shen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fa_cai_shen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9986",
        "GSubSort": "9986",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "0a306095-40c1-49d1-8698-2f50080b6b29",
        "MobileExternalCode": "0a306095-40c1-49d1-8698-2f50080b6b29",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "62.50",
        "MaxMultiplier": "28293.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476821",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fa_cai_shen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1571251",
        "System": "944",
        "SubSystem": null,
        "PageCode": "389",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100389",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chronos Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/chronos_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9986",
        "GSubSort": "9986",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1571251",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/chronos_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1476815",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRomanEmpire",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRomanEmpire",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Roman Empire"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/roman_empire.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9985",
        "GSubSort": "9985",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "3b0511cd-949e-4500-82c7-99569f8ab048",
        "MobileExternalCode": "3b0511cd-949e-4500-82c7-99569f8ab048",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "24120.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476815",
        "RTP": "96.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/roman_empire.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476644",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGangsters",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGangsters",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gangsters"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/gangsters.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9984",
        "GSubSort": "9984",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f253bb25-c6af-4114-b0aa-f92272670551",
        "MobileExternalCode": "f253bb25-c6af-4114-b0aa-f92272670551",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "80100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476644",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/gangsters.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476812",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCoyoteCrash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCoyoteCrash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Coyote Crash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/coyote_crash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9982",
        "GSubSort": "9982",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "67ce86e8-437c-47ae-9c3c-687a44a7d261",
        "MobileExternalCode": "67ce86e8-437c-47ae-9c3c-687a44a7d261",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "33280.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476812",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/coyote_crash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1583686",
        "System": "944",
        "SubSystem": null,
        "PageCode": "344",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100344",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Colts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_colt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9958",
        "GSubSort": "9958",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1583686",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_colt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1577443",
        "System": "944",
        "SubSystem": null,
        "PageCode": "387",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100387",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ankh of Anubis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ankh_of_anubis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9931",
        "GSubSort": "9931",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "15000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1577443",
        "RTP": "96.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ankh_of_anubis.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1618972",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFaCaiShenDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFaCaiShenDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fa Cai Shen Deluxe",
            "id": "Fa Cai Shen Deluxe",
            "de": "Fa Cai Shen Deluxe",
            "es": "Fa Cai Shen Deluxe",
            "fr": "Fa Cai Shen Deluxe",
            "it": "Fa Cai Shen Deluxe",
            "nl": "Fa Cai Shen Deluxe",
            "no": "Fa Cai Shen Deluxe",
            "pt": "Fa Cai Shen Deluxe",
            "fi": "Fa Cai Shen Deluxe",
            "sv": "Fa Cai Shen Deluxe",
            "vi": "Fa Cai Shen Deluxe",
            "tr": "Fa Cai Shen Deluxe",
            "gr": "Fa Cai Shen Deluxe",
            "ru": "Fa Cai Shen Deluxe",
            "th": "Fa Cai Shen Deluxe",
            "ja": "Fa Cai Shen Deluxe",
            "zh-hant": "雙喜財神",
            "zh-hans": "双喜财神",
            "ko": "Fa Cai Shen Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fa_cai_shen_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9775",
        "GSubSort": "9775",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "67bfc822-66c2-41d1-9bcb-4a397e260b20",
        "MobileExternalCode": "67bfc822-66c2-41d1-9bcb-4a397e260b20",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.28",
        "MaxBetDefault": "140.00",
        "MaxMultiplier": "16168.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1618972",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fa_cai_shen_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1516648",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPumpkinPatch",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPumpkinPatch",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pumpkin Patch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/pumpkin_patch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9710",
        "GSubSort": "9710",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "4",
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "4": 0,
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "ce17ed20-0508-429d-9b89-446d7fa12b40",
        "MobileExternalCode": "ce17ed20-0508-429d-9b89-446d7fa12b40",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "32910.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1516648",
        "RTP": "96.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/pumpkin_patch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1567939",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20honey",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20honey",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Honey Honey Honey"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/honey.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9666",
        "GSubSort": "9666",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "910.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1567939",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/honey.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1555177",
        "System": "944",
        "SubSystem": null,
        "PageCode": "385",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100385",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Rails"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_rails.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9649",
        "GSubSort": "9649",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1555177",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_rails.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1554456",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs7776secrets",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs7776secrets",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "John Hunter and the Aztec Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/john_hunter_and_the_aztec_treasure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "9644",
        "GSubSort": "9644",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10804.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1554456",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/john_hunter_and_the_aztec_treasure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1582924",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5493",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5493",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Book of Rest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/book_of_rest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8696",
        "GSubSort": "8696",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2286.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1582924",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/book_of_rest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1577689",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5491",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5491",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scratch Match"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/scratch_match.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8695",
        "GSubSort": "8695",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "19"
        ],
        "SortPerCategory": {
            "19": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2341.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1577689",
        "RTP": "97.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/scratch_match.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1576078",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5483",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5483",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Maze : Desire for Power"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/maze_desire_for_power.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8694",
        "GSubSort": "8694",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "7950.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1576078",
        "RTP": "95.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/maze_desire_for_power.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1570048",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5479",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5479",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Brutal Santa"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/brutal_santa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8693",
        "GSubSort": "8693",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "448.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570048",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/brutal_santa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1570600",
        "System": "944",
        "SubSystem": null,
        "PageCode": "390",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100390",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Xmas Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/xmas_magic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8569",
        "GSubSort": "8569",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570600",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/xmas_magic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1567822",
        "System": "939",
        "SubSystem": null,
        "PageCode": "muay-thai-champion",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "muay-thai-champion",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Muay Thai Champion"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/muay_thai_champion.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8538",
        "GSubSort": "8538",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "64",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3120.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1567822",
        "RTP": "97.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/muay_thai_champion.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1567792",
        "System": "939",
        "SubSystem": null,
        "PageCode": "gem-saviour-conquest",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "gem-saviour-conquest",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gem Saviour Conquest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/gem_saviour_conquest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8536",
        "GSubSort": "8536",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "62",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "7551.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1567792",
        "RTP": "96.9200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/gem_saviour_conquest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1568920",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243fortseren",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243fortseren",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Greek Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/greek_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8474",
        "GSubSort": "8474",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4320.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1568920",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/greek_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1568887",
        "System": "944",
        "SubSystem": null,
        "PageCode": "417",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100417",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Doom of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/doom_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8463",
        "GSubSort": "8463",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1568887",
        "RTP": "96.2100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/doom_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1566073",
        "System": "939",
        "SubSystem": null,
        "PageCode": "ninja-vs-samurai",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "ninja-vs-samurai",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ninja vs Samurai"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/ninja_vs_samurai.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8461",
        "GSubSort": "8461",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "59",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2610.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1566073",
        "RTP": "97.4400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/ninja_vs_samurai.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1571149",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGNaughtySanta",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGNaughtySanta",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Naughty Santa"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/naughty_santa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8447",
        "GSubSort": "8447",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "846a0c93-2643-4609-9eaf-2d762f42bfff",
        "MobileExternalCode": "846a0c93-2643-4609-9eaf-2d762f42bfff",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "999999.99",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1571149",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/naughty_santa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1570537",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20hercpeg",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20hercpeg",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hercules and Pegasus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hercules_end_pegasus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8435",
        "GSubSort": "8435",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570537",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hercules_end_pegasus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1570402",
        "System": "944",
        "SubSystem": null,
        "PageCode": "400",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100400",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Flex Game"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/flex_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8432",
        "GSubSort": "8432",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.04",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570402",
        "RTP": "97.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/flex_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1570396",
        "System": "944",
        "SubSystem": null,
        "PageCode": "402",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100402",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Park Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/park_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8431",
        "GSubSort": "8431",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.04",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570396",
        "RTP": "97.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/park_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1570390",
        "System": "939",
        "SubSystem": null,
        "PageCode": "dragon-tiger-luck",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "dragon-tiger-luck",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Tiger Luck"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/dragon_tiger_luck.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "GSort": "8430",
        "GSubSort": "8430",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "63",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570390",
        "RTP": "96.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/dragon_tiger_luck.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1570393",
        "System": "944",
        "SubSystem": null,
        "PageCode": "381",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100381",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Bingo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hot_bingo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8429",
        "GSubSort": "8429",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.04",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1570393",
        "RTP": "94.8900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hot_bingo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1564540",
        "System": "939",
        "SubSystem": null,
        "PageCode": "flirting-scholar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "flirting-scholar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Flirting Scholar"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/flirting_scholar.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8427",
        "GSubSort": "8427",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "61",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "1407.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1564540",
        "RTP": "97.4400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/flirting_scholar.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1567162",
        "System": "944",
        "SubSystem": null,
        "PageCode": "374",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100374",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Win 777"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/big_win_777.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8406",
        "GSubSort": "8406",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1567162",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/big_win_777.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1568014",
        "System": "944",
        "SubSystem": null,
        "PageCode": "375",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100375",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Honey Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/honey_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8405",
        "GSubSort": "8405",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1568014",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/honey_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1561771",
        "System": "939",
        "SubSystem": null,
        "PageCode": "leprechaun-riches",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "leprechaun-riches",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/leprechaun_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8380",
        "GSubSort": "8380",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "60",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "9893.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1561771",
        "RTP": "97.3500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/leprechaun_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1554205",
        "System": "939",
        "SubSystem": null,
        "PageCode": "the-great-icescape",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "the-great-icescape",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Great Icescape"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/the_great_icescape.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8361",
        "GSubSort": "8361",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "53",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "7963.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1554205",
        "RTP": "96.3300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/the_great_icescape.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1566358",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20aladdinsorc",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20aladdinsorc",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aladdin and the Sorcerer"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/aladdin_and_the_sorcerer.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8320",
        "GSubSort": "8320",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6532.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1566358",
        "RTP": "96.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/aladdin_and_the_sorcerer.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1557616",
        "System": "939",
        "SubSystem": null,
        "PageCode": "captains-bounty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "captains-bounty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Captain's Bounty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/captains_bounty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8307",
        "GSubSort": "8307",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "54",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4796.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1557616",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/captains_bounty.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1566322",
        "System": "944",
        "SubSystem": null,
        "PageCode": "397",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100397",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Pump"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cash_pump.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8307",
        "GSubSort": "8307",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1566322",
        "RTP": "96.8600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cash_pump.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1566934",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHotHotHalloween",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHotHotHalloween",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Hot Halloween"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/hot_hot_halloween.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8297",
        "GSubSort": "8297",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "85cabf2a-585e-441c-baef-fac357141bf9",
        "MobileExternalCode": "85cabf2a-585e-441c-baef-fac357141bf9",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "75117.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1566934",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/hot_hot_halloween.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1565608",
        "System": "944",
        "SubSystem": null,
        "PageCode": "388",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100388",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rainforest Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rainforest_magic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8284",
        "GSubSort": "8284",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1565608",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rainforest_magic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1564855",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1fortunetree",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1fortunetree",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tree of Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/tree_of_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8275",
        "GSubSort": "8275",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "2880.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1564855",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/tree_of_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1563826",
        "System": "944",
        "SubSystem": null,
        "PageCode": "394",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100394",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Demon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/demon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8223",
        "GSubSort": "8223",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1563826",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/demon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1563418",
        "System": "944",
        "SubSystem": null,
        "PageCode": "379",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100379",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mission Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mission_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8219",
        "GSubSort": "8219",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1563418",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mission_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1562692",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10firestrike",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10firestrike",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire Strike"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/firestrike.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8213",
        "GSubSort": "8213",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "27000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1562692",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/firestrike.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1562695",
        "System": "944",
        "SubSystem": null,
        "PageCode": "399",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100399",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Inferno Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/inferno_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8210",
        "GSubSort": "8210",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1562695",
        "RTP": "96.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/inferno_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1562698",
        "System": "944",
        "SubSystem": null,
        "PageCode": "409",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100409",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Hand Casino Hold'em"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/3hand_casino_holdem.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8209",
        "GSubSort": "8209",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "213.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1562698",
        "RTP": "97.8400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/3hand_casino_holdem.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1561834",
        "System": "944",
        "SubSystem": null,
        "PageCode": "392",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100392",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo's Adventure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugos_adventure.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8202",
        "GSubSort": "8202",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1561834",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugos_adventure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1556368",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10vampwolf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10vampwolf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Vampires vs Wolves"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vampires_vs_wolves.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8182",
        "GSubSort": "8182",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1556368",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vampires_vs_wolves.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1560781",
        "System": "944",
        "SubSystem": null,
        "PageCode": "367",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100367",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rise of Dead"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rise_of_dead.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8179",
        "GSubSort": "8179",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1560781",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rise_of_dead.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1556548",
        "System": "944",
        "SubSystem": null,
        "PageCode": "364",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100364",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Firefly Frenzy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/firefly_frenzy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8178",
        "GSubSort": "8178",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1556548",
        "RTP": "96.5900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/firefly_frenzy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1559719",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5spjoker",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5spjoker",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/super_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8165",
        "GSubSort": "8165",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1559719",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/super_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1564555",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWizardsWantWar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWizardsWantWar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wizards Want War",
            "id": "Wizards Want War",
            "de": "Wizards Want War",
            "es": "Wizards Want War",
            "fr": "Wizards Want War",
            "it": "Wizards Want War",
            "nl": "Wizards Want War",
            "no": "Wizards Want War",
            "pt": "Wizards Want War",
            "fi": "Wizards Want War",
            "sv": "Wizards Want War",
            "vi": "Wizards Want War",
            "tr": "Wizards Want War",
            "gr": "Wizards Want War",
            "ru": "Wizards Want War",
            "th": "Wizards Want War",
            "ja": "Wizards Want War",
            "zh-hant": "Wizards Want War",
            "zh-hans": "Wizards Want War",
            "ko": "Wizards Want War"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/wizards_want_war.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8143",
        "GSubSort": "8143",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "2bf24e06-90d0-41b8-a56f-172e9811b151",
        "MobileExternalCode": "2bf24e06-90d0-41b8-a56f-172e9811b151",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1564555",
        "RTP": "96.5900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/wizards_want_war.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1565419",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5452",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5452",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dungeon: Immortal Evil"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/dungeon_Immortal_evil.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "8134",
        "GSubSort": "8134",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6316.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1565419",
        "RTP": "96.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/dungeon_Immortal_evil.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1565392",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5398",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5398",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Nuke World"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/nuke_world.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8133",
        "GSubSort": "8133",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "804.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1565392",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/nuke_world.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1564681",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5455",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5455",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sprinkle"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/sprinkle.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8132",
        "GSubSort": "8132",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "352.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1564681",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/sprinkle.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1560895",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGColossalGems",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGColossalGems",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Colossal Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/colossal_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8097",
        "GSubSort": "8097",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "63bc713a-ab25-4eea-bf4b-0a839ed4903f",
        "MobileExternalCode": "63bc713a-ab25-4eea-bf4b-0a839ed4903f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "13821.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1560895",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/colossal_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1540294",
        "System": "939",
        "SubSystem": null,
        "PageCode": "jungle-delight",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "jungle-delight",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jungle Delight"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/jungle_delight.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8074",
        "GSubSort": "8074",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "40",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "2669.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540294",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/jungle_delight.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540288",
        "System": "939",
        "SubSystem": null,
        "PageCode": "ganesha-gold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "ganesha-gold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ganesha Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/ganesha_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8072",
        "GSubSort": "8072",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "42",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "4963.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540288",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/ganesha_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540282",
        "System": "939",
        "SubSystem": null,
        "PageCode": "symbols-of-egypt",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "symbols-of-egypt",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Symbols of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/symbols_of_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8071",
        "GSubSort": "8071",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "41",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.27",
        "MaxBetDefault": "54.00",
        "MaxMultiplier": "1080.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540282",
        "RTP": "95.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/symbols_of_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540279",
        "System": "939",
        "SubSystem": null,
        "PageCode": "piggy-gold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "piggy-gold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Piggy Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/piggy_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8070",
        "GSubSort": "8070",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "39",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540279",
        "RTP": "95.8600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/piggy_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540276",
        "System": "939",
        "SubSystem": null,
        "PageCode": "gem-saviour-sword",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "gem-saviour-sword",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gem Saviour Sword"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/gem_saviour_sword.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8069",
        "GSubSort": "8069",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "38",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540276",
        "RTP": "95.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/gem_saviour_sword.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1558492",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9hotroll",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9hotroll",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Chilli"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/hot_chilli.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8068",
        "GSubSort": "8068",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "45.00",
        "MaxMultiplier": "888.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1558492",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/hot_chilli.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1540264",
        "System": "939",
        "SubSystem": null,
        "PageCode": "prosperity-lion",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "prosperity-lion",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Prosperity Lion"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/prosperity_lion.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8065",
        "GSubSort": "8065",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "36",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.27",
        "MaxBetDefault": "135.00",
        "MaxMultiplier": "727.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540264",
        "RTP": "95.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/prosperity_lion.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540258",
        "System": "939",
        "SubSystem": null,
        "PageCode": "legend-of-hou-yi",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "legend-of-hou-yi",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legend of Hou Yi"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/legend_of_hou_yi.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8063",
        "GSubSort": "8063",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "34",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "900.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540258",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/legend_of_hou_yi.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1547242",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWildTrucks",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWildTrucks",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Trucks",
            "id": "Wild Trucks",
            "de": "Wild Trucks",
            "es": "Wild Trucks",
            "fr": "Wild Trucks",
            "it": "Wild Trucks",
            "nl": "Wild Trucks",
            "no": "Wild Trucks",
            "pt": "Wild Trucks",
            "fi": "Wild Trucks",
            "sv": "Wild Trucks",
            "vi": "Wild Trucks",
            "tr": "Wild Trucks",
            "gr": "Wild Trucks",
            "ru": "Wild Trucks",
            "th": "Wild Trucks",
            "ja": "Wild Trucks",
            "zh-hant": "狂野卡車",
            "zh-hans": "狂野卡车",
            "ko": "Wild Trucks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/wild_trucks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8063",
        "GSubSort": "8063",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "4",
            "16"
        ],
        "SortPerCategory": {
            "4": 0,
            "16": 0
        },
        "ExternalCode": "7acbfdc9-121b-4fad-9c7f-430daf1f3df8",
        "MobileExternalCode": "7acbfdc9-121b-4fad-9c7f-430daf1f3df8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "999999.99",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1547242",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/wild_trucks.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1540255",
        "System": "939",
        "SubSystem": null,
        "PageCode": "mr-hallow-win",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "mr-hallow-win",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mr. Hallow-Win"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/mr_hallow_win.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8062",
        "GSubSort": "8062",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "35",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "1964.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540255",
        "RTP": "95.8600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/mr_hallow_win.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540252",
        "System": "939",
        "SubSystem": null,
        "PageCode": "dragon-legend",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "dragon-legend",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Legend"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/dragon_legend.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8061",
        "GSubSort": "8061",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "29",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.27",
        "MaxBetDefault": "270.00",
        "MaxMultiplier": "1672.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540252",
        "RTP": "97.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/dragon_legend.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540249",
        "System": "939",
        "SubSystem": null,
        "PageCode": "hotpot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "hotpot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hotpot"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/hotpot.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8060",
        "GSubSort": "8060",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "28",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "12352.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540249",
        "RTP": "95.8300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/hotpot.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540246",
        "System": "939",
        "SubSystem": null,
        "PageCode": "hood-wolf",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "hood-wolf",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hood vs Wolf"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/hood_vs_wolf.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8059",
        "GSubSort": "8059",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "18",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "360.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540246",
        "RTP": "95.3900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/hood_vs_wolf.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540237",
        "System": "939",
        "SubSystem": null,
        "PageCode": "plushie-frenzy",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "plushie-frenzy",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Plushie Frenzy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/plushie_frenzy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8056",
        "GSubSort": "8056",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "25",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "505.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540237",
        "RTP": "94.8200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/plushie_frenzy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1557430",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20chicken",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20chicken",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Great Chicken Escape"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_great_chicken_escape.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8054",
        "GSubSort": "8054",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1557430",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_great_chicken_escape.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1540228",
        "System": "939",
        "SubSystem": null,
        "PageCode": "medusa",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "medusa",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Medusa 1"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/medusa_1.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8053",
        "GSubSort": "8053",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540228",
        "RTP": "95.2900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/medusa_1.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540222",
        "System": "939",
        "SubSystem": null,
        "PageCode": "fortune-tree",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "fortune-tree",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tree of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/tree_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8051",
        "GSubSort": "8051",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "26",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "375.00",
        "MaxMultiplier": "1060.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540222",
        "RTP": "95.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/tree_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540213",
        "System": "939",
        "SubSystem": null,
        "PageCode": "win-win-won",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "win-win-won",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Win Win Won"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/win_win_won.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "GSort": "8048",
        "GSubSort": "8048",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "24",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "45.00",
        "MaxMultiplier": "2600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540213",
        "RTP": "97.6200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/win_win_won.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1539712",
        "System": "939",
        "SubSystem": null,
        "PageCode": "gem-saviour",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "gem-saviour",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gem Saviour"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/gem_saviour.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8034",
        "GSubSort": "8034",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "2",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "750.00",
        "MaxMultiplier": "74.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1539712",
        "RTP": "96.8200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/gem_saviour.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1539709",
        "System": "939",
        "SubSystem": null,
        "PageCode": "diaochan",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "diaochan",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Honey Trap of Diao Chan"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/honey_trap_of_diao_chan.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8033",
        "GSubSort": "8033",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "1",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "20230.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1539709",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/honey_trap_of_diao_chan.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1539706",
        "System": "939",
        "SubSystem": null,
        "PageCode": "fortune-gods",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "fortune-gods",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/fortune_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "8032",
        "GSubSort": "8032",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "3",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "1024.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1539706",
        "RTP": "95.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/fortune_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1543861",
        "System": "944",
        "SubSystem": null,
        "PageCode": "362",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100362",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mahjong 88"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mahjong_88.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8011",
        "GSubSort": "8011",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1543861",
        "RTP": "96.6200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mahjong_88.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1544959",
        "System": "944",
        "SubSystem": null,
        "PageCode": "369",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100369",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Game of Gladiators"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/game_of_gladiators.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8010",
        "GSubSort": "8010",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1544959",
        "RTP": "96.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/game_of_gladiators.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1557223",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGNuwa",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGNuwa",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Nuwa"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/nuwa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8010",
        "GSubSort": "8010",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "451747d1-ddbb-4f12-a703-1173eb680fcb",
        "MobileExternalCode": "451747d1-ddbb-4f12-a703-1173eb680fcb",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.28",
        "MaxBetDefault": "140.00",
        "MaxMultiplier": "15400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1557223",
        "RTP": "96.5700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/nuwa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1541599",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243mwarrior",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243mwarrior",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monkey Warrior"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243mwarrior.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7981",
        "GSubSort": "7981",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "6080.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1541599",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243mwarrior.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1540552",
        "System": "944",
        "SubSystem": null,
        "PageCode": "378",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100378",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sabaton"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sabaton.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7967",
        "GSubSort": "7967",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540552",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sabaton.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "2172968",
        "System": "940",
        "SubSystem": null,
        "PageCode": "1009",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "1009",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "American Roulette 3D"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/american_roulette_3d.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7962",
        "GSubSort": "7962",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1366"
        ],
        "SortPerCategory": {
            "7": 0,
            "1366": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30.72",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172968",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/american_roulette_3d.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1542754",
        "System": "944",
        "SubSystem": null,
        "PageCode": "366",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100366",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Inferno Star"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/inferno_star.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7949",
        "GSubSort": "7949",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1542754",
        "RTP": "96.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/inferno_star.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1540522",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMagicOak",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMagicOak",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Oak"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/magic_oak.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7892",
        "GSubSort": "7892",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "35"
        ],
        "SortPerCategory": {
            "16": 0,
            "35": 0
        },
        "ExternalCode": "ccbe2cfa-3ae9-45d8-a157-31cd92d8e168",
        "MobileExternalCode": "ccbe2cfa-3ae9-45d8-a157-31cd92d8e168",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540522",
        "RTP": "98.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/magic_oak.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1543972",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGLuckyLucky",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGLuckyLucky",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Lucky"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/lucky_lucky.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7891",
        "GSubSort": "7891",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "35"
        ],
        "SortPerCategory": {
            "16": 0,
            "35": 0
        },
        "ExternalCode": "f265caa0-b658-43bb-af26-33d0ec4b1f12",
        "MobileExternalCode": "f265caa0-b658-43bb-af26-33d0ec4b1f12",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "900.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1543972",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/lucky_lucky.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1559137",
        "System": "940",
        "SubSystem": null,
        "PageCode": "5344",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "5344",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "High Striker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/high_striker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7876",
        "GSubSort": "7876",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "3604"
        ],
        "SortPerCategory": {
            "3604": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1559137",
        "RTP": "99.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/high_striker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172876",
        "System": "940",
        "SubSystem": null,
        "PageCode": "1018",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "1018",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Reign of Dragons"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/reign_of_dragons.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7875",
        "GSubSort": "7875",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "732.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172876",
        "RTP": "95.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/reign_of_dragons.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172976",
        "System": "940",
        "SubSystem": null,
        "PageCode": "1006",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "1006",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Poker Teen Patti"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/teen_patti.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7873",
        "GSubSort": "7873",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "36.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172976",
        "RTP": "97.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/teen_patti.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172912",
        "System": "940",
        "SubSystem": null,
        "PageCode": "994",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "994",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "E.T. Lost Socks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/e.t.lost_socks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7852",
        "GSubSort": "7852",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1005.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172912",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/e.t.lost_socks.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172896",
        "System": "940",
        "SubSystem": null,
        "PageCode": "1003",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "1003",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Trip to the Future"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/trip_to_the_future.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7851",
        "GSubSort": "7851",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "311.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172896",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/trip_to_the_future.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172920",
        "System": "940",
        "SubSystem": null,
        "PageCode": "991",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "991",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Syndicate"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/syndicate.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7851",
        "GSubSort": "7851",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172920",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/syndicate.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173216",
        "System": "940",
        "SubSystem": null,
        "PageCode": "985",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "985",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rock Paper Scissors"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/rock_vs_paper.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7851",
        "GSubSort": "7851",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173216",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/rock_vs_paper.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173228",
        "System": "940",
        "SubSystem": null,
        "PageCode": "1012",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "1012",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "E.T. Races"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/et_races.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7851",
        "GSubSort": "7851",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173228",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/et_races.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170444",
        "System": "940",
        "SubSystem": null,
        "PageCode": "988",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "988",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Girls"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/lucky_girls.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7848",
        "GSubSort": "7848",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "316.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170444",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/lucky_girls.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172868",
        "System": "940",
        "SubSystem": null,
        "PageCode": "979",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "979",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Charming Queens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/charming_queens.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7848",
        "GSubSort": "7848",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "657.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172868",
        "RTP": "95.7400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/charming_queens.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172932",
        "System": "940",
        "SubSystem": null,
        "PageCode": "982",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "982",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Robots: Energy Conflict"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/robots_energy_conflict.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7848",
        "GSubSort": "7848",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1877.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172932",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/robots_energy_conflict.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172936",
        "System": "940",
        "SubSystem": null,
        "PageCode": "973",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "973",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystery Planet"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/mystery_planet.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7848",
        "GSubSort": "7848",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2021.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172936",
        "RTP": "93.6300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/mystery_planet.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172928",
        "System": "940",
        "SubSystem": null,
        "PageCode": "725",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "725",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aeronauts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/aeronauts.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7847",
        "GSubSort": "7847",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "603.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172928",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/aeronauts.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170388",
        "System": "940",
        "SubSystem": null,
        "PageCode": "296",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "296",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Candy Dreams"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/candy_dreams.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "440.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170388",
        "RTP": "93.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/candy_dreams.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170396",
        "System": "940",
        "SubSystem": null,
        "PageCode": "22",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "22",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chinese New Year"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/chinese_new_year.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "22725.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170396",
        "RTP": "93.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/chinese_new_year.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170412",
        "System": "940",
        "SubSystem": null,
        "PageCode": "28",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "28",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Journey to the West"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/journey_to_the_west.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170412",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/journey_to_the_west.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170420",
        "System": "940",
        "SubSystem": null,
        "PageCode": "95",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "95",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Emperor's Tomb"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/emperors_tomb.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1520.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170420",
        "RTP": "93.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/emperors_tomb.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170428",
        "System": "940",
        "SubSystem": null,
        "PageCode": "88",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "88",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Burst"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruitburst.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "511.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170428",
        "RTP": "95.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruitburst.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170432",
        "System": "940",
        "SubSystem": null,
        "PageCode": "10",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "10",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Basketball"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/basketball.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170432",
        "RTP": "94.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/basketball.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170436",
        "System": "940",
        "SubSystem": null,
        "PageCode": "37",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "37",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jewellery Store"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/jewellery_store.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "480.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170436",
        "RTP": "95.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/jewellery_store.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170448",
        "System": "940",
        "SubSystem": null,
        "PageCode": "25",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "25",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Clash of Pirates"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/clash_of_pirates.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "550.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170448",
        "RTP": "95.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/clash_of_pirates.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170452",
        "System": "940",
        "SubSystem": null,
        "PageCode": "76",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "76",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Red Cliff"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/red_cliff.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6316.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170452",
        "RTP": "94.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/red_cliff.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170460",
        "System": "940",
        "SubSystem": null,
        "PageCode": "104",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "104",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Naughty Girls Cabaret"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/naughty_girls_cabaret.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2174.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170460",
        "RTP": "93.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/naughty_girls_cabaret.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170464",
        "System": "940",
        "SubSystem": null,
        "PageCode": "293",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "293",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Robinson"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/robinson.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "9600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170464",
        "RTP": "92.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/robinson.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170468",
        "System": "940",
        "SubSystem": null,
        "PageCode": "719",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "719",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Slavs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/slavs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170468",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/slavs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172856",
        "System": "940",
        "SubSystem": null,
        "PageCode": "742",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "742",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dolphins Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/dolphins_treasure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "598.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172856",
        "RTP": "94.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/dolphins_treasure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172864",
        "System": "940",
        "SubSystem": null,
        "PageCode": "976",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "976",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruits Land"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/fruitsland.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "324.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172864",
        "RTP": "94.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/fruitsland.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172872",
        "System": "940",
        "SubSystem": null,
        "PageCode": "733",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "733",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Great Conflict"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/the_great_conflict.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "726.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172872",
        "RTP": "93.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/the_great_conflict.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172884",
        "System": "940",
        "SubSystem": null,
        "PageCode": "736",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "736",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Battle Tanks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/battle_tanks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1348.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172884",
        "RTP": "93.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/battle_tanks.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172888",
        "System": "940",
        "SubSystem": null,
        "PageCode": "85",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "85",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ace Round"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/ace_round.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172888",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/ace_round.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172904",
        "System": "940",
        "SubSystem": null,
        "PageCode": "107",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "107",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Football"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/football.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "50000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172904",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/football.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172908",
        "System": "940",
        "SubSystem": null,
        "PageCode": "98",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "98",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Atlantis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/atlantis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172908",
        "RTP": "94.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/atlantis.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172952",
        "System": "940",
        "SubSystem": null,
        "PageCode": "730",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "730",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monster Lab"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/monster_lab.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2803.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172952",
        "RTP": "94.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/monster_lab.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172960",
        "System": "940",
        "SubSystem": null,
        "PageCode": "745",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "745",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "BlackJack Lucky Sevens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/blackjack.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "41"
        ],
        "SortPerCategory": {
            "7": 0,
            "41": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172960",
        "RTP": "99.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/blackjack.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172964",
        "System": "940",
        "SubSystem": null,
        "PageCode": "946",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "946",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "European Roulette"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/european_roulette.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1366"
        ],
        "SortPerCategory": {
            "7": 0,
            "1366": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172964",
        "RTP": "97.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/european_roulette.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172972",
        "System": "940",
        "SubSystem": null,
        "PageCode": "748",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "748",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Oasis Poker Classic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/oasis_poker_classic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "7": 0,
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "87.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172972",
        "RTP": "99.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/oasis_poker_classic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172980",
        "System": "940",
        "SubSystem": null,
        "PageCode": "967",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "967",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Baccarat 777"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/baccarat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1364"
        ],
        "SortPerCategory": {
            "7": 0,
            "1364": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "49.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172980",
        "RTP": "98.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/baccarat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172984",
        "System": "940",
        "SubSystem": null,
        "PageCode": "964",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "964",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Heads & Tails"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/heads_tails.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172984",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/heads_tails.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172988",
        "System": "940",
        "SubSystem": null,
        "PageCode": "961",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "961",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "More or Less"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/more_or_less.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "77.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172988",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/more_or_less.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173208",
        "System": "940",
        "SubSystem": null,
        "PageCode": "949",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "949",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Roll The Dice"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/roll_the_dice.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22",
            "3810"
        ],
        "SortPerCategory": {
            "22": 0,
            "3810": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "30.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173208",
        "RTP": "98.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/roll_the_dice.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173212",
        "System": "940",
        "SubSystem": null,
        "PageCode": "958",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "958",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Four Aces"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/four_aces.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173212",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/four_aces.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173220",
        "System": "940",
        "SubSystem": null,
        "PageCode": "952",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "952",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Thimbles"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/thimbles.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173220",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/thimbles.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2173224",
        "System": "940",
        "SubSystem": null,
        "PageCode": "955",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "955",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Red Queen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/red_queen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "7846",
        "GSubSort": "7846",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2173224",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/red_queen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1540273",
        "System": "939",
        "SubSystem": null,
        "PageCode": "baccarat-deluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "baccarat-deluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Baccarat Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/baccarat_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "GSort": "7830",
        "GSubSort": "7830",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1364"
        ],
        "SortPerCategory": {
            "7": 0,
            "1364": 0
        },
        "ExternalCode": "31",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "1500.00",
        "MaxMultiplier": "11.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540273",
        "RTP": "98.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/baccarat_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "2172900",
        "System": "940",
        "SubSystem": null,
        "PageCode": "970",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "970",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Necromancer"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/necromancer.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7815",
        "GSubSort": "7815",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "520.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172900",
        "RTP": "95.9000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/necromancer.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170392",
        "System": "940",
        "SubSystem": null,
        "PageCode": "13",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "13",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Talismans of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/talismans_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7809",
        "GSubSort": "7809",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1803.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170392",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/talismans_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170416",
        "System": "940",
        "SubSystem": null,
        "PageCode": "34",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "34",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Vegas Nights"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/vegas_night.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7809",
        "GSubSort": "7809",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3558.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170416",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/vegas_night.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2172880",
        "System": "940",
        "SubSystem": null,
        "PageCode": "16",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "16",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Great Wall Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/great_wall.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7809",
        "GSubSort": "7809",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2033.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2172880",
        "RTP": "94.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/great_wall.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170456",
        "System": "940",
        "SubSystem": null,
        "PageCode": "94",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "94",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Legend of Ra"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/legend_of_ra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7806",
        "GSubSort": "7806",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170456",
        "RTP": "92.2000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/legend_of_ra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170404",
        "System": "940",
        "SubSystem": null,
        "PageCode": "19",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "19",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Robin Hood"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/robin_hood.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7802",
        "GSubSort": "7802",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "835.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170404",
        "RTP": "95.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/robin_hood.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170424",
        "System": "940",
        "SubSystem": null,
        "PageCode": "4",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "4",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Egypt Gods"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/egypt_gods.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7802",
        "GSubSort": "7802",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5510.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170424",
        "RTP": "94.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/egypt_gods.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "2170440",
        "System": "940",
        "SubSystem": null,
        "PageCode": "101",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "101",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Epic Gladiators"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/evoplay/epic_gladiators.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7802",
        "GSubSort": "7802",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Evoplay",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1149.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "2170440",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/evoplay/epic_gladiators.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "247"
    },
    {
        "ID": "1540270",
        "System": "939",
        "SubSystem": null,
        "PageCode": "santas-gift-rush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "santas-gift-rush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Santa's Gift Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/santas_gift_rush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "7792",
        "GSubSort": "7792",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "37",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "689.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540270",
        "RTP": "96.3600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/santas_gift_rush.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540267",
        "System": "939",
        "SubSystem": null,
        "PageCode": "hip-hop-panda",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "hip-hop-panda",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hip Hop Panda"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/hip_hop_panda.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "7790",
        "GSubSort": "7790",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "33",
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "572.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540267",
        "RTP": "95.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/hip_hop_panda.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1540216",
        "System": "939",
        "SubSystem": null,
        "PageCode": "medusa2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "medusa2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Medusa 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pgsoft/medusa_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "0",
        "Freeround": "1",
        "GSort": "7776",
        "GSubSort": "7776",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "6",
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "PGSoft",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1540216",
        "RTP": "94.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pgsoft/medusa_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "244"
    },
    {
        "ID": "1539280",
        "System": "944",
        "SubSystem": null,
        "PageCode": "371",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100371",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Phoenix Reborn"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/phoenix_reborn.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6903",
        "GSubSort": "6903",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1539280",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/phoenix_reborn.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1538320",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20egypttrs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20egypttrs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Egyptian Fortunes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20egypttrs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6809",
        "GSubSort": "6809",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "35"
        ],
        "SortPerCategory": {
            "16": 0,
            "35": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "309.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1538320",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20egypttrs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1538215",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243lionsgold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243lionsgold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Lions Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243lionsgold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6808",
        "GSubSort": "6808",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "35"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "35": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "7342.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1538215",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243lionsgold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1538218",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243caishien",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243caishien",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Caishen's Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243caishien.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6807",
        "GSubSort": "6807",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "21749.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1538218",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243caishien.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1538425",
        "System": "944",
        "SubSystem": null,
        "PageCode": "372",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100372",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crystal Sun"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/crystal_sun.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6807",
        "GSubSort": "6807",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1538425",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/crystal_sun.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1536997",
        "System": "944",
        "SubSystem": null,
        "PageCode": "359",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100359",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Contact"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/contact.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6790",
        "GSubSort": "6790",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1517.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1536997",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/contact.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1535956",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5trjokers",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5trjokers",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Triple Jokers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs5trjokers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6775",
        "GSubSort": "6775",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1535956",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs5trjokers.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1533544",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs40pirate",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs40pirate",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pirate Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs40pirate.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6766",
        "GSubSort": "6766",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "32270.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1533544",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs40pirate.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1534873",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25gladiator",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25gladiator",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Gladiators"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25gladiator.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6763",
        "GSubSort": "6763",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1534873",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25gladiator.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1538323",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20doghouse",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20doghouse",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Dog House"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20doghouse.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6755",
        "GSubSort": "6755",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "35"
        ],
        "SortPerCategory": {
            "16": 0,
            "35": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6750.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1538323",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20doghouse.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1534657",
        "System": "944",
        "SubSystem": null,
        "PageCode": "370",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100370",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Vandal"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cash_vandal.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6751",
        "GSubSort": "6751",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1534657",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cash_vandal.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1533937",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20wildpix",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20wildpix",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Pixies"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20wildpix.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6742",
        "GSubSort": "6742",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "261.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1533937",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20wildpix.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1536766",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMountMazuma",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMountMazuma",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mount Mazuma"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/mount_mazuma.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6693",
        "GSubSort": "6693",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "35"
        ],
        "SortPerCategory": {
            "16": 0,
            "35": 0
        },
        "ExternalCode": "e3a83dd1-0f58-4cec-a9dd-60fca2410c6f",
        "MobileExternalCode": "e3a83dd1-0f58-4cec-a9dd-60fca2410c6f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "160120.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1536766",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/mount_mazuma.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1533967",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SG5LuckyLions",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SG5LuckyLions",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Lucky Lions"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/5_lucky_lions.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6655",
        "GSubSort": "6655",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "518554c8-fbce-4cd7-9724-880f0d98c04d",
        "MobileExternalCode": "518554c8-fbce-4cd7-9724-880f0d98c04d",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "88.00",
        "MaxMultiplier": "46400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1533967",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/5_lucky_lions.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1530095",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10fruity2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10fruity2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Extra juicy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs10fruity2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6557",
        "GSubSort": "6557",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "60000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1530095",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs10fruity2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1529693",
        "System": "944",
        "SubSystem": null,
        "PageCode": "350",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100350",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Falls"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_falls.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6546",
        "GSubSort": "6546",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529693",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_falls.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529690",
        "System": "944",
        "SubSystem": null,
        "PageCode": "363",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100363",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen's Day Tilt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/queens_day_tilt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6545",
        "GSubSort": "6545",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529690",
        "RTP": "96.3600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/queens_day_tilt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529684",
        "System": "944",
        "SubSystem": null,
        "PageCode": "309",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100309",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Xmas Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/xmas_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6543",
        "GSubSort": "6543",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6480.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529684",
        "RTP": "96.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/xmas_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529681",
        "System": "944",
        "SubSystem": null,
        "PageCode": "302",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100302",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wizard of Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wizard_of_gems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6542",
        "GSubSort": "6542",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "12500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529681",
        "RTP": "96.6600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wizard_of_gems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529678",
        "System": "944",
        "SubSystem": null,
        "PageCode": "294",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100294",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild North"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_north.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6541",
        "GSubSort": "6541",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529678",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_north.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529675",
        "System": "944",
        "SubSystem": null,
        "PageCode": "4",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100004",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Melon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_melon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6540",
        "GSubSort": "6540",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3333.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529675",
        "RTP": "93.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_melon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529672",
        "System": "944",
        "SubSystem": null,
        "PageCode": "250",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100250",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Blood"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/wild_blood.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6539",
        "GSubSort": "6539",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "12500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529672",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/wild_blood.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529669",
        "System": "944",
        "SubSystem": null,
        "PageCode": "319",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100319",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Viking Runecraft"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/viking_runecraft.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6538",
        "GSubSort": "6538",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529669",
        "RTP": "96.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/viking_runecraft.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529666",
        "System": "944",
        "SubSystem": null,
        "PageCode": "254",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100254",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Troll Hunters"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/troll_hunters.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6537",
        "GSubSort": "6537",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "18566.15",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529666",
        "RTP": "96.6900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/troll_hunters.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529660",
        "System": "944",
        "SubSystem": null,
        "PageCode": "287",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100287",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tower Quest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/tower_quest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6535",
        "GSubSort": "6535",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529660",
        "RTP": "96.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/tower_quest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529654",
        "System": "944",
        "SubSystem": null,
        "PageCode": "336",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100336",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet Alchemy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sweet_alchemy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6534",
        "GSubSort": "6534",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529654",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sweet_alchemy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529651",
        "System": "944",
        "SubSystem": null,
        "PageCode": "335",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100335",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sweet 27"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sweet_27.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6533",
        "GSubSort": "6533",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1758.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529651",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sweet_27.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529648",
        "System": "944",
        "SubSystem": null,
        "PageCode": "324",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100324",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Wheel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/super_wheel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6532",
        "GSubSort": "6532",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "48.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529648",
        "RTP": "92.3100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/super_wheel.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529645",
        "System": "944",
        "SubSystem": null,
        "PageCode": "295",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100295",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Flip"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/super_flip.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6531",
        "GSubSort": "6531",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529645",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/super_flip.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529642",
        "System": "944",
        "SubSystem": null,
        "PageCode": "351",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100351",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Street Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/street_magic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6530",
        "GSubSort": "6530",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529642",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/street_magic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529639",
        "System": "944",
        "SubSystem": null,
        "PageCode": "292",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100292",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Spin Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/spin_party.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6529",
        "GSubSort": "6529",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529639",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/spin_party.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529633",
        "System": "944",
        "SubSystem": null,
        "PageCode": "198",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100198",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Space Race"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/space_race.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6526",
        "GSubSort": "6526",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10657.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529633",
        "RTP": "94.2300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/space_race.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529630",
        "System": "944",
        "SubSystem": null,
        "PageCode": "357",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100357",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sizzling Spins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sizzling_spins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6525",
        "GSubSort": "6525",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529630",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sizzling_spins.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529627",
        "System": "944",
        "SubSystem": null,
        "PageCode": "55",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100055",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Single Deck BlackJack MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/single_deck_blackjack_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6524",
        "GSubSort": "6524",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6.60",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529627",
        "RTP": "98.8900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/single_deck_blackjack_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529624",
        "System": "944",
        "SubSystem": null,
        "PageCode": "331",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100331",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sea Hunter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sea_hunter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6523",
        "GSubSort": "6523",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "920.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529624",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sea_hunter.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529618",
        "System": "944",
        "SubSystem": null,
        "PageCode": "304",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100304",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Samba Carnival"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/samba_carnival.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6521",
        "GSubSort": "6521",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1756.20",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529618",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/samba_carnival.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529615",
        "System": "944",
        "SubSystem": null,
        "PageCode": "311",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100311",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sails of Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/sails_of_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6520",
        "GSubSort": "6520",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529615",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/sails_of_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529612",
        "System": "944",
        "SubSystem": null,
        "PageCode": "298",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100298",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Royal Masquerade"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/royal_masquerade.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6519",
        "GSubSort": "6519",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529612",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/royal_masquerade.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529582",
        "System": "944",
        "SubSystem": null,
        "PageCode": "237",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100237",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Riches of RA"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/riches_of_ra.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6518",
        "GSubSort": "6518",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "8032.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529582",
        "RTP": "94.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/riches_of_ra.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529573",
        "System": "944",
        "SubSystem": null,
        "PageCode": "257",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100257",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rage to Riches"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/rage_to_riches.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6517",
        "GSubSort": "6517",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "29347.95",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529573",
        "RTP": "97.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/rage_to_riches.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529576",
        "System": "944",
        "SubSystem": null,
        "PageCode": "361",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100361",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Raging Rex"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/raging_rex.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6517",
        "GSubSort": "6517",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529576",
        "RTP": "96.3400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/raging_rex.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529570",
        "System": "944",
        "SubSystem": null,
        "PageCode": "328",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100328",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Prosperity Palace"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/prosperity_palace.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6515",
        "GSubSort": "6515",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529570",
        "RTP": "96.2500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/prosperity_palace.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529567",
        "System": "944",
        "SubSystem": null,
        "PageCode": "325",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100325",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Prissy Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/prissy_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6514",
        "GSubSort": "6514",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1250.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529567",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/prissy_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529564",
        "System": "944",
        "SubSystem": null,
        "PageCode": "345",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100345",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Planet Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/planet_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6513",
        "GSubSort": "6513",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529564",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/planet_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529486",
        "System": "944",
        "SubSystem": null,
        "PageCode": "197",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100197",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Photo Safari"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/photo_safari.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6512",
        "GSubSort": "6512",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10657.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529486",
        "RTP": "94.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/photo_safari.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529483",
        "System": "944",
        "SubSystem": null,
        "PageCode": "282",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100282",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pearls of India"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pearls_of_india.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6511",
        "GSubSort": "6511",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529483",
        "RTP": "96.8700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pearls_of_india.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529480",
        "System": "944",
        "SubSystem": null,
        "PageCode": "241",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100241",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pearl Lagoon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/pearl_lagoon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6510",
        "GSubSort": "6510",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "67332.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529480",
        "RTP": "96.8800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/pearl_lagoon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529474",
        "System": "944",
        "SubSystem": null,
        "PageCode": "253",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100253",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ninja Fruits"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ninja_fruits.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6509",
        "GSubSort": "6509",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "22725.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529474",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ninja_fruits.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529471",
        "System": "944",
        "SubSystem": null,
        "PageCode": "245",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100245",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Myth"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/myth.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6507",
        "GSubSort": "6507",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "67332.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529471",
        "RTP": "96.8800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/myth.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529468",
        "System": "944",
        "SubSystem": null,
        "PageCode": "338",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100338",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystery Joker 6000"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mystery_joker_6000.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6506",
        "GSubSort": "6506",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2397.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529468",
        "RTP": "96.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mystery_joker_6000.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529465",
        "System": "944",
        "SubSystem": null,
        "PageCode": "283",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100283",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystery Joker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mystery_joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6505",
        "GSubSort": "6505",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6480.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529465",
        "RTP": "96.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mystery_joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529462",
        "System": "944",
        "SubSystem": null,
        "PageCode": "330",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100330",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Multifruit 81"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/multifruit_81.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6504",
        "GSubSort": "6504",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2520.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529462",
        "RTP": "96.5500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/multifruit_81.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529456",
        "System": "944",
        "SubSystem": null,
        "PageCode": "318",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100318",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Money Wheel"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/money_wheel.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6503",
        "GSubSort": "6503",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "500.00",
        "MaxMultiplier": "46.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529456",
        "RTP": "88.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/money_wheel.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529450",
        "System": "944",
        "SubSystem": null,
        "PageCode": "288",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100288",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Merry Xmas"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/merry_xmas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6501",
        "GSubSort": "6501",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1756.20",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529450",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/merry_xmas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529447",
        "System": "944",
        "SubSystem": null,
        "PageCode": "329",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100329",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mermaid's Diamond"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mermaids_diamond.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6500",
        "GSubSort": "6500",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529447",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mermaids_diamond.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529444",
        "System": "944",
        "SubSystem": null,
        "PageCode": "320",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100320",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Matsuri"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/matsuri.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6499",
        "GSubSort": "6499",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529444",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/matsuri.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529441",
        "System": "944",
        "SubSystem": null,
        "PageCode": "5",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100005",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Diamonds"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/lucky_diamonds.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6498",
        "GSubSort": "6498",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "833.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529441",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/lucky_diamonds.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529429",
        "System": "944",
        "SubSystem": null,
        "PageCode": "278",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100278",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lady of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/lady_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6495",
        "GSubSort": "6495",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1756.20",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529429",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/lady_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529423",
        "System": "944",
        "SubSystem": null,
        "PageCode": "239",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100239",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Keno"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/keno.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6493",
        "GSubSort": "6493",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529423",
        "RTP": "92.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/keno.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529420",
        "System": "944",
        "SubSystem": null,
        "PageCode": "40",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100040",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jolly Roger"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jolly_roger.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6492",
        "GSubSort": "6492",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "928.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529420",
        "RTP": "94.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jolly_roger.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529414",
        "System": "944",
        "SubSystem": null,
        "PageCode": "242",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100242",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jewel Box"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jewel_box.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6489",
        "GSubSort": "6489",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1756.20",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529414",
        "RTP": "95.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jewel_box.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529411",
        "System": "944",
        "SubSystem": null,
        "PageCode": "326",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100326",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jade Magician"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jade_magician.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6488",
        "GSubSort": "6488",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529411",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jade_magician.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529366",
        "System": "944",
        "SubSystem": null,
        "PageCode": "346",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100346",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugo_2.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6486",
        "GSubSort": "6486",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529366",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugo_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529369",
        "System": "944",
        "SubSystem": null,
        "PageCode": "354",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100354",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo Goal"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugo_goal.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6486",
        "GSubSort": "6486",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529369",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugo_goal.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529375",
        "System": "944",
        "SubSystem": null,
        "PageCode": "200",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100200",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Irish Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/irish_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6486",
        "GSubSort": "6486",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "1050.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529375",
        "RTP": "95.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/irish_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529378",
        "System": "944",
        "SubSystem": null,
        "PageCode": "306",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100306",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Iron Girl"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/iron_girl.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6485",
        "GSubSort": "6485",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1071.25",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529378",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/iron_girl.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529372",
        "System": "944",
        "SubSystem": null,
        "PageCode": "348",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100348",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Imperial Opera"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/imperial_opera.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6484",
        "GSubSort": "6484",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529372",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/imperial_opera.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529363",
        "System": "944",
        "SubSystem": null,
        "PageCode": "322",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100322",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hugo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/hugo.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6483",
        "GSubSort": "6483",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529363",
        "RTP": "96.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/hugo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529186",
        "System": "944",
        "SubSystem": null,
        "PageCode": "31",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100031",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "European Roulette Pro"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/european_roulette_pro.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6480",
        "GSubSort": "6480",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1366",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "7": 0,
            "1366": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "300.00",
        "MaxMultiplier": "14.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529186",
        "RTP": "97.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/european_roulette_pro.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529360",
        "System": "944",
        "SubSystem": null,
        "PageCode": "666",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100666",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "House of Doom"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/house_of_doom.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6480",
        "GSubSort": "6480",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529360",
        "RTP": "96.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/house_of_doom.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529636",
        "System": "944",
        "SubSystem": null,
        "PageCode": "106",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100106",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Speed Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/speed_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6479",
        "GSubSort": "6479",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "0.75",
        "MaxMultiplier": "3400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529636",
        "RTP": "94.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/speed_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529357",
        "System": "944",
        "SubSystem": null,
        "PageCode": "327",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100327",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Holiday Season"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/holiday_season.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6478",
        "GSubSort": "6478",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529357",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/holiday_season.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529351",
        "System": "944",
        "SubSystem": null,
        "PageCode": "305",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100305",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Happy Halloween"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/happy_halloween.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6477",
        "GSubSort": "6477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529351",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/happy_halloween.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529240",
        "System": "944",
        "SubSystem": null,
        "PageCode": "303",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100303",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Grim Muerto"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/grim_muerto.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6475",
        "GSubSort": "6475",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529240",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/grim_muerto.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529237",
        "System": "944",
        "SubSystem": null,
        "PageCode": "285",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100285",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Ticket"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_ticket.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6474",
        "GSubSort": "6474",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "18566.15",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529237",
        "RTP": "96.6900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_ticket.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529234",
        "System": "944",
        "SubSystem": null,
        "PageCode": "290",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100290",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Legend"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_legend.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6473",
        "GSubSort": "6473",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529234",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_legend.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529228",
        "System": "944",
        "SubSystem": null,
        "PageCode": "312",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100312",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Caravan"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_caravan.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6472",
        "GSubSort": "6472",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529228",
        "RTP": "96.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_caravan.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529225",
        "System": "944",
        "SubSystem": null,
        "PageCode": "246",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100246",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Trophy 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gold_trophy_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6471",
        "GSubSort": "6471",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "67332.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529225",
        "RTP": "96.8800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gold_trophy_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529222",
        "System": "944",
        "SubSystem": null,
        "PageCode": "341",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100341",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gold_king.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6470",
        "GSubSort": "6470",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529222",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gold_king.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529213",
        "System": "944",
        "SubSystem": null,
        "PageCode": "286",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100286",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gemix"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gemix.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6466",
        "GSubSort": "6466",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "4557.30",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529213",
        "RTP": "96.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gemix.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529210",
        "System": "944",
        "SubSystem": null,
        "PageCode": "337",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100337",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fu Er Dai"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fu_er_dai.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6465",
        "GSubSort": "6465",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529210",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fu_er_dai.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529183",
        "System": "944",
        "SubSystem": null,
        "PageCode": "53",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100053",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "European BlackJack MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/european_blackjack_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6461",
        "GSubSort": "6461",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529183",
        "RTP": "99.3400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/european_blackjack_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529195",
        "System": "944",
        "SubSystem": null,
        "PageCode": "316",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100316",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Flying Pigs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/flying_pigs.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6461",
        "GSubSort": "6461",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "1681.25",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529195",
        "RTP": "93.7300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/flying_pigs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529198",
        "System": "944",
        "SubSystem": null,
        "PageCode": "196",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100196",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Teller"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fortune_teller.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6460",
        "GSubSort": "6460",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10657.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529198",
        "RTP": "94.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fortune_teller.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529174",
        "System": "944",
        "SubSystem": null,
        "PageCode": "225",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100225",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Enchanted Meadow"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/enchanted_meadow.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6458",
        "GSubSort": "6458",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "7.50",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529174",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/enchanted_meadow.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529177",
        "System": "944",
        "SubSystem": null,
        "PageCode": "262",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100262",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Energoonz"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/energoonz.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6455",
        "GSubSort": "6455",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "18566.15",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529177",
        "RTP": "96.6900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/energoonz.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529453",
        "System": "944",
        "SubSystem": null,
        "PageCode": "11",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100011",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mini Baccarat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/mini_baccarat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6454",
        "GSubSort": "6454",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1364"
        ],
        "SortPerCategory": {
            "7": 0,
            "1364": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529453",
        "RTP": "98.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/mini_baccarat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529171",
        "System": "944",
        "SubSystem": null,
        "PageCode": "259",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100259",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Enchanted Crystals"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/enchanted_crystals.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6453",
        "GSubSort": "6453",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "68308.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529171",
        "RTP": "96.7200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/enchanted_crystals.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529168",
        "System": "944",
        "SubSystem": null,
        "PageCode": "293",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100293",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Easter Eggs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/easter_eggs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6452",
        "GSubSort": "6452",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6568.80",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529168",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/easter_eggs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529165",
        "System": "944",
        "SubSystem": null,
        "PageCode": "238",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100238",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Ship"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/dragon_ship.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6451",
        "GSubSort": "6451",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "8032.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529165",
        "RTP": "94.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/dragon_ship.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529162",
        "System": "944",
        "SubSystem": null,
        "PageCode": "355",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100355",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Maiden"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/dragon_maiden.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6450",
        "GSubSort": "6450",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529162",
        "RTP": "96.4900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/dragon_maiden.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529438",
        "System": "944",
        "SubSystem": null,
        "PageCode": "315",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100315",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun goes to Hell"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/leprechaun_goes_to_hell.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6449",
        "GSubSort": "6449",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "10000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529438",
        "RTP": "96.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/leprechaun_goes_to_hell.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529126",
        "System": "944",
        "SubSystem": null,
        "PageCode": "317",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100317",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bugs Party"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/bugs_party.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6446",
        "GSubSort": "6446",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "22"
        ],
        "SortPerCategory": {
            "22": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "80.00",
        "MaxMultiplier": "4620.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529126",
        "RTP": "94.2400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/bugs_party.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529129",
        "System": "944",
        "SubSystem": null,
        "PageCode": "57",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100057",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Casino Hold'em"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/casino_holdem.jpg",
        "Branded": "1",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6446",
        "GSubSort": "6446",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "152.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529129",
        "RTP": "97.8400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/casino_holdem.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529144",
        "System": "944",
        "SubSystem": null,
        "PageCode": "194",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100194",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cops'n'Robbers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/copsnrobbers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6445",
        "GSubSort": "6445",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "29166.67",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529144",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/copsnrobbers.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529135",
        "System": "944",
        "SubSystem": null,
        "PageCode": "193",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100193",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cats and Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cats_and_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6444",
        "GSubSort": "6444",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1153.87",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529135",
        "RTP": "96.0700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cats_and_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529138",
        "System": "944",
        "SubSystem": null,
        "PageCode": "277",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100277",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chinese New Year"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/chinese_new_year.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6444",
        "GSubSort": "6444",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "22725.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529138",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/chinese_new_year.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529141",
        "System": "944",
        "SubSystem": null,
        "PageCode": "300",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100300",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cloud Quest"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/cloud_quest.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6444",
        "GSubSort": "6444",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529141",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/cloud_quest.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529381",
        "System": "944",
        "SubSystem": null,
        "PageCode": "276",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100276",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jackpot Poker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jackpot_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6443",
        "GSubSort": "6443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529381",
        "RTP": "95.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jackpot_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529417",
        "System": "944",
        "SubSystem": null,
        "PageCode": "271",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100271",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/joker_poker_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6442",
        "GSubSort": "6442",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "31.25",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529417",
        "RTP": "95.0100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/joker_poker_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529120",
        "System": "944",
        "SubSystem": null,
        "PageCode": "52",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100052",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "BlackJack Multihand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/blackjack_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6440",
        "GSubSort": "6440",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529120",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/blackjack_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529384",
        "System": "944",
        "SubSystem": null,
        "PageCode": "269",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100269",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/jacks_or_better_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6439",
        "GSubSort": "6439",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "31.25",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529384",
        "RTP": "95.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/jacks_or_better_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529117",
        "System": "944",
        "SubSystem": null,
        "PageCode": "342",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100342",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Big Win Cat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/big_win_cat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6438",
        "GSubSort": "6438",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529117",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/big_win_cat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529246",
        "System": "944",
        "SubSystem": null,
        "PageCode": "368",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100368",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gunslinger: Reloaded"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gunslinger_reloaded.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6428",
        "GSubSort": "6428",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529246",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gunslinger_reloaded.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528424",
        "System": "944",
        "SubSystem": null,
        "PageCode": "356",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100356",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Battle Royal"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/battle_royal.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6422",
        "GSubSort": "6422",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528424",
        "RTP": "96.5600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/battle_royal.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529219",
        "System": "944",
        "SubSystem": null,
        "PageCode": "107",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100107",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gift Shop"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/gift_shop.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6421",
        "GSubSort": "6421",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "0.75",
        "MaxMultiplier": "600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529219",
        "RTP": "94.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/gift_shop.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529231",
        "System": "944",
        "SubSystem": null,
        "PageCode": "108",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100108",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Goal"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/golden_goal.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6420",
        "GSubSort": "6420",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "4:3",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "0.75",
        "MaxMultiplier": "600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529231",
        "RTP": "94.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/golden_goal.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528418",
        "System": "944",
        "SubSystem": null,
        "PageCode": "349",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100349",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Baker's Treat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/bakers_treat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6419",
        "GSubSort": "6419",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528418",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/bakers_treat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528421",
        "System": "944",
        "SubSystem": null,
        "PageCode": "347",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100347",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Banana Rock"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/banana_rock.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6419",
        "GSubSort": "6419",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528421",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/banana_rock.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528391",
        "System": "944",
        "SubSystem": null,
        "PageCode": "332",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100332",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Warrior Princess"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/aztec_warrior_princess.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6418",
        "GSubSort": "6418",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10657.50",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528391",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/aztec_warrior_princess.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529207",
        "System": "944",
        "SubSystem": null,
        "PageCode": "43",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100043",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fruit Bonanza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/fruit_bonanza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6417",
        "GSubSort": "6417",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "4.50",
        "MaxMultiplier": "5566.67",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529207",
        "RTP": "93.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/fruit_bonanza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528385",
        "System": "944",
        "SubSystem": null,
        "PageCode": "243",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100243",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Idols"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/aztec_idols.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6416",
        "GSubSort": "6416",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "22725.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528385",
        "RTP": "96.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/aztec_idols.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529159",
        "System": "944",
        "SubSystem": null,
        "PageCode": "54",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100054",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Exposure BlackJack MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/double_exposure_blackjack_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6415",
        "GSubSort": "6415",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529159",
        "RTP": "99.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/double_exposure_blackjack_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528379",
        "System": "944",
        "SubSystem": null,
        "PageCode": "321",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100321",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "7 Sins"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/7_sins.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6414",
        "GSubSort": "6414",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1580.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528379",
        "RTP": "96.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/7_sins.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528382",
        "System": "944",
        "SubSystem": null,
        "PageCode": "99",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100099",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ace of Spades"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/ace_of_spades.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6413",
        "GSubSort": "6413",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "833.33",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528382",
        "RTP": "95.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/ace_of_spades.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1528376",
        "System": "944",
        "SubSystem": null,
        "PageCode": "199",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100199",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5x Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/5x_magic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6412",
        "GSubSort": "6412",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "1050.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1528376",
        "RTP": "95.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/5x_magic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529435",
        "System": "944",
        "SubSystem": null,
        "PageCode": "251",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100251",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun goes Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/leprechaun_goes_egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6410",
        "GSubSort": "6410",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6568.80",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529435",
        "RTP": "96.7500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/leprechaun_goes_egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529153",
        "System": "944",
        "SubSystem": null,
        "PageCode": "270",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100270",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild MH"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/deuces_wild_mh.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6401",
        "GSubSort": "6401",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "31.25",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529153",
        "RTP": "94.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/deuces_wild_mh.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529132",
        "System": "944",
        "SubSystem": null,
        "PageCode": "35",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100035",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Casino Stud Poker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/casino_stud_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6400",
        "GSubSort": "6400",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1368"
        ],
        "SortPerCategory": {
            "7": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "202.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529132",
        "RTP": "94.7800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/casino_stud_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529114",
        "System": "944",
        "SubSystem": null,
        "PageCode": "105",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100105",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bell of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/bell_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "6389",
        "GSubSort": "6389",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "0.75",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529114",
        "RTP": "94.1200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/bell_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1525271",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs18mashang",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs18mashang",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure Horse"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/treasure_horse.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6379",
        "GSubSort": "6379",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1525271",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/treasure_horse.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1529189",
        "System": "944",
        "SubSystem": null,
        "PageCode": "297",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100297",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Eye of the Kraken"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/eye_of_the_kraken.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6371",
        "GSubSort": "6371",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529189",
        "RTP": "96.4200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/eye_of_the_kraken.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1529147",
        "System": "944",
        "SubSystem": null,
        "PageCode": "284",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "100284",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Crazy Cows"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/playngo/crazy_cows.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6362",
        "GSubSort": "6362",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Play'n GO",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.75",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1529147",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/playngo/crazy_cows.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "1704"
    },
    {
        "ID": "1524523",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50safariking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50safariking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Safari King"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50safariking.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6290",
        "GSubSort": "6290",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1524523",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50safariking.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1478210",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSantasVillage",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSantasVillage",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Santa's Village"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/santas_village.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6239",
        "GSubSort": "6239",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "4",
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "4": 0,
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "71de3f3a-8e99-40de-8efb-60bc0e0b88e5",
        "MobileExternalCode": "71de3f3a-8e99-40de-8efb-60bc0e0b88e5",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "8150.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478210",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/santas_village.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1499118",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25davinci",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25davinci",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Da Vinci’s Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25davinci.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6199",
        "GSubSort": "6199",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "48000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1499118",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25davinci.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1509646",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGJump",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGJump",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jump!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jump.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6188",
        "GSubSort": "6188",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "dc5c2de2-c653-4801-a027-80d52f6c0a5c",
        "MobileExternalCode": "dc5c2de2-c653-4801-a027-80d52f6c0a5c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "36000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1509646",
        "RTP": "98.1300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jump.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1483710",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20rhino",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20rhino",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Great Rhino"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20rhino.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6187",
        "GSubSort": "6187",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "900.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483710",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20rhino.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483713",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5joker",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5joker",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker's Jewels"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs5joker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6155",
        "GSubSort": "6155",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "1040.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483713",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs5joker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1476884",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGEgyptianDreams",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGEgyptianDreams",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Egyptian Dreams"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/egyptian_dreams.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6142",
        "GSubSort": "6142",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "5a7bedbf-6c14-46c9-b341-4d63d4bb7e5c",
        "MobileExternalCode": "5a7bedbf-6c14-46c9-b341-4d63d4bb7e5c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "134400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476884",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/egyptian_dreams.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1496406",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25peking",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25peking",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Peking Luck"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25peking.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6080",
        "GSubSort": "6080",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "180000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1496406",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25peking.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483722",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25chilli",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25chilli",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Chilli Heat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25chilli.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "6023",
        "GSubSort": "6023",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16",
            "3534"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0,
            "3534": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2512.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483722",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25chilli.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1506697",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9chen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9chen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Master Chen’s Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/master_chens_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5947",
        "GSubSort": "5947",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "45.00",
        "MaxMultiplier": "3600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1506697",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/master_chens_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483740",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25goldrush",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25goldrush",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Rush"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25goldrush.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5941",
        "GSubSort": "5941",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "161.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483740",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25goldrush.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483749",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs7pigs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs7pigs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "7 piggies"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs7pigs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5924",
        "GSubSort": "5924",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.07",
        "MaxBetDefault": "70.00",
        "MaxMultiplier": "39000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483749",
        "RTP": "95.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs7pigs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1506064",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGEgyptianDreamsDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGEgyptianDreamsDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Egyptian Dreams Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/egyptian_dreams_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5906",
        "GSubSort": "5906",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "640660c9-8d37-4d86-a694-7ccf46c9ed61",
        "MobileExternalCode": "640660c9-8d37-4d86-a694-7ccf46c9ed61",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "54055.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1506064",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/egyptian_dreams_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1483797",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25dragonkingdom",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25dragonkingdom",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Kingdom"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25dragonkingdom.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5886",
        "GSubSort": "5886",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483797",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25dragonkingdom.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483803",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50aladdin",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50aladdin",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Genie Wishes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50aladdin.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5885",
        "GSubSort": "5885",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "519.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483803",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50aladdin.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1521241",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHappiestChristmasTree",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHappiestChristmasTree",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Happiest Christmas Tree"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/happiest_christmas_tree.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5864",
        "GSubSort": "5864",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "9fb38726-6500-4710-bc96-b1055154189f",
        "MobileExternalCode": "9fb38726-6500-4710-bc96-b1055154189f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "50225.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1521241",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/happiest_christmas_tree.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1483752",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs15diamond",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs15diamond",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamond Strike"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs15diamond.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5827",
        "GSubSort": "5827",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "1246.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483752",
        "RTP": "97.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs15diamond.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483833",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25safari",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25safari",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Safari"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25safari.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5825",
        "GSubSort": "5825",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483833",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25safari.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1493091",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25asgard",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25asgard",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Asgard"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25asgard.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5816",
        "GSubSort": "5816",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1493091",
        "RTP": "96.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25asgard.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483758",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25wildspells",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25wildspells",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wild Spells"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25wildspells.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "5815",
        "GSubSort": "5815",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1450.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483758",
        "RTP": "96.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25wildspells.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1482936",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFortuneDogs",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFortuneDogs",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fortune Dogs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/fortune_dogs.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "5738",
        "GSubSort": "5738",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": "bf431909-da6d-4f1a-a278-4e34495dd7ae",
        "MobileExternalCode": "bf431909-da6d-4f1a-a278-4e34495dd7ae",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.18",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "180000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1482936",
        "RTP": "96.6800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/fortune_dogs.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478351",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFourDivineBeasts",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFourDivineBeasts",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Four Divine Beasts"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/four_divine_beasts.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "4697",
        "GSubSort": "4697",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f05ba1a5-00c0-4d2f-ac0c-03625935f5fa",
        "MobileExternalCode": "f05ba1a5-00c0-4d2f-ac0c-03625935f5fa",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "54000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478351",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/four_divine_beasts.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478307",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SG5Mariachis",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SG5Mariachis",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Mariachis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/5_mariachis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3898",
        "GSubSort": "3898",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "7bd73b27-d6ed-4475-a7f3-d9cd48dbe952",
        "MobileExternalCode": "7bd73b27-d6ed-4475-a7f3-d9cd48dbe952",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "25640.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478307",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/5_mariachis.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477804",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGScruffyScallywags",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGScruffyScallywags",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Scruffy Scallywags"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/scruffy_scallywags.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3876",
        "GSubSort": "3876",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "66911426-0a5a-445b-8e7e-3f4e7e9f5f25",
        "MobileExternalCode": "66911426-0a5a-445b-8e7e-3f4e7e9f5f25",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "8150.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477804",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/scruffy_scallywags.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478316",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRollingRoger",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRollingRoger",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rolling Roger"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/rolling_roger.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3867",
        "GSubSort": "3867",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": "0d36c36c-d957-4319-8cc7-4a5d7046047f",
        "MobileExternalCode": "0d36c36c-d957-4319-8cc7-4a5d7046047f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "13350.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478316",
        "RTP": "96.6700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/rolling_roger.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1487316",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TGBlackjackAmerican",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TGBlackjackAmerican",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "American Blackjack"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/american_blackjack.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3863",
        "GSubSort": "3863",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "41"
        ],
        "SortPerCategory": {
            "41": 0
        },
        "ExternalCode": "5f672cec-dfd3-4b1b-9fa3-a569c17c5c39",
        "MobileExternalCode": "5f672cec-dfd3-4b1b-9fa3-a569c17c5c39",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "1000.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1487316",
        "RTP": "99.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/american_blackjack.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476851",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGAzlandsGold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGAzlandsGold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztlan's Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/azlands_gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3861",
        "GSubSort": "3861",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "597c145d-adc8-409f-9185-d9af49931af7",
        "MobileExternalCode": "597c145d-adc8-409f-9185-d9af49931af7",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "481280.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476851",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/azlands_gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476998",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGZeus2",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGZeus2",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Zeus 2"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/zeus_2.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3860",
        "GSubSort": "3860",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "bd9aeda3-2fb0-45f8-a9e5-9a05806c2539",
        "MobileExternalCode": "bd9aeda3-2fb0-45f8-a9e5-9a05806c2539",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "12500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476998",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/zeus_2.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476893",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGoldenUnicorn",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGoldenUnicorn",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Golden Unicorn"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/golden_unicorn.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3856",
        "GSubSort": "3856",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "67eb42d5-1d50-44ae-afd6-773e0f75ee71",
        "MobileExternalCode": "67eb42d5-1d50-44ae-afd6-773e0f75ee71",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "51897.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476893",
        "RTP": "96.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/golden_unicorn.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478346",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TGDragonTiger",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TGDragonTiger",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Tiger"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/dragon_tiger.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3854",
        "GSubSort": "3854",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "2db6df8b-06d1-48d5-9b31-018135e5bb58",
        "MobileExternalCode": "2db6df8b-06d1-48d5-9b31-018135e5bb58",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "2500.00",
        "MaxMultiplier": "13.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478346",
        "RTP": "96.2700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/dragon_tiger.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476845",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGArcticWonders",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGArcticWonders",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Arctic Wonders"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/arctic_wonders.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3838",
        "GSubSort": "3838",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "66d92f52-2b49-435c-988e-c491e02ef95d",
        "MobileExternalCode": "66d92f52-2b49-435c-988e-c491e02ef95d",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "500000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476845",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/arctic_wonders.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477181",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TGWar",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TGWar",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "War"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/war.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3829",
        "GSubSort": "3829",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "bda99cec-80a8-4b30-a599-b23dc03f5ed7",
        "MobileExternalCode": "bda99cec-80a8-4b30-a599-b23dc03f5ed7",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "10.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477181",
        "RTP": "97.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/war.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478532",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGLondonHunter",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGLondonHunter",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "London Hunter"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/london_hunter.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3795",
        "GSubSort": "3795",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "bf1f2857-7b9b-46c9-bc5e-5e8dd20dbe9b",
        "MobileExternalCode": "bf1f2857-7b9b-46c9-bc5e-5e8dd20dbe9b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "24960.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478532",
        "RTP": "97.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/london_hunter.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477178",
        "System": "976",
        "SubSystem": null,
        "PageCode": "Baccarat3HZC",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "Baccarat3HZC",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "American Baccarat Zero Commission"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/baccarat_zero_commission.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3721",
        "GSubSort": "3721",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1364"
        ],
        "SortPerCategory": {
            "7": 0,
            "1364": 0
        },
        "ExternalCode": "d46a31ba-b542-451a-80d8-ddfcadd7e80e",
        "MobileExternalCode": "d46a31ba-b542-451a-80d8-ddfcadd7e80e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "8.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477178",
        "RTP": "0.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/baccarat_zero_commission.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477070",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusPoker1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusPoker1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Poker 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3707",
        "GSubSort": "3707",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10"
        ],
        "SortPerCategory": {
            "10": 0
        },
        "ExternalCode": "54ab7ce7-0067-4cb6-a0ec-0f8877b75831",
        "MobileExternalCode": "54ab7ce7-0067-4cb6-a0ec-0f8877b75831",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477070",
        "RTP": "99.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1513561",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TGThreeCardPokerDeluxe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TGThreeCardPokerDeluxe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Three Card Poker Deluxe",
            "id": "Three Card Poker Deluxe",
            "de": "Three Card Poker Deluxe",
            "es": "Three Card Poker Deluxe",
            "nl": "Three Card Poker Deluxe",
            "fr": "Three Card Poker Deluxe",
            "it": "Three Card Poker Deluxe",
            "no": "Three Card Poker Deluxe",
            "pt": "Three Card Poker Deluxe",
            "fi": "Three Card Poker Deluxe",
            "sv": "Three Card Poker Deluxe",
            "vi": "Three Card Poker Deluxe",
            "tr": "Three Card Poker Deluxe",
            "gr": "Three Card Poker Deluxe",
            "ru": "Three Card Poker Deluxe",
            "th": "Three Card Poker Deluxe",
            "ja": "Three Card Poker Deluxe",
            "zh-hant": "Three Card Poker Deluxe",
            "zh-hans": "Three Card Poker Deluxe",
            "ko": "Three Card Poker Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/three_card_poker_deluxe.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3687",
        "GSubSort": "3687",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "74ca8c2d-d261-4a03-bc8f-e0ebfc844696",
        "MobileExternalCode": "74ca8c2d-d261-4a03-bc8f-e0ebfc844696",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1513561",
        "RTP": "98.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/three_card_poker_deluxe.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477004",
        "System": "976",
        "SubSystem": null,
        "PageCode": "CaribbeanStud",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "CaribbeanStud",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Caribbean Stud"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/carribean_stud.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3685",
        "GSubSort": "3685",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "87860aaf-fca0-4053-b293-f53786a2f03b",
        "MobileExternalCode": "87860aaf-fca0-4053-b293-f53786a2f03b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477004",
        "RTP": "97.4800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/carribean_stud.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476995",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGZeus",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGZeus",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Zeus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/zeus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3683",
        "GSubSort": "3683",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "37c5372c-dfcb-4241-990b-8389e48ab700",
        "MobileExternalCode": "37c5372c-dfcb-4241-990b-8389e48ab700",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.88",
        "MaxBetDefault": "88.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476995",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/zeus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476992",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWeirdScience",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWeirdScience",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Weird Science"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/weird_science.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3682",
        "GSubSort": "3682",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "73c3a413-9eb9-4c8f-8f13-6b30cb3918a2",
        "MobileExternalCode": "73c3a413-9eb9-4c8f-8f13-6b30cb3918a2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "135327.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476992",
        "RTP": "95.8700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/weird_science.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476986",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTreasureTomb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTreasureTomb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure Tomb"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/treasure_tomb.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3681",
        "GSubSort": "3681",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "a68709f5-6536-4a36-b351-2f343c5d9fbf",
        "MobileExternalCode": "a68709f5-6536-4a36-b351-2f343c5d9fbf",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "30000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476986",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/treasure_tomb.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476989",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGVikingsPlunder",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGVikingsPlunder",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Viking's Plunder"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/vikings_plunder.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3681",
        "GSubSort": "3681",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "8cfed522-7d76-4b11-8da7-c3540fdc04a0",
        "MobileExternalCode": "8cfed522-7d76-4b11-8da7-c3540fdc04a0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "46725.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476989",
        "RTP": "96.1300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/vikings_plunder.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1498944",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWaysOfFortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWaysOfFortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ways Of Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/ways_of_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3680",
        "GSubSort": "3680",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "0231c069-0f4a-4e6c-a6e6-6ecb01265d91",
        "MobileExternalCode": "0231c069-0f4a-4e6c-a6e6-6ecb01265d91",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.28",
        "MaxBetDefault": "140.00",
        "MaxMultiplier": "15105.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1498944",
        "RTP": "93.9400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/ways_of_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476980",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTowerOfPizza",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTowerOfPizza",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tower Of Pizza"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tower_of_pizza.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3679",
        "GSubSort": "3679",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "dad21f68-7514-41e0-aaee-b74dbf8910af",
        "MobileExternalCode": "dad21f68-7514-41e0-aaee-b74dbf8910af",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "138435.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476980",
        "RTP": "96.4100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tower_of_pizza.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476983",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTreasureDiver",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTreasureDiver",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Treasure Diver"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/treasure_diver.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3679",
        "GSubSort": "3679",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "0b7a8d68-1d0c-485d-96f9-24c7817ecc0b",
        "MobileExternalCode": "0b7a8d68-1d0c-485d-96f9-24c7817ecc0b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "32550.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476983",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/treasure_diver.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476824",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBombsAway",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBombsAway",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bombs Away"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bombs_away.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3678",
        "GSubSort": "3678",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "55b6a7be-bbdc-40da-8173-d758497a47c4",
        "MobileExternalCode": "55b6a7be-bbdc-40da-8173-d758497a47c4",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476824",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bombs_away.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1489599",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGKnockoutFootball",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGKnockoutFootball",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Knockout Football"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/knockout_football.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3677",
        "GSubSort": "3677",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "153a2ac1-2970-45a3-be8a-3a3428eae069",
        "MobileExternalCode": "153a2ac1-2970-45a3-be8a-3a3428eae069",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "22590.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1489599",
        "RTP": "96.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/knockout_football.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476971",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSuperStrike",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSuperStrike",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Strike"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/super_strike.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3676",
        "GSubSort": "3676",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "64250f1b-8a99-4eac-902e-1e132c8114cb",
        "MobileExternalCode": "64250f1b-8a99-4eac-902e-1e132c8114cb",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "55380.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476971",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/super_strike.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476974",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTheBigDeal",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTheBigDeal",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Big Deal"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/the_big_deal.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3676",
        "GSubSort": "3676",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ed17c99f-20a9-4f63-a0e2-cb8bfa9f19bf",
        "MobileExternalCode": "ed17c99f-20a9-4f63-a0e2-cb8bfa9f19bf",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "25920.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476974",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/the_big_deal.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1530413",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHotHotFruit",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHotHotFruit",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hot Hot Fruit"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/hot_hot_fruit.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3675",
        "GSubSort": "3675",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "da81c733-5953-4795-a03f-5fccac6b80e0",
        "MobileExternalCode": "da81c733-5953-4795-a03f-5fccac6b80e0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "76300.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1530413",
        "RTP": "96.7000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/hot_hot_fruit.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476968",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSpaceFortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSpaceFortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Space Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/space_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3674",
        "GSubSort": "3674",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ef421354-4af0-4ebd-b3ad-6a87a116fca2",
        "MobileExternalCode": "ef421354-4af0-4ebd-b3ad-6a87a116fca2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "53636.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476968",
        "RTP": "95.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/space_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476965",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSOS",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSOS",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "S.O.S!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/sos.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3673",
        "GSubSort": "3673",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "5b4cae1b-bf31-4994-8cd9-d0b8252f4142",
        "MobileExternalCode": "5b4cae1b-bf31-4994-8cd9-d0b8252f4142",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "37500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476965",
        "RTP": "96.1800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/sos.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476962",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSkysTheLimit",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSkysTheLimit",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sky's the Limit"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/skys_the_limit.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3672",
        "GSubSort": "3672",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "dc966efb-ada0-429b-86e4-5db694936703",
        "MobileExternalCode": "dc966efb-ada0-429b-86e4-5db694936703",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "44896.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476962",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/skys_the_limit.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476956",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGShogunsLand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGShogunsLand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shogun's Land"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/shoguns_land.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3671",
        "GSubSort": "3671",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "b304e32c-cd0c-455a-845e-dda557b37de8",
        "MobileExternalCode": "b304e32c-cd0c-455a-845e-dda557b37de8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "432336.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476956",
        "RTP": "94.4000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/shoguns_land.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476959",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSirBlingalot",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSirBlingalot",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sir Blingalot"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/sir_blingalot.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3671",
        "GSubSort": "3671",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "96fb5355-e499-4975-874d-93c2b3972932",
        "MobileExternalCode": "96fb5355-e499-4975-874d-93c2b3972932",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "72750.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476959",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/sir_blingalot.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476953",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGShaolinFortunes243",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGShaolinFortunes243",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shaolin Fortunes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/shaolin_fortunes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3670",
        "GSubSort": "3670",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "308aa892-ee67-4ce1-8dd2-de2ade4a70d4",
        "MobileExternalCode": "308aa892-ee67-4ce1-8dd2-de2ade4a70d4",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "55500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476953",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/shaolin_fortunes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476950",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGShaolinFortunes100",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGShaolinFortunes100",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Shaolin Fortunes 100"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/shaolin_fortunes_100.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3669",
        "GSubSort": "3669",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ab0537ca-5090-4cd0-a7f2-868cc29ccf63",
        "MobileExternalCode": "ab0537ca-5090-4cd0-a7f2-868cc29ccf63",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "1.00",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "100000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476950",
        "RTP": "96.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/shaolin_fortunes_100.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476944",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRideEmCowboy",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRideEmCowboy",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ride 'em Cowboy"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/ride_em_cowboy.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3667",
        "GSubSort": "3667",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "8bdfccd7-9aa8-46bf-ae9a-3c8d0addef3d",
        "MobileExternalCode": "8bdfccd7-9aa8-46bf-ae9a-3c8d0addef3d",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "181410.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476944",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/ride_em_cowboy.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476947",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRodeoDrive",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRodeoDrive",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Rodeo Drive"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/rodeo_drive.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3667",
        "GSubSort": "3667",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "6875dd7d-8415-47b6-8ecd-3e51284543b3",
        "MobileExternalCode": "6875dd7d-8415-47b6-8ecd-3e51284543b3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "150400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476947",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/rodeo_drive.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476941",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGQueenOfQueens243",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGQueenOfQueens243",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen of Queens"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/queen_of_queens_243.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3666",
        "GSubSort": "3666",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "879f5675-7d8e-49c3-bcd1-3cf6a095e969",
        "MobileExternalCode": "879f5675-7d8e-49c3-bcd1-3cf6a095e969",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "36000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476941",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/queen_of_queens_243.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476938",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGQueenOfQueens1024",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGQueenOfQueens1024",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen of Queens II"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/queen_of_queens_1024.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3665",
        "GSubSort": "3665",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "733d78c1-de76-43fd-9818-387027b65deb",
        "MobileExternalCode": "733d78c1-de76-43fd-9818-387027b65deb",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "86400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476938",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/queen_of_queens_1024.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476926",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPamperMe",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPamperMe",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pamper Me"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/pamper_me.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3663",
        "GSubSort": "3663",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f9014ef4-6468-4b9b-947e-cc3d6f3f01d0",
        "MobileExternalCode": "f9014ef4-6468-4b9b-947e-cc3d6f3f01d0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "18780.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476926",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/pamper_me.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476929",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPiratesPlunder",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPiratesPlunder",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pirate's Plunder"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/pirates_plunder.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3663",
        "GSubSort": "3663",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "514f0711-4186-4a10-a889-102d893809a6",
        "MobileExternalCode": "514f0711-4186-4a10-a889-102d893809a6",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "313890.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476929",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/pirates_plunder.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476932",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPoolShark",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPoolShark",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pool Shark"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/pool_shark.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3663",
        "GSubSort": "3663",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "008ac545-bcbe-4342-9062-e9bb76d04531",
        "MobileExternalCode": "008ac545-bcbe-4342-9062-e9bb76d04531",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "313700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476932",
        "RTP": "95.8400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/pool_shark.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476935",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPuckerUpPrince",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPuckerUpPrince",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pucker Up Prince"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/pucker_up_prince.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3663",
        "GSubSort": "3663",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ed98565a-f017-49a0-a242-f77c68f15c62",
        "MobileExternalCode": "ed98565a-f017-49a0-a242-f77c68f15c62",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "90090.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476935",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/pucker_up_prince.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1478784",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGPresto",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGPresto",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Presto!"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/presto.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3662",
        "GSubSort": "3662",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f18ccbb0-acf7-4114-a0b2-c243110eef53",
        "MobileExternalCode": "f18ccbb0-acf7-4114-a0b2-c243110eef53",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "48600.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1478784",
        "RTP": "96.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/presto.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476920",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMummyMoney",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMummyMoney",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mummy Money"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/mummy_money.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3659",
        "GSubSort": "3659",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "2b187121-fccd-4b26-ad66-22c272bfb65f",
        "MobileExternalCode": "2b187121-fccd-4b26-ad66-22c272bfb65f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "37500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476920",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/mummy_money.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476923",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMysticFortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMysticFortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mystic Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/mystic_fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3659",
        "GSubSort": "3659",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "1157c77e-68dc-4dde-a7a7-c447963ff38a",
        "MobileExternalCode": "1157c77e-68dc-4dde-a7a7-c447963ff38a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "48684.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476923",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/mystic_fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476869",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCashReef",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCashReef",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cash Reef"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/cash_reef.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e9bd3bdd-8227-4909-b3c7-8660cf6d0aae",
        "MobileExternalCode": "e9bd3bdd-8227-4909-b3c7-8660cf6d0aae",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "150.00",
        "MaxMultiplier": "132840.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476869",
        "RTP": "96.1600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/cash_reef.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476878",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDragonsRealm",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDragonsRealm",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon's Realm"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/dragons_realm.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "28a268f6-94c1-4757-8089-5eee0f51a1d9",
        "MobileExternalCode": "28a268f6-94c1-4757-8089-5eee0f51a1d9",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "548670.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476878",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/dragons_realm.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476881",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDrFeelgood",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDrFeelgood",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dr Feelgood"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/dr_feel_good.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "b7750308-b4f4-4ddc-8a93-5e3edb6bb953",
        "MobileExternalCode": "b7750308-b4f4-4ddc-8a93-5e3edb6bb953",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "50336.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476881",
        "RTP": "96.0200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/dr_feel_good.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476887",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFlyingHigh",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFlyingHigh",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Flying High"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/flying_high.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "a813cd2a-924b-401d-b485-d97cb4c3231b",
        "MobileExternalCode": "a813cd2a-924b-401d-b485-d97cb4c3231b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "45000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476887",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/flying_high.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476896",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGrapeEscape",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGrapeEscape",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Grape Escape"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/grape_escape.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "198b9e82-316b-4330-95a7-fe53de52adab",
        "MobileExternalCode": "198b9e82-316b-4330-95a7-fe53de52adab",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "33105.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476896",
        "RTP": "95.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/grape_escape.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476899",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGHauntedHouse",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGHauntedHouse",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Haunted House"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/haunted_house.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "227587e0-4999-4276-9d47-ef57f01fbb0e",
        "MobileExternalCode": "227587e0-4999-4276-9d47-ef57f01fbb0e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "444840.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476899",
        "RTP": "96.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/haunted_house.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476905",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGJungleRumble",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGJungleRumble",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jungle Rumble"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jungle_rumble.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "28cf2318-2cbb-421d-87e4-8e5b4d2bbc95",
        "MobileExternalCode": "28cf2318-2cbb-421d-87e4-8e5b4d2bbc95",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "275025.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476905",
        "RTP": "95.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jungle_rumble.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476908",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGKingTutsTomb",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGKingTutsTomb",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "King Tut's Tomb"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/king_tuts_tomb.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e812baac-2781-4f40-a189-6e12c699876b",
        "MobileExternalCode": "e812baac-2781-4f40-a189-6e12c699876b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "13620.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476908",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/king_tuts_tomb.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476911",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGLittleGreenMoney",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGLittleGreenMoney",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Little Green Money"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/little_green_money.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "0db29009-f09e-48b4-8d02-94f7aa42b456",
        "MobileExternalCode": "0db29009-f09e-48b4-8d02-94f7aa42b456",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "500000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476911",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/little_green_money.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476914",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMonsterMashCash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMonsterMashCash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monster Mash Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/monster_mash_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "0f166506-6287-4767-bb3b-9a9eb97d7986",
        "MobileExternalCode": "0f166506-6287-4767-bb3b-9a9eb97d7986",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "224406.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476914",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/monster_mash_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476917",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGMrBling",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGMrBling",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mr Bling"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/mr_bling.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3657",
        "GSubSort": "3657",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "f2872e6c-77dd-4eb4-9335-2771ebcad785",
        "MobileExternalCode": "f2872e6c-77dd-4eb4-9335-2771ebcad785",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "252700.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476917",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/mr_bling.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476866",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCashosaurus",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCashosaurus",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Cashosaurus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/cashosaurus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3641",
        "GSubSort": "3641",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "3f2d0547-04fa-48d0-bfbc-6a9df274a6dc",
        "MobileExternalCode": "3f2d0547-04fa-48d0-bfbc-6a9df274a6dc",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "123330.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476866",
        "RTP": "95.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/cashosaurus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476863",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGCarnivalCash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGCarnivalCash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Carnival Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/carnival_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3640",
        "GSubSort": "3640",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "8abe0549-7549-4ef4-b531-f21cb8b69a41",
        "MobileExternalCode": "8abe0549-7549-4ef4-b531-f21cb8b69a41",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "62500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476863",
        "RTP": "95.9700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/carnival_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476854",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBarnstormerBucks",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBarnstormerBucks",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Barnstormer Bucks"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/barnstormer_bucks.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3639",
        "GSubSort": "3639",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "9b4c396a-7faa-4df3-aded-7a2a609fe8ee",
        "MobileExternalCode": "9b4c396a-7faa-4df3-aded-7a2a609fe8ee",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "47190.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476854",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/barnstormer_bucks.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476857",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBikiniIsland",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBikiniIsland",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bikini Island"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bikini_island.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3639",
        "GSubSort": "3639",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "c77fe6ef-3ed3-4d48-a1b4-33b05994d850",
        "MobileExternalCode": "c77fe6ef-3ed3-4d48-a1b4-33b05994d850",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "35010.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476857",
        "RTP": "96.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bikini_island.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476860",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBlackbeardsBounty",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBlackbeardsBounty",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blackbeard's Bounty"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/black_beards_bounty.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3639",
        "GSubSort": "3639",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "8f8dc688-7faa-43cf-8724-f9c5addf0a57",
        "MobileExternalCode": "8f8dc688-7faa-43cf-8724-f9c5addf0a57",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "93040.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476860",
        "RTP": "96.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/black_beards_bounty.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476833",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGGalacticCash",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGGalacticCash",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Galactic Cash"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/galactic_cash.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3632",
        "GSubSort": "3632",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "048cff58-a00e-48fc-a09b-90c002347220",
        "MobileExternalCode": "048cff58-a00e-48fc-a09b-90c002347220",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476833",
        "RTP": "95.9200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/galactic_cash.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476875",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDoubleODollars",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDoubleODollars",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double-O Dollars"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/doubleo_dollars.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3564",
        "GSubSort": "3564",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "c085ebb8-71ca-466e-a26d-97d357256769",
        "MobileExternalCode": "c085ebb8-71ca-466e-a26d-97d357256769",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "12000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476875",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/doubleo_dollars.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476872",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDiscoFunk",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDiscoFunk",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Disco Funk"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/disco_funk.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3563",
        "GSubSort": "3563",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "ea4a73da-711c-4a70-98aa-aee2eb5ef5c2",
        "MobileExternalCode": "ea4a73da-711c-4a70-98aa-aee2eb5ef5c2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "112635.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476872",
        "RTP": "96.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/disco_funk.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477157",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TensorBetter1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TensorBetter1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tens Or Better 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tens_or_better.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3557",
        "GSubSort": "3557",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "aff467c9-8bfb-4c88-894d-a678175b578e",
        "MobileExternalCode": "aff467c9-8bfb-4c88-894d-a678175b578e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477157",
        "RTP": "99.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tens_or_better.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476842",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGAllForOne",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGAllForOne",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All For One"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_for_one.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3554",
        "GSubSort": "3554",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "fa25d0b4-2d55-4e68-8abd-e9b74aa293ec",
        "MobileExternalCode": "fa25d0b4-2d55-4e68-8abd-e9b74aa293ec",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "214140.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476842",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_for_one.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476836",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGBuggyBonus",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGBuggyBonus",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Buggy Bonus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/buggy_bonus.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3552",
        "GSubSort": "3552",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "dbb7935e-582b-4431-a757-9d8a3817d923",
        "MobileExternalCode": "dbb7935e-582b-4431-a757-9d8a3817d923",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "12500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476836",
        "RTP": "96.1000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/buggy_bonus.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477142",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JokerPoker1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JokerPoker1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/joker_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3551",
        "GSubSort": "3551",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "2e2e8382-8e7c-4098-9566-a6e00636e625",
        "MobileExternalCode": "2e2e8382-8e7c-4098-9566-a6e00636e625",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477142",
        "RTP": "98.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/joker_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477127",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JacksorBetter1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JacksorBetter1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jacks_or_better.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3549",
        "GSubSort": "3549",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "58b40a09-af1c-4db0-a6aa-381f89c0b681",
        "MobileExternalCode": "58b40a09-af1c-4db0-a6aa-381f89c0b681",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477127",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jacks_or_better.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477112",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleDoubleBonusPoker1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleDoubleBonusPoker1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Double Bonus Poker 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_double_bonus_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3541",
        "GSubSort": "3541",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "71deed89-1ff8-4e42-b62e-e5f862166e0c",
        "MobileExternalCode": "71deed89-1ff8-4e42-b62e-e5f862166e0c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477112",
        "RTP": "98.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_double_bonus_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477097",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleBonusPoker1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleBonusPoker1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Bonus Poker 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_bonus_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3536",
        "GSubSort": "3536",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "6a685d75-a047-46c8-885f-05c928280b38",
        "MobileExternalCode": "6a685d75-a047-46c8-885f-05c928280b38",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477097",
        "RTP": "99.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_bonus_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477083",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DuecesWild1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DuecesWild1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/deuces_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3531",
        "GSubSort": "3531",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "73533891-129c-4a99-aef7-212ad66a75e3",
        "MobileExternalCode": "73533891-129c-4a99-aef7-212ad66a75e3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477083",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/deuces_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477079",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusPoker50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusPoker50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Poker 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3529",
        "GSubSort": "3529",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "d09ccf39-b87f-4a07-9384-5bdfb72ca776",
        "MobileExternalCode": "d09ccf39-b87f-4a07-9384-5bdfb72ca776",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477079",
        "RTP": "99.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477055",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusDuecesWild1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusDuecesWild1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Deuces Wild 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_deuces_wild.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3521",
        "GSubSort": "3521",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "b3e56fb8-630b-4abd-a9d9-1f24bad121a3",
        "MobileExternalCode": "b3e56fb8-630b-4abd-a9d9-1f24bad121a3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477055",
        "RTP": "99.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_deuces_wild.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477025",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AcesandEights1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AcesandEights1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aces & Eights 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/aces_and_eights.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3518",
        "GSubSort": "3518",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "ae7f0f2e-6b20-4b2d-a656-0481890dd5e6",
        "MobileExternalCode": "ae7f0f2e-6b20-4b2d-a656-0481890dd5e6",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477025",
        "RTP": "99.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/aces_and_eights.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477040",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AllAmericanPoker1Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AllAmericanPoker1Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All American Poker 1 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_american_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3517",
        "GSubSort": "3517",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "8adb996e-e5ef-444c-a49e-5dc105ff8dfe",
        "MobileExternalCode": "8adb996e-e5ef-444c-a49e-5dc105ff8dfe",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "20.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477040",
        "RTP": "99.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_american_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477160",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TensorBetter5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TensorBetter5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tens Or Better 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tens_or_better_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3508",
        "GSubSort": "3508",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "61cc41d8-8ac0-42db-8228-38481b49369d",
        "MobileExternalCode": "61cc41d8-8ac0-42db-8228-38481b49369d",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477160",
        "RTP": "99.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tens_or_better_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477163",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TensorBetter10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TensorBetter10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tens Or Better 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tens_or_better_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3506",
        "GSubSort": "3506",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "373f5d22-d9ac-463f-bc7c-f2b6ef7e182f",
        "MobileExternalCode": "373f5d22-d9ac-463f-bc7c-f2b6ef7e182f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477163",
        "RTP": "99.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tens_or_better_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477166",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TensorBetter50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TensorBetter50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tens Or Better 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tens_or_better_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3504",
        "GSubSort": "3504",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "5b5545f4-ff73-4b3a-8f41-b3dac6bfcdd0",
        "MobileExternalCode": "5b5545f4-ff73-4b3a-8f41-b3dac6bfcdd0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477166",
        "RTP": "99.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tens_or_better_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477019",
        "System": "976",
        "SubSystem": null,
        "PageCode": "Sicbo",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "Sicbo",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Sic Bo"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/sic_bo.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3501",
        "GSubSort": "3501",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "c68116cc-9bf4-46f0-9a62-4104bc4054d2",
        "MobileExternalCode": "c68116cc-9bf4-46f0-9a62-4104bc4054d2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "5.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477019",
        "RTP": "90.3000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/sic_bo.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477010",
        "System": "976",
        "SubSystem": null,
        "PageCode": "Blackjack3HDoubleExposure",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "Blackjack3HDoubleExposure",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blackjack Double Exposure 3 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/blackjack_3_hands_double_exposure.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3498",
        "GSubSort": "3498",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "1cd4d52c-e41e-4052-96b5-240b4f55d305",
        "MobileExternalCode": "1cd4d52c-e41e-4052-96b5-240b4f55d305",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477010",
        "RTP": "99.3400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/blackjack_3_hands_double_exposure.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477022",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TGThreeCardPoker",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TGThreeCardPoker",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Three Card Poker"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/3_card_poker.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3497",
        "GSubSort": "3497",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1368"
        ],
        "SortPerCategory": {
            "7": 0,
            "1368": 0
        },
        "ExternalCode": "6557fe94-6cd2-42b7-9ac3-3eff43b3b007",
        "MobileExternalCode": "6557fe94-6cd2-42b7-9ac3-3eff43b3b007",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "40.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477022",
        "RTP": "97.9900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/3_card_poker.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477016",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AmericanBaccarat",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AmericanBaccarat",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "American Baccarat"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/american_baccarat.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3496",
        "GSubSort": "3496",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1364"
        ],
        "SortPerCategory": {
            "7": 0,
            "1364": 0
        },
        "ExternalCode": "0677974b-a061-4564-9ebd-c826a0b8c4bc",
        "MobileExternalCode": "0677974b-a061-4564-9ebd-c826a0b8c4bc",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "8.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477016",
        "RTP": "98.8500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/american_baccarat.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477013",
        "System": "976",
        "SubSystem": null,
        "PageCode": "EURoulette",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "EURoulette",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "European Roulette"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/eu_roulette.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3495",
        "GSubSort": "3495",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7",
            "1366"
        ],
        "SortPerCategory": {
            "7": 0,
            "1366": 0
        },
        "ExternalCode": "b73dd7ec-0ec1-4885-8152-925b16711bc2",
        "MobileExternalCode": "b73dd7ec-0ec1-4885-8152-925b16711bc2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "2000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477013",
        "RTP": "98.6500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/eu_roulette.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477007",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BlackJack3H",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BlackJack3H",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Blackjack 3 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/blackjack_3_hands.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3494",
        "GSubSort": "3494",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "df2e0465-8acf-4c86-a4e4-aa6c3b4c4f84",
        "MobileExternalCode": "df2e0465-8acf-4c86-a4e4-aa6c3b4c4f84",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477007",
        "RTP": "99.5900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/blackjack_3_hands.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477001",
        "System": "976",
        "SubSystem": null,
        "PageCode": "CaribbeanHoldem",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "CaribbeanHoldem",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Caribbean Hold'em"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/caribbean_holdem.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3493",
        "GSubSort": "3493",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "7"
        ],
        "SortPerCategory": {
            "7": 0
        },
        "ExternalCode": "57cafeb6-3eea-4048-95e5-b3f51627b1b0",
        "MobileExternalCode": "57cafeb6-3eea-4048-95e5-b3f51627b1b0",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477001",
        "RTP": "96.8000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/caribbean_holdem.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477124",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleDoubleBonusPoker100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleDoubleBonusPoker100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Double Bonus Poker 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_double_bonus_poker_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3477",
        "GSubSort": "3477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "f83d116a-b4f9-4221-83da-c95b2855b7c8",
        "MobileExternalCode": "f83d116a-b4f9-4221-83da-c95b2855b7c8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477124",
        "RTP": "98.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_double_bonus_poker_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477145",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JokerPoker5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JokerPoker5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/joker_poker_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3477",
        "GSubSort": "3477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "75db0c89-20e2-47c1-bd5e-b4410f1c433b",
        "MobileExternalCode": "75db0c89-20e2-47c1-bd5e-b4410f1c433b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477145",
        "RTP": "98.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/joker_poker_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477148",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JokerPoker10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JokerPoker10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/joker_poker_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3477",
        "GSubSort": "3477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "d0ff71d8-1136-4d1f-acb5-22bce78c2fdc",
        "MobileExternalCode": "d0ff71d8-1136-4d1f-acb5-22bce78c2fdc",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477148",
        "RTP": "98.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/joker_poker_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477151",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JokerPoker50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JokerPoker50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/joker_poker_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3477",
        "GSubSort": "3477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "a5d27815-5faf-45d3-90c2-1bc9bd7ad9d3",
        "MobileExternalCode": "a5d27815-5faf-45d3-90c2-1bc9bd7ad9d3",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477151",
        "RTP": "98.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/joker_poker_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477154",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JokerPoker100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JokerPoker100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Joker Poker 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/joker_poker_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3477",
        "GSubSort": "3477",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "75151323-5068-4bd9-8c9b-7accd1672028",
        "MobileExternalCode": "75151323-5068-4bd9-8c9b-7accd1672028",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477154",
        "RTP": "98.6000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/joker_poker_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477136",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JacksorBetter50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JacksorBetter50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jacks_or_better_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3476",
        "GSubSort": "3476",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "c364dfd7-cf5b-40d5-b54e-046d6711b03a",
        "MobileExternalCode": "c364dfd7-cf5b-40d5-b54e-046d6711b03a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477136",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jacks_or_better_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477133",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JacksorBetter10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JacksorBetter10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jacks_or_better_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3475",
        "GSubSort": "3475",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "7ac7a443-d115-4707-9e96-b4093df6cdd1",
        "MobileExternalCode": "7ac7a443-d115-4707-9e96-b4093df6cdd1",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477133",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jacks_or_better_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477130",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JacksorBetter5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JacksorBetter5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jacks_or_better_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3474",
        "GSubSort": "3474",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "684e8691-6edd-42cd-89d3-96a00e83b205",
        "MobileExternalCode": "684e8691-6edd-42cd-89d3-96a00e83b205",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477130",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jacks_or_better_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477118",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleDoubleBonusPoker10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleDoubleBonusPoker10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Double Bonus Poker 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_double_bonus_poker_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3473",
        "GSubSort": "3473",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "a78deda7-9976-4b64-bd81-2ecfc6b2d759",
        "MobileExternalCode": "a78deda7-9976-4b64-bd81-2ecfc6b2d759",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477118",
        "RTP": "98.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_double_bonus_poker_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477121",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleDoubleBonusPoker50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleDoubleBonusPoker50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Double Bonus Poker 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_double_bonus_poker_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3472",
        "GSubSort": "3472",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "cb0f6b81-a1e9-4fbe-b044-fbe1f7638f15",
        "MobileExternalCode": "cb0f6b81-a1e9-4fbe-b044-fbe1f7638f15",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477121",
        "RTP": "98.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_double_bonus_poker_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477139",
        "System": "976",
        "SubSystem": null,
        "PageCode": "JacksorBetter100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "JacksorBetter100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/jacks_or_better_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3471",
        "GSubSort": "3471",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "9f7d5286-0a29-4a24-b3ad-7ddbc512bc3e",
        "MobileExternalCode": "9f7d5286-0a29-4a24-b3ad-7ddbc512bc3e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477139",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/jacks_or_better_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477100",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleBonusPoker5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleBonusPoker5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Bonus Poker 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_bonus_poker_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3470",
        "GSubSort": "3470",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "7119c7d2-6630-4360-9a34-bbee3d1c8141",
        "MobileExternalCode": "7119c7d2-6630-4360-9a34-bbee3d1c8141",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477100",
        "RTP": "99.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_bonus_poker_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477103",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleBonusPoker10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleBonusPoker10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Bonus Poker 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_bonus_poker_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3469",
        "GSubSort": "3469",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "011b6c1c-6171-481e-8180-5ef10353fed8",
        "MobileExternalCode": "011b6c1c-6171-481e-8180-5ef10353fed8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477103",
        "RTP": "99.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_bonus_poker_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477106",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleBonusPoker50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleBonusPoker50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Bonus Poker 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_bonus_poker_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3468",
        "GSubSort": "3468",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "6ae67a63-8185-49d7-97a3-794c98bf9635",
        "MobileExternalCode": "6ae67a63-8185-49d7-97a3-794c98bf9635",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477106",
        "RTP": "99.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_bonus_poker_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477109",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleBonusPoker100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleBonusPoker100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Bonus Poker 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_bonus_poker_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3467",
        "GSubSort": "3467",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "9a2fa7d1-aeea-43e2-a747-bfbd88d96d01",
        "MobileExternalCode": "9a2fa7d1-aeea-43e2-a747-bfbd88d96d01",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477109",
        "RTP": "99.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_bonus_poker_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477115",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DoubleDoubleBonusPoker5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DoubleDoubleBonusPoker5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Double Double Bonus Poker 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/double_double_bonus_poker_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3466",
        "GSubSort": "3466",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "3d101e54-a0b8-4cca-ae14-570204704ac7",
        "MobileExternalCode": "3d101e54-a0b8-4cca-ae14-570204704ac7",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477115",
        "RTP": "98.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/double_double_bonus_poker_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477094",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DuecesWild100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DuecesWild100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/deuces_wild_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3457",
        "GSubSort": "3457",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "b92beb68-d1a1-46c6-9124-043fa924e921",
        "MobileExternalCode": "b92beb68-d1a1-46c6-9124-043fa924e921",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477094",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/deuces_wild_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477073",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusPoker5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusPoker5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Poker 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_poker_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3456",
        "GSubSort": "3456",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "7c1a3016-c97d-421c-8104-6496103d9164",
        "MobileExternalCode": "7c1a3016-c97d-421c-8104-6496103d9164",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477073",
        "RTP": "99.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_poker_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477081",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusPoker100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusPoker100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Poker 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_poker_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3456",
        "GSubSort": "3456",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "90f876be-f71a-4d4a-9ce0-579b185a3d5b",
        "MobileExternalCode": "90f876be-f71a-4d4a-9ce0-579b185a3d5b",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477081",
        "RTP": "99.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_poker_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477085",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DuecesWild5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DuecesWild5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/deuces_wild_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3456",
        "GSubSort": "3456",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "6206d348-4cc5-4f61-9f4c-61db19dcd283",
        "MobileExternalCode": "6206d348-4cc5-4f61-9f4c-61db19dcd283",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477085",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/deuces_wild_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477088",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DuecesWild10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DuecesWild10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/deuces_wild_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3456",
        "GSubSort": "3456",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "73a4c4f6-29cf-486d-b6eb-cb30a1c36ac4",
        "MobileExternalCode": "73a4c4f6-29cf-486d-b6eb-cb30a1c36ac4",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477088",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/deuces_wild_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477091",
        "System": "976",
        "SubSystem": null,
        "PageCode": "DuecesWild50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "DuecesWild50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Deuces Wild 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/deuces_wild_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3456",
        "GSubSort": "3456",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "d4b57655-dc94-4177-9861-4bf01aa57057",
        "MobileExternalCode": "d4b57655-dc94-4177-9861-4bf01aa57057",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477091",
        "RTP": "96.7700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/deuces_wild_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477049",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AllAmericanPoker50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AllAmericanPoker50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All American Poker 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_american_poker_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3455",
        "GSubSort": "3455",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "9d8b1934-15a9-4d2e-99a3-39bbbab97787",
        "MobileExternalCode": "9d8b1934-15a9-4d2e-99a3-39bbbab97787",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477049",
        "RTP": "0.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_american_poker_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477077",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusPoker10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusPoker10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Poker 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_poker_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3449",
        "GSubSort": "3449",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "cd38b18d-c4ce-49f7-808c-36ec2b7c5e61",
        "MobileExternalCode": "cd38b18d-c4ce-49f7-808c-36ec2b7c5e61",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477077",
        "RTP": "99.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_poker_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477064",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusDuecesWild50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusDuecesWild50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Deuces Wild 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_deuces_wild_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3445",
        "GSubSort": "3445",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "ebbeda53-788f-4c92-86ad-db6af82bf843",
        "MobileExternalCode": "ebbeda53-788f-4c92-86ad-db6af82bf843",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477064",
        "RTP": "99.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_deuces_wild_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477067",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusDuecesWild100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusDuecesWild100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Deuces Wild 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_deuces_wild_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3445",
        "GSubSort": "3445",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "ea84bc2e-5285-4332-ae53-a7cb4d93386c",
        "MobileExternalCode": "ea84bc2e-5285-4332-ae53-a7cb4d93386c",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477067",
        "RTP": "99.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_deuces_wild_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477058",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusDuecesWild5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusDuecesWild5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Deuces Wild 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_deuces_wild_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3444",
        "GSubSort": "3444",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "6392a2f7-7669-4eee-a9cd-444f062d9229",
        "MobileExternalCode": "6392a2f7-7669-4eee-a9cd-444f062d9229",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477058",
        "RTP": "99.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_deuces_wild_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477061",
        "System": "976",
        "SubSystem": null,
        "PageCode": "BonusDuecesWild10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "BonusDuecesWild10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Bonus Deuces Wild 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/bonus_deuces_wild_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3443",
        "GSubSort": "3443",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "270a5cf7-8239-4ee1-9757-5ce378af202a",
        "MobileExternalCode": "270a5cf7-8239-4ee1-9757-5ce378af202a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "5000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477061",
        "RTP": "99.1500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/bonus_deuces_wild_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477052",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AllAmericanPoker100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AllAmericanPoker100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All American Poker 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_american_poker_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3441",
        "GSubSort": "3441",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "b860ab9c-8ecd-4ca5-9f92-fcc02922f0e8",
        "MobileExternalCode": "b860ab9c-8ecd-4ca5-9f92-fcc02922f0e8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477052",
        "RTP": "99.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_american_poker_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477043",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AllAmericanPoker5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AllAmericanPoker5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All American Poker 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_american_poker_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3440",
        "GSubSort": "3440",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "fa6a7b5c-2175-4707-8d38-53147750af92",
        "MobileExternalCode": "fa6a7b5c-2175-4707-8d38-53147750af92",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477043",
        "RTP": "99.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_american_poker_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477046",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AllAmericanPoker10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AllAmericanPoker10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "All American Poker 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/all_american_poker_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3440",
        "GSubSort": "3440",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "c6bf203e-454c-4980-a9c2-86ce865738ff",
        "MobileExternalCode": "c6bf203e-454c-4980-a9c2-86ce865738ff",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477046",
        "RTP": "99.3800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/all_american_poker_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477169",
        "System": "976",
        "SubSystem": null,
        "PageCode": "TensorBetter100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "TensorBetter100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tens Or Better 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/tens_or_better_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3439",
        "GSubSort": "3439",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "4dc4404f-b927-488f-a614-81b19f0e2b73",
        "MobileExternalCode": "4dc4404f-b927-488f-a614-81b19f0e2b73",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477169",
        "RTP": "99.1400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/tens_or_better_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477037",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AcesandEights100Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AcesandEights100Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aces & Eights 100 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/aces_end_eights_100_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3435",
        "GSubSort": "3435",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "79f0add0-db16-4626-be6e-cade4c76f07f",
        "MobileExternalCode": "79f0add0-db16-4626-be6e-cade4c76f07f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "0.20",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477037",
        "RTP": "99.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/aces_end_eights_100_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477031",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AcesandEights10Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AcesandEights10Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aces & Eights 10 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/aces_end_eights_10_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3434",
        "GSubSort": "3434",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "c8085c5b-5ece-44c6-b97c-0f1914c8a1fa",
        "MobileExternalCode": "c8085c5b-5ece-44c6-b97c-0f1914c8a1fa",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "2.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477031",
        "RTP": "99.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/aces_end_eights_10_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477034",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AcesandEights50Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AcesandEights50Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aces & Eights 50 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/aces_end_eights_50_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3433",
        "GSubSort": "3433",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "2a5ad7a7-40ff-4cd6-8224-688bb69f28c8",
        "MobileExternalCode": "2a5ad7a7-40ff-4cd6-8224-688bb69f28c8",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.02",
        "MaxBetDefault": "0.50",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477034",
        "RTP": "99.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/aces_end_eights_50_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1477028",
        "System": "976",
        "SubSystem": null,
        "PageCode": "AcesandEights5Hand",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "AcesandEights5Hand",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aces & Eights 5 Hand"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/aces_end_eights_5_hand.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "3432",
        "GSubSort": "3432",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": "01b5c34b-9aab-4570-b9da-e3f2eb119699",
        "MobileExternalCode": "01b5c34b-9aab-4570-b9da-e3f2eb119699",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "5.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1477028",
        "RTP": "99.0900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/aces_end_eights_5_hand.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476977",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGTheDragonCastle",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGTheDragonCastle",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon Castle"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/the_dragon_castle.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3396",
        "GSubSort": "3396",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "fab57c0a-e47d-458d-a6da-7d847121bc97",
        "MobileExternalCode": "fab57c0a-e47d-458d-a6da-7d847121bc97",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "54192.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476977",
        "RTP": "96.1300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/the_dragon_castle.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476902",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGIndianCashCatcher",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGIndianCashCatcher",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Indian Cash Catcher"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/indian_cash_catcher.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3372",
        "GSubSort": "3372",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "6a30f420-13fe-45de-b122-950aa0dc08f7",
        "MobileExternalCode": "6a30f420-13fe-45de-b122-950aa0dc08f7",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "48235.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476902",
        "RTP": "95.8700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/indian_cash_catcher.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476890",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGFrontierFortunes",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGFrontierFortunes",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Frontier Fortunes"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/frontier_fortunes.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3369",
        "GSubSort": "3369",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "95da1264-d7b4-4f82-b4d5-22803fa8626e",
        "MobileExternalCode": "95da1264-d7b4-4f82-b4d5-22803fa8626e",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "22918.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476890",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/frontier_fortunes.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476839",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGKanesInferno",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGKanesInferno",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Kane's Inferno"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/kanes_inferno.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3354",
        "GSubSort": "3354",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "a35573c5-f794-4bd8-9b2a-c145a7dbd09a",
        "MobileExternalCode": "a35573c5-f794-4bd8-9b2a-c145a7dbd09a",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476839",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/kanes_inferno.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476830",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGWickedWitch",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGWickedWitch",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Wicked Witch"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/wicked_witch.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3352",
        "GSubSort": "3352",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "a47725be-bc11-4b22-a8fa-591dca73560f",
        "MobileExternalCode": "a47725be-bc11-4b22-a8fa-591dca73560f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "26570.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476830",
        "RTP": "95.9800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/wicked_witch.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476818",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGRuffledUp",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGRuffledUp",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ruffled Up"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/ruffled_up.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3351",
        "GSubSort": "3351",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "bf9c45ce-5d0e-452d-b2cd-332a3c91d71f",
        "MobileExternalCode": "bf9c45ce-5d0e-452d-b2cd-332a3c91d71f",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "54000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476818",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/ruffled_up.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476809",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGDragonsThrone",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGDragonsThrone",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dragon's Throne"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/dragons_throne.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3349",
        "GSubSort": "3349",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "125eda8d-1f5c-4f3a-8cba-f67d7905de97",
        "MobileExternalCode": "125eda8d-1f5c-4f3a-8cba-f67d7905de97",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "25000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476809",
        "RTP": "96.0400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/dragons_throne.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476806",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGArcaneElements",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGArcaneElements",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Arcane Elements"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/arcane_elements.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3348",
        "GSubSort": "3348",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "6b349d0e-49dc-4e56-b4d4-823c366e0722",
        "MobileExternalCode": "6b349d0e-49dc-4e56-b4d4-823c366e0722",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "30400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476806",
        "RTP": "96.0600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/arcane_elements.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476800",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGSuperTwister",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGSuperTwister",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Super Twister"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/super_twister.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3347",
        "GSubSort": "3347",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "5c3f6f7c-f2ca-4188-a7ff-435a9b304ab2",
        "MobileExternalCode": "5c3f6f7c-f2ca-4188-a7ff-435a9b304ab2",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "38000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476800",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/super_twister.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1476641",
        "System": "976",
        "SubSystem": null,
        "PageCode": "SGOceansCall",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "SGOceansCall",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ocean's Call"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/habanero/oceans_call.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "3280",
        "GSubSort": "3280",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": "e34cb7b4-d7a9-411e-b744-08d827c9e8c5",
        "MobileExternalCode": "e34cb7b4-d7a9-411e-b744-08d827c9e8c5",
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Habanero",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "24000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1476641",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/habanero/oceans_call.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "50"
    },
    {
        "ID": "1489395",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10madame",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10madame",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Madame Destiny"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/madame_destiny.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "872",
        "GSubSort": "872",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "1500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1489395",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/madame_destiny.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483779",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25kingdoms",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25kingdoms",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "3 Kingdoms - Battle of Red Cliffs"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25kingdoms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "690",
        "GSubSort": "690",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "200.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483779",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25kingdoms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1484709",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25champ",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25champ",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "The Champions"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/the_champions.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "688",
        "GSubSort": "688",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1364.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1484709",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/the_champions.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1496856",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20leprechaun",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20leprechaun",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun Song"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20leprechaun.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "687",
        "GSubSort": "687",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "757.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1496856",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20leprechaun.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1512649",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs10egyptcls",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs10egyptcls",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Ancient Egypt Classic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/ancient_egypt_classic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "686",
        "GSubSort": "686",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "5015.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1512649",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/ancient_egypt_classic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1514368",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5trdragons",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5trdragons",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Triple Dragons"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs5trdragons.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "685",
        "GSubSort": "685",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1514368",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs5trdragons.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1489476",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243lions",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243lions",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "5 Lions"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243lions.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "684",
        "GSubSort": "684",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3520.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1489476",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243lions.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1516948",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20leprexmas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20leprexmas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Leprechaun Carol"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/leprechaun_carol.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "371",
        "GSubSort": "371",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16",
            "4036",
            "4096"
        ],
        "SortPerCategory": {
            "16": 0,
            "4036": 0,
            "4096": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "6000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1516948",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/leprechaun_carol.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483845",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50kingkong",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50kingkong",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Mighty Kong"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50kingkong.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "164",
        "GSubSort": "164",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "955.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483845",
        "RTP": "94.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50kingkong.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483893",
        "System": "960",
        "SubSystem": null,
        "PageCode": "cs3w",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "cs3w",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Diamonds are Forever 3 Lines"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cs3w.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "100",
        "GSubSort": "100",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.03",
        "MaxBetDefault": "15.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483893",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cs3w.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1489986",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024butterfly",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024butterfly",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jade Butterfly"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs1024butterfly.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "99",
        "GSubSort": "99",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "30.00",
        "MaxMultiplier": "636.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1489986",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs1024butterfly.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483794",
        "System": "960",
        "SubSystem": null,
        "PageCode": "cs5triple8gold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "cs5triple8gold",
        "MobileAndroidPageCode": "cs5triple8gold",
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "888 Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cs5triple8gold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "95",
        "GSubSort": "95",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "25.00",
        "MaxMultiplier": "2001.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483794",
        "RTP": "97.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cs5triple8gold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483785",
        "System": "960",
        "SubSystem": null,
        "PageCode": "cs3irishcharms",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "cs3irishcharms",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Irish Charms"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/cs3irishcharms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "94",
        "GSubSort": "94",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.03",
        "MaxBetDefault": "15.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483785",
        "RTP": "96.9600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/cs3irishcharms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483857",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50amt",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50amt",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aladdin's Treasure"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50amt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "93",
        "GSubSort": "93",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "745.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483857",
        "RTP": "96.5800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50amt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483860",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs13ladyofmoon",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs13ladyofmoon",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lady of the Moon"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs13ladyofmoon.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "92",
        "GSubSort": "92",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.13",
        "MaxBetDefault": "195.00",
        "MaxMultiplier": "1101.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483860",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs13ladyofmoon.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483872",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20egypt",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20egypt",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Tales of Egypt"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20egypt.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "91",
        "GSubSort": "91",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483872",
        "RTP": "91.9300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20egypt.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483875",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25dwarves",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25dwarves",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dwarven Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25dwarves.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "90",
        "GSubSort": "90",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483875",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25dwarves.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483890",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25sea",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25sea",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Great Reef"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25sea.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "87",
        "GSubSort": "87",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "3000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483890",
        "RTP": "96.6400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25sea.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1509061",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20vegasmagic",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20vegasmagic",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Vegas Magic"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20vegasmagic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "86",
        "GSubSort": "86",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3549.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1509061",
        "RTP": "96.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20vegasmagic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483863",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs7monkeys",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs7monkeys",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "7 Monkeys"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs7monkeys.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "85",
        "GSubSort": "85",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.07",
        "MaxBetDefault": "35.00",
        "MaxMultiplier": "4500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483863",
        "RTP": "92.7100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs7monkeys.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1487352",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs9madmonkey",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs9madmonkey",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Monkey Madness"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs9madmonkey.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "83",
        "GSubSort": "83",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.09",
        "MaxBetDefault": "90.00",
        "MaxMultiplier": "181.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1487352",
        "RTP": "96.5300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs9madmonkey.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483878",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20rome",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20rome",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Glorious Rome"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20rome.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "82",
        "GSubSort": "82",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "200.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483878",
        "RTP": "94.1100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20rome.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483776",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20eightdragons",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20eightdragons",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "8 Dragons"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20eightdragons.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "53",
        "GSubSort": "53",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "7500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483776",
        "RTP": "96.3700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20eightdragons.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483770",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs3train",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs3train",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Gold Train"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs3train.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "52",
        "GSubSort": "52",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.03",
        "MaxBetDefault": "15.00",
        "MaxMultiplier": "513.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483770",
        "RTP": "97.1600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs3train.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483767",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs4096jurassic",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs4096jurassic",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jurassic Giants"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs4096jurassic.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "51",
        "GSubSort": "51",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "15552.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483767",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs4096jurassic.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483761",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243fortune",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243fortune",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Caishen's Gold"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243fortune.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "50",
        "GSubSort": "50",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.38",
        "MaxBetDefault": "190.00",
        "MaxMultiplier": "1378.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483761",
        "RTP": "97.0800",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243fortune.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483716",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs15fairytale",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs15fairytale",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fairytale Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs15fairytale.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "45",
        "GSubSort": "45",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.15",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483716",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs15fairytale.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483737",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1tigers",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1tigers",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Triple Tigers"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs1tigers.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "45",
        "GSubSort": "45",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483737",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs1tigers.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483743",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20santa",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20santa",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Santa"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20santa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "45",
        "GSubSort": "45",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "451.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483743",
        "RTP": "95.9200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20santa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483842",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs20godiva",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs20godiva",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lady Godiva"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs20godiva.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "45",
        "GSubSort": "45",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.20",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "2400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483842",
        "RTP": "94.0500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs20godiva.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483788",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1024atlantis",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1024atlantis",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Queen of Atlantis"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs1024atlantis.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "43",
        "GSubSort": "43",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "75.00",
        "MaxMultiplier": "4608.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483788",
        "RTP": "96.4100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs1024atlantis.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483782",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25pantherqueen",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25pantherqueen",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Panther Queen"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25pantherqueen.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "42",
        "GSubSort": "42",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "4000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483782",
        "RTP": "96.5000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25pantherqueen.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483824",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25dwarves_new",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25dwarves_new",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Dwarven Gold Deluxe"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25dwarves_new.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "41",
        "GSubSort": "41",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "1000.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483824",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25dwarves_new.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483755",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25vegas",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25vegas",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Vegas Nights"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25vegas.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "40",
        "GSubSort": "40",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "3558.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483755",
        "RTP": "96.0300",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25vegas.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483764",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50pixie",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50pixie",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Pixie Wings"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50pixie.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "38",
        "GSubSort": "38",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483764",
        "RTP": "96.5100",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50pixie.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483902",
        "System": "960",
        "SubSystem": null,
        "PageCode": null,
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vpa",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Jacks or Better"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vpa.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "GSort": "37",
        "GSubSort": "37",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "10",
            "1368"
        ],
        "SortPerCategory": {
            "10": 0,
            "1368": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "5:4",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.40",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483902",
        "RTP": "99.5400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vpa.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483818",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50chinesecharms",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50chinesecharms",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky Dragons"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50chinesecharms.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "33",
        "GSubSort": "33",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "250.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483818",
        "RTP": "95.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50chinesecharms.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483734",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25newyear",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25newyear",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Lucky New Year"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25newyear.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "32",
        "GSubSort": "32",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "2500.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483734",
        "RTP": "96.0000",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25newyear.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483812",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25journey",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25journey",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Journey to the West"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25journey.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "30",
        "GSubSort": "30",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.10",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "400.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483812",
        "RTP": "96.4700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25journey.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483800",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs50hercules",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs50hercules",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Hercules Son of Zeus"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs50hercules.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "29",
        "GSubSort": "29",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.50",
        "MaxBetDefault": "50.00",
        "MaxMultiplier": "800.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483800",
        "RTP": "95.1900",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs50hercules.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483773",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs1dragon8",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs1dragon8",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "888 Dragons"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs1dragon8.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "28",
        "GSubSort": "28",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.01",
        "MaxBetDefault": "130.00",
        "MaxMultiplier": "100.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483773",
        "RTP": "96.8400",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs1dragon8.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483851",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs243crystalcave",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs243crystalcave",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Magic Crystals"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs243crystalcave.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "18",
        "GSubSort": "18",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.30",
        "MaxBetDefault": "240.00",
        "MaxMultiplier": "459.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483851",
        "RTP": "96.3500",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs243crystalcave.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 0,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483728",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs5aztecgems",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs5aztecgems",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Aztec Gems"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs5aztecgems.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "14",
        "GSubSort": "14",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "16"
        ],
        "SortPerCategory": {
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.05",
        "MaxBetDefault": "100.00",
        "MaxMultiplier": "375.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483728",
        "RTP": "96.5200",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs5aztecgems.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483719",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs7fire88",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs7fire88",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Fire 88"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs7fire88.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "8",
        "GSubSort": "8",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.07",
        "MaxBetDefault": "35.00",
        "MaxMultiplier": "902.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483719",
        "RTP": "96.4600",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs7fire88.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    },
    {
        "ID": "1483746",
        "System": "960",
        "SubSystem": null,
        "PageCode": "vs25pandagold",
        "LowRtpUrl": null,
        "LowRtpMobileUrl": null,
        "LowRtpUrlExternal": null,
        "LowRtpMobileUrlExternal": null,
        "MobilePageCode": "vs25pandagold",
        "MobileAndroidPageCode": null,
        "MobileWindowsPageCode": null,
        "Trans": {
            "en": "Panda's Fortune"
        },
        "Description": {},
        "CustomSortType": "Lang",
        "ImageURL": "/gstatic/games/pragmaticplay/vs25pandagold.jpg",
        "Branded": "0",
        "SuperBranded": "0",
        "HasDemo": "1",
        "Freeround": "1",
        "GSort": "7",
        "GSubSort": "7",
        "Status": "1",
        "GameStatus": "1",
        "Categories": [
            "13",
            "16"
        ],
        "SortPerCategory": {
            "13": 0,
            "16": 0
        },
        "ExternalCode": null,
        "MobileExternalCode": null,
        "AR": "16:9",
        "IDMerchantCountryRestriction": "0",
        "MerchantName": "Pragmatic Play",
        "MinBetDefault": "0.25",
        "MaxBetDefault": "125.00",
        "MaxMultiplier": "7272.00",
        "SubMerchantName": null,
        "IsVirtual": "0",
        "WorkingHours": null,
        "TableID": "1483746",
        "RTP": "96.1700",
        "BrandedNew": "0",
        "ImageFullPath": "https://agstatic.com/games/pragmaticplay/vs25pandagold.jpg",
        "CustomSort": [],
        "BonusBuy": 0,
        "Megaways": 0,
        "Freespins": 1,
        "IDCountryRestriction": "181"
    }
]